import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import * as fromRouter from '@ngrx/router-store';
import { environment } from 'projects/medtoday/src/environments/environment';
import { localStorageSyncReducer } from './local-storage-sync.reducer';
import { logoutResetReducer } from './logout-reset.reducer';
import { RouterStateUrl } from '../../router/reducers/custom-router-state-serializer';

export interface BaseAppState {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<BaseAppState> = {
  router: fromRouter.routerReducer,
};

export const metaReducers: Array<MetaReducer<BaseAppState>> = !environment.production
  ? [logoutResetReducer, storeFreeze, localStorageSyncReducer]
  : [logoutResetReducer, localStorageSyncReducer];
