import { Action } from '@ngrx/store';
import {
  OnDemandVideoDeeplink,
  CongressCmePageDataResponse,
  CongressParticipantResponseModel,
  ChannelDeeplink,
  OnDemandVideoUserData,
  CmeCertificatesResponse
} from 'medtoday-models-library';

import { CongressParticipantRequestModel } from 'medtoday-models-library/lib/models';
import { AsyncAction } from 'projects/todaylib/core/async-state/models/async-action.model';

export enum MedtodayStoreActionTypes {
  SendCMESignal = '[Data] Send timepoints to Backend',
  SendCMESignalSuccess = '[Data] Send timepoints to Backend Success',
  SendCMESignalFail = '[Data] Send timepoints to Backend Fail',

  LoadCongressCmePageData = '[Data] Load Congress Cme Page Data',
  LoadCongressCmePageDataSuccess = '[Data] Load Congress Cme Page Success',
  LoadCongressCmePageDataFail = '[Data] Load Congress Cme Page Fail',

  PushUserIdToGTM = '[Data] Push user id to Google Tag Manager',

  LoadTokenizedOnDemandVideo = '[Data] Load Tokenized Video On Demand',
  LoadTokenizedOnDemandVideoSuccess = '[Data] Load Tokenized Video On Demand Success',
  LoadTokenizedOnDemandVideoFail = '[Data] Load Tokenized Video On Demand Fail',

  LoadCongressParticipantData = '[Data] Load Congress Participant Data',
  LoadCongressParticipantDataSuccess = '[Data] Load Congress Participant Data Success',
  LoadCongressParticipantDataFail = '[Data] Load Congress Participant Data Fail',

  AddCongressParticipant = '[Data] Add Congress Participant Data',
  AddCongressParticipantSuccess = '[Data] Add Congress Participant Success',
  AddCongressParticipantFail = '[Data] Add Congress Participant Fail',

  StoreCongressTransaction = '[Data] Store Congress Transaction Data',
  StoreCongressTransactionSuccess = '[Data] Store Congress Transaction Data Success',
  StoreCongressTransactionFail = '[Data] Store Congress Transaction Data Fail',

  LoadTokenizedChannel = '[Data] Load Tokenized Channel',
  LoadTokenizedChannelSuccess = '[Data] Load Tokenized Channel Success',
  LoadTokenizedChannelFail = '[Data] Load Tokenized Channel Fail',

  SetVideoAsWatched = '[Data] Set Video as watched',
  SetVideoAsWatchedSuccess = '[Data] Set Video as watched Success',
  SetVideoAsWatchedFail = '[Data] Set Video as watched Fail',

  LoadOnDemandVideoUserData = '[Data] Load vod user data',
  LoadOnDemandVideoUserDataSuccess = '[Data] Load vod user data success',
  LoadOnDemandVideoUserDataFail = '[Data] Load vod user data fail',

  UpdateVideoUserPosition = '[Data] Update  video user position',
  UpdateVideoUserPositionSuccess = '[Data] Update  video user position success',
  UpdateVideoUserPositionFail = '[Data] Update  video user position fail',

  LoadCmeCertificates = '[Data] Load user cme certificates',
  LoadCmeCertificatesSuccess = '[Data] Load user cme certificates success',
  LoadCmeCertificatesFail = '[Data] Load user cme certificates fail',
  PurgeUserCmeCertificates = '[Data] Purge cme certificates',

  OpenCmeTrackingPromptModal = '[Modals] Open cme tracking prompt modal',
  CloseCmeTrackingPromptModal = '[Modals] Close cme tracking prompt modal'
}

export class LoadTokenizedOnDemandVideo implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadTokenizedOnDemandVideo;
  readonly asyncState = 'start';
  readonly type = MedtodayStoreActionTypes.LoadTokenizedOnDemandVideo;

  constructor(public congressSlug: string, public id: number, public token: string) {}
}

export class LoadTokenizedOnDemandVideoSuccess implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadTokenizedOnDemandVideo;
  readonly type = MedtodayStoreActionTypes.LoadTokenizedOnDemandVideoSuccess;
  readonly asyncState = 'success';

  constructor(public response: OnDemandVideoDeeplink) {}
}

export class LoadTokenizedOnDemandVideoFail implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadTokenizedOnDemandVideo;
  readonly type = MedtodayStoreActionTypes.LoadTokenizedOnDemandVideoFail;
  readonly asyncState = 'fail';
}

export class SendCMESignal implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.SendCMESignal;
  readonly asyncState = 'start';
  readonly type = MedtodayStoreActionTypes.SendCMESignal;

  constructor(public congressSlug: string, public timepoints: string[]) {}
}

export class SendCMESignalSuccess implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.SendCMESignal;
  readonly type = MedtodayStoreActionTypes.SendCMESignalSuccess;
  readonly asyncState = 'success';
}

export class SendCMESignalFail implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.SendCMESignal;
  readonly type = MedtodayStoreActionTypes.SendCMESignalFail;
  readonly asyncState = 'fail';
}

export class PushUserIdToGTM implements Action {
  readonly type = MedtodayStoreActionTypes.PushUserIdToGTM;

  constructor(public userId: string) {}
}

export class LoadCongressCmePageData implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadCongressCmePageData;
  readonly asyncState = 'start';
  readonly type = MedtodayStoreActionTypes.LoadCongressCmePageData;

  constructor(public congressSlug: string) {}
}

export class LoadCongressCmePageDataSuccess implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadCongressCmePageData;
  readonly type = MedtodayStoreActionTypes.LoadCongressCmePageDataSuccess;
  readonly asyncState = 'success';

  constructor(public response: CongressCmePageDataResponse) {}
}

export class LoadCongressCmePageDataFail implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadCongressCmePageData;
  readonly type = MedtodayStoreActionTypes.LoadCongressCmePageDataFail;
  readonly asyncState = 'fail';
}
export class LoadCongressParticipantData implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadCongressParticipantData;
  readonly asyncState = 'start';
  readonly type = MedtodayStoreActionTypes.LoadCongressParticipantData;

  constructor(public congressSlug: string, public userSubId: string) {}
}

export class LoadCongressParticipantDataSuccess implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadCongressParticipantData;
  readonly type = MedtodayStoreActionTypes.LoadCongressParticipantDataSuccess;
  readonly asyncState = 'success';

  constructor(public response: CongressParticipantResponseModel) {}
}

export class LoadCongressParticipantDataFail implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadCongressParticipantData;
  readonly type = MedtodayStoreActionTypes.LoadCongressParticipantDataFail;
  readonly asyncState = 'fail';
}
export class AddCongressParticipant implements AsyncAction {
  readonly type = MedtodayStoreActionTypes.AddCongressParticipant;
  readonly asyncKey = MedtodayStoreActionTypes.AddCongressParticipant;
  readonly asyncState = 'start';

  constructor(public congressSlug: string, public request: CongressParticipantRequestModel) {}
}
export class AddCongressParticipantSuccess implements AsyncAction {
  readonly type = MedtodayStoreActionTypes.AddCongressParticipantSuccess;
  readonly asyncKey = MedtodayStoreActionTypes.AddCongressParticipant;
  readonly asyncState = 'success';

  constructor(public congressSlug: string, public request: CongressParticipantRequestModel) {}
}
export class AddCongressParticipantFail implements AsyncAction {
  readonly type = MedtodayStoreActionTypes.AddCongressParticipantFail;
  readonly asyncKey = MedtodayStoreActionTypes.AddCongressParticipant;
  readonly asyncState = 'fail';
}
export class StoreCongressTransactionSuccess implements Action {
  readonly type = MedtodayStoreActionTypes.StoreCongressTransactionSuccess;
  readonly asyncKey = MedtodayStoreActionTypes.StoreCongressTransaction;
  readonly asyncState = 'success';

  constructor(public response: CongressParticipantResponseModel) {}
}

export class LoadTokenizedChannel implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadTokenizedChannel;
  readonly type = MedtodayStoreActionTypes.LoadTokenizedChannel;
  readonly asyncState = 'success';

  constructor(public congressSlug: string, public channelSlug: string, public token: string) {}
}

export class LoadTokenizedChannelSuccess implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadTokenizedChannel;
  readonly type = MedtodayStoreActionTypes.LoadTokenizedChannelSuccess;
  readonly asyncState = 'success';

  constructor(public response: ChannelDeeplink) {}
}

export class LoadTokenizedChannelFail implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadTokenizedChannel;
  readonly type = MedtodayStoreActionTypes.LoadTokenizedChannelFail;
  readonly asyncState = 'fail';
}

export class SetVideoAsWatched implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.SetVideoAsWatched;
  readonly type = MedtodayStoreActionTypes.SetVideoAsWatched;
  readonly asyncState = 'start';

  constructor(public congressSlug: string, public vodId: number) {}
}

export class SetVideoAsWatchedSuccess implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.SetVideoAsWatched;
  readonly type = MedtodayStoreActionTypes.SetVideoAsWatchedSuccess;
  readonly asyncState = 'success';

  constructor(public userData: OnDemandVideoUserData) {}
}

export class SetVideoAsWatchedFail implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.SetVideoAsWatched;
  readonly type = MedtodayStoreActionTypes.SetVideoAsWatchedFail;
  readonly asyncState = 'fail';
}

export class LoadOnDemandVideoUserData implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadOnDemandVideoUserData;
  readonly type = MedtodayStoreActionTypes.LoadOnDemandVideoUserData;
  readonly asyncState = 'start';

  constructor(public congressSlug: string, public vodId: number) {}
}

export class LoadOnDemandVideoUserDataSuccess implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadOnDemandVideoUserData;
  readonly type = MedtodayStoreActionTypes.LoadOnDemandVideoUserDataSuccess;
  readonly asyncState = 'success';

  constructor(public userData: OnDemandVideoUserData) {}
}

export class LoadOnDemandVideoUserDataFail implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadOnDemandVideoUserData;
  readonly type = MedtodayStoreActionTypes.LoadOnDemandVideoUserDataFail;
  readonly asyncState = 'fail';
}

export class UpdateVideoUserPosition implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.UpdateVideoUserPosition;
  readonly type = MedtodayStoreActionTypes.UpdateVideoUserPosition;
  readonly asyncState = 'start';

  constructor(public congressSlug: string, public vodId: number, public position: number) {}
}

export class UpdateVideoUserPositionSuccess implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.UpdateVideoUserPosition;
  readonly type = MedtodayStoreActionTypes.UpdateVideoUserPositionSuccess;
  readonly asyncState = 'success';

  constructor() {}
}

export class UpdateVideoUserPositionFail implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.UpdateVideoUserPosition;
  readonly type = MedtodayStoreActionTypes.UpdateVideoUserPositionFail;
  readonly asyncState = 'fail';
}

export class LoadCmeCertificates implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadCmeCertificates;
  readonly type = MedtodayStoreActionTypes.LoadCmeCertificates;
  readonly asyncState = 'start';

  constructor() {}
}

export class LoadCmeCertificatesSuccess implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadCmeCertificates;
  readonly type = MedtodayStoreActionTypes.LoadCmeCertificatesSuccess;
  readonly asyncState = 'success';

  constructor(public response: CmeCertificatesResponse) {}
}

export class LoadCmeCertificatesFail implements AsyncAction {
  readonly asyncKey = MedtodayStoreActionTypes.LoadCmeCertificates;
  readonly type = MedtodayStoreActionTypes.LoadCmeCertificatesFail;
  readonly asyncState = 'fail';
}

export class PurgeCmeCertificates implements Action {
  readonly type = MedtodayStoreActionTypes.PurgeUserCmeCertificates;
}

export class OpenCmeTrackingPromptModal implements Action {
  readonly type = MedtodayStoreActionTypes.OpenCmeTrackingPromptModal;
}

export class CloseCmeTrackingPromptModal implements Action {
  readonly type = MedtodayStoreActionTypes.CloseCmeTrackingPromptModal;
}

export type MedtodayStoreActions =
  | LoadTokenizedOnDemandVideoSuccess
  | LoadCongressCmePageDataSuccess
  | LoadCongressParticipantDataSuccess
  | AddCongressParticipantSuccess
  | StoreCongressTransactionSuccess
  | LoadTokenizedChannelSuccess
  | SetVideoAsWatchedSuccess
  | LoadOnDemandVideoUserDataSuccess
  | LoadCmeCertificatesSuccess
  | PurgeCmeCertificates
  | OpenCmeTrackingPromptModal
  | CloseCmeTrackingPromptModal;
