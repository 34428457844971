import { HttpBackend, HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { DynamicScriptLoaderService } from '../../services/dynamic-script-loader-service.service';
import { BehaviorSubject } from 'rxjs';
import { concatMap } from 'rxjs/operators';

// tslint:disable-next-line: no-any
declare var jwplayer: any;

export interface LiveStreamConfiguration {
  channelId: string;
  playlistId: string;
  playerId: string;
}

@Component({
  selector: 'app-live-stream',
  templateUrl: './live-stream.component.html',
  styleUrls: ['./live-stream.component.scss'],
})
export class LiveStreamComponent implements AfterViewInit, OnDestroy {

  private readonly pollingFrequencyInMs = 10 * 1000;
  private readonly livestreamCompleteError = 230001;
  private readonly maxRetries = 3;

  private configChange$ = new BehaviorSubject<{
    prev: LiveStreamConfiguration | null,
    current: LiveStreamConfiguration | null,
  }>({prev: null, current: null});

  private _config: LiveStreamConfiguration;
  public get config(): LiveStreamConfiguration {
    return this._config;
  }
  @Input() public set config(value: LiveStreamConfiguration) {
    const oldValue = this._config;
    this._config = value;
    this.configChange$.next({ prev: oldValue, current: this._config });
  }

  get channelStatusUrl(): string {
    return `https://cdn.jwplayer.com/live/channels/${this.config.channelId}.json`;
  }

  // tslint:disable-next-line: no-any
  playerInstance: any;
  currentEventId: string | null = null;
  intervalId: NodeJS.Timeout | null = null;
  httpClient: HttpClient;

  constructor(
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private handler: HttpBackend,
    private renderer: Renderer2,
    private element: ElementRef,
  ) {
    this.httpClient = new HttpClient(this.handler);
   }

  ngAfterViewInit(): void {
    this.configChange$.pipe(
      concatMap(({ prev, current }) => {
        return this.setupPlayer(prev, current);
      }),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.teardownPlayer(this.config);
  }

  private async setupPlayer(oldConfig: LiveStreamConfiguration | null, newConfig: LiveStreamConfiguration | null) {
    await this.teardownPlayer(oldConfig);

    if (!newConfig) {
      return;
    }

    // Create a unique id for the player DIV element so there's no interference
    // between multiple video players displayed on a page
    const playerElementId = `jwplayer_${newConfig.playerId}-${newConfig.playlistId}-${newConfig.channelId}`;

    // Create the DIV element for the player and add it to the DOM
    // to serve as a container for the video content
    const element = this.renderer.createElement('div');
    this.renderer.setProperty(element, 'id', playerElementId);
    this.renderer.appendChild(this.element.nativeElement, element);

    // Load the player script from the JW CDN and setup the player
    await this.dynamicScriptLoader.loadScript(this.getPlayerScriptUrlFromConfig(newConfig));
    this.playerInstance = jwplayer(playerElementId).setup(this.getPlayerConfiguration());
    this.playerInstance.on('playlistComplete', () => this.handleLivestreamFinished());
    this.playerInstance.on('error', error => this.handlePlayerError(error));

    // Check for the status of the live stream (in a loop)
    this.checkChannelStatus();
  }

  private async teardownPlayer(config: LiveStreamConfiguration | null) {
    if (!config) {
      return;
    }
    // Remove the jwplayer script from the DOM which has been loaded during the setup
    this.dynamicScriptLoader.removeScript(this.getPlayerScriptUrlFromConfig(config));

    // Clear the interval which checks for the live stream status
    if (!!this.intervalId) {
      clearInterval(this.intervalId!);
      this.intervalId = null;
    }

    // Destroy all child elements of the host as they are no longer needed
    for (const child of this.element.nativeElement.children) {
      this.renderer.removeChild(this.element.nativeElement, child);
    }
  }

  private getPlayerScriptUrlFromConfig(config: LiveStreamConfiguration) {
    return `https://cdn.jwplayer.com/libraries/${config.playerId}.js`;
  }

  private getPlayerConfiguration() {
    return {
      playlist: `https://cdn.jwplayer.com/v2/playlists/${this.config.playlistId}`,
      // Repeat the playlist indefinitely while we wait for the livestream to become available.
      repeat: true,
      autostart: true,
    };
  }

  private handleLivestreamFinished() {
    if (!!this.intervalId) {
      // We are already checking for a livestream.
      // In this state there should not be a reason to re-initialize the player -- it should already be in the correct
      // state.
      return;
    }
    // Enable looping of media.
    this.playerInstance.setConfig({ repeat: true });
    // Reload the VOD playlist.
    this.playerInstance.load(this.getPlayerConfiguration().playlist);
    if (this.config.channelId) {
      // Start checking for a new event.
      this.checkChannelStatus();
    }
    this.playerInstance.play();
  }

  // tslint:disable-next-line: no-any
  private handlePlayerError(error: any) {
    if (this.playerInstance.getPlaylistItem().mediaid !== this.currentEventId) {
      // Ignore errors during VOD playback.
      return;
    }
    if (error.code === this.livestreamCompleteError) {
      this.handleLivestreamFinished();
    }
  }

  /**
   * Periodically checks whether the specified livestream channel is available, and if it is, configures the player
   * to start playing it.
   */
  private checkChannelStatus() {
    if (!this.intervalId) {
      // Make sure to execute this method every x milliseconds.
      this.intervalId = setInterval(() => this.checkChannelStatus(), this.pollingFrequencyInMs);
    }

    if (!this.playerInstance || !this.config.channelId) {
      return;
    }

    this.getChannelStatus().then(channelStatus => {
      if (channelStatus.status === 'active') {
        // Determine the id of the active event based on the returned status.
        const eventId = channelStatus.current_event;

        // Check if we have seen this eventId before.
        if (this.currentEventId === eventId) {
          // The eventId returned by the API was not a new event id.
          // Ignore it and continue polling until we see a new id.
          return;
        }
        this.currentEventId = eventId;

        // Stop polling the channel status.
        clearInterval(this.intervalId!);
        this.intervalId = null;

        // Attempt to configure the player in order to start livestream playback.
        this.configurePlayer(eventId).catch((error) => {
          console.log(`Failed to start live event stream playback: ${error}`);
        });
      } else {
        this.currentEventId = null;
      }
    }, (error) => {
      console.log(`Unable to fetch the channel status for ${this.config.channelId}: ${error}`);
    });
  }

  /**
   * (Re-)configures the active playerInstance to play the livestream identified by eventId.
   */
  private async configurePlayer(eventId: string): Promise<void> {
    // There may be a slight delay between the livestream becoming available, and its playlist to become available.
    // Therefore, we first attempt to fetch the playlist for the new live event, as soon as we have successfully fetched
    // a playlist, we will load it on the player and start playback of the livestream.
    let playlist;
    let attempts = 0;

    while (!playlist) {
      try {
        playlist = await this.getPlaylist(eventId);
      } catch (e) {
        attempts++;
        console.error(e);
        if (attempts >= this.maxRetries) {
          // Manually set up the player if we were not able to retrieve the playlist after 3 retries
          playlist = {
            playlist: [{
              mediaid: eventId,
              file: `https://cdn.jwplayer.com/live/events/${eventId}.m3u8`,
            }],
          };
          break;
        }
        // Retry with exponential backoff, i.e. first retry after 5, 10, 20, 40, 80 seconds
        // after which we ultimately give up.
        await this.sleep(2 ** (attempts - 1) * 5 * 1000);
      }
    }

    // Once a playlist is available, use it to configure the player.
    this.playerInstance.setConfig({
      repeat: false,
    });
    this.playerInstance.load(playlist.playlist);
    // Start playback
    this.playerInstance.play();
    console.log(`Playing live event stream with id '${eventId}'.`);
  }

  /**
   * Fetches the current status of a Live Channel.
   * Returns a promise that will yield the status for a particular channel.
   *
   * @param channelId The channel to fetch the status for.
   */
  // tslint:disable-next-line: no-any
  private getChannelStatus(): Promise<any> {
    return this.httpClient.get(this.channelStatusUrl).toPromise();
  }

  /**
   * Fetches a JW Platform feed for a particular media item.
   *
   * @param mediaId The media id to fetch a single item playlist for.
   */
  // tslint:disable-next-line: no-any
  private getPlaylist(mediaId: string): any {
    return this.httpClient.get(`https://cdn.jwplayer.com/v2/media/${mediaId}`).toPromise();
  }

  /**
   * A simple utility method which can be used to wait for some time between retries.
   *
   * @param ms The amount of milliseconds to wait between retries.
   */
  private sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
