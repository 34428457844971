import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { FooterComponent } from 'projects/todaylib/core/layout/footer/footer.component';
import { SharedComponentsModule } from 'projects/todaylib/shared';
import { SharedDirectivesModule } from 'projects/todaylib/shared/directives/shared-directives.module';

import { UpcomingEventCardComponent } from '../pages/components/upcoming-events-page/upcoming-event-card/upcoming-event-card.component';
import { MedboardsComponent } from './medboards/medboards.component';
import { EfnUpdaterControlComponent } from './efn-updater-control/efn-updater-control.component';
import { CoreModule } from '../../../../todaylib/core/core.module';
import { CmeTrackingPromptModalComponent } from './cme-tracking-prompt-modal/cme-tracking-prompt-modal.component';

@NgModule({
  imports: [
    CommonModule,
    InlineSVGModule.forRoot(),
    TranslateModule,
    SharedDirectivesModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    TranslateModule,
    SharedComponentsModule,
    CoreModule
  ],
  declarations: [
    UpcomingEventCardComponent,
    MedboardsComponent,
    FooterComponent,
    EfnUpdaterControlComponent,
    CmeTrackingPromptModalComponent
  ],
  exports: [
    UpcomingEventCardComponent,
    MedboardsComponent,
    FooterComponent,
    EfnUpdaterControlComponent,
    CmeTrackingPromptModalComponent
  ],
  providers: [DatePipe, TranslatePipe]
})
export class ComponentsModule {}
