<div
  class="modal fade"
  id="deleteUserModal"
  tabindex="-1"
  aria-labelledby="deleteUserModalLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered rounded">
    <div class="modal-content bg-dark">
      <div class="modal-body">
        <h5>{{ 'EditUser.ConfirmDelete' | translate }}</h5>
        <div class="d-flex gap w-100 flex-column flex-md-row mt-3">
          <button (click)="handleDeleteUser()" class="btn btn-danger rounded-pill w-100">{{ 'EditUser.DeleteNow' | translate }}</button>
          <button data-dismiss="modal" aria-label="Close" class="btn ghost-button-light rounded-pill w-100">
            {{ 'ChannelPage.Abort' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
