import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { UserProfileResponseModel } from 'medtoday-models-library';
import { GoToLogin } from 'projects/medtoday/src/app/auth-ui/actions/auth-ui-navigation.actions';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { ApiResponse, LoginRequest } from '../../../shared/models';
import { AsyncStateClear } from '../../async-state/actions/async-state.actions';
import { AsyncAction } from '../../async-state/models/async-action.model';
import { handleAsyncErrors, handleErrors } from '../../async-state/operators/handle-errors';
import { DataApiService } from '../../data/services/data-api.service';
import { BaseAppState } from '../../store/reducers';
import { ToastService } from '../../toasts/services/toast.service';
import {
  AddUserToNewsletter,
  AddUserToNewsletterFail,
  AddUserToNewsletterSuccess,
  AuthActionTypes,
  EditUserProperties,
  EditUserPropertiesFail,
  EditUserPropertiesSuccess,
  GetUserProfileFail,
  GetUserProfileSuccess,
  HideFillMissingUserDataModal,
  Login,
  LoginFail,
  LoginRedirect,
  LoginSuccess,
  Logout,
  LogoutFail,
  LogoutSuccess,
  RefreshAuth,
  RefreshAuthFail,
  RefreshAuthSuccess,
  SessionExpired,
  ShouldShowFillMissingUserDataModal,
  ShowFillMissingUserDataModal,
  TokenValidation,
  UpdateEfnNumber,
  UpdateEfnNumberFail,
  UpdateNewsletter
} from '../actions/auth.actions';
import { getRedirectUrl } from '../selectors/auth.selectors';
import { AuthService, LoginResponse } from '../services/auth.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { TranslateService } from '@ngx-translate/core';

const SESSION_EXPIRED_TITLE = 'Sitzung abgelaufen';
const SESSION_EXPIRED_MESSAGE = 'Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich erneut ein.';

@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.Login),
        map((action: Login) => action.payload),
        switchMap((auth: LoginRequest) =>
          this.authService.login(auth.email, auth.password).pipe(
            handleErrors(() => new LoginFail()),
            filter(response => Boolean(response.data.loggedInUser && response.data.currentMember)),
            map(
              (response: ApiResponse<LoginResponse>) =>
                new LoginSuccess(response.data.loggedInUser, response.data.currentMember)
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  editUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.EditUserProperties),
        switchMap((action: EditUserProperties) =>
          this.authService.updateUser(action.updateUserRequest).pipe(
            handleErrors(() => new EditUserPropertiesFail()),
            map((response: ApiResponse<CognitoUser>) => {
              const user = JSON.parse(JSON.stringify(response.data));
              return new EditUserPropertiesSuccess(user, action.shouldRedirect);
            }),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  deleteUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.DeleteUser),
        switchMap(() =>
          this.authService.deleteUser().pipe(
            handleErrors(() => new Logout()),
            map(() => new Logout()),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  updateEfnNumber$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.UpdateEfnNumber),
        switchMap((action: UpdateEfnNumber) =>
          fromPromise(this.authService.patchUserAttributes({ 'custom:efnNumber': action.efnNumber })).pipe(
            handleAsyncErrors(() => new UpdateEfnNumberFail()),
            map((response: ApiResponse<CognitoUser>) => {
              const user = JSON.parse(JSON.stringify(response.data));
              return new EditUserPropertiesSuccess(user);
            }),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  updateNewsletterSetting$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.AddUserToNewsletter),
        switchMap((action: AddUserToNewsletter) =>
          this.dataApiService.updateNewsLetterSetting(action.needAuth, action.userNewsletter).pipe(
            handleAsyncErrors(() => new AddUserToNewsletterFail()),
            map(() => new AddUserToNewsletterSuccess()),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  updateUserNewsletter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.UpdateNewsletter),
        switchMap((action: UpdateNewsletter) =>
          this.dataApiService.updateNewsLetterSetting(action.needAuth, action.updateNewsletterRequest).pipe(
            handleAsyncErrors(() => new AddUserToNewsletterFail()),
            map(() => new AddUserToNewsletterSuccess()),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  refreshAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.RefreshAuth),
        switchMap((action: RefreshAuth) =>
          this.authService.refreshAuthIfNeeded().pipe(
            handleErrors(() => new RefreshAuthFail(action.authType)),
            filter(response => Boolean(response.data)),
            map((res: ApiResponse<CognitoUser>) => {
              const user = JSON.parse(JSON.stringify(res.data));

              return new RefreshAuthSuccess(user);
            }),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  redirectAfterLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LoginSuccess),
        concatLatestFrom(() => this.store),
        tap(([action, storeState]: [LoginSuccess, BaseAppState]) => {
          if (action.shouldRedirect) {
            const returnUrl = getRedirectUrl(storeState);
            if (!returnUrl?.startsWith('http') && !returnUrl?.startsWith('https')) {
              this.router.navigateByUrl(returnUrl ? returnUrl : '/congresses');
            } else {
              window.location.href = returnUrl;
            }
          }
        }),
        switchMap(() => [new AsyncStateClear(), new LoginRedirect(undefined), new ShouldShowFillMissingUserDataModal()])
      ),
    { dispatch: true }
  );

  redirectToLoginAfterSessionExpired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.SessionExpired),
        switchMap(() => [new LoginRedirect(this.router.url), new GoToLogin()])
      ),
    { dispatch: true }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.Logout),
        switchMap(() =>
          this.authService.signOut().pipe(
            handleErrors(() => new LogoutFail()),
            map(() => new LogoutSuccess()),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  redirectToLoginAfterLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LogoutSuccess),
        map(() => new GoToLogin(true))
      ),
    { dispatch: true }
  );

  handleRefreshFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.RefreshAuthFail),
        map((action: RefreshAuthFail) => {
          if (action.authType === 2) {
            const congressTokenArray = localStorage.getItem('medtoday_congress_tokens');
            const parsedArray = JSON.parse(congressTokenArray!);
            const found = parsedArray.find(ele => this.router.url.includes(ele.congressSlug));
            return new TokenValidation(found.congressSlug, found.token);
          } else {
            return new SessionExpired();
          }
        })
      ),
    { dispatch: true }
  );

  showMessageAfterSessionExpired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.SessionExpired),
        tap(() => {
          this.toastService.showInfo(SESSION_EXPIRED_TITLE, SESSION_EXPIRED_MESSAGE);
        })
      ),
    { dispatch: false }
  );

  showMessageAfterEditUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.EditUserPropertiesSuccess),
        switchMap((action: EditUserPropertiesSuccess) => {
          return fromPromise(this.authService.setAuthenticatedUser(action.response!)).pipe(
            switchMap(() => {
              return [
                new LoginSuccess(
                  this.authService.loginResponse$.value.loggedInUser,
                  this.authService.loginResponse$.value.currentMember,
                  action.shouldRedirect
                ),
                new HideFillMissingUserDataModal()
              ];
            })
          );
        }),
        tap(() => {
          this.toastService.showSuccess('Ihre Änderungen wurden erfolgreich gespeichert!');
        })
      ),
    { dispatch: true }
  );

  getUserProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.GetUserProfile),
        switchMap(() =>
          this.authService.getUserProfile().pipe(
            handleAsyncErrors(() => new GetUserProfileFail()),
            map((data: UserProfileResponseModel) => new GetUserProfileSuccess(data)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  showFillMissingUserDataModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.ShouldShowFillMissingUserDataModal),
        switchMap(() =>
          fromPromise(this.authService.userHasMissingDataOnStep()).pipe(
            map(hasMissingData => {
              if (hasMissingData) {
                this.store.dispatch(new ShowFillMissingUserDataModal());
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  sendSuccessfullyUpdatedUser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.EditUserPropertiesSuccess),
        switchMap((action: EditUserPropertiesSuccess) => {
          if (this.translateService.currentLang === 'de' && action.response) {
            return this.authService
              .sendUserUpdatedMessage(action.response)
              .pipe(catchError((errorAction: AsyncAction) => of(errorAction)));
          } else {
            return EMPTY;
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<BaseAppState>,
    private router: Router,
    private authService: AuthService,
    private dataApiService: DataApiService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {}
}
