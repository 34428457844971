import { Component, Input, OnChanges } from '@angular/core';
import moment from 'moment';

import { imagePaths } from '../../../../medtoday/src/environments/environment';
import { calculateTimestringForEvent, shouldShowHourSuffix } from '../../utilities/object.utils';
import { CarouselComponent } from '../carousel/carousel.component';

@Component({
  selector: 'app-carousel-large',
  templateUrl: './carousel-large.component.html',
  styleUrls: ['../carousel/carousel.component.scss', './carousel-large.component.scss']
})
export class CarouselLargeComponent extends CarouselComponent implements OnChanges {
  @Input() folderUrl: string;
  @Input() coverImage = true;
  @Input() isCmeCarousel = false;
  @Input() halfWidthItem = false;

  logoPathUrl = imagePaths.logos;

  ngOnChanges(): void {
    setTimeout(() => this.initCarousel(1, 1, 2));
  }

  calcTimes(dateA: Date, dateB: Date) {
    return calculateTimestringForEvent(dateA, dateB);
  }

  showHourSuffix(dateA: Date, dateB: Date) {
    return shouldShowHourSuffix(dateA, dateB);
  }

  isLive(start: string, finish: string) {
    const dateNow = moment.utc(new Date().toUTCString());
    return dateNow.isBetween(
      moment.utc(start).subtract(this.timeSlotInterval, 'minutes'),
      moment.utc(finish).add(this.timeSlotInterval, 'minutes')
    );
  }

  public isElementInViewPort(i: number) {
    if (window.innerWidth >= 992) {
      return i >= this.currentSlide && i < 2 + this.currentSlide;
    } else {
      return i === this.currentSlide;
    }
  }
}
