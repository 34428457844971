export const MAIN_NAVIGATION_PATH_ELEMENTS = {
  root: {
    medicalEducationElement: 'medical-education',
    upcomingEventsElement: 'upcoming-events',
    congressesElement: 'congresses',
    congressLandingPageElement: 'home',
    programmeElement: 'programme',
    expertsElement: 'experts',
    channelElement: 'channels',
    onDemandVideosElement: 'onDemand',
    featuredContentElement: 'featured-content',
    imprintElement: 'imprint',
    disclaimerElement: 'disclaimer',
    cookiesElement: 'cookies',
    cmeElement: 'cme',
    timePointsElement: 'time-points',
    homeElement: 'home',
    termsOfServiceElement: 'terms-of-service',
    aboutUs: 'about-us',
    events: 'med-ed',
    newsletter: 'newsletter',
    newsletterSuccess: 'newsletter-success',
    netiquette: 'netiquette',
    search: 'search',
    partnerNetwork: 'partner-network',
    partner: 'partner',
    sponsor: 'sponsor',
    eLearning: 'e-learning',
    myCme: 'my-cme',
    diseases: 'diseases',
    series: 'series',
    downloads: 'downloads',
    basicStudyKnowledge: 'basic-study-knowledge',
    phase3Studies: 'phase-3-studies'
  }
};

export const CONGRESS_PARAMS = {
  congressSlug: 'congressSlug',
  channelSlug: 'channelSlug',
  onDemandVideoId: 'onDemandVideoId',
  eventSlug: 'eventSlug',
  sponsorBoothSlug: 'sponsorBoothSlug'
};

export const GLOBAL_PARAMS = {
  partnerSlug: 'partnerSlug',
  topicCategorySlug: 'topicCategorySlug'
};

export const MISC_PARAMS = {
  fileSlug: 'fileSlug'
};

export const MAIN_NAVIGATION_PATHS = {
  root: {
    medicalEducation: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.medicalEducationElement}`,
    upcomingEvents: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.upcomingEventsElement}`,
    congresses: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement}`,
    timePointsPath: `:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.cmeElement}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.timePointsElement}`,
    congress: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement}/:${CONGRESS_PARAMS.congressSlug}`,
    congressLandingPage: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressLandingPageElement}`,
    programme: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.programmeElement}`,
    experts: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.expertsElement}`,
    channel: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.programmeElement}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.channelElement}/:${CONGRESS_PARAMS.channelSlug}`,
    onDemandVideos: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.onDemandVideosElement}`,
    onDemandVideoDetail: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.onDemandVideosElement}/:${CONGRESS_PARAMS.onDemandVideoId}`,
    featuredContent: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.featuredContentElement}`,
    featuredContentChannel: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.featuredContentElement}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.channelElement}/:${CONGRESS_PARAMS.channelSlug}`,
    sponsorBoothDetail: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement}/:${CONGRESS_PARAMS.congressSlug}/:${CONGRESS_PARAMS.sponsorBoothSlug}`,
    imprint: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.imprintElement}`,
    disclaimer: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.disclaimerElement}`,
    cookies: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.cookiesElement}`,
    termsOfService: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.termsOfServiceElement}`,
    cmeCongressPage: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressesElement}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.cmeElement}`,
    cmeInfoPage: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.cmeElement}`,
    aboutUs: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.aboutUs}`,
    events: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.events}`,
    knowledgeSeries: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.events}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.series}`,
    newsletter: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.newsletter}`,
    netiquette: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.netiquette}`,
    search: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.search}`,
    partnerNetwork: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.partnerNetwork}`,
    partnerDetail: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.partner}/:${GLOBAL_PARAMS.partnerSlug}`,
    eLearning: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.eLearning}`,
    myCme: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.myCme}`,
    diseases: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.diseases}/:${GLOBAL_PARAMS.topicCategorySlug}`,
    basicStudyKnowledge: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.basicStudyKnowledge}`,
    phase3Studies: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.phase3Studies}`,
    download: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.downloads}/:${MISC_PARAMS.fileSlug}`
  }
};
