import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../../../core/core.module';
import { SharedComponentsModule } from '../../components/shared-components.module';
import { CommonModule } from '@angular/common';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ELEARNING_STATE_KEY } from './definitions/elearning.definitions';
import { QuestionsComponent } from './components/questions/questions.component';
import { ElearningEffects } from './effects/elearning.effects';
import { reducer } from './reducers/elearning.reducer';
import { ElearningService } from './services/elearning.service';
import { ResultsComponent } from './components/results/results.component';

@NgModule({
  declarations: [QuestionsComponent, ResultsComponent],
  imports: [
    StoreModule.forFeature(ELEARNING_STATE_KEY, reducer),
    EffectsModule.forFeature([ElearningEffects]),
    TranslateModule,
    ReactiveFormsModule,
    CoreModule,
    SharedComponentsModule,
    CommonModule,
    SharedDirectivesModule,
    InlineSVGModule
  ],
  providers: [ElearningService]
})
export class ElearningModule {}
