import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { CoreModule } from 'projects/todaylib/core/core.module';
import { NavBarModule } from 'projects/todaylib/core/layout/nav-bar/nav-bar.module';

import { AsyncStateModule, AsyncStateOverlayModule } from '../../../../todaylib/core/async-state/async-state.module';
import { SharedComponentsModule, SharedDirectivesModule } from '../../../../todaylib/shared';
import { SharedPagesModule } from '../../../../todaylib/shared/pages/shared-pages.module';
import { AuthUIModule } from '../auth-ui/auth-ui.module';
import { ComponentsModule } from '../components/components.module';
import { CmeCongressPageComponent } from './components/cme-congress-page/cme-congress-page.component';
import { EnCmeCongressPageComponent } from './components/cme-congress-page/lang/en-cme-congress-page.component';
import { CmeInfoPageComponent } from './components/cme-info-page/cme-info-page.component';
import { EventCmePageComponent } from './components/event-cme-page/event-cme-page.component';
import { MedTodayEventPageComponent } from './components/event-page/medtoday-event-page';
import { MedTodayHomePageComponent } from './components/home-page/medtoday-home-page';
import { MedicalEducationPageComponent } from './components/medical-education-page/medical-education-page.component';
import { UpcomingEventsPageComponent } from './components/upcoming-events-page/upcoming-events-page.component';
import { PartnersModule } from '../../../../todaylib/shared/modules/partners/partners.module';
import { MyCmePageComponent } from './components/my-cme-page/my-cme-page.component';
import { KnowledgeSeriesPageComponent } from '../../../../todaylib/static/knowledge-series/knowledge-series-page/knowledge-series-page.component';
import { ChannelCmePromptGuard } from '../guards/channel-cme-prompt.guard';

const pages = [
  CmeInfoPageComponent,
  CmeCongressPageComponent,
  EnCmeCongressPageComponent,
  EventCmePageComponent,
  MedicalEducationPageComponent,
  UpcomingEventsPageComponent,
  MedTodayEventPageComponent,
  MyCmePageComponent,
  KnowledgeSeriesPageComponent
];
@NgModule({
  declarations: [MedTodayHomePageComponent, ...pages],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncStateModule,
    AsyncStateOverlayModule,
    SharedComponentsModule,
    SharedPagesModule,
    InlineSVGModule.forRoot(),
    TranslateModule,
    AuthUIModule,
    CommonModule,
    CoreModule,
    SharedDirectivesModule,
    NavBarModule,
    ComponentsModule,
    PartnersModule
  ],
  exports: [...pages],
  providers: [ChannelCmePromptGuard]
})
export class PagesModule {}
