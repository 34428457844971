import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsOfServiceComponent {
  constructor() {}
}
