<img class="d-md-block d-none background-image mt-5" src="assets/images/Service-Seiten-Kugeln@2x.png" />
<!-- <img class="d-md-none d-block background-image mobile mt-5" src="assets/images/Mobile-Kugel@2x.png" /> -->
<header>
  <div class="container">
    <div class="row offset-md-1 offset-lg-2 mt-4">
      <div class="col-lg-8 col-12 mt-4 mt-lg-0">
        <h3 class="mb-2">{{ 'EditUser.EditUserDetails' | translate }}</h3>
        <p class="display-6 mb-5" [innerHTML]="'EditUser.EditUserDetailsDesc' | translate"></p>
      </div>
    </div>
    <div class="row offset-md-1 offset-lg-2">
      <div class="col-lg-4 col-12">
        <div class="tabs-container">
          <a (click)="changeTab(UserTabs.Login)" class="mr-lg-5 d-block">
            <p [ngClass]="selectedTab === UserTabs.Login ? 'text-danger selected-tab' : ''" class="tab m-0 p-1">
              {{ 'Auth.LoginData' | translate }}
            </p>
            <div class="divider w-100 my-2 hide-mobile"></div>
          </a>
          <a (click)="changeTab(UserTabs.Personal)" class="mr-lg-5 d-block">
            <p [ngClass]="selectedTab === UserTabs.Personal ? 'text-danger selected-tab' : ''" class="tab m-0 p-1">
              {{ 'SignUp.PersonalData' | translate }}
            </p>
            <div class="divider w-100 my-2 hide-mobile"></div>
          </a>
          <a (click)="changeTab(UserTabs.Newsletter)" class="mr-lg-5 d-block" *ngIf="hasNewsletter">
            <p [ngClass]="selectedTab === UserTabs.Newsletter ? 'text-danger selected-tab' : ''" class="tab m-0 p-1">
              {{ 'NavigationMenu.Newsletter' | translate }}
            </p>
            <div class="divider w-100 my-2 hide-mobile"></div>
          </a>
        </div>
        <div class="divider w-100 mb-32 d-md-none"></div>
      </div>
      <ng-container *ngIf="selectedTab === UserTabs.Login">
        <div class="mb-5 col-lg-5 col-12">
          <form [formGroup]="loginDataForm" name="login-data-form">
            <div class="form-group row">
              <div class="col-12">
                <label>{{ 'Auth.Email' | translate }}*</label>
                <input
                  placeholder="{{ 'SignUp.EnterEmailAddress' | translate }}"
                  class="form-control form-control-lg"
                  name="email-address"
                  formControlName="emailAddress"
                />
              </div>
            </div>
            <div class="form-group row mt-4">
              <div class="col-12">
                <button
                  class="w-100 btn btn-danger rounded-pill"
                  *ngIf="!authService.isFederatedUser"
                  (click)="handleGoToChangePasswort()"
                >
                  {{ 'EditUser.ChangePassword' | translate }}
                </button>

                <ng-container *ngIf="authService.isFederatedUser" [ngSwitch]="authService.federatedIdentity">
                  <p *ngSwitchCase="'Google'">{{ 'EditUser.GoogleCantChangePassword' | translate }}</p>
                  <p *ngSwitchCase="'Apple'">{{ 'EditUser.AppleCantChangePassword' | translate }}</p>
                </ng-container>
              </div>
            </div>
            <div class="form-group row mt-5 d-inline">
              <p>
                {{ 'EditUser.DeleteAccount' | translate }}
                <a
                  href="#deleteUserModal"
                  draggable="false"
                  data-toggle="modal"
                  role="button"
                  class="text-danger text-decoration-underline"
                  >{{ 'EditUser.Delete' | translate }}</a
                >.
              </p>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedTab === UserTabs.Personal">
        <div class="mb-3 col-lg-5 col-12">
          <form [formGroup]="form" name="personal-data" (ngSubmit)="handleSaveUserClick()" #ngForm="ngForm" novalidate>
            <div class="form-group row">
              <div class="col-12">
                <label>{{ 'SignUp.Salutation' | translate }}*</label>
                <label class="float-right">* {{ 'SignUp.RequiredField' | translate }}</label>
                <div class="dropdown secondary">
                  <button
                    class="btn d-flex align-items-center justify-content-between navigation-menu dropdown-toggle text-light w-100"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    [class.is-invalid]="ngForm.submitted && form.controls['salutation'].invalid"
                    aria-expanded="false"
                  >
                    <h6 class="text-left m-0 w-100 text-capitalize">
                      {{
                        form.controls['salutation'].value
                          ? ('SignUp.' + form.controls['salutation'].value | translate)
                          : ('SignUp.Salutation' | translate)
                      }}
                    </h6>
                    <div class="icon-16 arrow-indicator" [inlineSVG]="'assets/icons/arrow-down.svg'"></div>
                  </button>
                  <div class="dropdown-menu dropdown-small-gap bg-dark w-100" aria-labelledby="select">
                    <a class="dropdown-item text-light text-primary" (click)="handleSalutationSelect('Mr')">{{
                      'SignUp.Mr' | translate
                    }}</a>
                    <a class="dropdown-item text-light text-primary" (click)="handleSalutationSelect('Ms')">{{
                      'SignUp.Ms' | translate
                    }}</a>
                    <a class="dropdown-item text-light text-primary" (click)="handleSalutationSelect('Divers')">{{
                      'SignUp.Divers' | translate
                    }}</a>
                  </div>
                  <div class="invalid-feedback">
                    <div *ngIf="form.controls['salutation'].hasError('required')">
                      {{ 'SignUp.SalutationRequired' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row" *ngIf="currentLang === 'de'">
              <div class="col-12">
                <label>EFN</label>
                <input
                  placeholder="{{ 'SignUp.EnterEFN' | translate }}"
                  class="form-control form-control-lg"
                  name="efnNumber"
                  formControlName="efnNumber"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label>{{ 'SignUp.Title' | translate }}</label>
                <input
                  placeholder="{{ 'SignUp.EnterTitle' | translate }}"
                  class="form-control form-control-lg"
                  name="title"
                  formControlName="title"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label>{{ 'SignUp.FirstName' | translate }}*</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  name="sign-up-name"
                  placeholder="{{ 'SignUp.EnterFirstName' | translate }}"
                  formControlName="name"
                  [class.is-invalid]="ngForm.submitted && form.controls['name'].invalid"
                />
                <div class="invalid-feedback">
                  <div *ngIf="form.controls['name'].hasError('required')">
                    {{ 'SignUp.FirstNameRequired' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label>{{ 'SignUp.Surname' | translate }}*</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  name="lastName"
                  placeholder="{{ 'SignUp.EnterSurname' | translate }}"
                  formControlName="lastName"
                  [class.is-invalid]="ngForm.submitted && form.controls['lastName'].invalid"
                />
                <div class="invalid-feedback">
                  <div *ngIf="form.controls['lastName'].hasError('required')">
                    {{ 'SignUp.SurnameRequired' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label>{{ 'SignUp.Institution' | translate }}</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  name="institution"
                  placeholder="{{ 'SignUp.EnterInstitution' | translate }}"
                  formControlName="institution"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label>{{ 'EditUser.Street' | translate }}, {{ 'EditUser.HouseNumber' | translate }}</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  name="streetAddress"
                  placeholder="{{ 'EditUser.EnterStreetHouseNumber' | translate }}"
                  formControlName="streetAddress"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label>{{ 'EditUser.PostCode' | translate }}</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  name="zipCode"
                  [class.invalid]="ngForm.submitted && form.controls['zipCode'].invalid"
                  [class.is-invalid]="ngForm.submitted && form.controls['zipCode'].invalid"
                  placeholder="{{ 'EditUser.EnterPostCode' | translate }}"
                  formControlName="zipCode"
                />
                <div class="invalid-feedback">
                  <div *ngIf="form.controls['zipCode'].hasError('pattern')">
                    {{ 'EditUser.PostCodeInvalid' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label>{{ 'EditUser.City' | translate }}</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  name="city"
                  placeholder="{{ 'EditUser.EnterCity' | translate }}"
                  formControlName="city"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label>{{ 'EditUser.PhoneNumber' | translate }}</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  name="phoneNumber"
                  placeholder="+49 12345678"
                  formControlName="phoneNumber"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-12 dropup">
                <label>{{ 'EditUser.EnterActivityType' | translate }}</label>
                <app-dropdown
                  class="d-block"
                  [control]="form.controls.occupationActivity"
                  [options]="occupationActivities"
                  (selected)="handleOccupationActivityChange($event)"
                  [placeholder]="'EditUser.EnterActivityType' | translate"
                  optionLabelPath="text"
                  optionValuePath="key"
                  [hideAll]="true"
                  [searchPlaceholder]="'EditUser.ActivityType' | translate"
                ></app-dropdown>
              </div>
            </div>
            <div class="form-group mt-2" *ngIf="isUserDoctor$ | async">
              <label class="d-block">{{ 'EditUser.AreaOfExpertise' | translate }}</label>
              <app-dropdown
                class="d-block"
                [control]="form.controls.topic"
                [options]="topics"
                (selected)="handleTopicSelect($event)"
                [placeholder]="'EditUser.ChooseSubjectArea' | translate"
                [search]="true"
                [hideAll]="true"
                [searchPlaceholder]="'EditUser.SearchTopic' | translate"
                (searchChanged)="searchForTopics($event)"
              ></app-dropdown>
            </div>
            <div class="form-group row mt-4">
              <div class="col-12">
                <button
                  [disabled]="form.pristine || !form.dirty"
                  type="submit"
                  (click)="handleSaveUserClick()"
                  class="w-100 btn btn-danger rounded-pill"
                >
                  {{ 'EditUser.SaveChanges' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedTab === UserTabs.Newsletter">
        <div class="mb-5 col-lg-5 col-12">
          <p>{{ 'EditUser.NewsletterText' | translate }}</p>
          <form [formGroup]="newsLetterForm" name="newsLetterForm" novalidate *ngIf="newsLetterForm">
            <ng-container *ngIf="userProfile$ | async as userProfile">
              <div
                class="switch-wrapper d-flex form-group"
                *ngFor="let newsletter of userProfile.newsletters; let i = index"
              >
                <div class="mr-4 custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input cms-toggle"
                    id="acceptNewsletterCheckbox-{{ i }}"
                    formControlName="{{ newsletter.id }}"
                  />
                  <label class="custom-control-label cms-toggle" for="acceptNewsletterCheckbox-{{ i }}"></label>
                </div>
                {{ newsletter.title }}
              </div>
            </ng-container>

            <div class="form-group row mt-4">
              <div class="col-12">
                <button
                  [disabled]="newsLetterForm.pristine || !newsLetterForm.dirty"
                  type="submit"
                  (click)="handleSaveNewsletterClick()"
                  class="w-100 btn btn-danger rounded-pill"
                >
                  {{ 'EditUser.SaveChanges' | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </ng-container>
    </div>
  </div>
</header>
<app-delete-user-modal></app-delete-user-modal>
<app-spacer></app-spacer>
<app-async-state-overlay [processKey]="asyncKey"></app-async-state-overlay>
