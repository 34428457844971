import { Component, Input, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ObservableComponent } from '../observable/observable.component';

@Component({
  selector: 'app-social-share-button',
  templateUrl: './social-share-button.component.html',
  styleUrls: ['./social-share-button.component.scss']
})
export class SocialShareButtonComponent extends ObservableComponent implements OnInit {
  @Input() size: string;

  link: string;

  constructor(@Inject('applicationName') public applicationName: string, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.link = window.location.href;

    this.router.events.pipe(takeUntil(this.ngDestroy$)).subscribe(_val => {
      this.link = window.location.href;
    });
  }
}
