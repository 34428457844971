<ng-container *ngIf="programmeData$ | async as programmeData">
  <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
    <app-subpage-header
      [congressMetaData]="congressMetaData"
      [subpageName]="'ProgrammePage.PatientsProgrammeOverview' | translate"
    ></app-subpage-header>
    <div
      class="container sticky-container"
      [class.filter-background]="showFilterBackground && applicationName === 'MedToday'"
      [class.filter-background-patients]="showFilterBackground && applicationName === 'PatientsToday'"
      [class.bg-medtoday]="applicationName === 'MedToday'"
    >
      <ng-container *ngIf="programmeDays?.length && congressMetaData.filterOptions['program'].includes('day')">
        <app-filter
          #filter
          [type]="'date'"
          [filterOptions]="filterContainer.getFilterOptions('day')"
          [hideLabel]="showFilterBackground"
          (handleFilter)="handleSelectFilter('day', $event)"
          [selectedOptions]="selectedDays"
          (allButtonHandler)="resetFilter('day')"
          [labelPipe]="'date'"
          [dateFormat]="'EEEE, dd.MM.'"
          [hasAllButton]="true"
          [filterLabel]="'ProgrammePage.SelectDay' | translate"
        >
        </app-filter>
      </ng-container>
      <ng-container *ngIf="programme.topics?.length && congressMetaData.filterOptions['program'].includes('topic')">
        <app-filter
          #filter
          [type]="'topic'"
          [showDots]="true"
          [filterOptions]="filterContainer.getFilterOptions('topic')"
          [hasAllButton]="true"
          [hideLabel]="showFilterBackground"
          [selectedOptions]="selectedTopics"
          (handleFilter)="handleSelectFilter('topic', $event)"
          (allButtonHandler)="resetFilter('topic')"
          [dataSource]="timeSlotRows"
          [dropdownForMobileEnabled]="false"
          [attributeAsButtonLabel]="'title'"
          [filterLabel]="'ProgrammePage.SelectDepartment' | translate"
        >
        </app-filter>
      </ng-container>
      <ng-container
        *ngIf="
          programmeData.languages?.length &&
          congressMetaData.mode === 'VoD' &&
          congressMetaData.filterOptions['program'].includes('language')
        "
      >
        <app-filter
          #filter
          [filterLabel]="'Common.Languages' | translate"
          [type]="'languages'"
          [hideLabel]="showFilterBackground"
          [hasAllButton]="true"
          [selectedOptions]="selectedLanguages"
          (allButtonHandler)="resetFilter('language')"
          (handleFilter)="handleSelectFilter('language', $event)"
          [changeToDropdownIfLength]="9"
          [filterOptions]="filterContainer.getFilterOptions('language')"
          [prefix]="'VideoOnDemand.'"
          [filterDropdownPlaceholder]="'Common.SelectLanguage' | translate"
        >
        </app-filter>
      </ng-container>
    </div>
    <ng-container
      *ngIf="
        congressMetaData.layout?.banner?.position !== 'null' &&
        congressMetaData.layout.banner?.subpagePosition === 'top'
      "
    >
      <app-congress-banner
        [congressLogo]="congressMetaData.layout?.banner?.logo_left"
        [congressSeriesLogo]="congressMetaData.layout?.banner?.logo"
        [disclaimer]="congressMetaData.layout?.banner?.text"
        class="mb-5 d-block"
      ></app-congress-banner>
    </ng-container>
    <div class="container" [class.subpage-content]="applicationName === 'MedToday'">
      <div class="row pt-5">
        <div class="hide-desktop col-12 mb-2" *ngIf="isAnyContentAvailable()">
          <h3>{{ selectedTopic ? selectedTopic.title : ('ProgrammePage.AlLDepartments' | translate) }}</h3>
        </div>
        <div class="col-lg-4">
          <button
            *ngIf="isAnySessionLive() && !hasOnlyLiveSessions()"
            class="btn btn-dark mb-5 mb-lg-3 next-session"
            (click)="scrollToLiveSessions()"
            type="button"
          >
            {{ 'Navigation.GoToLiveSession' | translate }}
            <i class="bi bi-play text-light pl-2 font-size-large"></i>
          </button>
        </div>
        <div class="offset-lg-4 col-lg-8 hide-tablet" *ngIf="isAnyContentAvailable()">
          <h4>
            {{ selectedTopicsNames?.length ? selectedTopicsNames : ('ProgrammePage.AlLDepartments' | translate) }}
          </h4>
        </div>
        <app-teaser-card
          *ngIf="!programmeData.hasFeaturedContent && programmeData.featuredCard?.length"
          class="w-100 pt-3 mt-1 mb-2 border-top"
          [title]="programmeData.featuredCard[0].title"
          [description]="programmeData.featuredCard[0].description"
          [buttonLabel]="programmeData.featuredCard[0].buttonLabel"
          [cardImage]="programmeData.featuredCard[0].cardImage"
          [buttonUrl]="programmeData.featuredCard[0].url"
          [congressBackgroundImage]="s3BucketUrl + congressMetaData.layout.backgroundImage"
          [slug]="congressSlug"
          [type]="programmeData.featuredCard[0].design"
        ></app-teaser-card>
      </div>
      <div class="col-12 py-4 px-0" *ngIf="!isAnyContentAvailable()">
        <h5 class="text-light">{{ 'ProgrammePage.NoSession' | translate }}</h5>
      </div>
      <ng-container *ngIf="isAnyContentAvailable()">
        <ng-container *ngFor="let row of visibleTimeSlots; let i = index">
          <section class="programme-item" [id]="i" *ngIf="row.sessions?.length > 0 || row.videos?.length > 0">
            <div [id]="isLive(row) ? 'live' : ''" class="row mt-3">
              <div class="divider full-width-divider w-100 hide-tablet"></div>
              <div class="col-lg-4 col-12 pt-2 headline">
                <span>
                  <h5
                    class="text-medium text-primary pb-0 pb-md-1 my-1 my-md-2 time"
                    *ngIf="isLiveCongress || row.sessions?.length"
                  >
                    {{ row.start | date : 'EEEE' }}, {{ row.start | date : 'HH:mm' }} -
                    {{ row.finish | date : 'HH:mm' }}
                    {{ 'Common.Hour' | translate }}
                  </h5>
                  <h5
                    class="text-medium text-primary pb-0 pb-md-1 my-1 my-md-2 time"
                    *ngIf="isVoDCongress && !row.sessions?.length"
                  >
                    {{ row.start | date : 'EEEE' }} {{ 'Common.ab' | translate }} {{ row.start | date : 'HH:mm' }}
                    {{ 'Common.Hour' | translate }}
                  </h5>
                  <h2 class="pb-3" *ngIf="!selectedTopic && isLiveCongress">
                    {{ getCommonSessionFormat(row.sessions) }}
                  </h2>
                  <h2 class="pb-3" *ngIf="!selectedTopic && isVoDCongress">{{ getCommonVoDFormat(row.videos) }}</h2>
                </span>
                <button
                  *ngIf="i !== visibleTimeSlots.length - 1 && row.sessions.length > 6"
                  class="next-session-button btn btn-dark btn-small mb-3"
                  type="button"
                  (click)="scrollToNextSessions(i + 1)"
                >
                  <span class="hide-tablet">{{ 'Navigation.GoToNextSession' | translate }}</span>
                  <div class="icon-16 icon-anchor-download" [inlineSVG]="'assets/icons/anchor-download.svg'"></div>
                </button>
              </div>
              <div class="col-lg-8 col-12">
                <app-session-row
                  [row]="row"
                  [congressSlug]="congressSlug"
                  [selectedTopic]="selectedTopic"
                ></app-session-row>

                <ng-container *ngIf="isVoDCongress">
                  <app-video-row
                    [row]="row"
                    [programmeData]="programmeData"
                    [congressSlug]="congressSlug"
                    [selectedTopic]="selectedTopic"
                  ></app-video-row>
                </ng-container>
              </div>
            </div>
          </section>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
<app-spacer></app-spacer>
<ng-container *ngIf="congressMetaData$ | async as congressMetaData">
  <ng-container
    *ngIf="
      congressMetaData.layout?.banner?.position !== 'null' &&
      congressMetaData.layout.banner?.subpagePosition === 'bottom'
    "
  >
    <app-congress-banner
      [congressLogo]="congressMetaData.layout?.banner?.logo_left"
      [congressSeriesLogo]="congressMetaData.layout?.banner?.logo"
      [disclaimer]="congressMetaData.layout?.banner?.text"
      [onBottom]="true"
    ></app-congress-banner>
  </ng-container>
</ng-container>
<app-scroll-to-top-button [ngClass]="hasScrolled() ? 'd-block' : 'd-none'"></app-scroll-to-top-button>
<app-async-state-overlay [processKey]="loadProgrammeDataAsyncKey"></app-async-state-overlay>
