import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  CongressCmePageDataResponse,
  OnDemandVideoDeeplink,
  CongressParticipantResponseModel,
  ChannelDeeplink,
  CmeCertificatesResponse
} from 'medtoday-models-library';
import { AsyncAction } from 'projects/todaylib/core/async-state/models/async-action.model';
import { handleAsyncErrors } from 'projects/todaylib/core/async-state/operators/handle-errors';
import { LoadFeaturedContentOverview } from 'projects/todaylib/core/data/actions/data-api.actions';
import { CMETrackerService } from '../services/cme-tracker.service';
import { DataApiService } from 'projects/todaylib/core/data/services/data-api.service';
import { of } from 'rxjs';
import { auditTime, catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import {
  MedtodayStoreActionTypes,
  LoadCongressCmePageDataFail,
  LoadCongressCmePageDataSuccess,
  LoadTokenizedOnDemandVideo,
  LoadTokenizedOnDemandVideoFail,
  LoadTokenizedOnDemandVideoSuccess,
  SendCMESignal,
  SendCMESignalFail,
  SendCMESignalSuccess,
  LoadCongressParticipantData,
  LoadCongressParticipantDataFail,
  LoadCongressParticipantDataSuccess,
  AddCongressParticipant,
  AddCongressParticipantFail,
  AddCongressParticipantSuccess,
  LoadTokenizedChannelFail,
  LoadTokenizedChannelSuccess,
  LoadTokenizedChannel,
  SetVideoAsWatched,
  SetVideoAsWatchedFail,
  SetVideoAsWatchedSuccess,
  LoadOnDemandVideoUserData,
  LoadOnDemandVideoUserDataSuccess,
  UpdateVideoUserPosition,
  UpdateVideoUserPositionFail,
  UpdateVideoUserPositionSuccess,
  LoadCmeCertificatesSuccess,
  LoadCmeCertificatesFail
} from '../actions/medtoday-store.actions';

@Injectable({
  providedIn: 'root'
})
export class MedtodayStoreEffects {
  loadCongressParticipantData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.LoadCongressParticipantData),
        mergeMap((action: LoadCongressParticipantData) =>
          this.api.getCongressParticipantData(action.congressSlug, action.userSubId).pipe(
            handleAsyncErrors(() => new LoadCongressParticipantDataFail()),
            map((data: CongressParticipantResponseModel) => new LoadCongressParticipantDataSuccess(data)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  addCongressParticipant$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.AddCongressParticipant),
        switchMap((action: AddCongressParticipant) =>
          this.api.addCongressParticipant(action.congressSlug, action.request).pipe(
            handleAsyncErrors(() => new AddCongressParticipantFail()),
            map(() => new AddCongressParticipantSuccess(action.congressSlug, action.request)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  afterParticipantAdded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.AddCongressParticipantSuccess),
        map(
          (action: AddCongressParticipantSuccess) =>
            new LoadCongressParticipantData(action.congressSlug, action.request.userSubId)
        )
      ),
    { dispatch: true }
  );

  loadTokenizedChannelData = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.LoadTokenizedChannel),
        auditTime(100),
        switchMap((action: LoadTokenizedChannel) =>
          this.api.getTokenizedChannel(action.congressSlug, action.channelSlug, action.token).pipe(
            handleAsyncErrors(() => new LoadTokenizedChannelFail()),
            map((response: ChannelDeeplink) => new LoadTokenizedChannelSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadTokenizedVideoOnDemand$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.LoadTokenizedOnDemandVideo),
        auditTime(100),
        switchMap((action: LoadTokenizedOnDemandVideo) =>
          this.api.getTokenizedOnDemandVideo(action.id, action.token, action.congressSlug).pipe(
            handleAsyncErrors(() => new LoadTokenizedOnDemandVideoFail()),
            map((response: OnDemandVideoDeeplink) => new LoadTokenizedOnDemandVideoSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  sendCMESignal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.SendCMESignal),
        mergeMap((action: SendCMESignal) =>
          this.cmeService.trackData(action.congressSlug, action.timepoints).pipe(
            handleAsyncErrors(() => new SendCMESignalFail()),
            map(() => new SendCMESignalSuccess()),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadCongressCmePageData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.LoadCongressCmePageData),
        mergeMap((action: LoadFeaturedContentOverview) =>
          this.api.getCongressCmePageData(action.congressSlug).pipe(
            handleAsyncErrors(() => new LoadCongressCmePageDataFail()),
            map((response: CongressCmePageDataResponse) => new LoadCongressCmePageDataSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  handleCMEFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.SendCMESignalFail),
        tap((_action: SendCMESignalFail) => this.cmeService.handleCMEFailure())
      ),
    { dispatch: false }
  );

  handleCMESuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.SendCMESignalSuccess),
        tap((_action: SendCMESignalSuccess) => this.cmeService.handleCMEResponse())
      ),
    { dispatch: false }
  );

  setVideoAsWatched$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.SetVideoAsWatched),
        mergeMap((action: SetVideoAsWatched) =>
          this.api.setVideoAsWatched(action.congressSlug, action.vodId).pipe(
            handleAsyncErrors(() => new SetVideoAsWatchedFail()),
            map(res => new SetVideoAsWatchedSuccess(res)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  getVideUserData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.LoadOnDemandVideoUserData),
        mergeMap((action: LoadOnDemandVideoUserData) =>
          this.api.getVideoUserData(action.congressSlug, action.vodId).pipe(
            handleAsyncErrors(() => new LoadTokenizedOnDemandVideoFail()),
            map(res => new LoadOnDemandVideoUserDataSuccess(res)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  updateVideoUserPosition$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.UpdateVideoUserPosition),
        mergeMap((action: UpdateVideoUserPosition) =>
          this.api.updateVideoUserPosition(action.congressSlug, action.vodId, action.position).pipe(
            handleAsyncErrors(() => new UpdateVideoUserPositionFail()),
            map(() => new UpdateVideoUserPositionSuccess()),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadCmeCertificates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MedtodayStoreActionTypes.LoadCmeCertificates),
        mergeMap(() =>
          this.api.loadCmeCertificates().pipe(
            handleAsyncErrors(() => new LoadCmeCertificatesFail()),
            map((response: CmeCertificatesResponse) => new LoadCmeCertificatesSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  constructor(private api: DataApiService, private cmeService: CMETrackerService, private actions$: Actions) {}
}
