import { Mode } from 'medtoday-models-library/lib/models';

export interface HeroSliderSideItem {
  image: string;
  name: string;
  tags: HeroSliderSideItemTag[];
  slug: string;
  congressMode: Mode;
  externalLink: string;
}

export interface HeroSliderSideItemTag {
  name: string;
  slug?: string;
}

export function extendToHeroSliderItem<T>(
  item: T,
  mapTo: () => object,
  tags: HeroSliderSideItemTag[]
): HeroSliderSideItem {
  return {
    ...extend<HeroSliderSideItem>(item, mapTo()),
    tags
  };
}

export function extendToHeroSliderItemTag<T>(item: T, mapTo: () => object): HeroSliderSideItemTag {
  return {
    ...extend<HeroSliderSideItemTag>(item, mapTo())
  };
}

function extend<T>(item, propertiesMap): T {
  const it = Object.keys(propertiesMap).reduce((agg, property) => {
    agg[property] = item[propertiesMap[property]];
    return agg;
  }, {} as any);

  return it;
}

export function fromCongress(): { [Property in keyof HeroSliderSideItem]: string } {
  return {
    image: 'backgroundImage',
    name: 'heroSliderHeadline',
    tags: 'topicCategoriesIds',
    slug: 'slug',
    congressMode: 'congressMode',
    externalLink: 'externalLink'
  };
}

export function fromEvent(): { [Property in keyof HeroSliderSideItem]: string } {
  return {
    ...fromCongress()
  };
}

export function fromTopicCategory() {
  return {
    name: 'title',
    slug: 'slug'
  };
}
