<a class="video-thumbnail w-100 rounded mr-sm-3" (click)="handleGoToVideoClick(video)">
  <app-carousel-label
    *ngIf="isShowLabel && label"
    [label]="label"
    [labelColor]="video.congressColor"
  ></app-carousel-label>
  <img draggable="false" class="w-100" *ngIf="video.thumbnail" [src]="thumbnail" [alt]="video.title" />
  <ng-container *ngIf="!video.thumbnail && mediaProvider === MEDIA_PROVIDERS.JW">
    <video
      #thumbnailVideo
      [poster]="'https://cdn.jwplayer.com/v2/media/' + video.mediaIdentifier + '/poster.jpg'"
      *ngIf="!video.thumbnail"
      preload="none"
      muted="muted"
      (mouseover)="thumbnailVideo.play()"
      (mouseout)="thumbnailVideo.pause()"
    >
      <source
        [src]="'https://cdn.jwplayer.com/v2/media/' + video.mediaIdentifier + '/poster.mp4?width=320'"
        type="video/mp4"
      />
      <img [src]="'https://cdn.jwplayer.com/v2/media/' + video.mediaIdentifier + '/poster.jpg'" [alt]="video.title" />
    </video>
  </ng-container>
  <ng-container *ngIf="!video.thumbnail && mediaProvider === MEDIA_PROVIDERS.YT">
    <img [src]="'https://i.ytimg.com/vi/' + video.mediaIdentifier + '/mqdefault.jpg'" [alt]="video.title" />
  </ng-container>
  <span *ngIf="duration && duration !== '00:00:00'">{{ duration }}</span>
</a>
