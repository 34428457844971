import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AsyncState } from '../reducers/async-state.reducers';

const getAsyncRootState = createFeatureSelector<AsyncState>('asyncState');

export const makeGetAsyncState = (key: string) => createSelector(getAsyncRootState, (asyncStates) => asyncStates[key]);

export const makeGetAsyncIsPending = (key: string) =>
  createSelector(makeGetAsyncState(key), (asyncState) => (asyncState ? asyncState.pending : false));

export const makeGetAsyncInitialAction = (key: string) =>
  createSelector(makeGetAsyncState(key), (asyncState) => (asyncState ? asyncState.initialAction : undefined));

export const makeGetAsyncIsRetryable = (key: string) => createSelector(makeGetAsyncInitialAction(key), (action) => Boolean(action));

export const makeGetAsyncErrorState = (key: string) =>
  createSelector(makeGetAsyncState(key), (asyncState) => (asyncState ? asyncState.error : undefined));

export const makeGetAsyncValidationErrors = (key: string) =>
  createSelector(makeGetAsyncErrorState(key), (errorState) => (errorState ? errorState.validationErrors : undefined));

export const makeGetAsyncIsFailed = (key: string) => createSelector(makeGetAsyncErrorState(key), (errorState) => Boolean(errorState));

export const makeGetAsyncFailedCode = (key: string) =>
  createSelector(makeGetAsyncErrorState(key), (errorState) => (errorState ? errorState.httpStatus : undefined));
