<section id="twitter">
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5">
        <img class="d-none twitter-logo" src="assets/images/pt-fb@2x.png" />
        <div class="twitter-channel mt-5">
          <div class="logo rounded-circle dark" [inlineSVG]="'assets/images/logo-small.svg'"></div>
          <h4 class="mb-0">patients today</h4>
          <a class="twitter-follow-button ml-4 bg-primary" [href]="patientsInstagram" target="_blank" draggable="false"
            ><i class="icon-instagram pr-2 line-height-2" [inlineSVG]="'assets/icons/icons8-instagram.svg'"></i
            >{{ 'Common.Follow' | translate }}</a
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="tagembed-container" style="width: 100%; height: 100%; overflow: auto">
        <ng-container *ngIf="currentLang === 'de'">
          <div class="tagembed-socialwall" data-wall-id="11237"></div>
        </ng-container>
        <ng-container *ngIf="currentLang === 'en'">
          <div class="tagembed-socialwall" data-wall-id="13833"></div>
        </ng-container>
      </div>
    </div>
  </div>
</section>
