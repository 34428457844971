import { Action } from '@ngrx/store';
import { AsyncAction } from '../../async-state/models/async-action.model';
import { LoginRequest } from '../../../shared/models';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AwsMember, UpdateUserRequest } from '../services/auth.service';
import {
  AuthorizationMode,
  NewsletterCreateUpdateRequestModel,
  UserProfileResponseModel
} from 'medtoday-models-library/lib/models';

export enum AuthActionTypes {
  Login = '[Auth] Login',
  LoginSuccess = '[Auth] Login Success',
  LoginFail = '[Auth] Login Failure',
  Logout = '[Auth] Logout',
  LogoutSuccess = '[Auth] Logout Success',
  LogoutFail = '[Auth] Logout Fail',
  SessionExpired = '[Auth] Session expired',
  LoginRedirect = '[Auth] Login Redirect',
  RefreshAuth = '[Auth] Refresh Token',
  RefreshAuthSuccess = '[Auth] Refresh Token success',
  RefreshAuthFail = '[Auth] Refresh Token fail',
  EditUserProperties = '[Auth] Edit User Properties',
  EditUserPropertiesSuccess = '[Auth] Edit User Properties Success',
  EditUserPropertiesFail = '[Auth] Edit User Properties Fail',
  UpdateEfnNumber = '[Auth] UpdateEfnNumber ',
  UpdateEfnNumberSuccess = '[Auth] UpdateEfnNumber  Success',
  UpdateEfnNumberFail = '[Auth] UpdateEfnNumber  Fail',
  DeleteUser = '[Auth] DeleteUser',
  DeleteUserFail = '[Auth] DeleteUser Fail',
  ResetScrollToNewsletter = '[Auth] Reset scroll to newsletter',
  UpdateNewsletter = '[Auth] Update User Newsletter Setting',
  AddUserToNewsletter = '[Auth] Add User to Newsletter',
  AddUserToNewsletterSuccess = '[Auth] Add User to Newsletter Success',
  AddUserToNewsletterFail = '[Auth] Add User to Newsletter failed',
  PurgeUserData = '[Auth] Purge user data',
  GetUserProfile = '[Auth] Get User Profile',
  GetUserProfileSuccess = '[Auth] Get User Profile Success',
  GetUserProfileFail = '[Auth] Get User Profile Failure',
  TokenValidation = '[Auth] Token Validation',
  ShouldShowFillMissingUserDataModal = '[Auth] Should Show fill missing user data modal',
  ShowFillMissingUserDataModal = '[Auth] Show fill missing user data modal',
  HideFillMissingUserDataModal = '[Auth] Hide fill missing user data modal',
  PurgeTemporaryData = '[SignUp] Purge temporary Data'
}

export class Login implements AsyncAction {
  readonly type = AuthActionTypes.Login;
  readonly asyncKey = AuthActionTypes.Login;
  readonly asyncState = 'start';

  constructor(public payload: LoginRequest, public redirectUrl?: string) {}
}

export class LoginSuccess implements AsyncAction {
  readonly type = AuthActionTypes.LoginSuccess;
  readonly asyncKey = AuthActionTypes.Login;
  readonly asyncState = 'success';

  constructor(
    public loginResponse: CognitoUser | null,
    public awsMember: AwsMember | null,
    public shouldRedirect = true
  ) {}
}

export class LoginFail implements AsyncAction {
  readonly type = AuthActionTypes.LoginFail;
  readonly asyncKey = AuthActionTypes.Login;
  readonly asyncState = 'fail';
}

export class Logout implements AsyncAction {
  readonly type = AuthActionTypes.Logout;
  readonly asyncKey = AuthActionTypes.Logout;
  readonly asyncState = 'start';

  constructor() {}
}

export class LogoutSuccess implements AsyncAction {
  readonly type = AuthActionTypes.LogoutSuccess;
  readonly asyncKey = AuthActionTypes.Logout;
  readonly asyncState = 'success';

  constructor() {}
}

export class LogoutFail implements AsyncAction {
  readonly type = AuthActionTypes.LogoutFail;
  readonly asyncKey = AuthActionTypes.Logout;
  readonly asyncState = 'fail';
}

export class SessionExpired implements Action {
  readonly type = AuthActionTypes.SessionExpired;
}

export class LoginRedirect implements Action {
  readonly type = AuthActionTypes.LoginRedirect;
  constructor(public url: string | undefined) {}
}

export class PurgeUserData implements Action {
  readonly type = AuthActionTypes.PurgeUserData;
}

export class EditUserProperties implements AsyncAction {
  readonly type = AuthActionTypes.EditUserProperties;
  readonly asyncKey = AuthActionTypes.EditUserProperties;
  readonly asyncState = 'start';

  constructor(public updateUserRequest: UpdateUserRequest, public shouldRedirect = false) {}
}
export class EditUserPropertiesSuccess implements AsyncAction {
  readonly type = AuthActionTypes.EditUserPropertiesSuccess;
  readonly asyncKey = AuthActionTypes.EditUserProperties;
  readonly asyncState = 'success';

  constructor(public response: CognitoUser | undefined, public shouldRedirect = false) {}
}
export class EditUserPropertiesFail implements AsyncAction {
  readonly type = AuthActionTypes.EditUserPropertiesFail;
  readonly asyncKey = AuthActionTypes.EditUserProperties;
  readonly asyncState = 'fail';
}
export class DeleteUser implements AsyncAction {
  readonly type = AuthActionTypes.DeleteUser;
  readonly asyncKey = AuthActionTypes.DeleteUser;
  readonly asyncState = 'start';

  constructor() {}
}
export class DeleteUserFail implements AsyncAction {
  readonly type = AuthActionTypes.DeleteUserFail;
  readonly asyncKey = AuthActionTypes.DeleteUser;
  readonly asyncState = 'fail';
}

export class UpdateNewsletter implements AsyncAction {
  readonly type = AuthActionTypes.UpdateNewsletter;
  readonly asyncKey = AuthActionTypes.UpdateNewsletter;
  readonly asyncState = 'start';

  constructor(public updateNewsletterRequest: NewsletterCreateUpdateRequestModel, public needAuth = true) {}
}

export class AddUserToNewsletter implements AsyncAction {
  readonly type = AuthActionTypes.AddUserToNewsletter;
  readonly asyncKey = AuthActionTypes.AddUserToNewsletter;
  readonly asyncState = 'start';

  constructor(public userNewsletter: NewsletterCreateUpdateRequestModel, public needAuth = true) {}
}
export class AddUserToNewsletterSuccess implements AsyncAction {
  readonly type = AuthActionTypes.AddUserToNewsletterSuccess;
  readonly asyncKey = AuthActionTypes.AddUserToNewsletter;
  readonly asyncState = 'success';

  constructor(public subscribed = true) {}
}
export class AddUserToNewsletterFail implements AsyncAction {
  readonly type = AuthActionTypes.AddUserToNewsletterFail;
  readonly asyncKey = AuthActionTypes.AddUserToNewsletter;
  readonly asyncState = 'fail';

  constructor(public subscribed = false) {}
}

export class RefreshAuth implements AsyncAction {
  readonly type = AuthActionTypes.RefreshAuth;
  readonly asyncKey = AuthActionTypes.RefreshAuth;
  readonly asyncState = 'start';

  constructor(public authType: AuthorizationMode) {}
}
export class RefreshAuthSuccess implements AsyncAction {
  readonly type = AuthActionTypes.RefreshAuthSuccess;
  readonly asyncKey = AuthActionTypes.RefreshAuth;
  readonly asyncState = 'success';

  constructor(public user: CognitoUser) {}
}
export class RefreshAuthFail implements AsyncAction {
  readonly type = AuthActionTypes.RefreshAuthFail;
  readonly asyncKey = AuthActionTypes.RefreshAuth;
  readonly asyncState = 'fail';

  constructor(public authType: AuthorizationMode) {}
}

export class ResetScrollToNewsletter implements Action {
  readonly type = AuthActionTypes.ResetScrollToNewsletter;
}

export class GetUserProfile implements AsyncAction {
  readonly type = AuthActionTypes.GetUserProfile;
  readonly asyncKey = AuthActionTypes.GetUserProfile;
  readonly asyncState = 'start';

  constructor() {}
}

export class GetUserProfileSuccess implements AsyncAction {
  readonly type = AuthActionTypes.GetUserProfileSuccess;
  readonly asyncKey = AuthActionTypes.GetUserProfile;
  readonly asyncState = 'success';

  constructor(public userProfile: UserProfileResponseModel) {}
}

export class GetUserProfileFail implements AsyncAction {
  readonly type = AuthActionTypes.GetUserProfileFail;
  readonly asyncKey = AuthActionTypes.GetUserProfile;
  readonly asyncState = 'fail';
}

export class TokenValidation implements Action {
  readonly type = AuthActionTypes.TokenValidation;
  constructor(public congressSlug: string, public token: string) {}
}

export class ShouldShowFillMissingUserDataModal implements Action {
  readonly type = AuthActionTypes.ShouldShowFillMissingUserDataModal;
}

export class ShowFillMissingUserDataModal implements Action {
  readonly type = AuthActionTypes.ShowFillMissingUserDataModal;
}

export class HideFillMissingUserDataModal implements Action {
  readonly type = AuthActionTypes.HideFillMissingUserDataModal;
}

export class PurgeTemporaryData implements Action {
  readonly type = AuthActionTypes.PurgeTemporaryData;
}

export class UpdateEfnNumber implements AsyncAction {
  readonly type = AuthActionTypes.UpdateEfnNumber;
  readonly asyncKey = AuthActionTypes.UpdateEfnNumber;
  readonly asyncState = 'start';

  constructor(public efnNumber: string) {}
}
export class UpdateEfnNumberSuccess implements AsyncAction {
  readonly type = AuthActionTypes.UpdateEfnNumberSuccess;
  readonly asyncKey = AuthActionTypes.UpdateEfnNumber;
  readonly asyncState = 'success';

  constructor(public response: CognitoUser | undefined) {}
}
export class UpdateEfnNumberFail implements AsyncAction {
  readonly type = AuthActionTypes.UpdateEfnNumberFail;
  readonly asyncKey = AuthActionTypes.UpdateEfnNumber;
  readonly asyncState = 'fail';
}

export type AuthActions =
  | Login
  | LoginSuccess
  | LoginFail
  | Logout
  | LogoutSuccess
  | LogoutFail
  | SessionExpired
  | LoginRedirect
  | EditUserPropertiesSuccess
  | RefreshAuthSuccess
  | ResetScrollToNewsletter
  | PurgeUserData
  | GetUserProfile
  | GetUserProfileSuccess
  | GetUserProfileFail
  | TokenValidation
  | ShowFillMissingUserDataModal
  | HideFillMissingUserDataModal
  | PurgeTemporaryData;
