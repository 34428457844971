<header>
  <div class="container">
    <div class="row d-flex align-items-center">
      <div
        class="col-12 col-md-5 order-md-2 offset-md-0 d-flex justify-content-center align-items-center w-100 logo-wrapper"
      >
        <img src="assets/images/my-cme-background.svg" draggable="false" alt="" />
      </div>
      <div class="col-12 col-md-7 order-md-1 h-100 mt-4 pr-sm-2 mb-3">
        <h1>{{ 'MyCme.HeaderTitle' | translate }}</h1>
        <h5 class="line-height-1">
          {{ 'MyCme.HeaderDescription' | translate }}
        </h5>
      </div>
    </div>
  </div>
</header>
<section *ngIf="cmeCertificates$ | async as cmeCertificates">
  <!--  <div class="container">-->
  <!--    <div class="row">-->
  <!--      <div class="col col-6 col-md-2">-->
  <!--        <span class="display-4 font-weight-bold text-danger">-->
  <!--          {{ cmeCertificates.finishedModules }}-->
  <!--        </span>-->
  <!--        <div class="cme-points-note">-->
  <!--          {{ 'MyCme.FinishedModules' | translate }}-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="col col-6 col-md-2">-->
  <!--        <span class="display-4 font-weight-bold text-danger">-->
  <!--          {{ cmeCertificates.cmePoints }}-->
  <!--        </span>-->
  <!--        <div class="cme-points-note">-->
  <!--          {{ 'MyCme.ReceivedPoints' | translate }}-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="col offset-0 offset-md-9"></div>-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  &lt;!&ndash; Live CME &ndash;&gt;-->
  <!--  <ng-container *ngIf="upcomingEventsPageData$ | async as upcomingEventsPageData">-->
  <!--    <section id="live-cme" *ngIf="upcomingEventsPageData.events?.length">-->
  <!--      <div class="container">-->
  <!--        <div class="row mb-lg-4 mb-2">-->
  <!--          <div class="col-12">-->
  <!--            <h4 class="mb-0">-->
  <!--              {{ 'MyCme.LiveCme' | translate }}-->
  <!--            </h4>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <app-carousel-small-->
  <!--        folderUrl="thumbnail"-->
  <!--        [isUpcomingEvents]="true"-->
  <!--        [carouselItems]="upcomingEventsPageData.events"-->
  <!--        [showCmePoints]="true"-->
  <!--        [showTitleOnThumbnail]="true"-->
  <!--      ></app-carousel-small>-->
  <!--    </section>-->
  <!--  </ng-container>-->

  <!-- CME Certificates -->
  <section id="cme-certificates" *ngIf="lastCmeCertificateEvent || paginatedCmeCertificates?.length">
    <div class="container">
      <div class="row mb-lg-2 mb-2">
        <div class="col-12">
          <h4 class="mb-0">
            {{ 'MyCme.AllYourModules' | translate }}
          </h4>
        </div>
      </div>
      <div class="divider w-100 pb-2"></div>
      <div class="featured-certificate mt-4">
        <app-upcoming-event-card
          class="hide-divider"
          [eventSameDay]="true"
          [subTitle]="lastCmeCertificateEvent.eventName"
          [hideCalendarButtons]="true"
          [event]="lastCmeCertificateEvent"
        >
          <button
            class="btn btn-danger btn-small font-size-small line-height-1"
            (click)="openCertificate(lastCmeCertificateEvent.certificateUrl)"
            *ngIf="lastCmeCertificateEvent.certificateUrl"
          >
            <i class="icon-16 pr-4" inlineSVG="assets/images/badge.svg"></i>
            <span>
              {{ 'CmeLearning.Certificate' | translate }}
            </span>
          </button>
          <button
            class="btn btn-small font-size-small line-height-2 text-white link-disabled"
            *ngIf="!lastCmeCertificateEvent.certificateUrl && lastCmeCertificateEvent.blacklistedUntil"
          >
            <i class="bi bi-clock pr-2 text-white line-height-0"></i>
            <span>
              {{
                'MyCme.BlacklistedUntil'
                  | translate : { daysUntil: lastCmeCertificateEvent.blacklistedUntil | daysUntil }
              }}
            </span>
          </button>
        </app-upcoming-event-card>
      </div>
      <div class="table-responsive mt-3" *ngIf="paginatedCmeCertificates?.length">
        <table class="table text-white">
          <thead>
            <tr>
              <th class="col-md-6" scope="col">Module</th>
              <th class="col-md-3" scope="col">Event</th>
              <th class="col-md-3" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cmeCertificateEntry of paginatedCmeCertificates[(currentPage$ | async) - 1]">
              <td>
                <span class="module-name">{{ cmeCertificateEntry.moduleName }}</span>
                <div class="mt-2" *ngIf="cmeCertificateEntry.formats?.length || cmeCertificateEntry.topics?.length">
                  <div class="badge badge-dark p-2 mr-1" *ngIf="cmeCertificateEntry.topics.length">
                    {{ cmeCertificateEntry.topics[0].title }}
                  </div>
                  <div class="badge badge-dark p-2 mr-1" *ngIf="cmeCertificateEntry.formats.length">
                    {{ cmeCertificateEntry.formats[0].title }}
                  </div>
                </div>
              </td>
              <td class="text-note">
                {{ cmeCertificateEntry.congressName }}
                <div class="mt-1" *ngIf="cmeCertificateEntry.certificateDate">
                  <span>
                    <i class="bi bi-calendar pr-2 text-white"></i>
                    {{ cmeCertificateEntry.certificateDate | date : 'dd.MM.yyyy' }}
                  </span>
                </div>
              </td>
              <td>
                <button
                  class="btn text-white show-on-hover"
                  *ngIf="cmeCertificateEntry.certificateUrl"
                  (click)="openCertificate(cmeCertificateEntry.certificateUrl)"
                >
                  <i class="icon-16" inlineSVG="assets/images/badge.svg"></i>
                  <span class="d-inline-block">
                    {{ 'CmeLearning.Certificate' | translate }}
                  </span>
                </button>
                <button class="btn" *ngIf="cmeCertificateEntry.blacklistedUntil">
                  <span>
                    <i class="bi bi-clock pr-2 text-white"></i>
                    {{
                      'MyCme.DaysToEnd' | translate : { daysUntil: cmeCertificateEntry.blacklistedUntil | daysUntil }
                    }}
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <nav *ngIf="paginatedCmeCertificates?.length > 7">
        <ul class="pagination" *ngIf="currentPage$ | async as currentPage">
          <li class="page-item mr-2" [class.invisible]="currentPage > 1">
            <a class="page-link" (click)="goToPage(currentPage - 1)" aria-label="Previous">
              <i class="bi bi-arrow-left"></i>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item" *ngFor="let page of paginatedCmeCertificates; let index = index">
            <a class="page-link" [class.current]="index + 1 === currentPage" (click)="goToPage(index + 1)">{{
              index + 1
            }}</a>
          </li>
          <li class="page-item ml-1" [class.invisible]="currentPage < paginatedCmeCertificates.length">
            <a class="page-link" (click)="goToPage(currentPage + 1)" aria-label="Next">
              <i class="bi bi-arrow-right"></i>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </section>
</section>
<app-spacer></app-spacer>
<app-async-state-overlay [processKey]="upcomingEventsDataKey"></app-async-state-overlay>
<app-async-state-overlay [processKey]="cmeCertificatesDataKey"></app-async-state-overlay>
