import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AsyncAction } from 'projects/todaylib/core/async-state/models/async-action.model';
import { handleAsyncErrors } from 'projects/todaylib/core/async-state/operators/handle-errors';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import {
  FileDownloadActionTypes,
  GetMediaLibraryItem,
  GetMediaLibraryItemFail,
  GetMediaLibraryItemSuccess
} from '../actions/file-downloads-api.actions';
import { FileDownloadDataApiService } from '../services/file-download-api.service';

@Injectable()
export class FileDownloadApiEffects {
  public getMediaLibraryItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FileDownloadActionTypes.GetMediaLibraryItem),
        switchMap((action: GetMediaLibraryItem) =>
          this.api.getMediaLibraryItem(action.slug).pipe(
            handleAsyncErrors(() => new GetMediaLibraryItemFail()),
            map((response: any) => new GetMediaLibraryItemSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  fromHTML(html: string, trim = true) {
    // Process the HTML string.
    html = trim ? html.trim() : html;
    if (!html) return null;

    // Then set up a new template element.
    const template = document.createElement('template');
    template.innerHTML = html;
    const result = template.content.firstChild as HTMLElement;

    // Then return either an HTMLElement or HTMLCollection,
    // based on whether the input HTML had one or more roots.
    return result;
  }

  public downloadFileAfterGettingMediaLibraryItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FileDownloadActionTypes.GetMediaLibraryItemSuccess),
        tap(async (action: GetMediaLibraryItemSuccess) => {
          window.location.href = `${this.s3BucketUrl}media-library/${action.data.file}`;
        })
      ),
    {
      dispatch: false
    }
  );

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    private actions$: Actions,
    private api: FileDownloadDataApiService
  ) {}
}
