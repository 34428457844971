<ng-container *ngIf="expertsData$ | async as expertData">
  <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
    <app-subpage-header
      [congressMetaData]="congressMetaData"
      [subpageName]="'ExpertsPage.expert' | translate"
    ></app-subpage-header>
    <div
      class="container sticky-container"
      [class.filter-background]="showFilterBackground && applicationName === 'MedToday'"
      [class.filter-background-patients]="showFilterBackground && applicationName === 'PatientsToday'"
      [class.bg-medtoday]="applicationName === 'MedToday'"
    >
      <app-filter
        *ngIf="expertsTypes?.length > 1"
        [filterLabel]="''"
        [hideLabel]="showFilterBackground"
        [type]="'topic'"
        [hasAllButton]="true"
        [selectedOptions]="expertTypesFilter.getSelectedFilterOptions('type')"
        (allButtonHandler)="handleSelectAllExperts()"
        (handleFilter)="handleSelectExpertType($event)"
        [prefix]="'ExpertsPage.Patients.'"
        [filterOptions]="expertTypesFilter.getFilterOptions('type')"
      >
      </app-filter>
      <h6 *ngIf="!showFilterBackground" class="mt-4">{{ 'Common.Departments' | translate }}</h6>
      <div class="flex-fill flex-xs-nowrap overflow-auto flex-sm-wrap my-md-3 d-flex">
        <div class="d-flex flex-fill flex-xs-nowrap overflow-auto flex-sm-wrap text-nowrap mt-md-3">
          <button
            type="button"
            *ngFor="let topic of expertData.topics"
            class="btn btn-programme-day mr-2 mb-2 d-flex align-items-center mobile-margin"
            (click)="handleTopicClick(topic.id)"
          >
            {{ topic.title }}
          </button>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="
        congressMetaData.layout?.banner?.position !== 'null' &&
        congressMetaData.layout.banner?.subpagePosition === 'top'
      "
    >
      <app-congress-banner
        [congressLogo]="congressMetaData.layout?.banner?.logo_left"
        [congressSeriesLogo]="congressMetaData.layout?.banner?.logo"
        [disclaimer]="congressMetaData.layout?.banner?.text"
        class="mb-5 d-block"
      ></app-congress-banner>
    </ng-container>
    <div id="experts" class="container pt-3" [class.subpage-content]="applicationName === 'MedToday'">
      <ng-container *ngIf="expertData.experts?.length > 0; else noData">
        <ng-container *ngIf="topicRows.length > 0">
          <section *ngFor="let row of topicRows" [id]="row.topic.id">
            <div class="row my-4">
              <div class="topic-container col-lg-4 col-12 text-lg-left text-md-left mb-3">
                <h3>{{ row.topic.title }}</h3>
              </div>
              <div class="experts-container col-lg-8 col-12">
                <div class="row">
                  <ng-container *ngFor="let expert of row.experts">
                    <div class="pb-5 col-lg-6 col-md-6 col-12">
                      <div class="d-flex pb-3" *ngIf="expert.profileImage">
                        <img
                          draggable="false"
                          class="rounded-circle"
                          [src]="s3BucketUrl + avatarsImageFolderSlug + expert.profileImage"
                        />
                        <div
                          *ngIf="expert.expertType && applicationName === 'PatientsToday'"
                          class="expert-type ml-3 align-self-center rounded"
                        >
                          <span *ngIf="applicationName === 'MedToday'">{{
                            'ExpertsPage.' + expert.expertType | translate
                          }}</span>
                          <span *ngIf="applicationName === 'PatientsToday'">{{
                            'ExpertsPage.Patients.' + expert.expertType | translate
                          }}</span>
                        </div>
                      </div>
                      <span class="font-size-small">{{ expert | expert }}</span>
                      <p class="text-note max-text-width">{{ expert.areaOfActivity }}</p>
                      <a
                        *ngIf="expert.disclosure || (applicationName === 'PatientsToday' && !expert.disclosure)"
                        class="text-primary font-size-small text-medium"
                        data-toggle="modal"
                        href="#expertDetails"
                        draggable="false"
                        (click)="handleExpertDetailsClick(expert)"
                        ><u>{{ 'ExpertsPage.Details' | translate }}</u>
                      </a>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="divider d-none d-sm-block pb-3 w-100" *ngIf="(expertsWithNoTopic$ | async)?.length"></div>
          </section>
        </ng-container>
        <ng-container *ngIf="expertsWithNoTopic$ | async as expertsWithoutTopic">
          <ng-container *ngIf="expertsWithoutTopic.length > 0">
            <div class="row mb-4">
              <div class="topic-container col-lg-4 col-12 text-lg-left text-md-left text-center mb-3">
                <h3>
                  {{ (hasExpertsWithAtLeastOneTopic$ | async) ? ('ExpertsPage.Other' | translate) : '' }}
                </h3>
              </div>
              <div class="experts-container col-lg-8 col-12">
                <div class="row">
                  <ng-container *ngFor="let expert of expertsWithoutTopic">
                    <div class="pb-5 col-lg-6 col-md-6 col-12">
                      <div class="d-flex pb-3" *ngIf="expert.profileImage">
                        <img
                          draggable="false"
                          class="rounded-circle"
                          [src]="s3BucketUrl + avatarsImageFolderSlug + expert.profileImage"
                        />
                        <div
                          *ngIf="expert.expertType && applicationName === 'PatientsToday'"
                          class="expert-type ml-3 align-self-center rounded"
                        >
                          <span>{{ expert.expertType | translate }}</span>
                        </div>
                      </div>
                      <h5>{{ expert | expert }}</h5>
                      <p class="text-note max-text-width">{{ expert.areaOfActivity }}</p>
                      <a
                        *ngIf="expert.disclosure || (applicationName === 'PatientsToday' && !expert.disclosure)"
                        class="text-primary"
                        data-toggle="modal"
                        href="#expertDetails"
                        draggable="false"
                        (click)="handleExpertDetailsClick(expert)"
                        ><u>{{ 'ExpertsPage.Details' | translate }}</u></a
                      >
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #noData>
      <h6 [innerHTML]="'ExpertsPage.NoData' | translate"></h6>
    </ng-template>

    <!-- Details Dialog -->
    <app-expert-details-modal></app-expert-details-modal>
  </ng-container>
</ng-container>
<app-spacer></app-spacer>
<ng-container *ngIf="congressMetaData$ | async as congressMetaData">
  <ng-container
    *ngIf="
      congressMetaData.layout?.banner?.position !== 'null' &&
      congressMetaData.layout.banner?.subpagePosition === 'bottom'
    "
  >
    <app-congress-banner
      [congressLogo]="congressMetaData.layout?.banner?.logo_left"
      [congressSeriesLogo]="congressMetaData.layout?.banner?.logo"
      [disclaimer]="congressMetaData.layout?.banner?.text"
      [onBottom]="true"
    ></app-congress-banner>
  </ng-container>
</ng-container>
<app-scroll-to-top-button [ngClass]="hasScrolled() ? 'd-block' : 'd-none'"></app-scroll-to-top-button>
<app-async-state-overlay [processKey]="asyncKey"></app-async-state-overlay>
