import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Timestamp } from '../../../models/jw-timestamp.model';
import { fromEvent, merge, switchMap } from 'rxjs';
import { auditTime, filter, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { TimestampsBaseComponent } from '../timestamps-base.component';

declare const jwplayer;

@Component({
  selector: 'app-vod-jw-timestamps',
  templateUrl: '../vod-timestamps.component.html',
  styleUrls: ['../vod-timestamps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VodJwTimestampsComponent extends TimestampsBaseComponent implements OnInit {
  ngOnInit() {
    this.observeEvents();
  }

  constructor(protected cdr: ChangeDetectorRef) {
    super();
  }

  private observeEvents() {
    this.observePlayingVideo();
    this.observeVideoTimestamps();
  }

  private getVideoTimestamps() {
    let cues: Timestamp[] | any = jwplayer().getCues() || [];
    const timestamps = cues.map((timestamp: Timestamp) => ({
      text: timestamp.text.replace('*', ''),
      begin: timestamp.begin,
      hasStar: timestamp.text.indexOf('*') > -1
    }));

    this.hasStar = timestamps.some(t => t.hasStar);
    this.timestamps$.next(timestamps);
    this.hasTimestamps$.next(!!timestamps.length);
  }

  private observePlayingVideo() {
    fromEvent(jwplayer(), 'time')
      .pipe(
        takeUntil(this.ngDestroy$),
        auditTime(500),
        withLatestFrom(this.timestamps$),
        filter(([_, timestamps]) => !!timestamps?.length),
        switchMap(([timeObj]: [{ position: number }, []]) => this.selectCurrentTimestamp(timeObj.position))
      )
      .subscribe();
  }

  private observeVideoTimestamps() {
    merge(fromEvent(jwplayer(), 'metadataCueParsed'), fromEvent(jwplayer(), 'meta'))
      .pipe(
        take(1),
        tap(() => {
          setTimeout(() => this.getVideoTimestamps());
        })
      )
      .subscribe();
  }
}
