import { Component } from '@angular/core';
import { DropdownComponent } from '../dropdown.component';

@Component({
  selector: 'app-dropdown-multiple',
  templateUrl: './dropdown-multiple.component.html',
  styleUrls: ['../dropdown.component.scss']
})
export class DropdownMultipleComponent extends DropdownComponent {
  selectedOptions: any[] = [];

  handleSelectOption(option): void {
    if (!this.selectedOptions.includes(option)) {
      this.selectedOptions.push(option);
    } else {
      this.selectedOptions = this.selectedOptions.filter(opt => opt !== option);
    }
    this.selected.emit(option);
  }

  handleReset(): void {
    this.selectedOptions = [];
    this.resetOption.emit();
  }
}
