import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DynamicScriptLoaderService } from '../../services/dynamic-script-loader-service.service';
import { fromEvent, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Timestamp } from '../../models/jw-timestamp.model';
import { ObservableComponent } from '../observable/observable.component';
import { PlayerPositionUpdater } from '../../models/player-position-updater.model';
import { PlayerInterface } from '../../models/player.interface';

declare var jwplayer;

@Component({
  selector: 'app-jw-player',
  templateUrl: './jw-player.component.html',
  styleUrls: ['./jw-player.component.scss']
})
export class JwPlayerComponent
  extends ObservableComponent
  implements PlayerPositionUpdater, PlayerInterface, OnDestroy
{
  private _mediaId = '';
  public get mediaId() {
    return this._mediaId;
  }
  @Input()
  public set mediaId(value) {
    this._mediaId = value;
    this.loadScripts();
  }
  private _playerId = '';
  public get playerId() {
    return this._playerId;
  }
  @Input()
  public set playerId(value) {
    this._playerId = value;
    this.loadScripts();
  }

  @Input() autostart = false;
  @Input() hideControls = false;
  @Input() selectedLanguage;
  @Input() initialPosition = 0;
  @Output() loaded = new EventEmitter<void>();
  @Output() timestamps = new EventEmitter<Timestamp[]>();
  @Output() languages = new EventEmitter<any[]>();
  @Output() videoWatched = new EventEmitter<{ media: string }>();
  containerId: string = '';
  @Output() position = new EventEmitter<number>();

  get jwplayer() {
    return jwplayer(this.containerId);
  }

  constructor(private dynamicScriptLoader: DynamicScriptLoaderService, private element: ElementRef) {
    super();
  }

  private async loadScripts() {
    if (this._mediaId && this._playerId) {
      this.containerId = `media-${this.mediaId}`;
      await this.dynamicScriptLoader.loadScript(`https://cdn.jwplayer.com/libraries/${this.playerId}.js`);
      this.jwplayer.setup({
        playlist: `https://cdn.jwplayer.com/v2/media/${this.mediaId}`,
        autostart: this.autostart
      });
      this.setupMediaAssets();
      this.loaded.emit();

      if (this.hideControls) {
        this.disableKeyboardEvents();
      }
    }
  }

  ngOnDestroy() {
    if (this.mediaId && this.playerId) {
      this.dynamicScriptLoader.removeScript(`https://cdn.jwplayer.com/libraries/${this.playerId}.js`);
    }
    super.ngOnDestroy();
  }

  disableKeyboardEvents() {
    setTimeout(() => {
      this.element.nativeElement.querySelectorAll('.jwplayer').forEach(elem => {
        elem.addEventListener('keydown', e => e.stopImmediatePropagation(), true);
      });
    }, 1000);
  }

  private setVideoLanguage() {
    const languagesList = this.jwplayer.getCaptionsList();

    if (this.selectedLanguage) {
      const index = languagesList.map(item => item.language).indexOf(this.selectedLanguage);
      if (index !== -1) {
        this.jwplayer.setCurrentCaptions(index);
      }
    }

    this.languages.emit(languagesList);
  }

  setupMediaAssets() {
    fromEvent(this.jwplayer, 'viewable')
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(() => {
        this.observePosition();
        this.setVideoLanguage();
      });

    fromEvent(this.jwplayer, 'captionsChanged')
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(() => {
        this.setVideoLanguage();
      });

    fromEvent(this.jwplayer, 'complete')
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(() => {
        this.videoWatched.emit({ media: this.mediaId });
      });
  }

  observePosition() {
    if (this.initialPosition > 0) {
      this.jwplayer.seek(this.initialPosition);
    }

    interval(10000)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(() => {
        if (this.jwplayer.getState() !== 'paused') {
          this.position.emit(this.jwplayer.getPosition());
        }
      });
  }

  seekTo(timestamp: Timestamp) {
    jwplayer().seek(timestamp.begin);
  }

  getCurrentTime(): Promise<number> {
    return new Promise(() => 0);
  }
}
