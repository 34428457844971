import { Component, HostListener, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  CongressMetadata,
  Format,
  Mode,
  OnDemandVideoListItem,
  OnDemandVideoListResponse
} from 'medtoday-models-library/lib/models';
import { Programme } from 'medtoday-models-library/lib/models/programme.model';
import { Session } from 'medtoday-models-library/lib/models/session.model';
import { TimeSlot } from 'medtoday-models-library/lib/models/timeslot.model';
import { Topic } from 'medtoday-models-library/lib/models/topic.model';
import moment from 'moment';
import { LoadCongressParticipantData } from 'projects/medtoday/src/app/medtoday-store/actions/medtoday-store.actions';
import { imagePaths } from 'projects/medtoday/src/environments/environment';
import { EventRegistrationService } from 'projects/todaylib/shared/services/event-registration.service';
import { combineLatest } from 'rxjs';
import { delay, filter, take, takeUntil, tap } from 'rxjs/operators';

import {
  DataApiActionTypes,
  LoadOnDemandVideos,
  LoadProgrammeData
} from '../../../../core/data/actions/data-api.actions';
import {
  getCongressMetaData,
  getOnDemandVideosData,
  getProgrammeData,
  getProgrammeDataNavigatedFromLandingPage
} from '../../../../core/data/selectors/data-api.selectors';
import { getCongressSlugRouterParam } from '../../../../core/router/selectors/router.selectors';
import { BaseAppState } from '../../../../core/store/reducers';
import { FilterComponent } from '../../../components/filter/filter.component';
import { ObservableComponent } from '../../../components/observable/observable.component';
import { TimeSlotRow } from '../../../models/time-slot-row';
import { SESSION_UTILS_TOKEN } from '../../../utilities/session.utils';
import { hasSrolled } from '../../../utilities/window.utils';
import { Filter } from '../../../modules/filters/classes/filter';
import { FilterOption } from '../../../modules/filters/models/filter-option.model';
import { FilterContainer } from '../../../modules/filters/classes/filter-container';
import { ProgramFilters } from './program-filters';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AnalyticsService } from '../../../../core/data/services/ga-analytics.service';

@Component({
  selector: 'app-programme-page',
  templateUrl: './programme-page.component.html',
  styleUrls: ['./programme-page.component.scss'],
  providers: [EventRegistrationService]
})
export class ProgrammePageComponent extends ObservableComponent implements OnInit, OnDestroy {
  get isVoDCongress(): boolean {
    return this.congressMetaData?.mode === Mode.VoD;
  }

  get isLiveCongress(): boolean {
    return this.congressMetaData?.mode === Mode.Live;
  }

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    private store: Store<BaseAppState>,
    @Inject('applicationName') public applicationName: string,
    @Inject(SESSION_UTILS_TOKEN) private sessionUtils,
    public registrationService: EventRegistrationService,
    private translateService: TranslateService,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {
    super();
  }
  @ViewChildren('filter') filters: QueryList<FilterComponent>;
  readonly loadProgrammeDataAsyncKey = DataApiActionTypes.LoadProgrammeData;

  showFilterBackground = false;
  congressSlug: string;
  logosFolderSlug = imagePaths.logos;
  congressMetaData: CongressMetadata | undefined;
  programme: Programme | undefined;
  vodFormats: Format[] | undefined;
  visibleTimeSlots: TimeSlotRow[] = [];
  programmeDays: moment.Moment[];
  timeSlotRows: TimeSlotRow[];
  currentLang = this.translateService.currentLang;

  navigatedFromLandingPage$ = this.store.select(getProgrammeDataNavigatedFromLandingPage);
  congressMetaData$ = this.store.select(getCongressMetaData);
  onDemandVideosData$ = this.store.select(getOnDemandVideosData);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  programmeData$ = this.store.select(getProgrammeData);
  filterContainer = new FilterContainer({
    videosFilter: new Filter<OnDemandVideoListItem>(),
    sessionFilter: new Filter<Session>()
  });
  selectedDays: FilterOption<any>[] = [];
  selectedTopics: FilterOption<Session | OnDemandVideoListItem>[] = [];
  selectedLanguages: FilterOption<any>[] = [];
  selectedTopicsNames = '';

  get videosFilter() {
    return this.filterContainer.filters['videosFilter'];
  }
  get sessionFilter() {
    return this.filterContainer.filters['sessionFilter'];
  }

  @HostListener('window:scroll', []) hasScrolled() {
    if (window.pageYOffset > 180 && window.outerWidth < 575) {
      this.showFilterBackground = true;
    } else {
      this.showFilterBackground = false;
    }
    return hasSrolled();
  }

  ngOnInit(): void {
    this.registrationService.getUserData();
    this.observeCongressSlug();
    this.registrationService.checkUserCongressData();
    this.observeCongressMetaData();
    this.loadInitialData();
    this.observeProgramme();
    this.observeOnDemandVideos();
    this.observeTimeSlotData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.visibleTimeSlots = [];
    this.programmeDays = [];
    this.programme = undefined;
    this.timeSlotRows = [];
    this.filterContainer.destroy();
  }

  observeProgramme() {
    combineLatest([this.congressMetaData$, this.programmeData$])
      .pipe(
        tap(([congressMetaData, programmeData]) => {
          if (programmeData && congressMetaData) {
            this.programme = programmeData;
            this.programmeDays = programmeData?.scheduledDays
              ?.map((date: string) => moment.utc(date, 'DD-MM-YYYY'))
              .filter((date: moment.Moment) => {
                const timeSlotsForDay = programmeData?.timeSlots?.filter((row: TimeSlot) =>
                  moment.utc(date).isSame(row.start, 'D')
                );

                return timeSlotsForDay?.some((ts: TimeSlot) => {
                  if (congressMetaData.mode === Mode.Live) {
                    return programmeData.sessions.some(session => session.timeSlotId === ts.id);
                  } else {
                    return (
                      programmeData.onDemandVideos.some(video =>
                        moment.utc(video.timeSlotStart).isSame(moment.utc(ts.start))
                      ) || programmeData.sessions.some(session => session.timeSlotId === ts.id)
                    );
                  }
                });
              })
              .sort((a, b) => a.diff(b));
            this.observeNavigation();
            this.initFilters();
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  observeCongressMetaData() {
    this.congressMetaData$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((congressMetaData: CongressMetadata) => (this.congressMetaData = congressMetaData));
  }

  observeOnDemandVideos() {
    this.onDemandVideosData$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((onDemandVideos: OnDemandVideoListResponse) => (this.vodFormats = onDemandVideos?.formats));
  }

  observeCongressSlug() {
    this.congressSlug$.pipe(takeUntil(this.ngDestroy$)).subscribe((slug: string) => {
      this.congressSlug = slug;

      if (this.registrationService.user) {
        this.store.dispatch(new LoadCongressParticipantData(slug, this.registrationService.user.sub));
      }
    });
  }

  loadInitialData() {
    this.congressSlug$.pipe(take(1)).subscribe((slug: string) => {
      if (slug !== '') {
        this.store.dispatch(new LoadOnDemandVideos(slug));
        this.store.dispatch(new LoadProgrammeData(slug));
      }
    });
  }

  observeNavigation() {
    this.navigatedFromLandingPage$
      .pipe(
        take(1),
        delay(200),
        tap(navigatedFromLandingPage => {
          if (navigatedFromLandingPage) {
            this.scrollToLiveSessions();
          }
        })
      )
      .subscribe();
  }

  observeTimeSlotData() {
    combineLatest([
      this.congressMetaData$,
      this.videosFilter.filteredData$,
      this.sessionFilter.filteredData$,
      this.programmeData$
    ])
      .pipe(
        filter(([congressMetaData, _, __, programData]) => !!congressMetaData && !!programData),
        tap(([congressMetaData, onDemandVideos, sessions, programData]) => {
          const timeSlots = programData.timeSlots;
          if (timeSlots?.length > 0) {
            const timeSlotsAgg: TimeSlotRow[] = [];
            timeSlots.forEach((slot: TimeSlot) => {
              if (timeSlotsAgg.findIndex((row: TimeSlotRow) => row.id === slot.id) === -1) {
                const timeSlotRow: TimeSlotRow = {
                  id: slot.id,
                  start: moment.utc(slot.start).toISOString(),
                  finish: moment.utc(slot.finish).toISOString(),
                  sessions: sessions.filter((session: Session) => session.timeSlotId === slot.id),
                  videos:
                    congressMetaData.mode === Mode.VoD
                      ? onDemandVideos.filter((video: OnDemandVideoListItem) =>
                          moment.utc(video.timeSlotStart).isSame(moment.utc(slot.start))
                        )
                      : []
                };

                if (timeSlotRow.sessions?.length || timeSlotRow.videos.length) {
                  timeSlotsAgg.push(timeSlotRow);
                }
              }
            });

            timeSlotsAgg.sort((a: TimeSlotRow, b: TimeSlotRow) => a.start.localeCompare(b.start));

            this.timeSlotRows = timeSlotsAgg;
            this.visibleTimeSlots = timeSlotsAgg;
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  handleSelectFilter(filterName: string, filterOption: FilterOption<any>) {
    this.filterContainer.applyFilter(filterName, filterOption);
    this.refreshSelectedOptions(filterName);
    if (this.selectedTopics?.length) {
      this.selectedTopicsNames = this.selectedTopics.map(topic => topic.source.title).join(', ');
    } else {
      this.selectedTopicsNames = '';
    }
  }

  private refreshSelectedOptions(filterName: string) {
    switch (filterName) {
      case 'day':
        this.selectedDays = this.filterContainer.getSelectedFilterOptions('day');
        break;
      case 'topic':
        this.selectedTopics = this.filterContainer.getSelectedFilterOptions('topic');
        break;
      case 'language':
        this.selectedLanguages = this.filterContainer.getSelectedFilterOptions('language');
        if (this.selectedLanguages?.length) {
          this.analyticsService.pushTag(
            {},
            'video-language',
            'location',
            this.selectedLanguages.map(lang => lang.source).join(',')
          );
        }
        break;
    }

    this.filterContainer.updateFilterQueryParams(this.router, this.location, ['topic', 'language']);
  }

  getTopicById(topicId: number, topics: Topic[]) {
    return topics.find((topic: Topic) => topic.id === topicId);
  }

  getCommonSessionFormat(sessions: Session[]) {
    let format = '';
    sessions.forEach((sn: Session) => {
      const areAllSessionOfSameFormat = sessions.every((session: Session) => sn.format === session.format);
      if (areAllSessionOfSameFormat) {
        format = sessions[0].format;
      }
    });

    return format;
  }

  getCommonVoDFormat(videos: OnDemandVideoListItem[]) {
    let format = '';
    videos.forEach((video: OnDemandVideoListItem) => {
      const areAllSessionOfSameFormat = videos.every((v: OnDemandVideoListItem) => v.formatId === video.formatId);
      if (areAllSessionOfSameFormat) {
        format = this.getVoDFormat(videos[0]);
      }
    });

    return format;
  }

  getVoDFormat(video: OnDemandVideoListItem): string {
    return this.vodFormats?.find(f => f.id === video.formatId)?.title ?? '';
  }

  scrollToNextSessions(index: number) {
    document.getElementById(index.toString())?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  scrollToLiveSessions() {
    document.getElementById('live')?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  isLive(row: TimeSlotRow) {
    return row.sessions?.length && this.sessionUtils.isActive(row);
  }

  isAnyContentAvailable() {
    return this.visibleTimeSlots.some((tr: TimeSlotRow) => tr.sessions?.length > 0 || tr.videos?.length > 0);
  }

  isAnySessionLive() {
    return this.visibleTimeSlots.some((row: TimeSlotRow) => this.isLive(row));
  }

  hasOnlyLiveSessions() {
    return this.visibleTimeSlots.every((row: TimeSlotRow) => this.isLive(row));
  }

  private initFilters() {
    combineLatest([this.programmeData$, this.congressMetaData$])
      .pipe(
        filter(([program, metadata]) => !!program?.timeSlots?.length && !!metadata),
        take(1)
      )
      .subscribe(([program, metadata]) => {
        if (metadata.mode === Mode.VoD) {
          ProgramFilters.initVideosFilter(
            program,
            this.videosFilter,
            this.programmeDays,
            metadata.filterOptions['program']
          );
        }
        ProgramFilters.initSessionFilter(
          program,
          this.sessionFilter,
          this.programmeDays,
          metadata.filterOptions['program']
        );
        this.setDefaultFilters();
      });
  }

  private setDefaultFilters() {
    const dateToday = moment.utc(new Date());
    const actualCongressDay = this.filterContainer
      .getFilterOptions('day')
      .find(day => day.source.isSame(dateToday, 'd'));
    if (actualCongressDay) {
      this.handleSelectFilter('day', actualCongressDay);
    }
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      for (const filterName in params) {
        const filterOptions = params[filterName].split(',');
        filterOptions.forEach(filterOption => {
          const matchedFilterOption = this.filterContainer
            .getFilterOptions(filterName)
            .find(option => option.id == filterOption);
          if (matchedFilterOption) {
            this.handleSelectFilter(filterName, matchedFilterOption);
          }
        });
      }
    });
  }

  resetFilter(filterName: string) {
    this.filterContainer.reset(filterName);
    this.refreshSelectedOptions(filterName);
  }
}
