<div id="row" class="my-md-2 justify-content-center justify-content-md-start px-2 px-md-0 pb-2 pb-md-0">
  <div class="dropdown secondary w-100">
    <button
      type="button"
      class="btn d-flex dropdown-toggle navigation-menu align-items-center justify-content-between w-100 h-100 text-light"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <p class="m-0">
        {{ placeholder }}
        <ng-container *ngIf="selectedOptions?.length"> ({{ selectedOptions.length }}) </ng-container>
      </p>
      <i class="bi bi-chevron-down"></i>
    </button>
    <div class="dropdown-menu custom-scrollbar bg-dark w-100" aria-labelledby="select">
      <a
        class="dropdown-item d-flex flex-row"
        [ngClass]="!selectedOption ? 'text-danger' : 'text-light'"
        (click)="handleReset()"
      >
        {{ 'Common.All' | translate }}
      </a>
      <a
        class="dropdown-item d-flex flex-row"
        *ngFor="let option of options; let i = index"
        [ngClass]="selectedOptions.includes(option) ? 'text-danger' : 'text-light'"
        [class.disabled]="option.producesRecords === false"
        (click)="handleSelectOption(option)"
      >
        {{ (prefix ? prefix : '') + optionLabel(option) | translate }}
      </a>
    </div>
  </div>
</div>
