import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

interface Script {
  src: string;
  loaded: boolean;
}

@Injectable()
export class DynamicScriptLoaderService {
  private renderer: Renderer2;
  private scripts = new Map<string, Script>();

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public loadScript(srcUrl: string, scriptId?: string): Promise<Script> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Script>(async resolve => {
      if (!this.scripts.has(srcUrl)) {
        this.scripts.set(srcUrl, {
          loaded: false,
          src: srcUrl
        });
        // load script
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.id = scriptId ? scriptId : srcUrl;
        script.crossorigin = 'anonymous';
        script.src = srcUrl;
        if (script.readyState) {
          // for the Internet Explorer to work
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts[srcUrl].loaded = true;
              resolve({ src: srcUrl, loaded: true });
            }
          };
        } else {
          // other browsers
          script.onload = () => {
            script.onload = null;
            this.scripts.get(srcUrl)!.loaded = true;
            resolve({ src: srcUrl, loaded: true });
          };
        }
        script.onerror = () => resolve({ src: srcUrl, loaded: false });
        // append to head element
        this.renderer.appendChild(this.document.head, script);
      } else {
        // if script exists already, remove it and reload
        this.removeScript(srcUrl);
        return resolve(await this.loadScript(srcUrl));
      }
    });
  }

  removeScript(srcUrl: string) {
    this.scripts.delete(srcUrl);
    this.renderer.removeChild(this.document.head, document.getElementById(srcUrl));
  }
}
