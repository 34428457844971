import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GoToLogin } from 'projects/medtoday/src/app/auth-ui/actions/auth-ui-navigation.actions';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
  ApiResponse,
  RequestPasswordResetRequest,
  ResetPasswordRequest,
  UpdatePasswordRequest
} from '../../../shared/models';
import { AsyncAction } from '../../async-state/models/async-action.model';
import { handleErrors } from '../../async-state/operators/handle-errors';
import { GoToCongressList } from '../../router/actions/main-navigation.actions';
import { ToastService } from '../../toasts/services/toast.service';
import {
  EditPassword,
  EditPasswordFail,
  EditPasswordSuccess,
  PasswordActionTypes,
  RequestPasswordReset,
  RequestPasswordResetFail,
  RequestPasswordResetSuccess,
  ResetPassword,
  ResetPasswordFail,
  ResetPasswordSuccess
} from '../actions/auth-password.actions';
import { AuthService } from '../services/auth.service';

const PASSWORD_CHANGED_TITLE = 'Toast.PasswordChangedTitle';
const PASSWORD_CHANGED_MESSAGE = 'Toast.PasswordChangedMessage';

const PASSWORD_REQUESTED_TITLE = 'Toast.NewPasswordTitle';
const PASSWORD_REQUESTED_MESSAGE = 'Toast.NewPasswordMessage';

const PASSWORD_RESET_TITLE = 'Toast.ResetPasswordTitle';
const PASSWORD_RESET_MESSAGE = 'Toast.ResetPasswordMessage';

@Injectable()
export class AuthPasswordEffects {
  editPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PasswordActionTypes.EditPassword),
      map((action: EditPassword) => action.payload),
      switchMap((request: UpdatePasswordRequest) =>
        this.apiService.updatePassword(request).pipe(
          handleErrors(() => new EditPasswordFail()),
          map((response: ApiResponse<string>) => new EditPasswordSuccess(response)),
          catchError((errorAction: AsyncAction) => of(errorAction))
        )
      )
    )
  );

  redirectAfterEditPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PasswordActionTypes.EditPasswordSuccess),
        map((_action: EditPasswordSuccess) => new GoToCongressList())
      ),
    { dispatch: true }
  );

  showSuccessMessageAfterEditPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PasswordActionTypes.EditPasswordSuccess),
        tap((_action: EditPasswordSuccess) => {
          this.toastService.showInfo(PASSWORD_CHANGED_TITLE, PASSWORD_CHANGED_MESSAGE);
        })
      ),
    { dispatch: false }
  );

  requestPasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PasswordActionTypes.RequestPasswordReset),
      map((action: RequestPasswordReset) => action.payload),
      switchMap((request: RequestPasswordResetRequest) =>
        this.apiService.requestPasswordReset(request).pipe(
          handleErrors(() => new RequestPasswordResetFail()),
          map((response: ApiResponse<string>) => new RequestPasswordResetSuccess(response)),
          catchError((errorAction: AsyncAction) => of(errorAction))
        )
      )
    )
  );

  redirectAfterRequestPasswordReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PasswordActionTypes.RequestPasswordResetSuccess),
        map((_action: RequestPasswordResetSuccess) => new GoToLogin())
      ),
    { dispatch: true }
  );

  showSuccessMessageAfterRequestPasswordReset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PasswordActionTypes.RequestPasswordResetSuccess),
        tap((_action: RequestPasswordResetSuccess) => {
          this.toastService.showInfo(PASSWORD_REQUESTED_TITLE, PASSWORD_REQUESTED_MESSAGE);
        })
      ),
    { dispatch: false }
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PasswordActionTypes.ResetPassword),
      map((action: ResetPassword) => action.payload),
      switchMap((request: ResetPasswordRequest) =>
        this.apiService.resetPassword(request).pipe(
          handleErrors(() => new ResetPasswordFail()),
          map((response: ApiResponse<string>) => new ResetPasswordSuccess(response)),
          catchError((errorAction: AsyncAction) => of(errorAction))
        )
      )
    )
  );

  redirectAfterResetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PasswordActionTypes.ResetPasswordSuccess),
        map((_action: ResetPasswordSuccess) => new GoToLogin())
      ),
    { dispatch: true }
  );

  showSuccessMessageAfterResetPassword$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PasswordActionTypes.ResetPasswordSuccess),
        tap((_action: ResetPasswordSuccess) => {
          this.toastService.showInfo(PASSWORD_RESET_TITLE, PASSWORD_RESET_MESSAGE);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private apiService: AuthService, private toastService: ToastService) {}
}
