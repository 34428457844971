<div class="container mt-5">
  <div class="row d-flex align-items-center">
    <div class="col-sm-12 col-md-12 col-xl-6 col-lg-6 order-md-1 h-100 mt-5 pr-sm-2 mb-3">
      <h1 class="display-4" [innerHTML]="'PartnerNetwork.Title' | translate"></h1>
      <div class="col-sm-12 col-md-12 col-xl-10 col-lg-10 header-desc pl-0 font-weight-bolder">
        <div
          class="pt-3 font-size-large font-weight-bolder"
          replaceToDownloadLink
          [innerHTML]="'PartnerNetwork.Description' | translate"
        ></div>
      </div>
      <a
        href="mailto: {{ 'PartnerNetwork.Mail' | translate }}"
        class="btn btn-danger rounded-pill mt-4 font-size-large"
        >{{ 'PartnerNetwork.Mail' | translate }}</a
      >
    </div>
  </div>
</div>
<div
  class="container sticky-container"
  [class.filter-background-patients]="isPatientsTodayApp"
  *ngIf="topics$ | async as topics"
>
  <app-filter
    [filterOptions]="filterTopics$ | async"
    [hideLabel]="isPatientsTodayApp"
    (handleFilter)="handleSelectTopic($event)"
    [dropdownForMobileEnabled]="false"
    [filterLabel]="'PartnerNetwork.JumpTo' | translate"
    [attributeAsButtonLabel]="'title'"
    class="bolded partner-filter"
  >
  </app-filter>
</div>
<div class="container mt-5">
  <ng-container *ngIf="partnersWithoutTopics$ | async as partnersWithoutTopics">
    <div class="row" id="0" *ngIf="partnersWithoutTopics?.length">
      <h3 class="col-12 mb-4">{{ 'PartnerNetwork.UnassignedGroup' | translate }}</h3>
      <div class="col-12 col-lg-3 col-md-6" *ngFor="let partner of partnersWithoutTopics">
        <app-partner-network-card [partner]="partner"></app-partner-network-card>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="partnerBox$ | async as partnerBox">
    <app-partner-network-box
      *ngIf="partnerBox.partnerBoxPosition === 'top'"
      [partnerBox]="partnerBox"
    ></app-partner-network-box
  ></ng-container>
  <ng-container *ngFor="let topicPartner of partnersByTopics$ | async">
    <div class="row" id="{{ topicPartner.id }}">
      <h3 class="col-12 mb-4">{{ topicPartner.title }}</h3>
      <div class="col-12 col-lg-3 col-md-6" *ngFor="let partner of topicPartner.items">
        <app-partner-network-card [partner]="partner"></app-partner-network-card>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="partnerBox$ | async as partnerBox">
    <app-partner-network-box
      *ngIf="partnerBox.partnerBoxPosition === 'bottom'"
      [partnerBox]="partnerBox"
    ></app-partner-network-box
  ></ng-container>
  <app-spacer></app-spacer>
</div>

<app-scroll-to-top-button [ngClass]="hasScrolled() ? 'd-block' : 'd-none'"></app-scroll-to-top-button>
<app-async-state-overlay [processKey]="loadPartnersKey"></app-async-state-overlay>
