import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditPassword, PasswordActionTypes } from 'projects/todaylib/core/auth/actions/auth-password.actions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { UpdatePasswordRequest } from 'projects/todaylib/shared/models';

@Component({
  selector: 'app-edit-password-page',
  templateUrl: './edit-password-page.component.html',
  styleUrls: ['./edit-password-page.component.scss']
})
export class EditPasswordPageComponent {
  readonly asyncProcessKey = PasswordActionTypes.EditPassword;

  constructor(public store: Store<BaseAppState>) {}

  handleEditPasswordClick(request: UpdatePasswordRequest) {
    this.store.dispatch(new EditPassword(request));
  }
}
