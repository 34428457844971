import { QuestionAnswers } from 'medtoday-models-library';
import { ElearningActions, ElearningActionTypes } from '../actions/elearning.actions';
import { ElearningResultResponse } from 'medtoday-models-library/src/models/responses/e-learning-response.model';
import { ELearningMetadataResponse } from 'medtoday-models-library/lib';

export interface ElearningDataState {
  questionsAnswers: QuestionAnswers[];
  answersResults: ElearningResultResponse | undefined;
  userMetadata: ELearningMetadataResponse | undefined;
}

const initialState: ElearningDataState = {
  questionsAnswers: [],
  answersResults: undefined,
  userMetadata: undefined
};

export function reducer(state = initialState, action: ElearningActions): ElearningDataState {
  switch (action.type) {
    case ElearningActionTypes.LoadQuestionsSuccess: {
      return {
        ...state,
        questionsAnswers: action.response.questionAnswers
      };
    }
    case ElearningActionTypes.SubmitElearningAnswersSuccess: {
      return {
        ...state,
        answersResults: action.response
      };
    }
    case ElearningActionTypes.GetElearningUserMetadataSuccess: {
      return {
        ...state,
        userMetadata: action.response
      };
    }
    case ElearningActionTypes.PurgeData: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
