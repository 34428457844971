import { Component, HostListener, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CongressMetadata,
  FeaturedContentResponse,
  Format,
  Mode,
  OnDemandVideoListItem,
  Session,
  Sponsor,
  TimeSlot,
  Topic
} from 'medtoday-models-library/lib/models';
import moment from 'moment';
import { LoadCongressParticipantData } from 'projects/medtoday/src/app/medtoday-store/actions/medtoday-store.actions';
import { imagePaths } from 'projects/medtoday/src/environments/environment';
import { EventRegistrationService } from 'projects/todaylib/shared/services/event-registration.service';
import { combineLatest } from 'rxjs';
import { delay, filter, take, takeUntil, tap } from 'rxjs/operators';

import { DataApiActionTypes, LoadFeaturedContentOverview } from '../../../../core/data/actions/data-api.actions';
import {
  getCongressMetaData,
  getFeaturedContentOverviewData,
  getProgrammeDataNavigatedFromLandingPage
} from '../../../../core/data/selectors/data-api.selectors';
import { getCongressSlugRouterParam } from '../../../../core/router/selectors/router.selectors';
import { BaseAppState } from '../../../../core/store/reducers';
import { FilterComponent } from '../../../components/filter/filter.component';
import { ObservableComponent } from '../../../components/observable/observable.component';
import { TimeSlotRow } from '../../../models/time-slot-row';
import { SESSION_UTILS_TOKEN } from '../../../utilities/session.utils';
import { hasSrolled } from '../../../utilities/window.utils';
import { ProgramFilters } from '../programme-page/program-filters';
import { FilterContainer } from '../../../modules/filters/classes/filter-container';
import { Filter } from '../../../modules/filters/classes/filter';
import { FilterOption } from '../../../modules/filters/models/filter-option.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AnalyticsService } from '../../../../core/data/services/ga-analytics.service';

@Component({
  selector: 'app-featured-content-page',
  templateUrl: './featured-content-page.component.html',
  styleUrls: ['./featured-content-page.component.scss'],
  providers: [EventRegistrationService]
})
export class FeaturedContentPageComponent extends ObservableComponent implements OnInit, OnDestroy {
  @ViewChildren('filter') filters: QueryList<FilterComponent>;
  readonly loadFeaturedContentAsyncKey = DataApiActionTypes.LoadFeaturedContentOverview;
  showFilterBackground = false;
  logosFolderSlug = imagePaths.logos;
  congressSlug: string;
  sponsors: Sponsor[];
  selectedSponsor: Sponsor | undefined;
  selectableDays: moment.Moment[];
  vodFormats: Format[] | undefined;
  congressMetaData: CongressMetadata;
  selectedFormat: string | undefined;
  formats: string[] = [];
  topics: Topic[];
  timeSlotRows: TimeSlotRow[] = [];
  visibleTimeSlots: TimeSlotRow[] = [];
  featuredContent: FeaturedContentResponse | undefined = undefined;
  featuredContentOverviewData$ = this.store.select(getFeaturedContentOverviewData);
  congressMetaData$ = this.store.select(getCongressMetaData);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  navigatedFromLandingPage$ = this.store.select(getProgrammeDataNavigatedFromLandingPage);
  filterContainer = new FilterContainer({
    videosFilter: new Filter<OnDemandVideoListItem>(),
    sessionFilter: new Filter<Session>()
  });
  selectedDays: FilterOption<Session | OnDemandVideoListItem>[] = [];
  selectedTopics: FilterOption<Session | OnDemandVideoListItem>[] = [];
  selectedLanguages: FilterOption<Session | OnDemandVideoListItem>[] = [];
  selectedFormats: FilterOption<Session | OnDemandVideoListItem>[] = [];
  selectedSponsors: FilterOption<Session | OnDemandVideoListItem>[] = [];
  selectedTopicsNames = '';

  get videosFilter() {
    return this.filterContainer.filters['videosFilter'];
  }
  get sessionFilter() {
    return this.filterContainer.filters['sessionFilter'];
  }

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    @Inject('applicationName') public applicationName: string,
    private store: Store<BaseAppState>,
    public registrationService: EventRegistrationService,
    @Inject(SESSION_UTILS_TOKEN) private sessionUtils,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  @HostListener('window:scroll', []) hasScrolled() {
    if (window.pageYOffset > 180 && window.outerWidth < 575) {
      this.showFilterBackground = true;
    } else {
      this.showFilterBackground = false;
    }
    return hasSrolled();
  }

  get isVoDCongress(): boolean {
    return this.congressMetaData?.mode === Mode.VoD;
  }

  get isLiveCongress(): boolean {
    return this.congressMetaData?.mode === Mode.Live;
  }

  ngOnInit(): void {
    this.registrationService.getUserData();
    this.observeCongressSlug();
    this.registrationService.checkUserCongressData();
    this.observeCongressMetaData();
    this.store.dispatch(new LoadFeaturedContentOverview(this.congressSlug));
    this.observeData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.filterContainer.destroy();
  }

  handleSelectFilter(filterName: string, filterOption: FilterOption<any>) {
    this.filterContainer.applyFilter(filterName, filterOption);
    this.refreshSelectedOptions(filterName);
    if (this.selectedTopics?.length) {
      this.selectedTopicsNames = this.selectedTopics.map(topic => topic.source.title).join(', ');
    } else {
      this.selectedTopicsNames = '';
    }
  }

  private refreshSelectedOptions(filterName: string) {
    switch (filterName) {
      case 'day':
        this.selectedDays = this.filterContainer.getSelectedFilterOptions('day');
        break;
      case 'topic':
        this.selectedTopics = this.filterContainer.getSelectedFilterOptions('topic');
        break;
      case 'language':
        this.selectedLanguages = this.filterContainer.getSelectedFilterOptions('language');
        if (this.selectedLanguages?.length) {
          this.analyticsService.pushTag(
            {},
            'video-language',
            'location',
            this.selectedLanguages.map(lang => lang.source).join(',')
          );
        }
        break;
      case 'sponsor':
        this.selectedSponsors = this.filterContainer.getSelectedFilterOptions('sponsor');
        break;
      case 'format':
        this.selectedFormats = this.filterContainer.getSelectedFilterOptions('format');
        break;
    }

    this.filterContainer.updateFilterQueryParams(this.router, this.location, [
      'topic',
      'language',
      'sponsor',
      'format'
    ]);
  }

  observeNavigation() {
    this.navigatedFromLandingPage$
      .pipe(
        take(1),
        delay(200),
        tap(navigatedFromLandingPage => {
          if (navigatedFromLandingPage) {
            this.scrollToLiveSessions();
          }
        })
      )
      .subscribe();
  }

  observeCongressSlug() {
    this.congressSlug$.pipe(takeUntil(this.ngDestroy$)).subscribe((slug: string) => {
      this.congressSlug = slug;
      if (this.registrationService.user) {
        this.store.dispatch(new LoadCongressParticipantData(slug, this.registrationService.user.sub));
      }
    });
  }

  observeData() {
    this.observeProgrammeDays();
    this.initializeTimeSlots();
    this.featuredContentOverviewData$
      .pipe(
        tap((data: FeaturedContentResponse) => {
          this.initializeTopics(data);
          this.initializeSponsors(data);
          this.initializeFormats(data);
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  observeCongressMetaData() {
    this.congressMetaData$
      .pipe(
        filter(congressMetaData => !!congressMetaData),
        takeUntil(this.ngDestroy$)
      )
      .subscribe((congressMetaData: CongressMetadata) => (this.congressMetaData = congressMetaData));
  }

  observeProgrammeDays() {
    combineLatest([this.congressMetaData$, this.featuredContentOverviewData$])
      .pipe(
        filter(([congressMetaData, data]) => !!congressMetaData && !!data),
        tap(([metaData, data]: [CongressMetadata, FeaturedContentResponse]) => {
          if (data && metaData?.filterOptions['featured'].includes('day')) {
            this.selectableDays = data.programme.scheduledDays
              ?.map((day: string) => {
                return moment.utc(day, 'DD-MM-YYYY');
              })
              .filter((day: moment.Moment) => {
                const timeSlotsForDay = data?.programme?.timeSlots?.filter((row: TimeSlot) =>
                  moment.utc(day).isSame(row.start, 'D')
                );

                return timeSlotsForDay?.some((ts: TimeSlot) => {
                  if (metaData.mode === Mode.Live) {
                    return data.programme.sessions.some(session => session.timeSlotId === ts.id);
                  } else {
                    return (
                      data.programme.onDemandVideos.some(video =>
                        video.sponsors?.length ? moment.utc(video.timeSlotStart).isSame(moment.utc(ts.start)) : null
                      ) || data.programme.sessions.some(session => session.timeSlotId === ts.id)
                    );
                  }
                });
              })
              .sort((a: moment.Moment, b: moment.Moment) => a.diff(b));

            this.initFilters();
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  getTopicById(topicId: number, topics: Topic[]) {
    return topics.find((topic: Topic) => topic.id === topicId);
  }

  getCommonVoDFormat(videos: OnDemandVideoListItem[]) {
    let format = '';
    videos.forEach((video: OnDemandVideoListItem) => {
      const areAllSessionOfSameFormat = videos.every((v: OnDemandVideoListItem) => v.formatId === video.formatId);
      if (areAllSessionOfSameFormat) {
        format = this.getVoDFormat(videos[0]);
      }
    });

    return format;
  }

  getVoDFormat(video: OnDemandVideoListItem): string {
    return this.vodFormats?.find(f => f.id === video.formatId)?.title ?? '';
  }

  initializeSponsors(featuredContent: FeaturedContentResponse) {
    if (featuredContent?.sponsors?.length > 0) {
      this.sponsors = [];
      featuredContent.sponsors.forEach((sp: Sponsor) => {
        if (this.sponsors.some(_sp => _sp.id === sp.id)) {
          return false;
        }

        if (
          featuredContent.programme.sessions.some((sn: Session) =>
            sn.sponsors.some(sponsor => sponsor.sponsorId === sp.id)
          )
        ) {
          this.sponsors.push(sp);
          return;
        }
        if (
          featuredContent.programme.onDemandVideos.some((video: OnDemandVideoListItem) =>
            video.sponsors?.some(sponsor => sponsor.id === sp.id)
          )
        ) {
          this.sponsors.push(sp);
        }
      });
    }
  }

  initializeTopics(featuredContent: FeaturedContentResponse) {
    if (featuredContent?.programme?.topics?.length > 0) {
      this.topics = [];
      featuredContent.programme.topics.forEach((topic: Topic) => {
        if (featuredContent.programme.sessions.findIndex((sn: Session) => sn.topic?.id === topic.id) !== -1) {
          this.topics.push(topic);
        }
      });
    }
  }

  initializeTimeSlots() {
    combineLatest([
      this.congressMetaData$,
      this.videosFilter.filteredData$,
      this.sessionFilter.filteredData$,
      this.featuredContentOverviewData$
    ])
      .pipe(
        tap(
          ([metaData, onDemandVideos, sessions, featuredProgram]: [
            CongressMetadata,
            OnDemandVideoListItem[],
            Session[],
            FeaturedContentResponse
          ]) => {
            if (featuredProgram?.programme?.timeSlots?.length > 0) {
              const timeSlots: TimeSlotRow[] = [];
              featuredProgram.programme.timeSlots.forEach((slot: TimeSlot) => {
                if (timeSlots.findIndex((row: TimeSlotRow) => row.id === slot.id) === -1) {
                  const timeSlotRow: TimeSlotRow = {
                    id: slot.id,
                    start: moment.utc(slot.start).toISOString(),
                    finish: moment.utc(slot.finish).toISOString(),
                    sessions: sessions.filter((session: Session) => session.timeSlotId === slot.id),
                    videos:
                      metaData.mode === Mode.VoD
                        ? onDemandVideos.filter((video: OnDemandVideoListItem) =>
                            video.sponsors?.length
                              ? moment.utc(video.timeSlotStart).isSame(moment.utc(slot.start))
                              : null
                          )
                        : []
                  };
                  timeSlots.push(timeSlotRow);
                }
              });

              this.timeSlotRows = timeSlots.sort((a: TimeSlotRow, b: TimeSlotRow) => a.start.localeCompare(b.start));
              this.visibleTimeSlots = this.timeSlotRows;
              this.observeNavigation();
            }
          }
        ),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  initializeFormats(featuredContent: FeaturedContentResponse) {
    if (featuredContent?.programme?.sessions?.length > 0) {
      featuredContent.programme?.sessions.forEach((sn: Session) => {
        if (!this.formats.includes(sn.format)) {
          this.formats.push(sn.format);
        }
      });
    }
  }

  scrollToLiveSessions() {
    document.getElementById('live')?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  isLive(row: TimeSlotRow) {
    return this.sessionUtils.isActive(row);
  }

  isAnyContentAvailable() {
    return this.visibleTimeSlots.some((tr: TimeSlotRow) => tr.sessions?.length > 0 || tr.videos?.length > 0);
  }

  getCommonSessionFormat(sessions: Session[]) {
    let format = '';
    sessions.forEach((sn: Session) => {
      const areAllSessionOfSameFormat = sessions.every((session: Session) => sn.format === session.format);
      if (areAllSessionOfSameFormat) {
        format = sessions[0].format;
      }
    });

    return format;
  }

  private initFilters() {
    combineLatest([this.featuredContentOverviewData$, this.congressMetaData$])
      .pipe(
        filter(([featuredContent, metadata]) => !!featuredContent?.programme.timeSlots?.length && !!metadata),
        take(1)
      )
      .subscribe(([featuredContent, metadata]) => {
        ProgramFilters.initSessionFilter(
          featuredContent.programme,
          this.sessionFilter,
          this.selectableDays,
          metadata.filterOptions['featured']
        );
        this.setDefaultFilters();
      });
  }

  private setDefaultFilters() {
    const dateToday = moment.utc(new Date());
    const actualCongressDay = this.filterContainer
      .getFilterOptions('day')
      .find(day => day.source.isSame(dateToday, 'd'));
    if (actualCongressDay) {
      this.handleSelectFilter('day', actualCongressDay);
    }
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      for (const filterName in params) {
        const filterOptions = params[filterName].split(',');
        filterOptions.forEach(filterOption => {
          const matchedFilterOption = this.filterContainer
            .getFilterOptions(filterName)
            .find(option => option.id == filterOption);
          if (matchedFilterOption) {
            this.handleSelectFilter(filterName, matchedFilterOption);
          }
        });
      }
    });
  }

  resetFilter(filterName: string) {
    this.filterContainer.reset(filterName);
    this.refreshSelectedOptions(filterName);
  }
}
