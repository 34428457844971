import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ELearningMetadataResponse,
  ElearningQuestionsResponse,
  ElearningResultResponse
} from 'medtoday-models-library/lib';
import { createNeedsAuthenticationHeader } from '../../../../core/data/utilities/networking.utilites';

@Injectable()
export class ElearningService {
  constructor(@Inject('apiBaseUrl') protected apiBaseUrl: string, protected http: HttpClient) {}

  getQuestionsAnswers(vodId: number): Observable<ElearningQuestionsResponse> {
    const headers = createNeedsAuthenticationHeader();
    return this.http.get<ElearningQuestionsResponse>(`${this.apiBaseUrl}/elearning/${vodId}`, headers);
  }

  submitElearningAnswers(vodId: number, data: {}): Observable<ElearningResultResponse> {
    const headers = createNeedsAuthenticationHeader();
    return this.http.post<ElearningResultResponse>(`${this.apiBaseUrl}/elearning/${vodId}`, { data }, headers);
  }

  getElearningMetadata(vodId: number): Observable<ELearningMetadataResponse> {
    const headers = createNeedsAuthenticationHeader();
    return this.http.get<ELearningMetadataResponse>(`${this.apiBaseUrl}/elearning/${vodId}/metadata`, headers);
  }
}
