import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CongressLandingPage, Sponsor } from 'medtoday-models-library';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { LoadCongressLangingPageData } from '../../../../../../todaylib/core/data/actions/data-api.actions';
import {
  getCongressLandingPageData,
  getCongressMetaData
} from '../../../../../../todaylib/core/data/selectors/data-api.selectors';
import { GoToExperts, GoToProgramme } from '../../../../../../todaylib/core/router/actions/main-navigation.actions';
import { getCongressSlugRouterParam } from '../../../../../../todaylib/core/router/selectors/router.selectors';
import { BaseAppState } from '../../../../../../todaylib/core/store/reducers';
import { ObservableComponent } from '../../../../../../todaylib/shared/components/observable/observable.component';
import { imagePaths } from '../../../../environments/environment';
import { GoToEditUser } from '../../../auth-ui/actions/auth-ui-navigation.actions';
import { LoadCongressCmePageData } from '../../../medtoday-store/actions/medtoday-store.actions';
import { getCongressCmePageData } from '../../../medtoday-store/selectors/medtoday-store.selectors';
import { MapSponsors } from '../../../../../../todaylib/shared/utilities/sponsor.utils';

@Component({
  selector: 'app-cme-congress-page',
  templateUrl: './cme-congress-page.component.html',
  styleUrls: ['./../event-cme-page/event-cme-page.component.scss', './cme-congress-page.component.scss']
})
export class CmeCongressPageComponent extends ObservableComponent implements OnInit {
  congressSlug: string;
  sponsors: Sponsor[];
  logoUrl = imagePaths.logos;

  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  congressLandingPageData$ = this.store.select(getCongressLandingPageData);
  congressMetaData$ = this.store.select(getCongressMetaData);
  congressCmePageData$ = this.store.select(getCongressCmePageData);

  get currentLang() {
    return this.translateService.getDefaultLang();
  }

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    private store: Store<BaseAppState>,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadDataForCongress();
    this.observeCongressSlug();
    this.initializeSponsorViewItems();
  }

  loadDataForCongress() {
    this.congressSlug$.pipe(take(1)).subscribe((slug: string) => {
      this.store.dispatch(new LoadCongressLangingPageData(slug));
      this.store.dispatch(new LoadCongressCmePageData(slug));
    });
  }

  observeCongressSlug() {
    this.congressSlug$.pipe(takeUntil(this.ngDestroy$)).subscribe((slug: string) => (this.congressSlug = slug));
  }

  initializeSponsorViewItems() {
    this.congressLandingPageData$
      .pipe(
        filter((data: CongressLandingPage) => Boolean(data)),
        take(1),
        tap((data: CongressLandingPage) => {
          this.sponsors = MapSponsors(data);
        })
      )
      .subscribe();
  }

  handleGoToExpertsClick() {
    this.store.dispatch(new GoToExperts(this.congressSlug));
  }

  handleGoToProfileClick() {
    this.store.dispatch(new GoToEditUser());
  }

  handleGoToProgramClick() {
    this.store.dispatch(new GoToProgramme(this.congressSlug));
  }
}
