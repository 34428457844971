<div class="container">
  <header>
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-4">
        <h1 class="h4 mb-3">{{ 'Auth.ThankForRegistration' | translate }}</h1>
        <p class="mb-5 font-weight-normal" [innerHTML]="'SignUp.ConfirmationSent' | translate"></p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 offset-md-4 mb-2">
        <button class="btn btn-danger btn-block rounded-pill" (click)="handleGoToCongresses()">
          {{ 'Navigation.BackHome' | translate }}
        </button>
      </div>
    </div>
  </header>
</div>
<app-spacer></app-spacer>
