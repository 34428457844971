<div class="container">
  <div class="row" [ngSwitch]="currentLang">
    <div class="col-12 col-md-6 offset-md-3" *ngSwitchCase="'de'">
      <h1 class="mb-3">Impressum</h1>
      <h5>Anbieter gemäß Telemediengesetz</h5>
      <p>art tempi communications gmbh</p>
      <br />
      <p class="font-weight-bolder">Standort Berlin</p>
      <p>Bismarckstraße 82, 10627 Berlin, Germany</p>
      <p>Tel.: +49 30 28 65 46 95</p>
      <br />
      <p class="font-weight-bolder">Standort Köln</p>
      <p>Maria-Hilf-Str. 15, D-50677 Cologne, Germany</p>
      <p>Tel.: +49 221 27 23 59 0</p>
      <br />
      <p>E-Mail: kontakt&#64;art-tempi.de</p>
      <p>Web: art-tempi.de</p>
      <br />
      <p>Geschäftsführung: Sabine Reineke</p>
      <br />
      <p>Gerichtsstand: Amtsgericht Köln, HRB: 34447</p>
      <p>Ust-Id Nr. gemäß §27 a UStG: DE 212750349</p>
      <h5>Angaben gemäß § 5 TMG:</h5>
      <p>
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den
        Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Copyright und
        Verantwortlichkeit für den Inhalt dieser Internetpräsenz liegen bei der art tempi communications gmbh.
      </p>
      <h5>Verantwortliche nach § 55 Abs. 2 Rundfunkstaatsvertrag (RStV), Redaktion und Design</h5>
      <p>art tempi communications gmbh</p>
      <br />
      <p>Maria-Hilf-Str. 15, D-50677 Köln</p>
      <p>E-Mail: info&#64;medtoday.de</p>
      <p>Web: art-tempi.de</p>
      <h5>Technik und LiveStreaming</h5>
      <p>TAKEPART Media + Science GmbH</p>
      <p>Maria-Hilf-Str. 15, D-50677 Köln</p>
      <p>Tel.: +49 221 292 576 0</p>
      <p>E-Mail: it&#64;takepart-media.de</p>
      <p>Web: takepart-media.de</p>
      <h5>Technische Realisierung</h5>
      <p>seracom GmbH</p>
      <p>Immenhofer Str. 23, D-70180 Stuttgart</p>
      <p>Tel.: +49 711 286993-0</p>
      <p>E-Mail: kontakt&#64;seracom.de</p>
      <p>Web: seracom.de</p>
      <br />
      <p>Juli 2022</p>
    </div>
    <div class="col-12 col-md-6 offset-md-3" *ngSwitchCase="'en'">
      <h1 class="mb-3">Impressum</h1>
      <h5>Provider according to Telemedia Act</h5>
      <p>art tempi communications gmbh</p>
      <p class="font-weight-bolder">Standort Berlin</p>
      <p>Bismarckstraße 82, 10627 Berlin, Germany</p>
      <p>Tel.: +49 30 28 65 46 95</p>
      <p class="font-weight-bolder">Standort Köln</p>
      <p>Maria-Hilf-Str. 15, D-50677 Cologne, Germany</p>
      <p>Tel.: +49 221 27 23 59 0</p>
      <p>Email: kontakt&#64;art-tempi.de</p>
      <p>Web: art-tempi.de</p>
      <br />
      <p>Managing Director: Sabine Reineke</p>
      <br />
      <p>Place of jurisdiction: Cologne Local Court, HRB: 3444</p>
      <p>Ust-Id Nr. according to §27 a UStG: DE 212750349</p>
      <h5>Information provided according to § 5 TMG:</h5>
      <p>
        Despite careful control of the content, we assume no liability for the content of external links. The operators
        of the linked pages are solely responsible for their content. Copyright and responsibility for the content of
        this website lies with art tempi communications gmbh.
      </p>
      <h5>Responsible pursuant to § 55 para. 2 of the Interstate Broadcasting Treaty (RStV), editing and design</h5>
      <p>art tempi communications gmbh</p>
      <p>Maria-Hilf-Str. 15, D-50677 Cologne, Germany</p>
      <p>Email: info&#64;medtoday.eu</p>
      <p>Web: art-tempi.de</p>
      <h5>Technology and live streaming</h5>
      <p>TAKEPART Media + Science GmbH</p>
      <p>Maria-Hilf-Str. 15, D-50677 Cologne, Germany</p>
      <p>Email: it&#64;takepart-media.de</p>
      <p>Web: takepart-media.de</p>
      <h5>Technical realization</h5>
      <p>seracom GmbH</p>
      <p>Immenhofer Str. 23, D-70180 Stuttgart, Germany</p>
      <p>Email: kontakt&#64;seracom.de</p>
      <p>Web: seracom.de</p>
      <br />
      <p>July 2022</p>
    </div>
    <div class="col-12 col-md-6 offset-md-3" *ngSwitchCase="'ch'">
      <h1 class="mb-3">Imprint</h1>
      <h5>Provider according to Telemedia Act</h5>
      <p>PlayToKnow AG</p>
      <p>Alte Landstrasse 55, 8802 Kilchberg, Schweiz</p>
      <p>E-Mail: info&#64;medtoday.ch</p>
      <br />
      <p>Managing Director: Sybille Holtkamp</p>
      <br />
      <p>UID CHE-220.199.704</p>
      <h5>Technical realization</h5>
      <p>seracom GmbH</p>
      <p>Immenhofer Str. 23, D-70180 Stuttgart, Germany</p>
      <p>Email: kontakt&#64;seracom.de</p>
      <p>Web: seracom.de</p>
      <br />
      <p>July 2022</p>
      <div class="col-12 col-md-6 offset-md-3" *ngSwitchCase="'it'">
        <h1 class="mb-3">impronta</h1>
        <h5>
          <h5>Provider according to Telemedia Act</h5>
          <p>art tempi comunicazioni gmbh</p>
          <p>Maria-Hilf-Str. 15, D-50677 Colonia</p>
          <p>Telefono: +49 221 27 23 59 0</p>
          <p>Fax: +49 221 27 23 59 90</p>
          <p>Email: kontakt&#64;art-tempi.de</p>
          <p>Web: art-tempi.de</p>
          <br />
          <p>Direzione: Sabine Reineke</p>
          <br />
          <p>Foro competente: Tribunale distrettuale di Colonia, HRB: 34447</p>
          <p>Partita IVA secondo §27 a UStG: DE 212750349</p>
          <h5>Secondo § 5 TMG:</h5>
          <p>
            Nonostante un attento controllo dei contenuti, non ci assumiamo alcuna responsabilità per il contenuto dei
            link esterni. Gli operatori delle pagine collegate sono gli unici responsabili del loro contenuto. Art tempi
            communication gmbh detiene i diritti d'autore e la responsabilità per il contenuto di questo sito web.
          </p>
          <h5>Responsabile ai sensi del § 55 Abs. 2 Rundfunkstaatsvertrag (RStV), redazione e design</h5>
          <p>art tempi comunicazioni gmbh</p>
          <p>Maria-Hilf-Str. 15, D-50677 Colonia</p>
          <p>Email: info&#64;medtoday.eu</p>
          <p>Web: art-tempi.de</p>
          <h5>tecnologia e live streaming</h5>
          <p>PARTECIPA Media + Science GmbH</p>
          <p>Maria-Hilf-Str. 15, D-50677 Colonia</p>
          <p>Telefono: +49 221 292 576 0</p>
          <p>Email: it&#64;takepart-media.de</p>
          <p>Web: takepart-media.de</p>
          <h5>realizzazione tecnica</h5>
          <p>seracom GmbH</p>
          <p>Telefono: +49 711 286993-0</p>
          <p>Immenhofer Str. 23, D-70180 Stoccarda</p>
          <p>Email: kontakt&#64;seracom.de</p>
          <p>Web: seracom.de</p>
          <br />
          <p>Luglio 2022</p>
        </h5>
      </div>
    </div>
  </div>
  <app-spacer></app-spacer>
</div>
