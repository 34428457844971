<h6 *ngIf="!hideLabel" class="text-medium mt-4 ml-2 ml-sm-0">{{ filterLabel }}</h6>

<div
  id="row"
  [ngClass]="dropdownForMobileEnabled ? ['d-none', 'd-md-flex'] : 'd-flex'"
  class="flex-fill flex-xs-nowrap overflow-auto flex-sm-wrap my-md-3"
  *ngIf="!changeToDropdownIfLength || (changeToDropdownIfLength && changeToDropdownIfLength > filterOptions.length)"
>
  <ng-container *ngIf="hasAllButton">
    <button
      type="button"
      [class.selected]="!selectedOptions.length"
      (click)="handleReset()"
      class="d-flex btn btn-programme-day align-items-center text-nowrap mr-2 mb-2 ml-2 ml-sm-0"
    >
      {{ 'Common.All' | translate }}
    </button>
  </ng-container>
  <ng-container *ngFor="let option of filterOptions; let i = index">
    <button
      type="button"
      [class.selected]="option.isEnabled"
      [class.mobile-margin]="!hasAllButton"
      (click)="handleSelectOption(option)"
      class="d-flex btn btn-programme-day align-items-center text-nowrap mr-2 mb-2"
      [disabled]="option.producesRecords === false"
    >
      <ng-container [ngSwitch]="labelPipe">
        <div *ngSwitchCase="'date'">
          <ng-container *ngIf="attributeAsButtonLabel">
            {{ option.source[attributeAsButtonLabel] | date : dateFormat }}
          </ng-container>
          <ng-container *ngIf="!attributeAsButtonLabel">
            {{ option.source | date : dateFormat }}
          </ng-container>
        </div>
        <div *ngSwitchDefault>
          <ng-container *ngIf="attributeAsButtonLabel">
            {{ (prefix ? prefix + option[attributeAsButtonLabel] : option.source[attributeAsButtonLabel]) | translate }}
          </ng-container>
          <ng-container *ngIf="!attributeAsButtonLabel">
            {{ (prefix ? prefix + option.source : option.source) | translate }}
          </ng-container>
        </div>
      </ng-container>
      <div
        *ngIf="type === 'topic' && showDots"
        class="ml-3 topic-dot rounded"
        [class.live-topic]="doesTopicHaveLiveSession(option['id'])"
      ></div>
    </button>
  </ng-container>
</div>

<app-dropdown-multiple
  [options]="filterOptions"
  [optionLabelPath]="'source'"
  [placeholder]="filterDropdownPlaceholder"
  [prefix]="prefix"
  (selected)="handleSelectOption($event)"
  (resetOption)="handleReset()"
  *ngIf="changeToDropdownIfLength && filterOptions.length >= changeToDropdownIfLength"
></app-dropdown-multiple>
