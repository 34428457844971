import { Inject, Injectable } from '@angular/core';
import {
  ApiResponse,
  RequestError,
  RequestPasswordResetRequest,
  ResetPasswordRequest,
  SignUpRequest,
  UpdatePasswordRequest
} from '../../../shared/models';
import { Observable, from, BehaviorSubject, combineLatest } from 'rxjs';
import { Auth } from '@aws-amplify/auth';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
import { SignUpParams } from '@aws-amplify/auth/lib/types';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { filter, map } from 'rxjs/operators';
import { createNeedsAuthenticationHeader } from '../../data/utilities/networking.utilites';
import { UserProfileResponseModel } from 'medtoday-models-library';
import { HttpClient } from '@angular/common/http';
import { isUserDoctor } from '../../../../medtoday/src/app/auth-ui/components/sign-up/sign-up-form/sign-up-form-definitions';

export interface LoginResponse {
  currentMember: AwsMember | null;
  loggedInUser: CognitoUser | null;
}
export interface UpdateUserRequest {
  currentUser: CognitoUser | string;
  salutation: string;
  title: string;
  name: string;
  lastName: string;
  institution: string;
  efnNumber: string;
  streetAddress: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
  topic: string;
  hasAcceptedNewsletter: boolean;
  hasAcceptedPrivacyPolicy: boolean;
  occupationActivity: string;
  sourceApp: string;
}
export interface AwsCredentials {
  accessKeyId: string;
  sessionToken: string;
  secretAccessKey: string;
  identityId: string;
  authenticated: boolean;
}
export interface AwsMember {
  username: string;
  userId: string;
  chimeUserId: string;
}

@Injectable()
export class AuthService {
  constructor(@Inject('apiBaseUrl') protected apiBaseUrl: string, protected http: HttpClient) {}

  isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isFederatedUser = false;
  federatedIdentity: string;
  loginResponse$: BehaviorSubject<LoginResponse> = new BehaviorSubject({
    currentMember: null,
    loggedInUser: null
  });

  awsCredentials$: BehaviorSubject<AwsCredentials> = new BehaviorSubject({
    accessKeyId: '',
    sessionToken: '',
    secretAccessKey: '',
    identityId: '',
    authenticated: false
  });

  login(username: string, password: string) {
    const reqError: BehaviorSubject<RequestError | null> = new BehaviorSubject(null);
    Auth.signIn(username, password)
      .then(success => {
        if (success) {
          this.setAuthenticatedUser(success);
        }
      })
      .catch(err => {
        reqError.next(err);
      });

    return combineLatest([this.loginResponse$, reqError]).pipe(
      map(([loginResponse, error]: [LoginResponse, RequestError]) => {
        const response: ApiResponse<LoginResponse | null> = {
          data: !error ? loginResponse : null,
          error: !error ? undefined : error,
          success: !error ? true : false,
          validationErrors: []
        };
        return response;
      })
    );
  }

  refreshAuthIfNeeded(): Observable<ApiResponse<CognitoUser | null>> {
    const response$: BehaviorSubject<CognitoUser | null> = new BehaviorSubject(null);
    const error$: BehaviorSubject<RequestError | undefined> = new BehaviorSubject(undefined);

    (async () => {
      let user: CognitoUser | null = null;
      let currentSession: CognitoUserSession | null = null;
      try {
        user = await Auth.currentAuthenticatedUser();
        this.isFederatedUser = !!(user as any).attributes.identities;
        if (this.isFederatedUser) {
          this.federatedIdentity = JSON.parse((user as any).attributes.identities)[0]['providerName'];
        }
        // this.federatedIdentity =
        currentSession = await Auth.currentSession();
      } catch (error) {
        error$.next(error);
        return;
      }
      if (currentSession.isValid()) {
        user = await Auth.currentAuthenticatedUser();
        response$.next(user);
      } else {
        user!.refreshSession(currentSession.getRefreshToken(), async err => {
          if (!err) {
            user = await Auth.currentAuthenticatedUser();
            response$.next(user);
          } else {
            error$.next(err);
          }
        });
      }
    })();

    return combineLatest([response$, error$]).pipe(
      filter(([res, err]: [CognitoUser, RequestError]) => Boolean(res) || Boolean(err)),
      map(([res, err]: [CognitoUser, RequestError]) => {
        const response: ApiResponse<CognitoUser | null> = {
          data: err ? null : res,
          error: err ? err : undefined,
          validationErrors: [],
          success: err ? false : true
        };

        return response;
      })
    );
  }

  signOut(): Observable<ApiResponse<{}>> {
    this.setIsAuthenticated(false);
    this.loginResponse$.next({
      currentMember: null,
      loggedInUser: null
    });
    return this.genericPromiseHandler(Auth.signOut());
  }

  async getAwsCredentials(): Promise<void> {
    const creds = await Auth.currentCredentials();
    const essentialCreds = Auth.essentialCredentials(creds);
    this.awsCredentials$.next(essentialCreds);
  }

  signUp(signUpRequest: SignUpRequest): Observable<ApiResponse<{}>> {
    return this.genericPromiseHandler(
      Auth.signUp({
        username: signUpRequest.email,
        password: signUpRequest.password,
        attributes: {
          'custom:chimeUserId': 'none',
          'custom:chimeUserCreated': '0',
          email: signUpRequest.email,
          'custom:salutation': signUpRequest.salutation,
          'custom:title': signUpRequest.title,
          'custom:institution': signUpRequest.institution,
          given_name: signUpRequest.name,
          name: signUpRequest.lastName,
          'custom:hasPrivacyPolicy': signUpRequest.hasAcceptedPrivacyPolicy ? '1' : '0',
          'custom:hasNewsletter': signUpRequest.hasAcceptedNewsLetter ? '1' : '0',
          'custom:isAuthorizedPerson': signUpRequest.hasConfirmedAuthorizedPerson ? '1' : '0',
          'custom:efnNumber': signUpRequest.efnNumber,
          'custom:occupationActivity': signUpRequest.occupationActivity,
          'custom:sourceApp': signUpRequest.sourceApp,
          'custom:topic': signUpRequest.topic
        },
        autoSignIn: {
          enabled: true
        }
      } as SignUpParams)
    );
  }

  async setAuthenticatedUser(response: CognitoUser): Promise<void> {
    await Auth.currentUserInfo()
      .then(async curUser => {
        this.loginResponse$.next({
          currentMember: {
            username: curUser.username,
            userId: curUser.id,
            chimeUserId: curUser.attributes['custom:chimeUserId']
          },
          loggedInUser: JSON.parse(JSON.stringify(response))
        });
        this.setIsAuthenticated(true);
      })
      .catch(err => {
        throw err;
        // this.setMsg(`Failed to set authenticated user! ${err.message}`);
      });

    return this.getAwsCredentials();
  }

  setIsAuthenticated(isAuthenticated: boolean): void {
    this.isAuthenticated$.next(isAuthenticated);
  }

  confirmSignUp(userId: string, code: string): Observable<ApiResponse<{}>> {
    return this.genericPromiseHandler(Auth.confirmSignUp(userId, code));
  }

  resendSignUp(username: string): Observable<ApiResponse<{}>> {
    return this.genericPromiseHandler(Auth.resendSignUp(username));
  }

  updatePassword(request: UpdatePasswordRequest) {
    return this.genericPromiseHandler(
      Auth.currentAuthenticatedUser().then(user => Auth.changePassword(user, request.currentPassword, request.password))
    );
  }

  requestPasswordReset(request: RequestPasswordResetRequest): Observable<ApiResponse<{}>> {
    return this.genericPromiseHandler(Auth.forgotPassword(request.email));
  }

  resetPassword(request: ResetPasswordRequest) {
    return this.genericPromiseHandler(Auth.forgotPasswordSubmit(request.userId, request.code, request.password));
  }

  deleteUser(): Observable<ApiResponse<boolean>> {
    const headers = createNeedsAuthenticationHeader();
    return this.http.delete<ApiResponse<boolean>>(`${this.apiBaseUrl}/user/profile`, headers);
  }

  updateUser(request: UpdateUserRequest) {
    const observable = new Observable<CognitoUser>(subscriber => {
      const attributes = {
        'custom:salutation': request.salutation,
        'custom:efnNumber': request.efnNumber,
        'custom:title': request.title,
        'custom:institution': request.institution,
        'custom:streetAddress': request.streetAddress,
        'custom:zipCode': request.zipCode,
        'custom:city': request.city,
        'custom:phoneNumber': request.phoneNumber,
        'custom:topic': request.topic,
        'custom:hasNewsletter': request.hasAcceptedNewsletter ? '1' : '0',
        given_name: request.name,
        name: request.lastName,
        'custom:hasPrivacyPolicy': request.hasAcceptedPrivacyPolicy ? '1' : '0',
        'custom:occupationActivity': request.occupationActivity,
        'custom:sourceApp': request.sourceApp
      };

      (async () => {
        let user = await Auth.currentAuthenticatedUser();
        const updateReponse = await Auth.updateUserAttributes(user, attributes);

        if (updateReponse === 'SUCCESS') {
          user = await Auth.currentAuthenticatedUser();
        }

        subscriber.next(user);
        subscriber.complete();
      })();
    });

    return observable.pipe(
      map(res => {
        const response: ApiResponse<CognitoUser> = {
          data: res,
          error: undefined,
          validationErrors: [],
          success: true
        };

        return response;
      })
    );
  }

  async patchUserAttributes(attributes: {}): Promise<ApiResponse<CognitoUser>> {
    let user = await Auth.currentAuthenticatedUser();
    const updateResponse = await Auth.updateUserAttributes(user, attributes);

    if (updateResponse === 'SUCCESS') {
      user = await Auth.currentAuthenticatedUser();
    }

    return <ApiResponse<CognitoUser>>{
      data: user,
      error: undefined,
      validationErrors: [],
      success: true
    };
  }

  getUserProfile() {
    const headers = createNeedsAuthenticationHeader();
    return this.http.get<UserProfileResponseModel>(`${this.apiBaseUrl}/user/profile`, headers);
  }

  // tslint:disable-next-line: no-any
  genericPromiseHandler(promise: Promise<any>): Observable<ApiResponse<{}>> {
    const res = Promise.resolve(
      promise
        .then(() => {
          return {
            data: {},
            success: true,
            validationErrors: []
          };
        })
        .catch(error => {
          return {
            data: {},
            error: {
              code: error.code,
              message: error.message
            },
            success: false,
            validationErrors: []
          };
        })
    );

    return from(res);
  }

  userHasMissingDataOnStep(): Promise<number> {
    return Auth.currentAuthenticatedUser()
      .then(user => {
        const attrs = user.attributes;
        if (!attrs['custom:salutation']) {
          return 1;
        }

        if (
          !attrs['custom:hasPrivacyPolicy'] ||
          !attrs['custom:occupationActivity'] ||
          attrs['custom:occupationActivity'] === 'Ärztliche Tätigkeit' ||
          (isUserDoctor(attrs['custom:occupationActivity']) && !attrs['custom:topic'])
        ) {
          return 2;
        }

        if (!this.hasAlreadyCheckedEfn() && attrs['custom:topic'] && !attrs['custom:efnNumber']) {
          this.setEfnChecked();
          return 2;
        }

        return 0;
      })
      .catch(() => {
        return 0;
      });
  }

  authProviderAttributesToModel(attributes): any {
    return {
      email: attributes['email'] || '',
      salutation: attributes['custom:salutation'] || '',
      title: attributes['custom:title'] || '',
      institution: attributes['custom:institution'] || '',
      name: attributes['given_name'] || '',
      lastName: attributes['name'] || '',
      hasAcceptedPrivacyPolicy: attributes['custom:hasPrivacyPolicy'] == 1 ? true : false,
      hasAcceptedNewsLetter: attributes['custom:hasNewsletter'] == 1 ? true : false,
      hasConfirmedAuthorizedPerson: attributes['custom:isAuthorizedPerson'] == 1 ? true : false,
      efnNumber: attributes['custom:efnNumber'] || '',
      occupationActivity: attributes['custom:occupationActivity'] || '',
      topic: attributes['custom:topic'] || '',
      sourceApp: attributes['custom:sourceApp'] || ''
    };
  }

  private hasAlreadyCheckedEfn(): boolean {
    return localStorage.getItem('checkedEfn') ? !!localStorage.getItem('checkedEfn') : false;
  }

  private setEfnChecked() {
    localStorage.setItem('checkedEfn', 'true');
  }

  sendUserUpdatedMessage(userPayload: CognitoUser): Observable<any> {
    const headers = createNeedsAuthenticationHeader();
    return this.http.patch<ApiResponse<boolean>>(`${this.apiBaseUrl}/user/profile`, userPayload, headers);
  }
}
