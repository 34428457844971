import { ApplicationRef, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { CongressCategory, CongressMetadata } from 'medtoday-models-library/lib/models';
import { LoadCongressParticipantData } from 'projects/medtoday/src/app/medtoday-store/actions/medtoday-store.actions';
import { getCongressParticipantData } from 'projects/medtoday/src/app/medtoday-store/selectors/medtoday-store.selectors';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, skip, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { PurgeUserData } from '../../core/auth/actions/auth.actions';
import { getAuthType, getUserAttributes, isLoggedIn } from '../../core/auth/selectors/auth.selectors';
import { AuthService } from '../../core/auth/services/auth.service';
import { getCongressMetaData } from '../../core/data/selectors/data-api.selectors';
import { BaseAppState } from '../../core/store/reducers';
import { ObservableComponent } from '../components/observable/observable.component';

@Injectable()
export class EventRegistrationService extends ObservableComponent {
  isUserAuthorized: boolean;
  showSignUpBtn = true;
  registrationClosedText: string;
  registerModalVisible = false;
  hideRegisterModal = true;
  user;
  authType;

  userAttributes$ = this.store.select(getUserAttributes);
  congressMetaData$ = this.store.select(getCongressMetaData);
  congressParticipantData$ = this.store.select(getCongressParticipantData);
  authType$ = this.store.select(getAuthType);

  isLoggedIn$ = this.store.select(isLoggedIn);

  constructor(public store: Store<BaseAppState>, public authService: AuthService, private ref: ApplicationRef) {
    super();
  }

  public forceAuthRefresh() {
    return this.authService.refreshAuthIfNeeded().pipe(
      take(1),
      withLatestFrom(this.authType$),
      tap(([result, type]) => {
        if (result.error && type !== 2) {
          this.store.dispatch(new PurgeUserData());
          this.authService.signOut();
        }
      })
    );
  }

  public handleShowRegisterModal(value: boolean) {
    this.forceAuthRefresh();
    this.registerModalVisible = value;
  }

  public setEventType(congressMetaData: CongressMetadata) {
    switch (congressMetaData.congressCategory) {
      case CongressCategory.ONLINE:
        return 'Online Event';
      case CongressCategory.ON_SITE:
        return 'On Site Event';
      case CongressCategory.HYBRID:
        return 'On Site & Online Event';
      default:
        break;
    }
  }

  public setHideRegisterModal() {
    this.hideRegisterModal = false;
  }

  public checkForRegistration() {
    this.congressMetaData$
      .pipe(
        filter(congressMetaData => !!congressMetaData),
        takeUntil(this.ngDestroy$)
      )
      .subscribe(congressMetaData => {
        const congressTokenArray = localStorage.getItem('medtoday_congress_tokens');
        let found = false;
        if (congressTokenArray) {
          const parsedArray = JSON.parse(congressTokenArray);
          found = parsedArray.some(ele => ele.congressSlug === congressMetaData.slug);
        }
        !congressMetaData.authorizedOnly || found ? (this.isUserAuthorized = true) : null;
      });
  }

  public getUserData() {
    this.userAttributes$.pipe(takeUntil(this.ngDestroy$)).subscribe((userAttributes: CognitoUserAttribute[]) => {
      this.user = userAttributes;
    });
  }

  public checkUserCongressData() {
    combineLatest([this.userAttributes$, this.congressMetaData$])
      .pipe(
        skip(1),
        filter(([userAttributes, congressMetaData]) => !!userAttributes && !!congressMetaData),
        takeUntil(this.ngDestroy$),
        distinctUntilChanged()
      )
      .subscribe(([userAttributes, congressMetaData]) => {
        this.store.dispatch(
          // @ts-ignore
          new LoadCongressParticipantData(congressMetaData.slug, userAttributes.sub)
        );

        if (!this.user) {
          this.user = userAttributes;
        }
      });

    combineLatest([this.congressMetaData$, this.congressParticipantData$])
      .pipe(
        takeUntil(this.ngDestroy$),
        filter(([congressMetaData, congressParticipantData]) => !!congressMetaData && !!congressParticipantData)
      )
      .subscribe(([congressMetaData, congressParticipantData]) => {
        if (congressMetaData && congressParticipantData) {
          this.isUserAuthorized =
            (congressMetaData.authorizedOnly && congressParticipantData.isParticipant) ||
            !congressMetaData.authorizedOnly;
          if (congressParticipantData.isParticipant && this.hideRegisterModal) {
            // @ts-ignore
            jQuery('#registerModal')!.modal('hide');
          }
        }
        this.ref.tick();
      });
  }

  public registrationClosed(congressMetaData: CongressMetadata) {
    if (
      (congressMetaData.onlineRegistrationClosed && congressMetaData.congressCategory === 'ONLINE') ||
      (congressMetaData.offlineRegistrationClosed && congressMetaData.congressCategory === 'ON_SITE') ||
      (congressMetaData.onlineRegistrationClosed && congressMetaData.offlineRegistrationClosed)
    ) {
      this.registrationClosedText = 'Auth.RegistrationClosed';
      this.showSignUpBtn = false;
      return true;
    }
    if (congressMetaData.offlineRegistrationClosed && !congressMetaData.onlineRegistrationClosed) {
      this.registrationClosedText = 'Auth.OfflineRegistrationClosed';
      return true;
    }
    if (congressMetaData.onlineRegistrationClosed && !congressMetaData.offlineRegistrationClosed) {
      this.registrationClosedText = 'Auth.OnlineRegistrationClosed';
      return true;
    }
  }
}
