import { CongressLandingPage, Sponsor } from 'medtoday-models-library/lib/models';
import { groupBy, keys } from 'lodash';

export const MapSponsorsByLevel: (sponsors: Sponsor[]) => Record<string, Sponsor[]> = (sponsors: Sponsor[]) => {
  const byGroup = groupBy(sponsors, 'level');
  const ordered = {};
  keys(byGroup)
    .sort((a, b) => byGroup[a][0].levelPosition! - byGroup[b][0].levelPosition!)
    .forEach(key => {
      ordered[key] = byGroup[key];
    });

  return ordered;
};

export const MapSponsors = (data: CongressLandingPage) => {
  const sponsors: Sponsor[] = [];
  if (data?.sponsorsSection?.firstLevelSponsors?.length > 0) {
    data?.sponsorsSection?.firstLevelSponsors.forEach((sponsor: Sponsor) => {
      if (sponsors.findIndex((sp: Sponsor) => sp.id === sponsor.id) === -1) {
        sponsors.push(sponsor);
      }
    });
  }
  if (data?.sponsorsSection?.secondLevelSponsors?.length > 0) {
    data?.sponsorsSection?.secondLevelSponsors.forEach((sponsor: Sponsor) => {
      if (sponsors.findIndex((sp: Sponsor) => sp.id === sponsor.id) === -1) {
        sponsors.push(sponsor);
      }
    });
  }
  if (data?.sponsorsSection?.thirdLevelSponsors?.length > 0) {
    data?.sponsorsSection?.thirdLevelSponsors.forEach((sponsor: Sponsor) => {
      if (sponsors.findIndex((sp: Sponsor) => sp.id === sponsor.id) === -1) {
        sponsors.push(sponsor);
      }
    });
  }
  if (data?.sponsorsSection?.fourthLevelSponsors?.length > 0) {
    data?.sponsorsSection?.fourthLevelSponsors.forEach((sponsor: Sponsor) => {
      if (sponsors.findIndex((sp: Sponsor) => sp.id === sponsor.id) === -1) {
        sponsors.push(sponsor);
      }
    });
  }
  if (data?.sponsorsSection?.associateSponsors?.length > 0) {
    data?.sponsorsSection?.associateSponsors.forEach((sponsor: Sponsor) => {
      if (sponsors.findIndex((sp: Sponsor) => sp.id === sponsor.id) === -1) {
        sponsors.push(sponsor);
      }
    });
  }
  sponsors.sort((a, b) => a.name.localeCompare(b.name));

  return sponsors;
};
