import { Component, Inject, OnInit } from '@angular/core';
import {
  DataApiActionTypes,
  LoadCongressLangingPageData,
  LoadExpertsData,
  LoadProgrammeData,
  LoadOnDemandVideo
} from '../../../../core/data/actions/data-api.actions';
import { Store } from '@ngrx/store';
import {
  getCongressLandingPageData,
  getCongressMetaData,
  getExpertsData,
  getProgrammeData,
  getSelectedOnDemandVideo
} from '../../../../core/data/selectors/data-api.selectors';
import { BaseAppState } from '../../../../core/store/reducers';
import { ObservableComponent } from '../../../components/observable/observable.component';
import { getCongressSlugRouterParam } from '../../../../core/router/selectors/router.selectors';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { imagePaths } from '../../../../../medtoday/src/environments/environment';
import {
  CongressMetadata,
  TimeSlot,
  CongressCategory,
  Session,
  OnDemandVideoListItem,
  Sponsor
} from 'medtoday-models-library/lib/models';
import { GoToChannel, GoToOnDemandVideo } from '../../../../core/router/actions/main-navigation.actions';
import { AnalyticsService } from '../../../../core/data/services/ga-analytics.service';
import { BehaviorSubject, combineLatest, interval, Observable } from 'rxjs';
import { MAIN_NAVIGATION_PATH_ELEMENTS } from '../../../../core/router/definitions/main-navigation.definitions';
import { SESSION_UTILS_TOKEN } from '../../../utilities/session.utils';
import { TimeSlotRow } from '../../../models/time-slot-row';
import { Programme } from 'medtoday-models-library';
import { EventRegistrationService } from '../../../services/event-registration.service';
import moment from 'moment';
import { keyBy } from 'lodash';
import { LoadCongressParticipantData } from 'projects/medtoday/src/app/medtoday-store/actions/medtoday-store.actions';
import { getCongressParticipantData } from 'projects/medtoday/src/app/medtoday-store/selectors/medtoday-store.selectors';
import { CongressUtils } from 'projects/todaylib/shared/utilities/congress.utils';
import { TokenValidationService } from 'projects/todaylib/core/auth/services/token-validation.service';
import { calculateTimestringForEvent, shouldShowHourSuffix } from '../../../utilities/object.utils';
import { TranslateService } from '@ngx-translate/core';
import { IS_MED_TODAY_APP, IS_PATIENTS_TODAY_APP } from '../../../../core/definitions/app.definitions';
import { ICalendar } from 'datebook';

export enum EventTabs {
  'Programme',
  'Experts'
}

@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.scss'],
  providers: [EventRegistrationService, CongressUtils, TokenValidationService]
})
export class EventPageComponent extends ObservableComponent implements OnInit {
  readonly loadCongressLayoutDataAsyncKey = DataApiActionTypes.LoadCongressLandingPageData;
  readonly loadVideoOnDemandDataAsyncKey = DataApiActionTypes.LoadOnDemandVideo;
  readonly EventTabs = EventTabs;

  selectedTab: EventTabs = EventTabs.Programme;
  congressLandingPageData$ = this.store.select(getCongressLandingPageData);
  congressMetaData$ = this.store.select(getCongressMetaData);
  videoOnDemandData$ = this.store.select(getSelectedOnDemandVideo);
  programmeData$ = this.store.select(getProgrammeData);
  eventSlug$ = this.store.select(getCongressSlugRouterParam);
  expertsData$ = this.store.select(getExpertsData);
  congressParticipantData$ = this.store.select(getCongressParticipantData);
  sponsors$: Observable<Sponsor[]>;

  isAnySessionLive = new BehaviorSubject<boolean>(false);
  isMultiChannel = new BehaviorSubject<boolean>(false);
  logosImageFolderSlug = imagePaths.logos;
  timeSlotsById: { [key: number]: TimeSlot };
  timeSlotRows: TimeSlotRow[] = [];
  liveSession: undefined | Session;
  user;
  pdfImageUrl = imagePaths.pdfs;
  congressTimeRange: string;

  private eventSlug: string;

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    protected store: Store<BaseAppState>,
    private analyticsService: AnalyticsService,
    @Inject(SESSION_UTILS_TOKEN) private sessionUtils,
    private registrationService: EventRegistrationService,
    public congressUtils: CongressUtils,
    private tokenValidationService: TokenValidationService,
    @Inject(IS_MED_TODAY_APP) public isMedTodayApp: string,
    @Inject(IS_PATIENTS_TODAY_APP) public isPatientsTodayApp: string,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.tokenValidationService.observeToken();
    this.registrationService.forceAuthRefresh().subscribe(() => {
      this.registrationService.getUserData();
      this.eventSlug$
        .pipe(
          filter(slug => !!slug),
          takeUntil(this.ngDestroy$)
        )
        .subscribe(slug => {
          this.eventSlug = slug;
          this.store.dispatch(new LoadCongressLangingPageData(this.eventSlug));
          this.store.dispatch(new LoadProgrammeData(this.eventSlug));
          this.store.dispatch(new LoadExpertsData(this.eventSlug));

          if (this.registrationService.user) {
            this.store.dispatch(new LoadCongressParticipantData(this.eventSlug, this.registrationService.user.sub));
          }
        });

      this.observeProgramme();
      this.checkLiveSession();
      this.checkMultiChannels();
      this.parseCongressTimeFrame();
      this.registrationService.checkUserCongressData();
      this.mapSponsors();
    });
  }

  observeProgramme() {
    this.programmeData$
      .pipe(
        filter(programmeData => !!programmeData),
        tap(programmeData => {
          const timeSlots: TimeSlotRow[] = [];
          programmeData.timeSlots.forEach((slot: TimeSlot) => {
            if (timeSlots.findIndex((row: TimeSlotRow) => row.id === slot.id) === -1) {
              const timeSlotRow: TimeSlotRow = {
                id: slot.id,
                start: moment.utc(slot.start).toISOString(),
                finish: moment.utc(slot.finish).toISOString(),
                sessions: programmeData.sessions.filter((session: Session) => session.timeSlotId === slot.id),
                videos: programmeData.onDemandVideos.filter((video: OnDemandVideoListItem) =>
                  moment.utc(video.timeSlotStart).isSame(moment.utc(slot.start))
                )
              };

              timeSlots.push(timeSlotRow);
            }
          });

          timeSlots.sort((a: TimeSlotRow, b: TimeSlotRow) => a.start.localeCompare(b.start));
          this.timeSlotRows = timeSlots;
          this.timeSlotsById = keyBy(programmeData.timeSlots, 'id');
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  isSameDay(timeSlotsByIdElement: TimeSlot) {
    return moment.utc(timeSlotsByIdElement.start).isSame(moment.utc(timeSlotsByIdElement.finish), 'day');
  }

  calcTimes(dateA: Date, dateB: Date) {
    return calculateTimestringForEvent(dateA, dateB);
  }

  showHourSuffix(dateA: Date, dateB: Date) {
    return shouldShowHourSuffix(dateA, dateB);
  }

  goToLiveSession() {
    combineLatest([this.programmeData$, this.eventSlug$])
      .pipe(take(1))
      .subscribe(([programData, eventSlug]) => {
        if (!programData?.sessions?.length) {
          return;
        }

        const liveSession = this.getLiveSession(programData);

        if (liveSession) {
          this.analyticsService.pushTag({}, 'navigate-to-channel', 'is-featured-content', false);
          this.store.dispatch(
            new GoToChannel(eventSlug, liveSession.channelSlug, MAIN_NAVIGATION_PATH_ELEMENTS.root.programmeElement)
          );
        }
      });
  }

  getLiveSession(programData: Programme) {
    const liveTimeSlot = programData.timeSlots.find(timeSlot =>
      this.sessionUtils.isActive({
        ...timeSlot,
        sessions: programData.sessions.filter(s => s.timeSlotId === timeSlot.id)
      })
    );
    if (!liveTimeSlot) {
      return;
    }

    return programData.sessions.find(session => session.timeSlotId === liveTimeSlot.id);
  }

  goToChannelSession(channelSlug: string) {
    this.eventSlug$.pipe(take(1)).subscribe(eventSlug => {
      this.analyticsService.pushTag({}, 'navigate-to-channel', 'is-featured-content', false);
      this.store.dispatch(new GoToChannel(eventSlug, channelSlug, MAIN_NAVIGATION_PATH_ELEMENTS.root.programmeElement));
    });
  }

  changeTab(tab: EventTabs) {
    this.selectedTab = tab;
  }

  handleGoToVideo(id: number) {
    this.store.dispatch(new LoadOnDemandVideo(id, this.eventSlug));
    this.videoOnDemandData$
      .pipe(
        filter(data => Boolean(data)),
        take(1)
      )
      .subscribe(_data => this.store.dispatch(new GoToOnDemandVideo(id, false, undefined, this.eventSlug)));
  }

  checkLiveSession() {
    interval(10000)
      .pipe(
        startWith(0),
        switchMap(() => this.programmeData$),
        filter(programmeData => !!programmeData),
        takeUntil(this.ngDestroy$)
      )
      .subscribe(programmeData => {
        this.liveSession = this.getLiveSession(programmeData);
        this.isAnySessionLive.next(!!this.liveSession);
      });
  }

  checkMultiChannels() {
    this.programmeData$.pipe(takeUntil(this.ngDestroy$)).subscribe(programmeData => {
      if (programmeData) {
        const slug = programmeData.sessions[0]?.channelSlug;
        const differentChannel = programmeData.sessions.find(session => session.channelSlug !== slug);
        this.isMultiChannel.next(!!differentChannel);
      }
    });
  }

  checkOneSessionLive(timeslot: TimeSlot, session: Session) {
    return this.sessionUtils.isActive({
      ...timeslot,
      sessions: [session]
    });
  }

  isVideoOnline(row: TimeSlotRow) {
    const dateNow = moment(new Date());
    return dateNow.isSameOrAfter(moment(row.start));
  }

  setEventType(congressMetaData: CongressMetadata) {
    switch (congressMetaData.congressCategory) {
      case CongressCategory.ONLINE:
        return 'Online Event';
      case CongressCategory.ON_SITE:
        return 'On Site Event';
      case CongressCategory.HYBRID:
        return 'On Site & Online Event';
      default:
        break;
    }
  }

  goToZoomRoom(link: string) {
    window.open(link, '_blank')?.focus();
  }

  handleAddToCalendarClick(congressMetaData?: CongressMetadata) {
    const icalendar = new ICalendar(
      this.congressUtils.createCalendarEvent(this.congressUtils.createCalendarEventItem(congressMetaData, undefined))
    );
    icalendar.download();
  }

  private parseCongressTimeFrame() {
    this.congressMetaData$
      .pipe(
        filter(metaData => !!metaData),
        takeUntil(this.ngDestroy$)
      )
      .subscribe(metaData => {
        this.congressTimeRange =
          this.calcTimes(metaData.startDate, metaData.endDate) +
          ' ' +
          (this.showHourSuffix(metaData.startDate, metaData.endDate)
            ? this.translateService.instant('Common.Hour')
            : '');
      });
  }

  protected mapSponsors() {
    this.sponsors$ = this.congressLandingPageData$.pipe(
      filter(data => !!data),
      take(1),
      map(data => {
        return [
          ...data.sponsorsSection.firstLevelSponsors,
          ...data.sponsorsSection.secondLevelSponsors,
          ...data.sponsorsSection.thirdLevelSponsors,
          ...data.sponsorsSection.fourthLevelSponsors,
          ...data.sponsorsSection.associateSponsors
        ];
      })
    );
  }
}
