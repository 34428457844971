import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export const firstStepSignUp = new FormGroup({
  emailAddress: new FormControl(null, [Validators.required, Validators.email]),
  password: new FormControl(null, Validators.required)
});

export const secondStepSignUp = new FormGroup({
  salutation: new FormControl('', [Validators.required]),
  title: new FormControl(''),
  name: new FormControl(null, Validators.required),
  lastName: new FormControl(null, Validators.required),
  institution: new FormControl('')
});

export const thirdStepSignUp = new FormGroup({
  hasAcknowledgedDataProtectionInformation: new FormControl(false, Validators.requiredTrue),
  hasAcceptedNewsLetter: new FormControl(false),
  efnNumber: new FormControl(''),
  topic: new FormControl(''),
  occupationActivity: new FormControl('', [Validators.required]),
  sourceApp: new FormControl(''),
  hasAcknowledgedTruthfulness: new FormControl(false, Validators.requiredTrue)
});

export const secondLevelOccupationActivities = [
  { key: 'Klinik', text: 'SignUp.Clinic' },
  { key: 'Praxis / MVZ', text: 'SignUp.Practice' },
  { key: 'Forschung', text: 'SignUp.Research' },
  { key: 'Industrie', text: 'SignUp.Industry' },
  { key: 'In der Ausbildung', text: 'SignUp.InEducation' },
  { key: 'Retired', text: 'SignUp.Retired' }
];

export const firstLevelOccupationActivities = [
  { key: 'Pflege', text: 'SignUp.Nurse' },
  { key: 'Apotheker:in', text: 'SignUp.Pharmacist' },
  { key: 'Vertreter:in der Pharmaindustrie', text: 'SignUp.RepresentativePharm' },
  { key: 'Medizinische Fachpresse', text: 'SignUp.MedicalPress' },
  { key: 'Other professions', text: 'SignUp.OtherProfessions' }
];

export const occupationActivities = [...secondLevelOccupationActivities, ...firstLevelOccupationActivities];

export const isUserDoctor = (activity: string) => secondLevelOccupationActivities.some(item => item.key === activity);

export function getTranslatedOccupationActivity(occupationActivityValue: string, translateService: TranslateService) {
  const activity = occupationActivities.find(activity => activity.key === occupationActivityValue);
  if (!activity) {
    return occupationActivityValue;
  }

  return translateService.instant(activity.text);
}

export enum SIGNUP_PROCESS {
  FULL,
  DETAILS
}
