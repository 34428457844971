import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TOPICS } from '../../definitions/topics';
import { includes } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { getUserAttributes } from '../../../../../../todaylib/core/auth/selectors/auth.selectors';
import { BaseAppState } from '../../../../../../todaylib/core/store/reducers';
import { Store } from '@ngrx/store';
import { ObservableComponent } from '../../../../../../todaylib/shared/components/observable/observable.component';
import { filter, take } from 'rxjs/operators';
import {
  EditUserProperties,
  HideFillMissingUserDataModal
} from '../../../../../../todaylib/core/auth/actions/auth.actions';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { UpdateUserRequest } from '../../../../../../todaylib/core/auth/services/auth.service';
import { occupationActivities } from '../sign-up/sign-up-form/sign-up-form-definitions';

declare var $;

@Component({
  selector: 'app-missing-user-data-modal',
  templateUrl: './missing-user-data-modal.component.html',
  styleUrls: ['./missing-user-data-modal.component.scss']
})
export class MissingUserDataModalComponent extends ObservableComponent implements OnInit, OnDestroy {
  userAttributes$ = this.store.select(getUserAttributes);
  protected readonly occupationActivities = occupationActivities;
  topics = TOPICS;
  currentLang = this.translateService.currentLang;
  form: FormGroup = new FormGroup({
    occupationActivity: new FormControl('', [Validators.required]),
    topic: new FormControl('', [Validators.required]),
    efn: new FormControl('')
  });

  constructor(private translateService: TranslateService, private store: Store<BaseAppState>) {
    super();
  }

  ngOnInit() {
    $('#missingUserDataModal').modal('show');
    this.userAttributes$
      .pipe(
        filter(attributes => !!attributes),
        take(1)
      )
      .subscribe((userAttributes: CognitoUserAttribute[]) => {
        let occupationActivity = userAttributes['custom:occupationActivity'];
        if (userAttributes['custom:occupationActivity'] === 'Ärztliche Tätigkeit') {
          occupationActivity = '';
        }

        this.form.patchValue({
          occupationActivity: occupationActivity,
          topic: userAttributes['custom:topic'],
          efn: userAttributes['custom:efnNumber']
        });
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    $('#missingUserDataModal').modal('hide');
  }

  handleTopicSelect(topicName: string) {
    this.form.controls['topic'].setValue(topicName);
  }

  searchForTopics(term: string) {
    const termLc = term.toLowerCase();
    this.topics = TOPICS.filter(topic => includes(topic.toLowerCase(), termLc));
  }

  handleSaveData() {
    if (this.form.valid) {
      this.userAttributes$.pipe(take(1)).subscribe((userAttributes: CognitoUserAttribute[]) => {
        if (userAttributes) {
          const request: UpdateUserRequest = {
            currentUser: this.userAttributes$['email'],
            topic: this.form.controls['topic'].value ?? '',
            efnNumber: this.form.controls['efn'].value ?? '',
            occupationActivity: this.form.controls['occupationActivity'].value ?? '',
            salutation: userAttributes['custom:salutation'] ? userAttributes['custom:salutation'] : null,
            title: userAttributes['custom:title'] ? userAttributes['custom:title'] : '',
            name: userAttributes['given_name'],
            lastName: userAttributes['name'],
            streetAddress: userAttributes['custom:streetAddress'] ?? '',
            city: userAttributes['custom:city'] ?? '',
            zipCode: userAttributes['custom:zipCode'] ?? '',
            phoneNumber: userAttributes['custom:phoneNumber'] ?? '',
            institution: userAttributes['custom:institution'] ?? '',
            hasAcceptedNewsletter: userAttributes['custom:hasNewsletter'],
            sourceApp: userAttributes['custom:sourceApp'] ?? '',
            hasAcceptedPrivacyPolicy: userAttributes['custom:hasPrivacyPolicy']
          };
          this.store.dispatch(new EditUserProperties(request));
        }
      });
    }
  }

  handleOccupationActivityChange(occupationActivity: { key: string; text: string }) {
    if (occupationActivity) {
      this.form.get('occupationActivity')?.setValue(occupationActivity.key);
    }
  }

  handleCloseModal() {
    this.store.dispatch(new HideFillMissingUserDataModal());
  }
}
