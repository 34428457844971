import { Component, Inject, Input } from '@angular/core';
import { imagePaths } from 'projects/medtoday/src/environments/environment';

@Component({
  selector: 'app-premium-teaser-box',
  templateUrl: './premium-teaser-box.component.html',
  styleUrls: ['./premium-teaser-box.component.scss']
})
export class PremiumTeaserBoxComponent {
  @Input() backgroundImage: string;
  @Input() logo: string;
  @Input() headline: string;
  @Input() description: string;
  @Input() detailButtonUrl: string;
  @Input() detailButtonText: string;
  @Input() openInNewTab: boolean;
  @Input() time: string;
  @Input() start: string;
  @Input() end: string;
  @Input() liveUrl: string;
  @Input() postUrl: string;

  imagesPath = imagePaths.logos;

  constructor(@Inject('s3BucketUrl') public s3BucketUrl: string) {}
}
