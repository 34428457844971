<ng-container *ngIf="showMainSurveyPopup$ | async">
  <div
    class="survey-popup shadow-lg"
    #surveyPopup
    [class.minimized]="minimizedMainSurveyPopup"
    [class.patients-survey-popup]="isPatientsToday"
  >
    <div class="survey-popup-header">
      <button class="close" (click)="minimize()"><i class="bi bi-dash text-white"></i></button>
      <button class="close" (click)="maximize()"><i class="bi bi-square text-white"></i></button>
      <button class="close" (click)="minimize()"><i class="bi bi-x text-white"></i></button>
    </div>
    <div class="survey-popup-inner">
      <div id="smcx-sdk"></div>
    </div>
  </div>
</ng-container>
