import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { groupBy, uniqBy } from 'lodash';
import { ExpertListItem, Topic } from 'medtoday-models-library/lib/models';
import { filter, skipWhile, take, takeUntil, tap } from 'rxjs/operators';

import { SetSelectedExpert } from '../../../../../medtoday/src/app/pages/actions/experts-details.actions';
import { imagePaths } from '../../../../../medtoday/src/environments/environment';
import { DataApiActionTypes, LoadExpertsData } from '../../../../core/data/actions/data-api.actions';
import {
  getCongressMetaData,
  getExpertsData,
  getExpertsWithoutTopic,
  getHasExpertsWithAtLeastOneTopic
} from '../../../../core/data/selectors/data-api.selectors';
import { getCongressSlugRouterParam } from '../../../../core/router/selectors/router.selectors';
import { BaseAppState } from '../../../../core/store/reducers';
import { ObservableComponent } from '../../../components/observable/observable.component';
import { hasSrolled } from '../../../utilities/window.utils';
import { Filter } from '../../../modules/filters/classes/filter';
import { FilterOption } from '../../../modules/filters/models/filter-option.model';
import { combineLatest } from 'rxjs';

interface TopicRow {
  topic: Topic;
  experts: ExpertListItem[];
}

@Component({
  selector: 'app-experts-page',
  templateUrl: './experts-page.component.html',
  styleUrls: ['./experts-page.component.scss']
})
export class ExpertsPageComponent extends ObservableComponent implements OnInit, OnDestroy {
  public showFilterBackground;
  readonly asyncKey = DataApiActionTypes.LoadExpertsData;

  topicRows: TopicRow[] = [];
  congressSlug: string;
  avatarsImageFolderSlug = imagePaths.avatars;
  logosFolderSlug = imagePaths.logos;
  expertsTypes = [];

  expertsWithNoTopic$ = this.store.select(getExpertsWithoutTopic);
  hasExpertsWithAtLeastOneTopic$ = this.store.select(getHasExpertsWithAtLeastOneTopic);
  expertsData$ = this.store.select(getExpertsData);
  congressMetaData$ = this.store.select(getCongressMetaData);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  expertTypesFilter = new Filter<ExpertListItem>();

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    @Inject('applicationName') public applicationName: string,
    private store: Store<BaseAppState>
  ) {
    super();
  }

  @HostListener('window:scroll', []) hasScrolled() {
    if (window.pageYOffset > 180 && window.outerWidth < 575) {
      this.showFilterBackground = true;
    } else {
      this.showFilterBackground = false;
    }
    return hasSrolled();
  }

  ngOnInit(): void {
    this.observeCongressSlug();
    this.store.dispatch(new LoadExpertsData(this.congressSlug));
    this.getExpertsViewData();
    this.initExpertsType();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.topicRows = [];
  }

  observeCongressSlug() {
    this.congressSlug$
      .pipe(
        tap((slug: string) => (this.congressSlug = slug)),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  handleTopicClick(topicId: number) {
    const sectionElement = document.getElementById(topicId.toString());
    if (sectionElement) {
      const elementPosition = sectionElement.getBoundingClientRect().top;
      window.scrollBy({ top: elementPosition - 120, behavior: 'smooth' });
    }
  }

  getExpertsViewData() {
    combineLatest([this.expertsData$, this.expertTypesFilter.filteredData$])
      .pipe(
        tap(([data, filteredExperts]) => {
          if (data && data.topics?.length > 0) {
            this.topicRows = [];
            this.topicRows = data.topics
              .map((topic: Topic) => {
                return {
                  topic: topic,
                  experts: uniqBy(
                    filteredExperts.filter((expert: ExpertListItem) => expert.topicIds.includes(topic.id)),
                    'id'
                  )
                };
              })
              .filter(topicExpert => !!topicExpert.experts.length);
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  handleExpertDetailsClick(expert: ExpertListItem) {
    this.store.dispatch(new SetSelectedExpert(expert));
  }

  handleSelectAllExperts() {
    this.expertsData$ = this.store.select(getExpertsData);
    this.getExpertsViewData();
  }

  handleSelectExpertType(expertType: FilterOption<ExpertListItem>) {
    this.expertTypesFilter.applyFilter('type', expertType);
  }

  private initExpertsType() {
    this.expertsData$
      .pipe(
        skipWhile(data => data === undefined),
        take(1)
      )
      .subscribe(data => {
        if (data) {
          this.expertsTypes = Object.keys(groupBy(data.experts, 'expertType')) as [];
          this.initFilters();
        }
      });
  }

  private initFilters() {
    this.expertsData$
      .pipe(
        filter(data => !!data),
        take(1)
      )
      .subscribe(expertsData => {
        let expertTypesFilterOptions = this.expertsTypes.map(expertType => ({
          id: expertType,
          source: expertType,
          condition: (record: ExpertListItem) => {
            return record.expertType === expertType;
          }
        }));
        this.expertTypesFilter.addFilters<ExpertListItem>('type', expertTypesFilterOptions);
        this.expertTypesFilter.loadFilterableRecords(expertsData!.experts);
      });
  }
}
