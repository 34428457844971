<section>
  <div class="row mobile-full-width">
    <div class="col-12">
      <div
        id="congressCarousel"
        class="carousel slide mb-5"
        data-ride="carousel"
        data-touch="false"
        *ngIf="congresses && congresses.length > 0"
      >
        <div class="carousel-inner">
          <div
            class="carousel-item"
            *ngFor="let congress of congresses; let i = index"
            [class.active]="i === activeCarouselItem"
            [class.dark-theme]="congress.headerDarkTheme"
          >
            <img
              *ngIf="congress.backgroundImage"
              class="background-image"
              draggable="false"
              [src]="this.s3BucketUrl + congress.backgroundImage"
            />
            <div class="congress-wrapper h-100 w-100 p-sm-0 p-md-4">
              <a
                id="congress-slide"
                class="d-md-none d-flex m-0 align-items-center h-100 w-100 justify-content-center congress-slide"
                (click)="handleGoToCongressClick(congress)"
              >
                <img
                  *ngIf="congress.logoHeroSlider"
                  draggable="false"
                  class="logo p-0 p-lg-4"
                  [src]="this.s3BucketUrl + logoPathUrl + congress.logoHeroSlider"
                />
                <ng-container *ifApp="'MedToday'">
                  <h1 *ngIf="!congress.logoHeroSlider && congress.heroSliderHeadline" class="d-block mb-0">
                    {{ congress.heroSliderHeadline }}
                  </h1>
                </ng-container>
              </a>
              <div
                *ifApp="'MedToday'"
                replaceToDownloadLink
                class="row d-md-flex d-none flex-column m-0 mx-5 mt-lg-5 mt-sm-5 mt-5 align-items-lg-start align-items-center pt-md-3 pt-1 pl-lg-5 pl-0"
              >
                <h1 *ngIf="!congress.logoHeroSlider && congress.heroSliderHeadline" class="w-50 mt-4">
                  {{ congress.heroSliderHeadline }}
                </h1>
                <div class="logo-wrapper">
                  <img
                    *ngIf="congress.logoHeroSlider"
                    draggable="false"
                    class="logo"
                    [src]="this.s3BucketUrl + logoPathUrl + congress.logoHeroSlider"
                  />
                </div>
                <span
                  *ngIf="congress.description"
                  class="w-50 d-none d-lg-block mt-4"
                  replaceToDownloadLink
                  [class.text-white]="congress.headerDarkTheme"
                  [innerHTML]="congress.description"
                ></span>
                <span class="big-info" *ngIf="congress.heroSliderEventDate">
                  {{ congress.heroSliderEventDate }}
                </span>
                <button
                  class="d-none d-md-block btn btn-danger mt-4 rounded-pill"
                  *ngIf="congress.showCongressLinkButton"
                  (click)="handleGoToCongressClick(congress)"
                >
                  {{
                    congress.congressButtonText
                      ? congress.congressButtonText
                      : ('Common.Zu ' | translate) + congress.name
                  }}
                </button>
              </div>
            </div>
          </div>
          <a
            class="d-md-flex carousel-control-prev"
            *ngIf="congresses && congresses.length > 1"
            href="#congressCarousel"
            role="button"
            data-slide="prev"
            draggable="false"
          >
            <div class="icon-16" [inlineSVG]="'assets/icons/arrow-left.svg'">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </div>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="d-md-flex carousel-control-next"
            *ngIf="congresses && congresses.length > 1"
            href="#congressCarousel"
            role="button"
            data-slide="next"
            draggable="false"
          >
            <div class="icon-16" [inlineSVG]="'assets/icons/arrow-right.svg'">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </div>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <ol class="carousel-indicators">
          <li
            *ngFor="let congresses of congresses; let i = index"
            data-target="#congressCarousel"
            [attr.data-slide-to]="i"
            [class.active]="i === activeCarouselItem"
          ></li>
        </ol>
      </div>
    </div>
  </div>
</section>
