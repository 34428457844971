import { Action } from '@ngrx/store';
import {
  Channel,
  CongressLandingPage,
  CongressListItem,
  ExpertsResponse,
  OnDemandVideoListResponse,
  OnDemandVideoDetail,
  FeaturedContentResponse,
  CongressMetadata,
  QuestionAndAnswerRequestModel,
  ArchivedCongressListItem,
  LandingPageVideoListItem,
  OnDemandVideoDeeplink,
  EventListItem,
  PremiumBox,
  Programme,
  PageItem,
  InternationalContent,
  ChannelDeeplink,
  CongressTopicsResponseModel,
  Page
} from 'medtoday-models-library';

import { AsyncAction } from '../../async-state/models/async-action.model';

export enum DataApiActionTypes {
  LoadCongressesList = '[Data] Load Congresses List',
  LoadCongressesListSuccess = '[Data] Load Congresses List Success',
  LoadCongressesListFail = '[Data] Load Congresses List Fail',

  LoadCongressLandingPageData = '[Data] Load Congress Landing Page Data',
  LoadCongressLandingPageDataSuccess = '[Data] Load Congress Landing Page Data Success',
  LoadCongressLandingPageDataFail = '[Data] Load Congress Landing Page Data Fail',

  LoadExpertsData = '[Data] Load Experts Data',
  LoadExpertsDataSuccess = '[Data] Load Experts Data Success',
  LoadExpertsDataFail = '[Data] Load Experts Data Fail',

  LoadCongressMetaData = '[Data] Load Congress Meta Data',
  LoadCongressMetaDataSuccess = '[Data] Load Congress Meta Data Success',
  LoadCongressMetaDataFail = '[Data] Load Congress Meta Data Fail',

  LoadProgrammeData = '[Data] Load Programme Data',
  LoadProgrammeDataSuccess = '[Data] Load Programme Data Success',
  LoadProgrammeDataFail = '[Data] Load Programme Data Fail',

  LoadChannelBySlug = '[Data] Load Channel Data',
  LoadChannelBySlugSuccess = '[Data] Load Channel Data Success',
  LoadChannelBySlugFail = '[Data] Load Channel Data Fail',

  LoadOnDemandVideos = '[Data] Load Videos On Demand Data',
  LoadOnDemandVideosSuccess = '[Data] Load Videos On Demand Data Success',
  LoadOnDemandVideosFail = '[Data] Load Videos On Demand Data Fail',
  PurgeOnDemandVideosList = '[Data] Purge on demand videos list',

  LoadOnDemandVideo = '[Data] Load Video On Demand',
  LoadOnDemandVideoSuccess = '[Data] Load Video On Demand Success',
  LoadOnDemandVideoFail = '[Data] Load Video On Demand Fail',
  PurgeOnDemandVideo = '[Data] Purge Video On Demand',

  SendOnDemandVideoQuestion = '[Data] Send Video On Demand Question',
  SendOnDemandVideoQuestionSuccess = '[Data] Send Video On Demand Question Success',
  SendOnDemandVideoQuestionFail = '[Data] Send Video On Demand Question Fail',

  LoadFeaturedContentOverview = '[Data] Load Featured Content Overview',
  LoadFeaturedContentOverviewSuccess = '[Data] Load Featured Content Overview Success',
  LoadFeaturedContentOverviewFail = '[Data] Load Featured Content Overview Fail',

  PushUserIdToGTM = '[Data] Push user id to Google Tag Manager',

  LoadTokenizedOnDemandVideo = '[Data] Load Tokenized Video On Demand',
  LoadTokenizedOnDemandVideoSuccess = '[Data] Load Tokenized Video On Demand Success',
  LoadTokenizedOnDemandVideoFail = '[Data] Load Tokenized Video On Demand Fail',

  LoadTokenizedChannel = '[Data] Load Tokenized Channel',
  LoadTokenizedChannelSuccess = '[Data] Load Tokenized Channel Success',
  LoadTokenizedChannelFail = '[Data] Load Tokenized Channel Fail',

  LoadCongressTopics = '[Data] Load Congress Topics',

  LoadCongressTopicsSuccess = '[Data] Load Congress Topics Success',

  LoadCongressTopicsFail = '[Data] Load Congress Topics Fail',

  PurgeChannelsData = '[Data] Purge channels Data'
}

export class LoadCongressesList implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadCongressesList;
  readonly asyncState = 'start';
  readonly type = DataApiActionTypes.LoadCongressesList;

  constructor() {}
}

export class LoadCongressesListSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadCongressesList;
  readonly type = DataApiActionTypes.LoadCongressesListSuccess;
  readonly asyncState = 'success';

  constructor(
    public congresses: CongressListItem[],
    public archivedCongresses: ArchivedCongressListItem[],
    public mostWatchedVideos: LandingPageVideoListItem[],
    public upcomingEvents: EventListItem[],
    public premiumBox: PremiumBox | null,
    public pageItems: PageItem[],
    public page: Page[],
    public intertnationalContent: InternationalContent[]
  ) {}
}

export class LoadCongressesListFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadCongressesList;
  readonly type = DataApiActionTypes.LoadCongressesListFail;
  readonly asyncState = 'fail';
}

export class LoadCongressLangingPageData implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadCongressLandingPageData;
  readonly asyncState = 'start';
  readonly type = DataApiActionTypes.LoadCongressLandingPageData;

  constructor(public congressSlug: string) {}
}

export class LoadCongressLangingPageDataSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadCongressLandingPageData;
  readonly type = DataApiActionTypes.LoadCongressLandingPageDataSuccess;
  readonly asyncState = 'success';

  constructor(public congressLandingPageData: CongressLandingPage) {}
}

export class LoadCongressLangingPageDataFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadCongressLandingPageData;
  readonly type = DataApiActionTypes.LoadCongressLandingPageDataFail;
  readonly asyncState = 'fail';
}

export class LoadCongressMetaData implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadCongressMetaData;
  readonly asyncState = 'start';
  readonly type = DataApiActionTypes.LoadCongressMetaData;

  constructor(public congressSlug: string) {}
}

export class LoadCongressMetaDataSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadCongressMetaData;
  readonly type = DataApiActionTypes.LoadCongressMetaDataSuccess;
  readonly asyncState = 'success';

  constructor(public congressMetaData: CongressMetadata) {}
}

export class LoadCongressMetaDataFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadCongressMetaData;
  readonly type = DataApiActionTypes.LoadCongressMetaDataFail;
  readonly asyncState = 'fail';
}

export class LoadProgrammeData implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadProgrammeData;
  readonly asyncState = 'start';
  readonly type = DataApiActionTypes.LoadProgrammeData;

  constructor(public congressSlug: string) {}
}

export class LoadProgrammeDataSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadProgrammeData;
  readonly type = DataApiActionTypes.LoadProgrammeDataSuccess;
  readonly asyncState = 'success';

  constructor(public programmeData: Programme) {}
}

export class LoadProgrammeDataFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadProgrammeData;
  readonly type = DataApiActionTypes.LoadProgrammeDataFail;
  readonly asyncState = 'fail';
}
export class LoadExpertsData implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadExpertsData;
  readonly asyncState = 'start';
  readonly type = DataApiActionTypes.LoadExpertsData;

  constructor(public congressSlug: string) {}
}

export class LoadExpertsDataSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadExpertsData;
  readonly type = DataApiActionTypes.LoadExpertsDataSuccess;
  readonly asyncState = 'success';

  constructor(public data: ExpertsResponse) {}
}

export class LoadExpertsDataFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadExpertsData;
  readonly type = DataApiActionTypes.LoadExpertsDataFail;
  readonly asyncState = 'fail';
}

export class LoadChannelBySlug implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadChannelBySlug;
  readonly asyncState = 'start';
  readonly type = DataApiActionTypes.LoadChannelBySlug;

  constructor(public congressSlug: string, public channelSlug: string) {}
}

export class LoadChannelBySlugSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadChannelBySlug;
  readonly type = DataApiActionTypes.LoadChannelBySlugSuccess;
  readonly asyncState = 'success';

  constructor(public channel: Channel) {}
}

export class LoadChannelBySlugFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadChannelBySlug;
  readonly type = DataApiActionTypes.LoadChannelBySlugFail;
  readonly asyncState = 'fail';
}

export class PurgeChannelsData implements Action {
  readonly type = DataApiActionTypes.PurgeChannelsData;
}

export class LoadOnDemandVideos implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadOnDemandVideos;
  readonly asyncState = 'start';
  readonly type = DataApiActionTypes.LoadOnDemandVideos;

  constructor(public congressSlug: string) {}
}

export class LoadOnDemandVideosSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadOnDemandVideos;
  readonly type = DataApiActionTypes.LoadOnDemandVideosSuccess;
  readonly asyncState = 'success';

  constructor(public onDemandVideosResponse: OnDemandVideoListResponse) {}
}

export class LoadOnDemandVideosFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadOnDemandVideos;
  readonly type = DataApiActionTypes.LoadOnDemandVideosFail;
  readonly asyncState = 'fail';
}

export class LoadOnDemandVideo implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadOnDemandVideo;
  readonly asyncState = 'start';
  readonly type = DataApiActionTypes.LoadOnDemandVideo;

  constructor(public id: number, public congressSlug?: string) {}
}

export class LoadTokenizedOnDemandVideo implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadTokenizedOnDemandVideo;
  readonly asyncState = 'start';
  readonly type = DataApiActionTypes.LoadTokenizedOnDemandVideo;

  constructor(public id: number, public token: string, public congressSlug?: string) {}
}

export class LoadOnDemandVideoSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadOnDemandVideo;
  readonly type = DataApiActionTypes.LoadOnDemandVideoSuccess;
  readonly asyncState = 'success';

  constructor(public response: OnDemandVideoDetail) {}
}

export class LoadOnDemandVideoFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadOnDemandVideo;
  readonly type = DataApiActionTypes.LoadOnDemandVideoFail;
  readonly asyncState = 'fail';
}

export class LoadTokenizedOnDemandVideoSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadTokenizedOnDemandVideo;
  readonly type = DataApiActionTypes.LoadTokenizedOnDemandVideoSuccess;
  readonly asyncState = 'success';

  constructor(public response: OnDemandVideoDeeplink) {}
}

export class LoadTokenizedOnDemandVideoFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadTokenizedOnDemandVideo;
  readonly type = DataApiActionTypes.LoadTokenizedOnDemandVideoFail;
  readonly asyncState = 'fail';
}

export class PurgeOnDemandVideo implements Action {
  readonly type = DataApiActionTypes.PurgeOnDemandVideo;
}

export class PurgeOnDemandVideosList implements Action {
  readonly type = DataApiActionTypes.PurgeOnDemandVideosList;
}

export class SendOnDemandVideoQuestion implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.SendOnDemandVideoQuestion;
  readonly asyncState = 'start';
  readonly type = DataApiActionTypes.SendOnDemandVideoQuestion;

  constructor(public congressSlug: string, public id: number, public request: QuestionAndAnswerRequestModel) {}
}

export class SendOnDemandVideoQuestionSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.SendOnDemandVideoQuestion;
  readonly type = DataApiActionTypes.SendOnDemandVideoQuestionSuccess;
  readonly asyncState = 'success';
}

export class SendOnDemandVideoQuestionFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.SendOnDemandVideoQuestion;
  readonly type = DataApiActionTypes.SendOnDemandVideoQuestionFail;
  readonly asyncState = 'fail';
}

export class LoadFeaturedContentOverview implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadFeaturedContentOverview;
  readonly asyncState = 'start';
  readonly type = DataApiActionTypes.LoadFeaturedContentOverview;

  constructor(public congressSlug: string) {}
}

export class LoadFeaturedContentOverviewSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadFeaturedContentOverview;
  readonly type = DataApiActionTypes.LoadFeaturedContentOverviewSuccess;
  readonly asyncState = 'success';

  constructor(public response: FeaturedContentResponse) {}
}

export class LoadFeaturedContentOverviewFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadFeaturedContentOverview;
  readonly type = DataApiActionTypes.LoadFeaturedContentOverviewFail;
  readonly asyncState = 'fail';
}

export class PushUserIdToGTM implements Action {
  readonly type = DataApiActionTypes.PushUserIdToGTM;

  constructor(public userId: string) {}
}

export class LoadTokenizedChannel implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadTokenizedChannel;
  readonly type = DataApiActionTypes.LoadTokenizedChannel;
  readonly asyncState = 'start';

  constructor(public congressSlug: string, public channelSlug: string, public token: string) {}
}

export class LoadTokenizedChannelSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadTokenizedChannel;
  readonly type = DataApiActionTypes.LoadTokenizedChannelSuccess;
  readonly asyncState = 'success';

  constructor(public response: ChannelDeeplink) {}
}

export class LoadTokenizedChannelFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadTokenizedChannel;
  readonly type = DataApiActionTypes.LoadTokenizedChannelFail;
  readonly asyncState = 'fail';
}

export class LoadCongressTopics implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadCongressTopics;
  readonly asyncState = 'start';
  readonly type = DataApiActionTypes.LoadCongressTopics;

  constructor(public exclude: string | null) {}
}

export class LoadCongressTopicsSuccess implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadCongressTopics;
  readonly type = DataApiActionTypes.LoadCongressTopicsSuccess;
  readonly asyncState = 'success';

  constructor(public response: CongressTopicsResponseModel) {}
}

export class LoadCongressTopicsFail implements AsyncAction {
  readonly asyncKey = DataApiActionTypes.LoadCongressTopics;
  readonly type = DataApiActionTypes.LoadCongressTopicsFail;
  readonly asyncState = 'fail';
}

export type DataApiActions =
  | LoadCongressLangingPageData
  | LoadCongressesListSuccess
  | LoadCongressLangingPageDataSuccess
  | LoadExpertsDataSuccess
  | LoadCongressMetaDataSuccess
  | LoadProgrammeDataSuccess
  | LoadOnDemandVideosSuccess
  | LoadChannelBySlugSuccess
  | LoadOnDemandVideoSuccess
  | LoadTokenizedOnDemandVideoSuccess
  | LoadFeaturedContentOverviewSuccess
  | PurgeOnDemandVideo
  | LoadTokenizedChannelSuccess
  | PurgeOnDemandVideosList
  | LoadCongressTopicsSuccess
  | PurgeChannelsData;
