import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { ObservableComponent } from 'projects/todaylib/shared/components/observable/observable.component';
import { takeUntil } from 'rxjs/operators';
import { IS_MED_TODAY_APP } from '../../definitions/app.definitions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends ObservableComponent implements AfterViewInit {
  @Input() backgroundImageUrl: string;
  @Input() hideNavigation = false;
  constructor(private url: LocationStrategy, private router: Router, @Inject(IS_MED_TODAY_APP) public isMedTodayApp) {
    super();
  }

  ngAfterViewInit() {
    this.router.events.pipe(takeUntil(this.ngDestroy$)).subscribe(_val => {
      setTimeout(() => {
        const headerImage = document.getElementById('image-header');

        if (window.location.href !== '') {
          if (window.location.href.endsWith('/home')) {
            headerImage?.classList.remove('teaser-image-low');
            headerImage?.classList.add('teaser-image-high');
          } else {
            headerImage?.classList.remove('teaser-image-high');
            headerImage?.classList.add('teaser-image-low');
          }
        }
      }, 200);
    });
  }

  checkIfReact() {
    if (this.url.path().includes('/playback')) {
      return false;
    } else {
      return true;
    }
  }
}
