<div class="my-4">
  <p class="text-center">
    {{ 'SignUp.Or' | translate }}
  </p>
</div>

<button class="btn text-white w-100 rounded btn-google" type="button" (click)="socialSignUp('google')">
  {{ 'SignUp.RegisterGoogle' | translate }}
</button>
<button class="btn text-white mb-3 mt-2 w-100 rounded btn-apple" type="button" (click)="socialSignUp('apple')">
  {{ 'SignUp.RegisterApple' | translate }}
</button>
