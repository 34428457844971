<ng-container *ngIf="congressMetaData$ | async as congressMetaData">
  <app-subpage-header
    [congressMetaData]="congressMetaData"
    [subpageName]="'VideoOnDemand.Title' | translate"
  ></app-subpage-header>
  <div
    class="container sticky-container"
    [class.filter-background]="showFilterBackground && applicationName === 'MedToday'"
    [class.filter-background-patients]="showFilterBackground && applicationName === 'PatientsToday'"
    [class.bg-medtoday]="applicationName === 'MedToday'"
  >
    <ng-container *ngIf="onDemandVideosData$ | async as onDemandVideosData">
      <ng-container *ngIf="congressMetaData.filterOptions['vod'].includes('day')">
        <app-filter
          #filter
          [type]="'date'"
          [filterOptions]="filterContainer.getFilterOptions('day')"
          [hideLabel]="showFilterBackground"
          (handleFilter)="handleSelectFilter('day', $event)"
          [selectedOptions]="selectedDays"
          (allButtonHandler)="resetFilter('day')"
          [labelPipe]="'date'"
          [dateFormat]="'EEEE, dd.MM.'"
          [hasAllButton]="true"
          [filterLabel]="'ProgrammePage.SelectDay' | translate"
        >
        </app-filter>
      </ng-container>
      <ng-container
        *ngIf="onDemandVideosData.topics?.length && congressMetaData.filterOptions['vod'].includes('topic')"
      >
        <app-filter
          #filter
          [filterLabel]="'Common.Departments' | translate"
          [type]="'topic'"
          [hideLabel]="showFilterBackground"
          [hasAllButton]="true"
          [attributeAsButtonLabel]="'title'"
          [selectedOptions]="selectedTopics"
          (allButtonHandler)="resetFilter('topic')"
          (handleFilter)="handleSelectFilter('topic', $event)"
          [filterOptions]="videosFilter.getFilterOptions('topic')"
        >
        </app-filter>
      </ng-container>
      <ng-container *ngIf="formats?.length && congressMetaData.filterOptions['vod'].includes('format')">
        <app-filter
          #filter
          [filterLabel]="'VideoOnDemand.Formats' | translate"
          [type]="'format'"
          [hideLabel]="showFilterBackground"
          [hasAllButton]="true"
          [attributeAsButtonLabel]="'title'"
          [selectedOptions]="selectedFormats"
          (allButtonHandler)="resetFilter('format')"
          (handleFilter)="handleSelectFilter('format', $event)"
          [filterOptions]="videosFilter.getFilterOptions('format')"
        >
        </app-filter>
      </ng-container>
      <ng-container
        *ngIf="onDemandVideosData.languages?.length && congressMetaData.filterOptions['vod'].includes('language')"
      >
        <app-filter
          #filter
          [filterLabel]="'Common.Languages' | translate"
          [type]="'languages'"
          [hideLabel]="showFilterBackground"
          [hasAllButton]="true"
          [selectedOptions]="selectedLanguages"
          (allButtonHandler)="resetFilter('language')"
          (handleFilter)="handleSelectFilter('language', $event)"
          [filterOptions]="videosFilter.getFilterOptions('language')"
          [filterDropdownPlaceholder]="'Common.SelectLanguage' | translate"
          [prefix]="'VideoOnDemand.'"
          [changeToDropdownIfLength]="9"
        >
        </app-filter>
      </ng-container>
    </ng-container>
  </div>
  <ng-container
    *ngIf="
      congressMetaData.layout?.banner?.position !== 'null' && congressMetaData.layout.banner?.subpagePosition === 'top'
    "
  >
    <app-congress-banner
      [congressLogo]="congressMetaData.layout?.banner?.logo_left"
      [congressSeriesLogo]="congressMetaData.layout?.banner?.logo"
      [disclaimer]="congressMetaData.layout?.banner?.text"
      class="mb-5 d-block"
    ></app-congress-banner>
  </ng-container>
  <div class="container" [class.subpage-content]="applicationName === 'MedToday'">
    <ng-container *ngIf="onDemandVideosData$ | async as onDemandVideosData">
      <div class="pt-4 mt-4 mt-lg-0">
        <ng-container *ngIf="timeGroups?.length > 0">
          <ng-container *ngFor="let group of timeGroups; let i = index">
            <div class="row">
              <div class="col-12 mb-4" *ngIf="group.videos.length > 0 && selectedDays.length">
                <h3 *ngIf="group.timeSlot !== NOT_ASSIGNED">
                  {{ group.timeSlot | date : 'EEEE, dd.MM.yyyy' }}
                </h3>
              </div>
              <ng-container *ngIf="group.videos.length > 0; else noVideos">
                <ng-container *ngFor="let video of group.videos">
                  <div class="video col-lg-6 col-12 pb-5">
                    <video-list-item
                      [video]="video"
                      [topicTitle]="getTopicById(video.topicId, onDemandVideosData.topics)?.title"
                      [formatTitle]="getFormatById(video.formatId, onDemandVideosData.formats)?.title"
                      (goToVideo)="handleGoToVideoClick($event)"
                    >
                    </video-list-item>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="stateOfTheArtVideos?.length > 0">
          <div class="row">
            <div class="col-12 mb-4">
              <h3 class="font-weight-light">{{ 'VideoOnDemand.StateOfArt' | translate }}</h3>
            </div>
            <ng-container *ngFor="let video of stateOfTheArtVideos">
              <div class="video col-lg-6 col-12 pb-5">
                <div class="video-wrapper d-flex">
                  <video-list-item
                    [video]="video"
                    [topicTitle]="getTopicById(video.topicId, onDemandVideosData.topics)?.title"
                    [formatTitle]="getFormatById(video.formatId, onDemandVideosData.formats)?.title"
                    (goToVideo)="handleGoToVideoClick($event)"
                  >
                  </video-list-item>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
<app-spacer></app-spacer>
<ng-container *ngIf="congressMetaData$ | async as congressMetaData">
  <ng-container
    *ngIf="
      congressMetaData.layout?.banner?.position !== 'null' &&
      congressMetaData.layout.banner?.subpagePosition === 'bottom'
    "
  >
    <app-congress-banner
      [congressLogo]="congressMetaData.layout?.banner?.logo_left"
      [congressSeriesLogo]="congressMetaData.layout?.banner?.logo"
      [disclaimer]="congressMetaData.layout?.banner?.text"
      [onBottom]="true"
    ></app-congress-banner>
  </ng-container>
</ng-container>
<app-scroll-to-top-button [ngClass]="hasScrolled() ? 'd-block' : 'd-none'"></app-scroll-to-top-button>
<app-async-state-overlay [processKey]="loadOnDemandVideosAsyncKey"></app-async-state-overlay>
<app-async-state-overlay [processKey]="loadOnDemandVideoAsyncKey"></app-async-state-overlay>
