<section id="twitter" *ngIf="currentLang !== 'ch'">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="twitter-channel">
          <div class="logo rounded-circle" [inlineSVG]="'assets/images/logo-small.svg'"></div>
          <span>medtoday</span>
          <a class="twitter-follow-button" href="{{ linkedInUrl }}" target="_blank" draggable="false"> Follow</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="tagembed-container" style="width: 100%; height: 100%; overflow: auto">
        <div
          class="tagembed-socialwall"
          attr.data-wall-id="{{ twitterWallId }}"
          attr.view-url="{{ twitterWallUrl }}"
        ></div>
      </div>
    </div>
  </div>
</section>
