<div class="social-share" [class.big]="size === 'big'" [class.small]="size === 'small'">
  <div class="share-inner d-inline-flex">
    <a
      class="btn btn-share"
      target="_blank"
      rel="noreferrer"
      href="https://twitter.com/intent/tweet?url={{ link }}"
      [inlineSVG]="'assets/icons/twitter.svg'"
    >
    </a>
    <a
      class="btn btn-share"
      target="_blank"
      rel="noreferrer"
      href="https://www.linkedin.com/sharing/share-offsite/?url={{ link }}"
      [inlineSVG]="'assets/icons/linkedin.svg'"
    >
    </a>
    <a
      class="btn btn-share"
      target="_blank"
      rel="noreferrer"
      href="https://www.facebook.com/sharer/sharer.php?u={{ link }}"
      [inlineSVG]="'assets/icons/facebook.svg'"
    >
    </a>
    <a
      class="btn btn-share"
      target="_blank"
      rel="noreferrer"
      href="https://www.instagram.com/?url={{ link }}"
      [inlineSVG]="'assets/icons/instagram.svg'"
    >
    </a>
  </div>
  <button class="btn btn-share share-toggler" [inlineSVG]="'assets/icons/share.svg'"></button>
</div>
