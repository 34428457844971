import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { handleAsyncErrors } from '../../async-state/operators/handle-errors';
import { AsyncAction } from '../../async-state/models/async-action.model';
import { of } from 'rxjs';
import {
  LoadYoutubeVideoData,
  LoadYoutubeVideoDataFail,
  LoadYoutubeVideoDataSuccess,
  ProvidersActionTypes
} from '../actions/providers.actions';
import { ProvidersService } from '../services/providers.service';

@Injectable()
export class ProvidersEffects {
  loadUpcomingEventsDates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProvidersActionTypes.LoadYoutubeVideoData),
        mergeMap((action: LoadYoutubeVideoData) =>
          this.providersService.getYoutubeVideoData(action.id).pipe(
            handleAsyncErrors(() => new LoadYoutubeVideoDataFail()),
            map(response => new LoadYoutubeVideoDataSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  constructor(private providersService: ProvidersService, private actions$: Actions) {}
}
