import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class FileDownloadDataApiService {
  public getMediaLibraryItem(slug: string) {
    return this.httpClient.get(`${this.apiBaseUrl}/downloads/${slug}`);
  }
  constructor(@Inject('apiBaseUrl') protected apiBaseUrl: string, private httpClient: HttpClient) {}
}
