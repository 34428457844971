import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { hasSrolled } from 'projects/todaylib/shared/utilities/window.utils';
import { BaseAppState } from '../../../../../core/store/reducers';
import { ObservableComponent } from '../../../../components/observable/observable.component';
import { getPartnerSlugParam } from '../../../../../core/router/selectors/router.selectors';
import { LoadPartnerDetails } from '../../actions/partners.actions';
import { PartnersSelectors } from '../../selectors/partners.selectors';
import selectPartnerDetails = PartnersSelectors.selectPartnerDetails;
import { imagePaths } from '../../../../../../medtoday/src/environments/environment';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Partner, SponsorBooth } from 'medtoday-models-library/lib';

@Component({
  selector: 'app-partner-detail-page',
  templateUrl: './partner-detail-page.component.html',
  styleUrls: ['./partner-detail-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerDetailPageComponent extends ObservableComponent implements OnInit {
  partnerParam$ = this.store.select(getPartnerSlugParam);
  partner$: Observable<Partner | SponsorBooth | null> = this.store.select(selectPartnerDetails);
  avatarsUrl = imagePaths.avatars;
  logosUrl = imagePaths.logos;
  pdfsUrl = imagePaths.pdfs;

  constructor(public store: Store<BaseAppState>, @Inject('s3BucketUrl') public s3BucketUrl: string) {
    super();
  }

  ngOnInit() {
    this.partnerParam$
      .pipe(
        takeUntil(this.ngDestroy$),
        filter(slug => !!slug)
      )
      .subscribe(partnerSlug => {
        this.store.dispatch(new LoadPartnerDetails(partnerSlug));
      });
  }

  hasScrolled() {
    return hasSrolled();
  }
}
