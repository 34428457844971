import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../../core/store/reducers';
import { handleAsyncErrors } from '../../../../core/async-state/operators/handle-errors';
import { AsyncAction } from '../../../../core/async-state/models/async-action.model';
import { of } from 'rxjs';
import {
  ElearningActionTypes,
  GetElearningUserMetadata,
  GetElearningUserMetadataFail,
  GetElearningUserMetadataSuccess,
  LoadQuestions,
  LoadQuestionsFail,
  LoadQuestionsSuccess,
  SubmitElearningAnswers,
  SubmitElearningAnswersFail,
  SubmitElearningAnswersSuccess
} from '../actions/elearning.actions';
import {
  ELearningMetadataResponse,
  ElearningQuestionsResponse,
  ElearningResultResponse
} from 'medtoday-models-library/lib';
import { ElearningService } from '../services/elearning.service';
import { GoToElearningResults } from '../../../../core/router/actions/main-navigation.actions';

@Injectable({
  providedIn: 'root'
})
export class ElearningEffects {
  loadQuestions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ElearningActionTypes.LoadQuestions),
        switchMap((action: LoadQuestions) =>
          this.api.getQuestionsAnswers(action.vodId).pipe(
            handleAsyncErrors(() => new LoadQuestionsFail()),
            map((response: ElearningQuestionsResponse) => new LoadQuestionsSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  getElearningUserMetadata = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ElearningActionTypes.GetElearningUserMetadata),
        switchMap((action: GetElearningUserMetadata) =>
          this.api.getElearningMetadata(action.vodId).pipe(
            handleAsyncErrors(() => new GetElearningUserMetadataFail()),
            map((response: ELearningMetadataResponse) => new GetElearningUserMetadataSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  submitElearningAnswers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ElearningActionTypes.SubmitElearningAnswers),
        tap((action: SubmitElearningAnswers) =>
          this.store.dispatch(new GoToElearningResults(action.vodId, action.congressSlug))
        ),
        switchMap((action: SubmitElearningAnswers) =>
          this.api.submitElearningAnswers(action.vodId, action.data).pipe(
            handleAsyncErrors(() => new SubmitElearningAnswersFail()),
            map((response: ElearningResultResponse) => new SubmitElearningAnswersSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  constructor(private actions$: Actions, public store: Store<BaseAppState>, public api: ElearningService) {}
}
