<img class="d-md-block d-none background-image mt-5" src="assets/images/Service-Seiten-Kugeln@2x.png" />
<img class="d-md-none d-block background-image mobile mt-5" src="assets/images/Mobile-Kugel@2x.png" />
<div class="container">
  <div data-overlay class="d-flex flex-column justify-content-md-center mt-5">
    <section class="py-3">
      <div class="row">
        <div class="m-auto col-md-6 col-lg-5 mb-5 mb-md-0">
          <div class="p-4 border-0">
            <app-login-form (login)="handleLoginClick($event)"></app-login-form>
          </div>
        </div>
      </div>
    </section>
  </div>
  <app-async-state-overlay [processKey]="asyncProcessKey" (resend)="resendSignUpCode()"></app-async-state-overlay>
</div>
<app-spacer></app-spacer>
