import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { VoDTabs } from '../video-on-demand-detail-page.component';
import { MediaProvider } from 'medtoday-models-library/lib/models';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import {
  getCongressMetaData,
  getSelectedOnDemandVideo
} from '../../../../../../core/data/selectors/data-api.selectors';
import { Store } from '@ngrx/store';
import { KNOWLEDGE_SERIES_OPTIONS } from '../../../../../../static/knowledge-series/knowledge-series-constants';
import {
  getCongressSlugRouterParam,
  getOnDemandVideoIdRouterParam
} from '../../../../../../core/router/selectors/router.selectors';
import { ObservableComponent } from '../../../../../components/observable/observable.component';
import { PlayerInterface } from '../../../../../models/player.interface';
import { Timestamp } from '../../../../../models/jw-timestamp.model';
import { PurgeOnDemandVideo } from '../../../../../../core/data/actions/data-api.actions';
import { GoToOnDemandVideo } from '../../../../../../core/router/actions/main-navigation.actions';
import { imagePaths } from '../../../../../../../medtoday/src/environments/environment';

@Component({
  selector: 'app-video-on-demand-tabs',
  templateUrl: './video-on-demand-tabs.component.html',
  styleUrls: ['./video-on-demand-tabs.component.scss']
})
export class VideoOnDemandTabsComponent extends ObservableComponent implements OnInit {
  readonly VoDTabs = VoDTabs;
  readonly MediaProviders = MediaProvider;

  avatarImageUrl = imagePaths.avatars;
  selectedTab: VoDTabs;
  @Input() videoWidgetReady: Subject<boolean>;
  @Input() player: PlayerInterface;

  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  congressMetaData$ = this.store.select(getCongressMetaData);
  onDemandVideo$ = this.store.select(getSelectedOnDemandVideo);
  hasTimestamps$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onDemandVideoId$ = this.store.select(getOnDemandVideoIdRouterParam).pipe(
    filter(id => !!id),
    map(parseInt)
  );

  // KNOWLEDGE SERIES - STATIC FEATURE
  isKnowledgeSeriesVideo$ = this.onDemandVideoId$.pipe(
    filter(id => !!id),
    map(id =>
      KNOWLEDGE_SERIES_OPTIONS.some(option => {
        return this.knowledgeSeriesVideosIds[option]?.some(sId => sId == id);
      })
    )
  );

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store,
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    @Inject('applicationName') public applicationName: string,
    @Inject('knowledgeSeriesVideosIds') private knowledgeSeriesVideosIds: object
  ) {
    super();
  }

  ngOnInit() {
    this.observeTabFeed();
  }

  observeTabFeed() {
    combineLatest([this.onDemandVideo$, this.isKnowledgeSeriesVideo$, this.congressSlug$, this.hasTimestamps$])
      .pipe(
        filter(([video, _, congressSlug]) => !!video && !!congressSlug),
        tap(([video, isKnowledgeSeriesVideo, _, hasTimestamps]) => {
          if (hasTimestamps) {
            this.changeTab(VoDTabs.Timestamps);
          } else if (isKnowledgeSeriesVideo) {
            this.changeTab(VoDTabs.Series);
          } else if (video.information?.length || video.description?.length) {
            this.changeTab(VoDTabs.Info);
          } else if (video?.featuredOnDemandVideos?.length > 0 && video.sponsors?.length) {
            this.changeTab(VoDTabs.Sponsor);
          } else if (video.experts?.length > 0) {
            this.changeTab(VoDTabs.Study);
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  changeTab(tab: VoDTabs) {
    this.selectedTab = tab;
    this.cdr.detectChanges();
  }

  seekToChapter(timestamp: Timestamp) {
    this.player.seekTo(timestamp);
  }

  handleGoToVideoClick(id: number) {
    this.congressSlug$.pipe(take(1)).subscribe(congressSlug => {
      this.store.dispatch(new PurgeOnDemandVideo());
      this.store.dispatch(new GoToOnDemandVideo(id, false, undefined, congressSlug));
    });
  }

  toggleShowStudyHighlights(expertId: number) {
    const collapseElement = document.getElementById(`study_${expertId}`);
    if (!collapseElement?.classList.contains('show')) {
      collapseElement?.classList.add('show');
    } else {
      collapseElement?.classList.remove('show');
    }
  }

  getIsStudyVisible(expertId: string) {
    const collapseElement = document.getElementById(`study_${expertId}`);
    return collapseElement?.classList.contains('show');
  }
}
