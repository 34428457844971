import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ObservableComponent implements OnDestroy {
  public ngDestroy$ = new Subject<{}>();

  public ngOnDestroy(): void {
    this.ngDestroy$.next({});
    this.ngDestroy$.complete();
  }
}
