import {Directive, Inject, Input, TemplateRef, ViewContainerRef} from '@angular/core';

export type ApplicationName = 'PatientsToday' | 'MedToday';

@Directive({
  selector: '[ifApp]',
})
export class IfAppDirective {

  @Input() set ifApp(app: ApplicationName) {
    if (app !== this.applicationName) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  constructor(private templateRef: TemplateRef<HTMLElement>,
              private viewContainer: ViewContainerRef,
              @Inject('applicationName') private applicationName: ApplicationName) { }

}
