import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { CongressMetadata } from 'medtoday-models-library/lib/models';
import { imagePaths } from '../../../../medtoday/src/environments/environment';

@Component({
  selector: 'app-subpage-header',
  templateUrl: './subpage-header.component.html',
  styleUrls: ['./subpage-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubpageHeaderComponent {
  @Input() congressMetaData: CongressMetadata;
  @Input() subpageName: string;

  logosFolderSlug = imagePaths.logos;

  constructor(@Inject('s3BucketUrl') public s3BucketUrl: string) {}
}
