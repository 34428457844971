<ng-container *ngIf="onDemandVideo$ | async as video">
  <div class="d-flex flex-fill tabs-container">
    <a (click)="changeTab(VoDTabs.Sponsor)" *ngIf="video.featuredOnDemandVideos?.length > 0 && video.sponsors?.length">
      <h5 [ngClass]="selectedTab === VoDTabs.Sponsor ? 'text-primary selected-tab' : ''" class="tab m-0">
        <ng-container *ngIf="video.tabHeaders?.sponsors">{{ video.tabHeaders.sponsors }}</ng-container>
        <ng-container *ngIf="!video.tabHeaders?.sponsors">
          {{ 'VideoOnDemand.More' | translate }}
          {{ video.sponsors.length > 1 ? ('VideoOnDemand.FromCompanies' | translate) : video.sponsors[0].name }}
        </ng-container>
      </h5>
    </a>
    <a (click)="changeTab(VoDTabs.Series)" *ngIf="isKnowledgeSeriesVideo$ | async">
      <h5 [ngClass]="selectedTab === VoDTabs.Series ? 'text-primary selected-tab' : ''" class="tab m-0">
        {{ 'Event.Content' | translate }}
      </h5>
    </a>
    <a (click)="changeTab(VoDTabs.Info)" *ngIf="video.information?.length > 0 || video.description?.length > 0">
      <h5 [ngClass]="selectedTab === VoDTabs.Info ? 'text-primary selected-tab' : ''" class="tab m-0">
        {{ video.tabHeaders?.information ?? 'VideoOnDemand.Info' | translate }}
      </h5>
    </a>
    <a (click)="changeTab(VoDTabs.Timestamps)" *ngIf="hasTimestamps$ | async">
      <h5 [ngClass]="selectedTab === VoDTabs.Timestamps ? 'text-primary selected-tab' : ''" class="tab m-0">
        {{ video.tabHeaders?.timestamps ?? 'VideoOnDemand.Timestamps' | translate }}
      </h5>
    </a>
    <a (click)="changeTab(VoDTabs.Study)" *ngIf="video.experts?.length > 0">
      <ng-container *ifApp="'MedToday'">
        <h5
          [ngClass]="selectedTab === VoDTabs.Study ? 'text-primary selected-tab' : ''"
          class="tab m-0 hide-tablet"
          *ngIf="video.experts?.length > 0"
        >
          {{ video.tabHeaders?.experts ?? 'VideoOnDemand.ExpertsStudy' | translate }}
        </h5>
        <h5
          [ngClass]="selectedTab === VoDTabs.Study ? 'text-primary selected-tab' : ''"
          class="tab m-0 hide-desktop"
          *ngIf="video.experts?.length > 0"
        >
          {{ video.tabHeaders?.experts ?? 'VideoOnDemand.ExpertsStudyMobile' | translate }}
        </h5>
      </ng-container>
      <ng-container *ifApp="'PatientsToday'">
        <h5
          [ngClass]="selectedTab === VoDTabs.Study ? 'text-primary selected-tab' : ''"
          class="tab m-0"
          *ngIf="video.experts?.length > 0"
        >
          {{ video.tabHeaders?.experts ?? 'VideoOnDemand.ExpertsStudyMobile' | translate }}
        </h5>
      </ng-container>
    </a>
    <a (click)="changeTab(VoDTabs.Glossary)" *ngIf="video?.glossaryDescription || video?.glossaryTitle">
      <h5 [ngClass]="selectedTab === VoDTabs.Glossary ? 'text-primary selected-tab' : ''" class="tab m-0">
        {{ video.tabHeaders?.glossar ?? 'VideoOnDemand.Glossary' | translate }}
      </h5>
    </a>
  </div>
  <div class="divider w-100 mb-4"></div>
  <ng-container *ngIf="selectedTab === VoDTabs.Info">
    <div class="row mt-2">
      <div replaceToDownloadLink class="col-12" [innerHTML]="video.information"></div>
    </div>
    <div class="row mt-4 info-description">
      <div replaceToDownloadLink class="col-12" [innerHTML]="video.description"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedTab === VoDTabs.Series && (videoWidgetReady | async)">
    <app-video-series [currentVideoId]="onDemandVideoId$ | async"></app-video-series>
  </ng-container>
  <ng-container *ngIf="video.experts?.length > 0 && selectedTab === VoDTabs.Study">
    <div class="mt-2">
      <ng-container *ngFor="let expert of video.experts">
        <div class="d-flex align-items-start pb-4">
          <div class="profile-picture rounded-circle overflow-hidden mr-3" *ngIf="expert.profileImage">
            <img [src]="s3BucketUrl + avatarImageUrl + expert.profileImage" />
          </div>
          <div class="row d-flex flex-column flex-fill">
            <div class="col-lg-8">
              <div class="badge badge-light p-2 mr-1" *ngIf="expert?.expertType && applicationName === 'PatientsToday'">
                {{ 'ExpertsPage.Patients.' + expert.expertType | translate }}
              </div>
              <h6>{{ expert | expert }}</h6>
              <p *ngIf="expert.areaOfActivity" class="line-height-1">
                <small class="text-note">{{ expert.areaOfActivity }}</small>
              </p>
            </div>
            <ng-container *ngIf="expert.studyHighlights">
              <div class="col-12 collapse mb-3" [id]="'study_' + expert.id">
                <div replaceToDownloadLink [innerHtml]="expert.studyHighlights" class="text-note"></div>
              </div>
              <div class="col-12">
                <button
                  class="btn btn-small btn-dark d-flex align-items-center expert-btn"
                  (click)="toggleShowStudyHighlights(expert.id)"
                  data-toggle="collapse"
                  data-target="#study{{ expert.id }}"
                  aria-expanded="false"
                  aria-controls="study{{ expert.id }}"
                >
                  {{ 'VideoOnDemand.ViewStudies' | translate }}
                  <i
                    *ngIf="!getIsStudyVisible(expert.id)"
                    class="bi bi-plus text-primary font-size-larger pl-2 d-flex"
                  ></i>
                  <i *ngIf="getIsStudyVisible(expert.id)" class="bi bi-x text-light font-size-larger pl-2 d-flex"></i>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedTab === VoDTabs.Glossary">
    <div class="row mt-2">
      <div class="col-12">
        {{ video.glossaryTitle }}
      </div>
    </div>
    <div class="row mt-4">
      <div replaceToDownloadLink class="col-12" [innerHTML]="video.glossaryDescription"></div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="video.featuredOnDemandVideos?.length > 0 && selectedTab === VoDTabs.Sponsor && video.sponsors?.length"
  >
    <div class="row mt-2">
      <ng-container *ngFor="let video of video.featuredOnDemandVideos">
        <div class="col-12 mb-4">
          <div class="badge badge-dark">{{ video.format.title }}</div>
          <h4 class="display-5 py-2">{{ video.title }}</h4>
          <button type="button" class="btn btn-secondary" (click)="handleGoToVideoClick(video.id)">
            {{ 'Navigation.GoToVideo' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div *ngIf="congressMetaData$ | async as congressMetadata" [hidden]="selectedTab !== VoDTabs.Timestamps">
    <ng-container *ngIf="videoWidgetReady | async">
      <app-vod-jw-timestamps
        *ngIf="congressMetadata.mediaProvider === MediaProviders.JW"
        [player]="player"
        [hasTimestamps$]="hasTimestamps$"
        (selectedTimestamp)="seekToChapter($event)"
        [showIndexColumn]="applicationName === 'PatientsToday'"
      ></app-vod-jw-timestamps>
      <app-vod-youtube-timestamps
        *ngIf="congressMetadata.mediaProvider === MediaProviders.YT"
        (selectedTimestamp)="seekToChapter($event)"
        [hasTimestamps$]="hasTimestamps$"
        [player]="player"
        [mediaId]="video.mediaIdentifier"
        [showIndexColumn]="applicationName === 'PatientsToday'"
      ></app-vod-youtube-timestamps>
    </ng-container>
  </div>
</ng-container>
