import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { environment } from 'projects/medtoday/src/environments/environment';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { AppUpdaterModule } from '../../../todaylib/core/app-updater/app-updater.module';
import { AsyncStateModule, AsyncStateOverlayModule } from '../../../todaylib/core/async-state/async-state.module';
import { CoreModule } from '../../../todaylib/core/core.module';
import { AnalyticsService } from '../../../todaylib/core/data/services/ga-analytics.service';
import { DataLayerCustomDimensionsService } from '../../../todaylib/core/data/services/ga-data-layer.service';
import { PagesModule } from './pages/pages.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NavBarModule } from '../../../todaylib/core/layout/nav-bar/nav-bar.module';
import { TIME_SLOT_INTERVAL } from '../../../todaylib/shared/utilities/session.utils';
import { MedboardsApiService } from 'projects/medtoday/src/app/medtoday-store/services/medboards-api.service';
import { EffectsModule } from '@ngrx/effects';
import { MedBoardsApiEffects } from 'projects/medtoday/src/app/medtoday-store/effects/medboards-api.effect';
import { ComponentsModule } from './components/components.module';
import { MedicalEducationApiEffects } from './medtoday-store/effects/medical-education-api.effects';
import { Store, StoreModule } from '@ngrx/store';
import { reducer } from './medtoday-store/reducers/medtoday-store.reducer';
import { MedtodayStoreEffects } from './medtoday-store/effects/medtoday-store.effects';
import { AuthUIModule } from './auth-ui/auth-ui.module';
import { SearchModule } from '../../../todaylib/shared/modules/search/search.module';
import { SurveyModule } from 'projects/todaylib/core/survey/survey.module';
import { ElearningModule } from '../../../todaylib/shared/modules/e-learning/elearning.module';
import { SharedDirectivesModule } from '../../../todaylib/shared';
import { IS_MED_TODAY_APP, IS_PATIENTS_TODAY_APP } from '../../../todaylib/core/definitions/app.definitions';
import { ProvidersService } from '../../../todaylib/core/data/services/providers.service';
import { CMETrackerService } from './medtoday-store/services/cme-tracker.service';
import { CmeTrackingTriggerStrategy } from './classes/cme/cme-tracking-trigger-strategy';
import { BaseAppState } from '../../../todaylib/core/store/reducers';
import { AutoCmeTrigger } from './classes/cme/strategies/auto-cme-trigger';
import { PromptCmeTrigger } from './classes/cme/strategies/prompt-cme-trigger';
import { FileDownloadModule } from 'projects/todaylib/shared/modules/file-download/file-download.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function CmeTrackingTriggerFactory(
  language: string,
  store: Store<BaseAppState>,
  injector: Injector
): AutoCmeTrigger | PromptCmeTrigger {
  if (language === 'de') {
    return new PromptCmeTrigger(store, injector);
  } else {
    return new AutoCmeTrigger(store, injector);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AsyncStateModule,
    AsyncStateOverlayModule,
    InlineSVGModule.forRoot(),
    AppUpdaterModule.forRoot(),
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload'
    }),
    TranslateModule.forRoot({
      defaultLanguage: environment.language,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CoreModule,
    AuthUIModule,
    PagesModule,
    ComponentsModule,
    SharedDirectivesModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NavBarModule,
    SurveyModule,
    SearchModule,
    ElearningModule,
    FileDownloadModule,
    EffectsModule.forFeature([MedBoardsApiEffects, MedicalEducationApiEffects, MedtodayStoreEffects]),
    StoreModule.forFeature('medtoday-store', reducer)
  ],
  providers: [
    { provide: 'apiBaseUrl', useValue: environment.apiBaseUrl },
    { provide: 's3BucketUrl', useValue: environment.s3BucketUrl },
    { provide: 'reactAppScriptUrl', useValue: environment.reactAppScriptUrl },
    { provide: 'applicationName', useValue: environment.name },
    { provide: IS_MED_TODAY_APP, useValue: true },
    { provide: IS_PATIENTS_TODAY_APP, useValue: false },
    { provide: 'medBoardsApiUrl', useValue: environment.medBoardsApiUrl },
    { provide: 'knowledgeSeriesVideosIds', useValue: environment.knowledgeSeriesVideosIds },
    { provide: LOCALE_ID, useValue: environment.language },
    { provide: 'surveyMonkeyScriptUrl', useValue: environment.surveyMonkeyScriptUrl },
    DataLayerCustomDimensionsService,
    AnalyticsService,
    MedboardsApiService,
    ProvidersService,
    Title,
    CMETrackerService,
    {
      provide: TIME_SLOT_INTERVAL,
      useValue: 10
    },
    {
      provide: 'cognitoConfig',
      useValue: environment.cognito
    },
    {
      provide: 'domain',
      useValue: environment.domain
    },
    {
      provide: 'payRexxDomain',
      useValue: environment.payRexxDomain
    },
    {
      provide: CmeTrackingTriggerStrategy,
      useFactory: (store, injector) => CmeTrackingTriggerFactory(environment.language, store, injector),
      deps: [Store, Injector]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
