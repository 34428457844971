import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoginRedirect } from 'projects/todaylib/core/auth/actions/auth.actions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { emailPatternValidator } from 'projects/todaylib/shared/form-validators/email-pattern-validator';

import { GoToRequestPasswordReset, GoToSignUp } from '../../../actions/auth-ui-navigation.actions';
import { AUTH_UI_PATHS } from '../../../definitions/auth-ui-navigation.definitions';
import { LoginFormValue } from './login-form-value.model';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  @Output('login') loginEmitter = new EventEmitter<LoginFormValue>();

  form: FormGroup = new FormGroup({
    emailAddress: new FormControl('', [Validators.required, emailPatternValidator]),
    password: new FormControl('', Validators.required)
  });

  constructor(private router: Router, private store: Store<BaseAppState>) {}

  handleLoginClick(): void {
    if (!this.form.valid) {
      return;
    }

    this.loginEmitter.emit(this.form.value);
  }

  handleSignUpClick(): void {
    this.store.dispatch(new LoginRedirect(this.router.url));
    this.router.createUrlTree([AUTH_UI_PATHS.root.login]);
    this.store.dispatch(new GoToSignUp());
  }

  handleResetClick(): void {
    this.store.dispatch(new GoToRequestPasswordReset());
  }
}
