<div class="congress-dropdown collapse" id="congressDropdown">
  <div class="container">
    <div class="congress-dropdown-container">
      <h4 *ngIf="domain === 'ch' || applicationName === 'PatientsToday'">{{ 'Event.Events' | translate }}</h4>
      <h4 *ngIf="domain !== 'ch' && applicationName === 'MedToday'">{{ 'MedicalEducation.Congresses' | translate }}</h4>
      <div class="congress-dropdown-wrapper" *ngIf="congressTopics$ | async as congressTopics">
        <div class="row" *ngIf="congressesColumnsCount$ | async as congressColumnsCount">
          <div class="col-4 congress-topic-list d-flex">
            <div class="congress-topic-inner">
              <p *ifApp="'MedToday'">{{ 'NavBar.TopicCategory' | translate }}</p>
              <p *ifApp="'PatientsToday'">{{ 'Patients.NavBar.EventsDiseases' | translate }}</p>
              <div class="divider mt-2 w-100"></div>

              <div class="topics-wrapper">
                <ng-container *ifApp="'PatientsToday'">
                  <ng-container *ngIf="(specialEvents$ | async)?.length">
                    <div class="congress-topic mt-2">
                      <a
                        class="py-1 w-100 d-flex justify-content-between align-items-center"
                        (click)="handleTopicCategoryClick()"
                      >
                        <span
                          class="circle mr-md-n2"
                          *ngIf="showTopicIndicator"
                          appTopicCategoryColor
                          [topicCategoryName]="'Special Events'"
                          [inverseColors]="true"
                        ></span>
                        <span class="mr-auto">Special Events</span>
                        <i class="icon-nav" [inlineSVG]="'assets/icons/arrow-right.svg'"></i>
                      </a>
                    </div>
                    <div class="divider my-2 w-100"></div>
                  </ng-container>
                </ng-container>

                <div class="congress-topic" *ngFor="let congressTopic of congressTopics">
                  <a
                    class="py-1 w-100 d-flex justify-content-between align-items-center"
                    (click)="handleTopicCategoryClick(congressTopic)"
                  >
                    <span
                      class="circle mr-md-n2"
                      *ngIf="showTopicIndicator"
                      appTopicCategoryColor
                      [topicCategoryName]="congressTopic.topicCategoryName"
                      [inverseColors]="true"
                    ></span>
                    <span class="mr-auto">{{ congressTopic.topicCategoryName }}</span>
                    <i class="icon-nav" [inlineSVG]="'assets/icons/arrow-right.svg'"></i>
                  </a>
                  <div class="divider my-2 w-100"></div>
                </div>
              </div>
            </div>
            <div class="congress-topic-inner">
              <div class="d-flex align-items-center mb-3">
                <a class="btn" role="button" (click)="handleBackToTopicCategories()">
                  {{ 'NavBar.Back' | translate }}
                </a>
                <p *ngIf="navBarService.selectedTopic" class="mb-0 font-weight-bold">
                  <span
                    class="circle"
                    *ngIf="showTopicIndicator"
                    appTopicCategoryColor
                    [topicCategoryName]="navBarService.selectedTopic"
                    [inverseColors]="true"
                  ></span>
                  {{ navBarService.selectedTopic }}
                </p>
              </div>
              <div class="divider mt-2 w-100"></div>
              <div class="topics-wrapper">
                <div
                  class="w-100 d-grid"
                  [class.mt-2]="i === 0"
                  *ngFor="let congressForTopic of getTopicCategoryChildren(0); let i = index"
                >
                  <app-congress-dropdown-list-item
                    [congressForTopic]="congressForTopic"
                    (congressClick)="handleAfterCongressClick()"
                  ></app-congress-dropdown-list-item>
                  <div class="divider my-2 w-100"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4" *ngIf="activeCongresses$ | async">
            <ng-container *ngIf="congressColumnsCount === 1 || !navBarService.selectedTopic">
              <ng-template
                [ngTemplateOutlet]="activeCongressTile"
                [ngTemplateOutletContext]="{
                  activeCongress: (congressForColumn(1) | async),
                  displayTitle: true
                }"
              ></ng-template>
            </ng-container>
            <div class="mt-5" *ngIf="congressColumnsCount !== 1 && navBarService.selectedTopic">
              <div class="divider my-2 w-100"></div>
              <div class="w-100 d-grid" *ngFor="let congressForTopic of getTopicCategoryChildren(8)">
                <app-congress-dropdown-list-item
                  [congressForTopic]="congressForTopic"
                  (congressClick)="handleAfterCongressClick()"
                ></app-congress-dropdown-list-item>
                <div class="divider my-2 w-100"></div>
              </div>
            </div>
          </div>
          <div class="col-4" *ngIf="activeCongresses$ | async">
            <ng-container *ngIf="congressColumnsCount <= 2 || !navBarService.selectedTopic">
              <ng-template
                [ngTemplateOutlet]="activeCongressTile"
                [ngTemplateOutletContext]="{
                  activeCongress: (congressForColumn(2) | async),
                  displayTitle: congressColumnsCount === 2
                }"
              ></ng-template>
            </ng-container>
            <div class="mt-5" *ngIf="congressColumnsCount > 2 && navBarService.selectedTopic">
              <div class="divider my-2 w-100"></div>
              <div class="w-100 d-grid" *ngFor="let congressForTopic of getTopicCategoryChildren(16)">
                <app-congress-dropdown-list-item
                  [congressForTopic]="congressForTopic"
                  (congressClick)="handleAfterCongressClick()"
                ></app-congress-dropdown-list-item>
                <div class="divider my-2 w-100"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #activeCongressTile let-congress="activeCongress" let-displayTitle="displayTitle">
  <ng-container *ngIf="congress">
    <div class="ml-auto w-75" [class.mr-3]="!displayTitle">
      <p *ngIf="displayTitle" class="text-color font-weight-bold">{{ 'NavBar.mostCurrentCongresses' | translate }}</p>
      <div class="congress-grid" [class.titleless]="!displayTitle">
        <a
          [class.tile-image-stretched]="congressTileStretched"
          role="button"
          class="img-link"
          (click)="handleGoToCongressClick(congress.slug, congress.congressMode)"
          data-toggle="collapse"
          data-target="#congressDropdown"
          aria-haspopup="true"
          aria-expanded="false"
          draggable="false"
        >
          <img
            *ngIf="applicationName === 'MedToday' && congress.logo"
            [src]="s3BucketUrl + logoPathUrl + congress.logo"
            [alt]="congress.name"
          />
          <img
            *ngIf="applicationName === 'PatientsToday' && congress.thumbnail"
            [src]="s3BucketUrl + thumbnailUrl + congress.thumbnail"
            [alt]="congress.name"
          />
          <h5 class="text-color" *ngIf="!congress.logo">{{ congress.heroSliderHeadline }}</h5>
        </a>
        <p class="mt-2">{{ congress.name }}</p>
      </div>
    </div>
  </ng-container>
</ng-template>
