import { NgModule } from '@angular/core';
import { SearchPageComponent } from './components/search-page/search-page.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SearchEffects } from './effects/search.effects';
import { SEARCH_STATE_KEY } from './definitions/search.definitions';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SearchService } from './services/search.service';
import { CoreModule } from '../../../core/core.module';
import { reducer } from './reducers/search.reducer';
import { SharedComponentsModule } from '../../components/shared-components.module';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';

@NgModule({
  declarations: [SearchPageComponent],
  imports: [
    StoreModule.forFeature(SEARCH_STATE_KEY, reducer),
    EffectsModule.forFeature([SearchEffects]),
    TranslateModule,
    ReactiveFormsModule,
    CoreModule,
    SharedComponentsModule,
    CommonModule,
    InlineSVGModule,
    SharedDirectivesModule
  ],
  providers: [SearchService],

  exports: [SearchPageComponent]
})
export class SearchModule {}
