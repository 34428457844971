import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AsyncAction } from '../../async-state/models/async-action.model';
import { handleErrors } from '../../async-state/operators/handle-errors';
import { SignUpRequest } from '../../../shared/models';
import { ApiResponse } from '../../../shared/models/response/response.model';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import {
  SignUp,
  SignUpActionTypes,
  SignUpConfirmation,
  SignUpConfirmationFailure,
  SignUpConfirmationSuccess,
  SignUpFailure,
  SignUpSuccess,
  ResendSignUp,
  ResendSignUpSuccess,
  ResendSignUpFailure
} from '../actions/auth-sign-up.actions';
import { AuthService } from '../services/auth.service';
import { GoToSignUpSuccess } from 'projects/medtoday/src/app/auth-ui/actions/auth-ui-navigation.actions';

@Injectable()
export class AuthSignUpEffects {
  signUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignUpActionTypes.SignUp),
        map((action: SignUp) => action.payload),
        exhaustMap((signUpRequest: SignUpRequest) =>
          this.authService.signUp(signUpRequest).pipe(
            handleErrors(() => new SignUpFailure()),
            map((_response: ApiResponse<{}>) => new SignUpSuccess(signUpRequest)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  redirectToSignUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignUpActionTypes.SignUpSuccess),
        map((_action: SignUpSuccess) => new GoToSignUpSuccess())
      ),
    { dispatch: true }
  );

  signUpConfirmation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignUpActionTypes.SignUpConfirmation),
        exhaustMap((action: SignUpConfirmation) =>
          this.authService.confirmSignUp(action.userId, action.code).pipe(
            handleErrors(() => new SignUpConfirmationFailure()),
            map((_: ApiResponse<boolean>) => new SignUpConfirmationSuccess()),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  // commented out for medboards btn
  // redirectToLogin$ = createEffect(() => this.actions$.pipe(
  //   ofType(SignUpActionTypes.SignUpConfirmationSuccess),
  //   delay(3000),
  //   map((_action: SignUpConfirmationSuccess) => new GoToLogin()),
  // ), { dispatch: true });

  resendSignUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignUpActionTypes.ResendSignUp),
        exhaustMap((action: ResendSignUp) =>
          this.authService.resendSignUp(action.username).pipe(
            handleErrors(() => new ResendSignUpFailure()),
            map((_: ApiResponse<boolean>) => new ResendSignUpSuccess()),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  constructor(private actions$: Actions, private authService: AuthService) {}
}
