import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UpcomingEventEventSessionTimeframeModel, UpcomingEventListItemModel } from 'medtoday-models-library';
import moment from 'moment';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { interval } from 'rxjs';
import { startWith, takeUntil, tap } from 'rxjs/operators';

import {
  GoToChannel,
  GoToCongressLandingPage,
  GoToOnDemandVideo,
  GoToPartnerDetails,
  GoToProgramme
} from '../../../core/router/actions/main-navigation.actions';
import { MAIN_NAVIGATION_PATH_ELEMENTS } from '../../../core/router/definitions/main-navigation.definitions';
import { CarouselItem } from '../../models/carousel-item.model';
import { SESSION_UTILS_TOKEN, SessionUtils } from '../../utilities/session.utils';
import { ObservableComponent } from '../observable/observable.component';
import { TranslateService } from '@ngx-translate/core';
import { SetSelectedUpcomingEvent } from '../../../core/data/actions/upcoming-events-api.actions';
import { imagePaths } from '../../../../medtoday/src/environments/environment';
import { MediaProvider, Mode } from 'medtoday-models-library/lib/models/responses/congress/congress-metadata.model';

@Component({
  selector: 'app-carousel-small-slide',
  templateUrl: './carousel-small-slide.component.html',
  styleUrls: ['./carousel-small-slide.component.scss']
})
export class CarouselSmallSlideComponent extends ObservableComponent implements OnInit {
  @Input() coverImage = true;
  @Input() labelColor = '';
  @Input() isCongressList = false;
  @Input() isUpcomingEvents = false;
  @Input() index: number;
  @Input() carouselItem;
  @Input() showCmePoints = false;
  @Input() showTitleOnThumbnail = false;
  @Input() scaleOnHover = false;
  @Input() showDatePrefix = false;
  @Input() imageKeyField = 'logo';

  // Mainly to apply the correct link to the Item -> if the carouselItem was neither congressList or upcomingEvents it was used for displaying a video
  // but now with the use of the carousel on the patients diseases subpage we also link partners,...
  @Input() typeOfItem: TypeOfCarouselItem | null;

  sessionIsLive: boolean;
  liveSession: UpcomingEventEventSessionTimeframeModel;
  MediaProvider = MediaProvider;

  constructor(
    @Inject('applicationName') public applicationName: string,
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    private store: Store<BaseAppState>,
    @Inject(SESSION_UTILS_TOKEN) private sessionUtils: SessionUtils,
    private translateService: TranslateService
  ) {
    super();
  }

  get folderUrl() {
    if (this.carouselItem.logo === this.carouselItem.thumbnail) {
      return imagePaths.logos;
    }

    return this.imageKeyField === 'logo' ? imagePaths.logos : imagePaths.thumbnails;
  }

  ngOnInit(): void {
    if (this.isUpcomingEvents) {
      this.canShowEventLabel(this.carouselItem);
    }
  }

  public handleCongressHover(index: number, color = '', mouseIn: boolean): void {
    if (this.scaleOnHover) {
      return;
    }

    const element = document.getElementById(`congress-list-${index}`);
    color = color ? color : 'bd1c7f';
    if (element) {
      element.style.background = mouseIn ? `linear-gradient(0deg, #${color}80 0%, #${color}40 100%)` : '';
    }
  }

  public getEventDates(event: UpcomingEventListItemModel): string {
    const start = moment(event.start);
    const end = moment(event.end);
    let prefix = '';
    if (this.showDatePrefix) {
      if (this.carouselItem.congressMode === Mode.VoD) {
        prefix = `${this.translateService.instant('Common.ab')} `;
      }
    }

    if (!start.isSame(end, 'day')) {
      return `${start.format('DD.MM.')} - ${end.format('DD.MM.YY')}`;
    } else {
      return `${prefix}${start.format('DD.MM.YY')}`;
    }
  }

  public handleCongressClick(congressSlug: string, congressMode: string): void {
    this.store.dispatch(new GoToCongressLandingPage(congressSlug, congressMode));
  }

  public canShowEventLabel(upcomingEventItem: UpcomingEventListItemModel): void {
    interval(30000)
      .pipe(
        startWith(0),
        takeUntil(this.ngDestroy$),
        tap(() => {
          for (let i = 0; i < upcomingEventItem.sessionTimeslots.length; i++) {
            this.sessionIsLive = this.sessionUtils.isActive({
              start: upcomingEventItem.sessionTimeslots[i].start,
              finish: upcomingEventItem.sessionTimeslots[i].finish,
              sessions: [upcomingEventItem.sessionTimeslots[i]]
            });
            if (this.sessionIsLive) {
              this.liveSession = upcomingEventItem.sessionTimeslots[i];
              return true;
            }
          }
        })
      )
      .subscribe();
  }

  public handleItemClick(carouselItem: CarouselItem): void {
    switch (this.typeOfItem) {
      case 'video':
        this.store.dispatch(new GoToOnDemandVideo(carouselItem.id!, false, undefined, carouselItem.url!));
        break;
      case 'congress':
        this.store.dispatch(new GoToCongressLandingPage(carouselItem.url!, this.carouselItem.congressMode));
        break;
      case 'partner':
        carouselItem.isExternalUrl
          ? window.open(carouselItem.url!, '_blank')
          : this.store.dispatch(new GoToPartnerDetails(carouselItem.url!));
        break;
      default: // This is the same as video in case we have an old carouselSmall without the typeOfItem input somewhere they used to be all video sliders
        this.store.dispatch(new GoToOnDemandVideo(carouselItem.id!, false, undefined, carouselItem.url!));
        break;
    }
  }

  public handleUpcomingLink(event) {
    if (!this.carouselItem.congressLinkActive) {
      this.showUpcomingEventModal();
      return;
    }

    if (this.isExternalLink()) {
      window.open(this.carouselItem.link!, '_blank');
      return;
    }

    event.preventDefault();

    if (this.sessionIsLive) {
      const result: UpcomingEventEventSessionTimeframeModel[] = [];
      for (let i = 0; i < this.carouselItem.sessionTimeslots.length; i++) {
        this.carouselItem.sessionTimeslots[i].start === this.liveSession.start
          ? result.push(this.carouselItem.sessionTimeslots[i])
          : null;
      }

      if (result.length === 1) {
        this.store.dispatch(
          new GoToChannel(
            this.carouselItem.congressSlug,
            this.liveSession.channelSlug,
            MAIN_NAVIGATION_PATH_ELEMENTS.root.programmeElement
          )
        );
      } else {
        this.carouselItem.congressMode === 'MedEd'
          ? this.store.dispatch(
              new GoToCongressLandingPage(this.carouselItem.congressSlug, this.carouselItem.congressMode)
            )
          : this.store.dispatch(new GoToProgramme(this.carouselItem.congressSlug, true));
      }
    } else {
      this.store.dispatch(new GoToCongressLandingPage(this.carouselItem.congressSlug, this.carouselItem.congressMode));
    }
  }

  private showUpcomingEventModal() {
    if (this.applicationName === 'PatientsToday') {
      this.store.dispatch(new SetSelectedUpcomingEvent(this.carouselItem));
    }
  }

  private isExternalLink(): boolean {
    return new URL(this.carouselItem.link).origin !== window.location.origin;
  }
}

export type TypeOfCarouselItem = 'video' | 'partner' | 'congress';
