<div class="image-box">
  <a [href]="link" target="_blank">
    <img
      [class.patients]="applicationName === 'PatientsToday'"
      [class.cover]="imageSize === 'cover'"
      [class.contain]="imageSize === 'contain'"
      [src]="src"
      alt=""
    />
  </a>
  <p *ngIf="textBelow">
    <small>{{ textBelow }}</small>
  </p>
</div>
