import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseAppState } from '../../../../../todaylib/core/store/reducers';
import { ObservableComponent } from '../../../../../todaylib/shared/components/observable/observable.component';
import { environment } from '../../../environments/environment';
import { LoadMedBoardsData } from '../../medtoday-store/actions/medboards-api.actions';
import { getMedBoardsData } from '../../medtoday-store/selectors/medtoday-store.selectors';
import { Board } from '../../models/medboards.model';

export interface BoardCard {
  title: string;
  description: string;
  image: string;
  url: string;
}
export interface BoardTypesCards {
  active: BoardCard[];
  finished: BoardCard[];
}

@Component({
  selector: 'app-medboards',
  templateUrl: './medboards.component.html',
  styleUrls: ['./medboards.component.scss']
})
export class MedboardsComponent extends ObservableComponent implements OnInit {
  medBoardsData$: Observable<{ boards: BoardTypesCards }> = this.store.select(getMedBoardsData).pipe(
    filter(medBoardsData => !!medBoardsData),
    map(medBoardsData => ({
      boards: {
        active: medBoardsData.boards
          .filter(board => !board.isArchived && board.isEnabled)
          .map(this.convertToBoardCard)
          .reverse(),
        finished: medBoardsData.boards.filter(board => board.isArchived).map(this.convertToBoardCard)
      }
    }))
  );

  medboardsUrl = environment.medboardsUrl;

  constructor(private store: Store<BaseAppState>) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadMedBoardsData());
  }

  private convertToBoardCard(board: Board) {
    return {
      title: board.title,
      description: board.description,
      image: board.image,
      url: board.url
    };
  }
}
