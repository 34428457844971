<div class="med-carousel overflow-hidden w-100">
  <div class="med-carousel-wrapper container">
    <div #sliderRef class="keen-slider">
      <div
        class="med-carousel-item keen-slider__slide"
        [class.half-width-item]="halfWidthItem"
        [class.in-vp]="isElementInViewPort(i)"
        *ngFor="let carouselItem of carouselItems; let i = index"
      >
        <div
          class="image-wrapper"
          [class.dark-theme]="carouselItem.headerDarkTheme"
          [class.patients-border]="applicationName === 'PatientsToday'"
        >
          <img
            [class.cover-image]="coverImage"
            draggable="false"
            *ngIf="carouselItem.backgroundImage || carouselItem.logo"
            [src]="s3BucketUrl + (carouselItem.logo ? logoPathUrl + carouselItem.logo : carouselItem.backgroundImage)"
            alt="thumbnail"
          />
          <img
            [class.cover-image]="coverImage"
            draggable="false"
            *ngIf="isCmeCarousel && !carouselItem.backgroundImage"
            [src]="'/assets/images/Landingpage-Teaser-Kugeln@2x.png'"
            alt="thumbnail"
          />

          <div class="image-pre" [class.patients-bg]="applicationName === 'PatientsToday'"></div>
          <div class="flex-column box-content" replaceToDownloadLink>
            <h4 class="font-weight-light mb-md-4 mb-2" *ngIf="carouselItem.additionalHeader">
              {{ carouselItem.additionalHeader }}
            </h4>
            <h4 class="font-weight-light" *ngIf="carouselItem.title || carouselItem.congressFeedHeader">
              {{ carouselItem.congressFeedHeader ? carouselItem.congressFeedHeader : carouselItem.title }}
            </h4>
            <div class="d-flex flex-row hide-mobile">
              <div
                class="badge p-2 mr-1 my-1"
                [class.badge-light]="applicationName === 'PatientsToday'"
                [class.badge-dark]="applicationName === 'MedToday'"
                *ngIf="carouselItem.eventType"
              >
                <ng-container *ngIf="carouselItem.eventType === 'MedEd'">
                  {{ 'MedicalEducation.EventType.Event' | translate }}
                </ng-container>
                <ng-container *ngIf="carouselItem.eventType === 'Live' || carouselItem.eventType === 'VoD'">
                  {{ 'MedicalEducation.EventType.Congress' | translate }}
                </ng-container>
              </div>
              <div class="badge badge-dark p-2 mr-1 my-1" *ngIf="carouselItem.cmeActive">CME</div>
            </div>
            <p
              class="font-weight-light mt-3 hide-mobile"
              *ngIf="carouselItem.description || carouselItem.congressFeedDescription"
              [innerHtml]="
                carouselItem.congressFeedDescription ? carouselItem.congressFeedDescription : carouselItem.description
              "
            ></p>
            <span
              class="text-danger font-weight-bold"
              [class.mt-3]="!carouselItem.description && carouselItem.congressFeedDescription"
              *ngIf="
                carouselItem.start && carouselItem.finish && !carouselItem.date && applicationName !== 'PatientsToday'
              "
            >
              {{ calcTimes(carouselItem.start, carouselItem.finish) }}
              {{ showHourSuffix(carouselItem.start, carouselItem.finish) ? ('Common.Hour' | translate) : '' }}
            </span>
            <span
              class="text-danger font-weight-bold"
              [class.mt-3]="!carouselItem.description && carouselItem.congressFeedDescription"
              *ngIf="carouselItem.date"
            >
              {{ carouselItem.date }}
            </span>
            <div class="d-flex flex-row mt-4 buttons-container">
              <button
                class="btn ghost-button-light rounded-pill mr-2"
                *ngIf="carouselItem.showCongressLinkButton && !carouselItem.congressFeedOnDemandVideoId"
                (click)="handleCongressClick(carouselItem.congressSlug, carouselItem.congressMode)"
              >
                {{ 'MedicalEducation.WatchDetails' | translate }}
              </button>
              <a
                class="btn ghost-button-light rounded-pill mr-2"
                *ngIf="carouselItem.url && carouselItem.buttonLabel"
                [href]="carouselItem.url"
                target="_blank"
                rel="noreferrer"
                >{{ carouselItem.buttonLabel }}</a
              >
              <button
                class="btn ghost-button-light rounded-pill mr-2"
                *ngIf="carouselItem.congressFeedOnDemandVideoId"
                (click)="handleItemOnDemandClick(carouselItem.congressSlug, carouselItem.congressFeedOnDemandVideoId)"
              >
                {{ 'VideoOnDemand.ViewPost' | translate }}
              </button>
              <ng-container *ngIf="isLive(carouselItem.start, carouselItem.finish)">
                <button
                  *ngIf="carouselItem.congressMode === 'Live'"
                  class="btn btn-danger rounded-pill"
                  (click)="handleLiveButtonClick(carouselItem.congressSlug)"
                >
                  <span>
                    {{ 'ChannelPage.LiveNow' | translate }}
                    <i class="text-light pl-3" [inlineSVG]="'assets/icons/live.svg'"></i>
                  </span>
                </button>
                <button
                  *ngIf="carouselItem.congressMode === 'MedEd'"
                  class="btn btn-danger rounded-pill"
                  (click)="handleGoToChannelClick(carouselItem.congressSlug)"
                >
                  <span>
                    {{ 'ChannelPage.LiveNow' | translate }}
                    <i class="text-light pl-3" [inlineSVG]="'assets/icons/live.svg'"></i>
                  </span>
                </button>
              </ng-container>
              <a
                *ngIf="carouselItem.livebuttonUrl && isLive(carouselItem?.livebuttonStart, carouselItem?.livebuttonEnd)"
                class="btn btn-danger rounded-pill"
                [href]="carouselItem.livebuttonUrl"
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  {{ 'ChannelPage.LiveNow' | translate }}
                  <i class="text-light pl-3" [inlineSVG]="'assets/icons/live.svg'"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ol class="carousel-indicators hide-desktop">
      <li
        *ngFor="let carouselItem of carouselItems; let i = index"
        [class.active]="i === currentSlide"
        (click)="slider.moveToIdx(i)"
      ></li>
    </ol>

    <ng-container *ngIf="carouselItems?.length > 2">
      <div class="controls left" (click)="slider.prev()" [class.disabled]="isPreviousButtonDisabled()">
        <div class="icon-16" [inlineSVG]="'assets/icons/arrow-left.svg'"></div>
      </div>
      <div class="controls right" (click)="slider.next()" [class.disabled]="isNextButtonDisabled()">
        <div class="icon-16" [inlineSVG]="'assets/icons/arrow-right.svg'"></div>
      </div>
    </ng-container>
  </div>
</div>
