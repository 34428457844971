import { Programme } from 'medtoday-models-library/lib/models/programme.model';
import { OnDemandVideoListItem } from 'medtoday-models-library/lib/models';
import moment from 'moment/moment';
import { Session } from 'medtoday-models-library/lib/models/session.model';
import { Filter } from '../../../modules/filters/classes/filter';
import { uniqBy } from 'lodash';
import { OnDemandVideoListResponse } from 'medtoday-models-library';

export class ProgramFilters {
  static initVideosFilter(
    program: Programme | OnDemandVideoListResponse,
    videosFilter: Filter<OnDemandVideoListItem>,
    programmeDays: moment.Moment[],
    filterVisibility: string[]
  ) {
    if (filterVisibility.includes('topic')) {
      let topicFilterOptions = program.topics
        .filter(topic =>
          program.onDemandVideos.some(video => video.topicId === topic.id || video.subTopicIds.includes(topic.id))
        )
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(topic => ({
          id: topic.id,
          source: topic,
          condition: (record: OnDemandVideoListItem) => {
            return record.topicId === topic.id || record.subTopicIds?.includes(topic.id);
          }
        }));
      videosFilter.addFilters<OnDemandVideoListItem>('topic', topicFilterOptions);
    }

    if (filterVisibility.includes('language')) {
      let languageFilterOptions = program.languages.map(language => ({
        id: language,
        source: language,
        condition: (record: OnDemandVideoListItem) => {
          return record.languages.includes(language) || record.spokenLanguage === language;
        }
      }));
      videosFilter.addFilters<OnDemandVideoListItem>('language', languageFilterOptions);
    }

    if (filterVisibility.includes('day')) {
      const dayFilterOptions = programmeDays.map(day => ({
        id: day,
        source: day,
        condition: (record: OnDemandVideoListItem) => moment.utc(record.timeSlotStart).isSame(moment.utc(day), 'd')
      }));
      videosFilter.addFilters<OnDemandVideoListItem>('day', dayFilterOptions);
    }

    if (filterVisibility.includes('format')) {
      const formatFilterOptions = (program as OnDemandVideoListResponse).formats.map(format => ({
        id: format.id,
        source: format,
        condition: (record: OnDemandVideoListItem) => record.formatId === format.id
      }));
      videosFilter.addFilters<OnDemandVideoListItem>('format', formatFilterOptions);
    }

    videosFilter.loadFilterableRecords(program.onDemandVideos);
  }

  static initSessionFilter(
    program: Programme,
    sessionFilter: Filter<Session>,
    programmeDays: moment.Moment[],
    filterVisibility: string[]
  ) {
    if (filterVisibility.includes('topic')) {
      const sessionTopicFilterOptions = program.topics.map(topic => ({
        id: topic.id,
        source: topic,
        condition: (record: Session) => {
          return record.topic?.id === topic.id || record.subTopicIds?.includes(topic.id);
        }
      }));
      sessionFilter.addFilters<Session>('topic', sessionTopicFilterOptions);
    }

    if (filterVisibility.includes('language')) {
      const sessionLanguageFilterOptions = program.languages.map(language => ({
        id: language,
        source: language,
        condition: (record: Session) => {
          return record.spokenLanguage === language;
        }
      }));
      sessionFilter.addFilters<Session>('language', sessionLanguageFilterOptions);
    }

    if (filterVisibility.includes('day')) {
      const dayFilterOptions = programmeDays.map(day => ({
        id: day,
        source: day,
        condition: (record: Session) => {
          const ts = program.timeSlots.find(ts => ts.id === record.timeSlotId);
          if (!ts) return false;
          return moment.utc(ts.start).isSame(moment.utc(day), 'd');
        }
      }));
      sessionFilter.addFilters<Session>('day', dayFilterOptions);
    }

    if (filterVisibility.includes('format')) {
      const formatFilterOptions = program.sessions.map(session => ({
        id: session.format,
        source: session.format,
        condition: (record: Session) => session.format === record.format
      }));
      sessionFilter.addFilters<Session>('format', formatFilterOptions);
    }

    if (filterVisibility.includes('sponsor')) {
      const sponsors: { sponsorName: string; sponsorId: number }[] = program.sessions.reduce(
        (agg: any[], session: Session) => {
          agg.push(...session.sponsors);
          return agg;
        },
        []
      );
      const sponsorFilterOptions = uniqBy(sponsors, 'sponsorId').map(sponsor => ({
        id: sponsor.sponsorId,
        source: sponsor,
        condition: (record: Session) => record.sponsors.some(sp => sp.sponsorId === sponsor.sponsorId)
      }));
      sessionFilter.addFilters<Session>('sponsor', sponsorFilterOptions);
    }

    sessionFilter.loadFilterableRecords(program.sessions);
  }
}
