import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../../core/store/reducers';
import { handleAsyncErrors } from '../../../../core/async-state/operators/handle-errors';
import { AsyncAction } from '../../../../core/async-state/models/async-action.model';
import { of } from 'rxjs';
import { PartnersService } from '../services/partners.service';
import {
  LoadPartnerDetails,
  LoadPartnerDetailsFail,
  LoadPartnerDetailsSuccess,
  LoadPartnersFail,
  LoadPartnersSuccess,
  LoadSponsorBoothDetails,
  LoadSponsorBoothDetailsFail,
  LoadSponsorBoothDetailsSuccess,
  PartnersActionTypes
} from '../actions/partners.actions';
import { Partner, PartnerListResponse, SponsorBooth } from 'medtoday-models-library/lib';

@Injectable({
  providedIn: 'root'
})
export class PartnersEffects {
  loadPartners$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PartnersActionTypes.LoadPartners),
        switchMap(() =>
          this.api.getPartners().pipe(
            handleAsyncErrors(() => new LoadPartnersFail()),
            map((response: PartnerListResponse) => new LoadPartnersSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadPartnerDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PartnersActionTypes.LoadPartnerDetails),
        switchMap((action: LoadPartnerDetails) =>
          this.api.getPartnerDetails(action.partnerSlug).pipe(
            handleAsyncErrors(() => new LoadPartnerDetailsFail()),
            map((response: Partner) => new LoadPartnerDetailsSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadSponsorDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PartnersActionTypes.LoadSponsorDetails),
        switchMap((action: LoadSponsorBoothDetails) =>
          this.api.getSponsorBoothDetails(action.congressSlug, action.sponsorSlug).pipe(
            handleAsyncErrors(() => new LoadSponsorBoothDetailsFail()),
            map((response: SponsorBooth) => new LoadSponsorBoothDetailsSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  constructor(private actions$: Actions, public store: Store<BaseAppState>, public api: PartnersService) {}
}
