import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TimeSlotRow } from '../../models/time-slot-row';
import { duration } from 'moment';
import { utc } from 'moment/moment';
import { interval, Observable } from 'rxjs';
import { ObservableComponent } from '../observable/observable.component';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { round } from 'lodash';
import { TimeSlot } from 'medtoday-models-library/lib';

@Component({
  selector: 'app-live-indicator',
  template:
    '<em class="text-lowercase" *ngIf="showTimeUntil && (isLive() | async) === false">live in {{ timeUntil | async }} min</em>' +
    '<em class="livedot" *ngIf="(isLive() | async) || !showTimeUntil">Live</em>',
  styleUrls: ['./live-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiveIndicatorComponent extends ObservableComponent implements OnInit {
  @Input() showTimeUntil = true;
  @Input() timeSlotRow: TimeSlotRow | TimeSlot;

  timeUntil: Observable<number>;

  ngOnInit() {
    // each 30 seconds
    this.timeUntil = interval(30000).pipe(
      startWith(0),
      takeUntil(this.ngDestroy$),
      filter(() => !!this.timeSlotRow),
      map(() => round(duration(utc(this.timeSlotRow.start).diff(utc())).asMinutes()))
    );
  }

  isLive() {
    return this.timeUntil.pipe(map(time => time < 1));
  }
}
