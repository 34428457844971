import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { BaseAppState } from '../../store/reducers';
import { isLoggedIn } from '../selectors/auth.selectors';
import { LoginRedirect } from '../actions/auth.actions';
import { AUTH_UI_PATHS } from 'projects/medtoday/src/app/auth-ui/definitions/auth-ui-navigation.definitions';

@Injectable()
export class LoggedInGuard  {
  isLoggedIn$ = this.store.select(isLoggedIn);

  constructor(private router: Router, private store: Store<BaseAppState>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedIn$.pipe(
      take(1),
      map(loggedIn => {
        if (loggedIn) {
          return true;
        }
        if (!loggedIn) {
          const congressTokenArray = localStorage.getItem('medtoday_congress_tokens');
          if (congressTokenArray) {
            const parsedArray = JSON.parse(congressTokenArray);
            const found = parsedArray.some(ele => ele.congressSlug === route.params.congressSlug);
            if (found) {
              return true;
            }
          }
          this.store.dispatch(new LoginRedirect(state.url));
        }
        return this.router.createUrlTree([AUTH_UI_PATHS.root.login]);
      })
    );
  }
}
