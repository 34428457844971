import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const TOAST_DURATION_SHORT = 3000;
const TOAST_DURATION_LONG = 5000;

@Injectable()
export class ToastService {
  constructor(private toastrService: ToastrService, private translate: TranslateService) {}

  public showInfo(title: string, message?: string, timeOut = TOAST_DURATION_SHORT, tapToDismiss = false): void {
    const config: Partial<IndividualConfig> = { timeOut, tapToDismiss };
    this.translate.get([title, message!]).subscribe(translations => {
      this.toastrService.info(translations[message!] ? translations[message!] : '', translations[title], config);
    });
  }

  public showSuccess(title: string, message?: string, timeOut = TOAST_DURATION_SHORT, tapToDismiss = false): void {
    const config: Partial<IndividualConfig> = { timeOut, tapToDismiss };
    this.translate.get([title, message!]).subscribe(translations => {
      this.toastrService.success(translations[message!] ? translations[message!] : '', translations[title], config);
    });
  }

  public showError(
    title: string,
    message?: string,
    timeOut = TOAST_DURATION_LONG,
    tapToDismiss = true,
    override: IndividualConfig | any = {}
  ): void {
    const config: Partial<IndividualConfig> = { timeOut, tapToDismiss, ...override };
    this.translate.get([title, message!]).subscribe(translations => {
      this.toastrService.error(translations[message!] ? translations[message!] : '', translations[title], config);
    });
  }

  public showWarning(title: string, message?: string, timeOut = TOAST_DURATION_SHORT, tapToDismiss = false): void {
    const config: Partial<IndividualConfig> = { timeOut, tapToDismiss };
    this.toastrService.warning(message ? message : '', title, config);
  }
}
