import {
  ExpertsDetailsActions,
  ExpertsDetailsActionTypes
} from '../../../../medtoday/src/app/pages/actions/experts-details.actions';
import { MainNavigationActions, MainNavigationActionTypes } from '../../router/actions/main-navigation.actions';
import {
  Channel,
  CongressLandingPage,
  CongressListItem,
  ArchivedCongressListItem,
  CongressMetadata,
  ExpertListItem,
  ExpertsResponse,
  FeaturedContentResponse,
  OnDemandVideoDetail,
  OnDemandVideoListResponse,
  Programme,
  LandingPageVideoListItem,
  DeeplinkModel,
  EventListItem,
  PremiumBox,
  PageItem,
  InternationalContent,
  UpcomingEventsResponseModel,
  UpcomingEventsTopicLanguagesResponseModel,
  UpcomingEventsDatesResponseModel,
  UpcomingEventsCountriesResponseModel
} from 'medtoday-models-library';
import { DataApiActions, DataApiActionTypes } from '../actions/data-api.actions';
import { LanguageActions, LanguageActionTypes } from '../actions/language.actions';
import { UpcomingEventsApiActions, UpcomingEventsApiActionTypes } from '../actions/upcoming-events-api.actions';
import { UpcomingEventListItemModel } from 'medtoday-models-library/src/models/responses/upcoming-events/upcoming-event-list-item.model';
import { CongressesTopicModel, Page } from 'medtoday-models-library/lib/models';

export interface AppDataState {
  congresses: CongressListItem[] | undefined;
  mostWatchedVideos: LandingPageVideoListItem[] | undefined;
  archivedCongresses: ArchivedCongressListItem[] | undefined;
  congressMetaData: CongressMetadata | undefined;
  congressListUpcomingEvents: EventListItem[] | undefined;
  congressListPremiumBox: PremiumBox | undefined;
  congressListPageItems: PageItem[] | undefined;
  congressPage: Page[] | undefined;
  congressListIntertnationalContent: InternationalContent[] | undefined;
  congressLandingPageData: CongressLandingPage | undefined;
  programmeData: {
    data: Programme | undefined;
    navigatedFromLandingPage: boolean | undefined;
  };
  expertsData: {
    data: ExpertsResponse | undefined;
    selectedExpert: ExpertListItem | undefined;
  };
  selectedUpcomingEvent: UpcomingEventListItemModel | undefined;
  channelData: Channel | undefined;
  onDemandVideosData: {
    data: OnDemandVideoListResponse | undefined;
    topicIdToFilter: number | undefined;
  };
  onDemandVideo: OnDemandVideoDetail | undefined;
  deeplink: DeeplinkModel | undefined;
  featuredContentOverview: FeaturedContentResponse | undefined;
  selectedLanguage: string | undefined;
  upcomingEventsPageData: {
    data: UpcomingEventsResponseModel | undefined;
    topicCategories: UpcomingEventsTopicLanguagesResponseModel | undefined;
    dates: UpcomingEventsDatesResponseModel | undefined;
    countries: UpcomingEventsCountriesResponseModel | undefined;
  };
  congressTopics: CongressesTopicModel[] | undefined;
}

const initialState: AppDataState = {
  congresses: undefined,
  archivedCongresses: undefined,
  mostWatchedVideos: undefined,
  congressMetaData: undefined,
  congressListPremiumBox: undefined,
  congressListPageItems: undefined,
  congressPage: undefined,
  congressListIntertnationalContent: undefined,
  congressListUpcomingEvents: undefined,
  congressLandingPageData: undefined,
  programmeData: {
    data: undefined,
    navigatedFromLandingPage: false
  },
  expertsData: {
    data: undefined,
    selectedExpert: undefined
  },
  channelData: undefined,
  onDemandVideosData: {
    data: undefined,
    topicIdToFilter: undefined
  },
  deeplink: undefined,
  onDemandVideo: undefined,
  featuredContentOverview: undefined,
  selectedLanguage: undefined,
  selectedUpcomingEvent: undefined,
  upcomingEventsPageData: {
    data: undefined,
    topicCategories: undefined,
    dates: undefined,
    countries: undefined
  },
  congressTopics: undefined
};

export function reducer(
  state = initialState,
  action: DataApiActions | ExpertsDetailsActions | MainNavigationActions | LanguageActions | UpcomingEventsApiActions
) {
  switch (action.type) {
    case DataApiActionTypes.LoadCongressesListSuccess: {
      return {
        ...state,
        congresses: action.congresses,
        archivedCongresses: action.archivedCongresses,
        mostWatchedVideos: action.mostWatchedVideos,
        congressListUpcomingEvents: action.upcomingEvents,
        congressListPremiumBox: action.premiumBox,
        congressListPageItems: action.pageItems,
        congressPage: action.page,
        congressListIntertnationalContent: action.intertnationalContent
      };
    }
    case DataApiActionTypes.LoadCongressLandingPageData: {
      return {
        ...state,
        congressLandingPageData: undefined
      };
    }
    case DataApiActionTypes.LoadCongressLandingPageDataSuccess: {
      return {
        ...state,
        congressLandingPageData: action.congressLandingPageData
      };
    }
    case DataApiActionTypes.LoadProgrammeDataSuccess: {
      return {
        ...state,
        programmeData: {
          data: action.programmeData
        }
      };
    }
    case DataApiActionTypes.LoadExpertsDataSuccess: {
      return {
        ...state,
        expertsData: {
          ...state.expertsData,
          data: action.data
        }
      };
    }
    case DataApiActionTypes.LoadCongressMetaDataSuccess: {
      return {
        ...state,
        congressMetaData: action.congressMetaData
      };
    }
    case DataApiActionTypes.LoadChannelBySlugSuccess: {
      return {
        ...state,
        channelData: action.channel
      };
    }
    case DataApiActionTypes.LoadTokenizedChannelSuccess: {
      return {
        ...state,
        channelData: action.response.channel,
        deeplink: {
          utmSource: action.response.utmSource,
          utmCampaign: action.response.utmCampaign,
          utmMedium: action.response.utmMedium
        }
      };
    }
    case DataApiActionTypes.LoadOnDemandVideosSuccess: {
      return {
        ...state,
        onDemandVideosData: {
          ...state.onDemandVideosData,
          data: action.onDemandVideosResponse
        }
      };
    }
    case MainNavigationActionTypes.GoToOnDemandVideos: {
      return {
        ...state,
        onDemandVideosData: {
          ...state.onDemandVideosData,
          topicIdToFilter: action.topicIdToFilter
        }
      };
    }
    case MainNavigationActionTypes.GoToProgramme: {
      return {
        ...state,
        programmeData: {
          ...state.programmeData,
          navigatedFromLandingPage: action.navigatedFromLandingPage
        }
      };
    }
    case MainNavigationActionTypes.GoToFeaturedContent: {
      return {
        ...state,
        programmeData: {
          ...state.programmeData,
          navigatedFromLandingPage: action.navigatedFromLandingPage
        }
      };
    }
    case DataApiActionTypes.LoadOnDemandVideoSuccess: {
      return {
        ...state,
        onDemandVideo: action.response
      };
    }
    case DataApiActionTypes.LoadTokenizedOnDemandVideoSuccess: {
      return {
        ...state,
        onDemandVideo: action.response.onDemandVideo,
        deeplink: {
          utmSource: action.response.utmSource,
          utmCampaign: action.response.utmCampaign,
          utmMedium: action.response.utmMedium
        }
      };
    }
    case DataApiActionTypes.PurgeOnDemandVideo: {
      return {
        ...state,
        onDemandVideo: undefined,
        deeplink: undefined
      };
    }
    case DataApiActionTypes.PurgeOnDemandVideosList: {
      return {
        ...state,
        onDemandVideosData: {
          data: undefined,
          topicIdToFilter: undefined
        }
      };
    }
    case DataApiActionTypes.LoadFeaturedContentOverviewSuccess: {
      return {
        ...state,
        featuredContentOverview: action.response
      };
    }
    case ExpertsDetailsActionTypes.SetSelectedExpert: {
      return {
        ...state,
        expertsData: {
          ...state.expertsData,
          selectedExpert: action.expert
        }
      };
    }
    case ExpertsDetailsActionTypes.PurgeSelectedExpert: {
      return {
        ...state,
        expertsData: {
          ...state.expertsData,
          selectedExpert: undefined
        }
      };
    }
    case DataApiActionTypes.PurgeChannelsData: {
      return {
        ...state,
        channelData: undefined
      };
    }
    case LanguageActionTypes.SetLanguage: {
      return {
        ...state,
        selectedLanguage: action.language
      };
    }

    case UpcomingEventsApiActionTypes.LoadUpcomingEventsPageDataSuccess: {
      return {
        ...state,
        upcomingEventsPageData: { ...state.upcomingEventsPageData, data: action.upcomingEventsPageData }
      };
    }
    case UpcomingEventsApiActionTypes.LoadUpcomingEventsTopicCategoriesSuccess: {
      return {
        ...state,
        upcomingEventsPageData: {
          ...state.upcomingEventsPageData,
          topicCategories: action.upcomingEventsTopicCategories
        }
      };
    }
    case UpcomingEventsApiActionTypes.LoadUpcomingEventsDatesSuccess: {
      return {
        ...state,
        upcomingEventsPageData: { ...state.upcomingEventsPageData, dates: action.upcomingEventsDates }
      };
    }
    case UpcomingEventsApiActionTypes.LoadUpcomingEventsCountriesSuccess: {
      return {
        ...state,
        upcomingEventsPageData: { ...state.upcomingEventsPageData, countries: action.upcomingEventsCountries }
      };
    }
    case UpcomingEventsApiActionTypes.SetSelectedUpcomingEvent: {
      return {
        ...state,
        selectedUpcomingEvent: action.selectedUpcomingEvent
      };
    }
    case DataApiActionTypes.LoadCongressTopicsSuccess: {
      return {
        ...state,
        congressTopics: action.response
      };
    }
    default:
      return state;
  }
}
