import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { AuthorizationMode, UserProfileResponseModel } from 'medtoday-models-library/lib/models';
import {
  AuthUINavigationActions,
  AuthUINavigationActionTypes
} from 'projects/medtoday/src/app/auth-ui/actions/auth-ui-navigation.actions';

import { NavBarActions, NavBarActionTypes } from '../../layout/nav-bar/actions/nav-bar.actions';
import { SignUpActions } from '../actions/auth-sign-up.actions';
import {
  AddUserToNewsletterFail,
  AddUserToNewsletterSuccess,
  AuthActions,
  AuthActionTypes
} from '../actions/auth.actions';
import { AwsMember } from '../services/auth.service';
import { SignUpRequest } from '../../../shared/models';
import { EditUserPageData } from '../models/edit-user-page-data.model';

export interface AuthState {
  status: AuthStatus;
  loggedInUser: {
    currentMember: AwsMember | undefined;
    userAttributes: CognitoUserAttribute[] | undefined;
  };
  settings: {
    cmeActive: boolean;
  };
  redirectUrl: string | undefined;
  temporarySignUpData: Partial<SignUpRequest> | undefined;
  temporaryEditUserData: EditUserPageData | undefined;
  newsletterSubscribed: boolean | undefined;
  userProfile: UserProfileResponseModel | undefined;
  showMissingUserDataModal: boolean | undefined;
}

export interface congressToken {
  congressSlug: string | undefined;
  token: string | undefined;
}

export interface AuthStatus {
  token: string | undefined;
  congressToken: congressToken | undefined;
  type: AuthorizationMode | undefined;
}

export const initialState: AuthState = {
  status: {
    token: undefined,
    congressToken: undefined,
    type: undefined
  },
  loggedInUser: {
    currentMember: undefined,
    userAttributes: undefined
  },
  settings: {
    cmeActive: false
  },
  redirectUrl: undefined,
  temporarySignUpData: undefined,
  newsletterSubscribed: undefined,
  temporaryEditUserData: undefined,
  userProfile: undefined,
  showMissingUserDataModal: undefined
};

export function reducer(
  state = initialState,
  action:
    | AuthActions
    | AuthUINavigationActions
    | NavBarActions
    | SignUpActions
    | AddUserToNewsletterSuccess
    | AddUserToNewsletterFail
): AuthState {
  switch (action.type) {
    case AuthUINavigationActionTypes.GoToEditUser: {
      return {
        ...state,
        temporaryEditUserData: action.data
      };
    }
    case AuthActionTypes.LoginSuccess: {
      return {
        ...state,
        loggedInUser: {
          currentMember: action.awsMember!,
          userAttributes: action.loginResponse!['attributes']
        },
        status: {
          ...state.status,
          token: action.loginResponse!['signInUserSession']['idToken']['jwtToken'],
          type: AuthorizationMode.Cognito
        }
      };
    }
    case AuthActionTypes.TokenValidation: {
      return {
        ...state,
        status: {
          ...state.status,
          congressToken: {
            congressSlug: action.congressSlug,
            token: action.token
          },

          type: AuthorizationMode.CongressToken
        }
      };
    }
    case NavBarActionTypes.ToggleCME: {
      return {
        ...state,
        settings: {
          cmeActive: !state.settings.cmeActive
        }
      };
    }

    case AuthActionTypes.RefreshAuthSuccess: {
      return {
        ...state,
        status: {
          ...state.status,
          token: action.user['signInUserSession']['idToken']['jwtToken']
        }
      };
    }

    case AuthActionTypes.LoginRedirect: {
      return {
        ...state,
        redirectUrl: action.url
      };
    }

    case AuthActionTypes.Login: {
      return {
        ...state,
        redirectUrl: action.redirectUrl ? action.redirectUrl : state.redirectUrl
      };
    }

    case AuthUINavigationActionTypes.GoToSignUp: {
      return {
        ...state,
        temporarySignUpData: action.userData
      };
    }

    case AuthActionTypes.ResetScrollToNewsletter: {
      return {
        ...state,
        temporaryEditUserData: {
          scrollToNewsletter: undefined
        }
      };
    }

    case AuthActionTypes.LogoutSuccess:
    case AuthActionTypes.PurgeUserData:
    case AuthActionTypes.SessionExpired: {
      return initialState;
    }

    case AuthActionTypes.EditUserPropertiesSuccess: {
      return {
        ...state,
        loggedInUser: {
          ...state.loggedInUser,
          userAttributes: action.response!['attributes']
        }
      };
    }

    case AuthActionTypes.AddUserToNewsletterSuccess: {
      return {
        ...state,
        newsletterSubscribed: action.subscribed
      };
    }

    case AuthActionTypes.AddUserToNewsletterFail: {
      return {
        ...state,
        newsletterSubscribed: action.subscribed
      };
    }
    case AuthActionTypes.GetUserProfileSuccess: {
      return {
        ...state,
        userProfile: action.userProfile
      };
    }
    case AuthActionTypes.ShowFillMissingUserDataModal: {
      return {
        ...state,
        showMissingUserDataModal: true
      };
    }
    case AuthActionTypes.HideFillMissingUserDataModal: {
      return {
        ...state,
        showMissingUserDataModal: false
      };
    }
    case AuthActionTypes.PurgeTemporaryData: {
      return {
        ...state,
        temporarySignUpData: undefined,
        temporaryEditUserData: undefined
      };
    }

    default: {
      return state;
    }
  }
}
