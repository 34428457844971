import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { BaseAppState } from '../../store/reducers';
import { isLoggedIn } from '../selectors/auth.selectors';

@Injectable()
export class LoggedOutGuard  {
  isLoggedIn$ = this.store.select(isLoggedIn);

  constructor(private router: Router, private store: Store<BaseAppState>) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedIn$.pipe(
      take(1),
      map(loggedIn => {
        if (!loggedIn) {
          return true;
        }
        return this.router.createUrlTree([`/`]);
      })
    );
  }
}
