import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  DataApiActionTypes,
  LoadOnDemandVideo,
  LoadProgrammeData,
  LoadTokenizedOnDemandVideo,
  PurgeOnDemandVideo,
  SendOnDemandVideoQuestion
} from '../../../../../core/data/actions/data-api.actions';
import {
  getCongressMetaData,
  getDeeplinkProperties,
  getSelectedOnDemandVideo
} from '../../../../../core/data/selectors/data-api.selectors';
import { AnalyticsService } from '../../../../../core/data/services/ga-analytics.service';
import {
  getCongressSlugRouterParam,
  getOnDemandVideoIdRouterParam
} from '../../../../../core/router/selectors/router.selectors';
import { BaseAppState } from '../../../../../core/store/reducers';
import {
  GoToCongressLandingPage,
  GoToElearning,
  GoToOnDemandVideo
} from '../../../../../core/router/actions/main-navigation.actions';
import { ObservableComponent } from '../../../../components/observable/observable.component';
import {
  CongressMetadata,
  OnDemandVideoDetail,
  QuestionAndAnswerRequestModel
} from 'medtoday-models-library/lib/models';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { imagePaths } from '../../../../../../medtoday/src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { getLanguage } from '../../../../../core/data/selectors/language.selectors';
import { SetLanguage } from '../../../../../core/data/actions/language.actions';
import {
  LoadOnDemandVideoUserData,
  SetVideoAsWatched,
  UpdateVideoUserPosition
} from '../../../../../../medtoday/src/app/medtoday-store/actions/medtoday-store.actions';
import { getOnDemandVideoUserData } from '../../../../../../medtoday/src/app/medtoday-store/selectors/medtoday-store.selectors';
import { GetElearningUserMetadata } from '../../../../modules/e-learning/actions/elearning.actions';
import { ElearningSelectors } from '../../../../modules/e-learning/selectors/elearning.selectors';
import { ELearningMetadataResponse } from 'medtoday-models-library/lib';
import moment from 'moment';
import { KNOWLEDGE_SERIES_OPTIONS } from '../../../../../static/knowledge-series/knowledge-series-constants';
import selectElearningUserMetadata = ElearningSelectors.selectElearningUserMetadata;
import { PlayerInterface } from '../../../../models/player.interface';
import { VideoType } from 'projects/medtoday/src/app/auth-ui/enums/video-types.enum';

declare const jwplayer;

export enum VoDTabs {
  'Info',
  'Sponsor',
  'Study',
  'Timestamps',
  'Glossary',
  'Series'
}

@Component({
  selector: 'app-video-on-demand-detail-page',
  templateUrl: './video-on-demand-detail-page.component.html',
  styleUrls: ['./video-on-demand-detail-page.component.scss']
})
export class VideoOnDemandDetailPageComponent
  extends ObservableComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  readonly loadOnDemandVideoAsyncKey = DataApiActionTypes.LoadOnDemandVideo;
  readonly sendOnDemandVideoQuestionAsyncKey = DataApiActionTypes.SendOnDemandVideoQuestion;

  @ViewChild('player') player: PlayerInterface;

  selectedTab: VoDTabs;
  congressSlug: string;
  private token: string;
  selectedLanguage: string;
  languageList: any[];
  videoType = VideoType;

  pdfImageUrl = imagePaths.pdfs;

  congressMetaData$ = this.store.select(getCongressMetaData);
  onDemandVideoId$ = this.store.select(getOnDemandVideoIdRouterParam).pipe(
    filter(id => !!id),
    map((id) => {
      if (typeof id === 'string') {
        return parseInt(id);
      } else {
        return id;
      }
    })
  );
  onDemandVideo$ = this.store.select(getSelectedOnDemandVideo);
  eLearningUserMetadata$ = this.store.select(selectElearningUserMetadata);
  blacklistedDaysLeft$ = this.eLearningUserMetadata$.pipe(map(meta => this.getBlacklistedDaysLeft(meta!)));
  isUserBlacklisted$ = this.eLearningUserMetadata$.pipe(
    filter(userMetadata => !!userMetadata),
    map(meta => this.isUserBlacklisted(meta!))
  );
  deeplink$ = this.store.select(getDeeplinkProperties);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  seletectedLanguage$ = this.store.select(getLanguage);
  onDemandVideoUserData$ = this.store.select(getOnDemandVideoUserData);
  videoWidgetReady = new Subject();
  hasTimestamps$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  // KNOWLEDGE SERIES - STATIC FEATURE
  isKnowledgeSeriesVideo$ = this.onDemandVideoId$.pipe(
    filter(id => !!id),
    map(id =>
      KNOWLEDGE_SERIES_OPTIONS.some(option => {
        return this.knowledgeSeriesVideosIds[option]?.some(sId => sId == id);
      })
    )
  );
  //

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    @Inject('applicationName') public applicationName: string,
    protected store: Store<BaseAppState>,
    private analyticsService: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private cdr: ChangeDetectorRef,
    @Inject('knowledgeSeriesVideosIds') private knowledgeSeriesVideosIds: object
  ) {
    super();
  }

  qaForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    message: new FormControl(''),
    hasAcknowledgedDataProtectionInformation: new FormControl(false, [Validators.requiredTrue])
  });

  ngOnInit(): void {
    this.observeCongressSlug();
    this.observeToken();
    this.loadData();
    this.observeKnowledgeSeriesVideo();
    this.observeBackNavigation();
    this.observeUtmTags();
    this.observeLanguage();
    this.loadElearningMetadata();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  private observeLanguage(): void {
    combineLatest([this.activatedRoute.queryParams, this.seletectedLanguage$])
      .pipe(distinctUntilChanged(), takeUntil(this.ngDestroy$))
      .subscribe(([params, store]) => {
        if (params.language) {
          this.selectedLanguage = params.language;
        }
        if (store) {
          this.selectedLanguage = store;
        }
      });
  }

  observeToken() {
    this.activatedRoute.params
      .pipe(
        distinctUntilChanged(),
        filter(params => !!params.token),
        takeUntil(this.ngDestroy$)
      )
      .subscribe(params => {
        this.token = params.token;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(new PurgeOnDemandVideo());
  }

  loadData() {
    this.onDemandVideoId$
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(this.onDemandVideo$),
        filter(([_id, video]) => Boolean(!video)),
        takeUntil(this.ngDestroy$),
        tap(([id, _video]) => {
          this.store.dispatch(new PurgeOnDemandVideo());
          if (id) {
            if (this.token) {
              this.store.dispatch(new LoadTokenizedOnDemandVideo(id, this.token, this.congressSlug));
            } else {
              this.store.dispatch(new LoadOnDemandVideo(id, this.congressSlug));
              if (this.applicationName === 'MedToday' && this.congressSlug) {
                this.store.dispatch(new LoadOnDemandVideoUserData(this.congressSlug, id));
              }
            }
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  observeBackNavigation() {
    // on browser back navigation, check if video is react video, do another back navigation
    // this is necessary as the url would have been reset prior to react component loading
    // which would end in an unloaded page state instead of going back to the video list
    let navigatedBack = false;
    this.onDemandVideo$
      .pipe(
        filter((data: OnDemandVideoDetail) => Boolean(data)),
        tap((onDemandVideo: OnDemandVideoDetail) => {
          if (onDemandVideo.reactLink && !onDemandVideo.isVideo) {
            window.onpopstate = function (_event) {
              if (!navigatedBack) {
                window.history.back();
                navigatedBack = true;
              }
            };
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  observeKnowledgeSeriesVideo() {
    combineLatest([this.onDemandVideo$, this.isKnowledgeSeriesVideo$, this.congressSlug$, this.hasTimestamps$])
      .pipe(
        filter(([video, _, congressSlug]) => !!video && !!congressSlug),
        tap(([_, isKnowledgeSeriesVideo, congressSlug]) => {
          if (isKnowledgeSeriesVideo) {
            this.store.dispatch(new LoadProgrammeData(congressSlug));
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  handleGoToVideoClick(id: number) {
    this.store.dispatch(new PurgeOnDemandVideo());
    this.store.dispatch(new GoToOnDemandVideo(id, false, undefined, this.congressSlug));
  }

  handleGoToCongressClick(slug: string, congressMode = 'Live') {
    this.store.dispatch(new GoToCongressLandingPage(slug, congressMode));
  }

  handlePdfDownloadClick(pdf: string) {
    this.analyticsService.pushTag({}, 'on-demand-video-pdf-download-click', 'pdf_download', pdf);
  }

  observeCongressSlug() {
    this.congressSlug$.pipe(takeUntil(this.ngDestroy$)).subscribe((slug: string) => (this.congressSlug = slug));
  }

  handleSendQuestionClick() {
    if (!this.qaForm.valid) {
      return;
    }

    this.analyticsService.pushTag({}, 'send-question-form');

    combineLatest([this.congressMetaData$, this.onDemandVideo$])
      .pipe(
        tap(([metaData, video]: [CongressMetadata, OnDemandVideoDetail]) => {
          const request: QuestionAndAnswerRequestModel = {
            viewerName: this.qaForm.get('name')?.value,
            recipientEmail: video.questionsAndAnswersEmail!,
            viewerEmail: this.qaForm.get('email')?.value,
            videoTitle: video.title,
            congressName: metaData.name,
            contentEmail: this.qaForm.get('message')?.value
          };
          this.store.dispatch(new SendOnDemandVideoQuestion(this.congressSlug, video.id, request));
          this.resetQAForm();
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  resetQAForm() {
    this.qaForm.reset();
    this.qaForm.get('name')?.setErrors(null);
    this.qaForm.get('email')?.setErrors(null);
    this.qaForm.get('message')?.setErrors(null);
    this.qaForm.get('hasAcknowledgedDataProtectionInformation')?.setErrors(null);
  }

  private observeUtmTags() {
    this.deeplink$
      .pipe(
        filter(deepLink => !!deepLink),
        takeUntil(this.ngDestroy$)
      )
      .subscribe(deeplink => {
        this.analyticsService.pushTag({}, 'navigate-to-on-demand-video', 'utm_source', deeplink.utmSource);
        this.analyticsService.pushTag({}, 'navigate-to-on-demand-video', 'utm_medium', deeplink.utmMedium);
        this.analyticsService.pushTag({}, 'navigate-to-on-demand-video', 'utm_campaign', deeplink.utmCampaign);
      });
  }

  goBack() {
    this.location.back();
  }

  public handleCaptionSelect(index: number) {
    this.selectedLanguage = this.languageList[index].language;
    jwplayer().setCurrentCaptions(index);
    this.store.dispatch(new SetLanguage(this.selectedLanguage));
    this.analyticsService.pushTag({}, 'video-language', 'location', this.selectedLanguage);
  }

  onVideoWatched(onDemandVideoId: number) {
    if (this.applicationName != 'MedToday') {
      return;
    }

    this.store.dispatch(new SetVideoAsWatched(this.congressSlug, onDemandVideoId));
    this.cdr.detectChanges();
  }

  onLanguagesUpdate(languagesList: any[]) {
    this.languageList = languagesList;
  }

  handleGoToElearning(id: number) {
    this.store.dispatch(new GoToElearning(id, this.congressSlug));
  }

  handlePositionUpdate(position: number) {
    if (this.applicationName != 'MedToday') {
      return;
    }

    combineLatest([this.onDemandVideoUserData$, this.onDemandVideoId$])
      .pipe(take(1))
      .subscribe(([userData, videoId]) => {
        if (position && !userData.watched) {
          this.store.dispatch(new UpdateVideoUserPosition(this.congressSlug, videoId, position));
        }
      });
  }

  private loadElearningMetadata() {
    this.onDemandVideo$.pipe(takeUntil(this.ngDestroy$)).subscribe(onDemandVideo => {
      if (onDemandVideo?.hasElearning) {
        this.store.dispatch(new GetElearningUserMetadata(onDemandVideo.id));
      }
    });
  }

  private getBlacklistedDaysLeft(eLearningMetadata: ELearningMetadataResponse) {
    if (!eLearningMetadata?.blacklistedUntil) {
      return 0;
    }

    return moment.utc(eLearningMetadata.blacklistedUntil).diff(moment.utc(), 'd');
  }

  private isUserBlacklisted(eLearningMetadata: ELearningMetadataResponse) {
    if (!eLearningMetadata) {
      return true;
    }

    if (eLearningMetadata?.blacklistedUntil === null) {
      return false;
    }
    return moment.utc(eLearningMetadata.blacklistedUntil).isAfter(moment.utc());
  }

  onWidgetSetupCompleted() {
    this.videoWidgetReady.next(true);
    this.videoWidgetReady.complete();
    this.cdr.detectChanges();
  }
}
