import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Message } from 'amazon-chime-sdk-js';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Chime } from 'aws-sdk';
import { uniqBy } from 'lodash';
import {
  Channel,
  ChannelNavigationItem,
  ExpertDetailItem,
  Session,
  TimeSlot
} from 'medtoday-models-library/lib/models';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';

import { environment, imagePaths } from '../../../../../medtoday/src/environments/environment';
import { getCurrentAWSMember, getUserAttributes } from '../../../../core/auth/selectors/auth.selectors';
import { AuthService, AwsCredentials, AwsMember } from '../../../../core/auth/services/auth.service';
import {
  DataApiActionTypes,
  LoadChannelBySlug,
  LoadTokenizedChannel,
  PushUserIdToGTM
} from '../../../../core/data/actions/data-api.actions';
import { getChannelData, getCongressMetaData } from '../../../../core/data/selectors/data-api.selectors';
import { AwsMessagingService } from '../../../../core/data/services/aws-messaging.service';
import {
  GoToChannel,
  GoToProgramme,
  GoToCongressLandingPage
} from '../../../../core/router/actions/main-navigation.actions';
import {
  getChannelSlugRouterParam,
  getCongressSlugRouterParam
} from '../../../../core/router/selectors/router.selectors';
import { ObservableComponent } from '../../../components/observable/observable.component';
import { TimeSlotRow } from '../../../models/time-slot-row';
import { hasSrolled } from '../../../utilities/window.utils';
import { SESSION_UTILS_TOKEN, TIME_SLOT_INTERVAL } from '../../../utilities/session.utils';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CmeTrackingTriggerStrategy } from '../../../../../medtoday/src/app/classes/cme/cme-tracking-trigger-strategy';

enum Tab {
  Chat,
  Information,
  Slido,
  Experts,
  Program
}

interface ChannelListItem {
  text: string;
  slug: string;
  live: boolean;
}
@Component({
  selector: 'app-channel-page',
  templateUrl: './channel-page.component.html',
  styleUrls: ['./channel-page.component.scss']
})
export class ChannelPageComponent extends ObservableComponent implements OnInit, OnDestroy {
  readonly asyncKey = DataApiActionTypes.LoadChannelBySlug;
  checkChannelLiveStatusInterval = 60000;
  checkChannelLiveStatus: NodeJS.Timeout | null = null;
  hasAcceptedTrackingConsent = false;
  congressSlug: string;
  channelSlug: string;
  channelExperts: ExpertDetailItem[];
  timeSlots: TimeSlotRow[] = [];
  channel: Channel;
  activeSession: Session | undefined;
  channelNavigationItems: ChannelListItem[] = [];
  hasScrolledToBottomOfChat = false;
  newMessagesAvailable = false;
  channelHasCommunicators = false;
  TABS = Tab;
  selectedTab: Tab = Tab.Information;
  currentLang = this.translateService.currentLang;

  messageForm = new FormGroup({
    message: new FormControl('', Validators.required)
  });

  avatarImageUrl = imagePaths.avatars;

  // Data
  congressMetaData$ = this.store.select(getCongressMetaData);
  channelData$ = this.store.select(getChannelData);

  // Router Params
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  channelSlug$ = this.store.select(getChannelSlugRouterParam);

  activeSession$: BehaviorSubject<Session | undefined> = new BehaviorSubject(undefined);
  activeChatChannel$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  userAttributes$ = this.store.select(getUserAttributes);
  currentMember$ = this.store.select(getCurrentAWSMember);

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  sortedMessages$: BehaviorSubject<Chime.ChannelMessageSummary[]> = new BehaviorSubject([]);
  slidoLink: SafeResourceUrl;

  private channelDeeplinkToken: any;

  @HostListener('window:scroll', []) hasScrolled() {
    return hasSrolled();
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event.target.innerWidth > 768 && (this.isTabActive(Tab.Chat) || this.isTabActive(Tab.Slido))) {
      this.changeTab(Tab.Program);
    }
  }

  @HostListener('scroll') onScroll() {
    const element = document.getElementById('chat-container');
    if (element?.scrollTop === element?.scrollHeight! - element?.offsetHeight!) {
      this.hasScrolledToBottomOfChat = true;
      this.newMessagesAvailable = false;
    } else {
      this.hasScrolledToBottomOfChat = false;
    }
  }

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    private store: Store,
    public awsMessagingService: AwsMessagingService,
    private authService: AuthService,
    @Inject(SESSION_UTILS_TOKEN) private sessionUtils,
    private location: Location,
    private sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    @Inject(TIME_SLOT_INTERVAL) private timeSlotInterval,
    public cmeTrackingTriggerStrategy: CmeTrackingTriggerStrategy
  ) {
    super();
  }

  ngOnInit(): void {
    this.observeToken();
    this.observeCongressSlug();
    this.observeChannelSlug();
    this.observeChannelCommunicators();
    this.loadChannel();
    this.observeChannel();
    this.getAllExpertsOfSessions();
    this.checkCurrentChannelLiveStatus();
    this.observeActiveSession();
    this.observeIncomingMessages();
    this.cmeTrackingTriggerStrategy.initCMESender();
  }

  get hasTitle(): boolean {
    return Boolean(this.channel.title) && this.channel.title !== '';
  }

  get hasJWVideo(): boolean {
    return (
      Boolean(this.channel.playerIdentifier) &&
      Boolean(this.channel.playlistIdentifier) &&
      Boolean(this.channel.channelIdentifier)
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.checkChannelLiveStatus) {
      clearInterval(this.checkChannelLiveStatus);
    }

    this.sortedMessages$.next([]);
    this.checkChannelLiveStatus = null;
    this.awsMessagingService.stopMessagingSession();

    if (environment.language === 'de') {
      this.cmeTrackingTriggerStrategy.stopCMESender();
    }
  }

  handleConsentClick() {
    document.body.style.overflow = 'auto';
    this.hasAcceptedTrackingConsent = true;
    const dialog = document.getElementById('consentDialog');
    const backdrop = document.getElementById('backdrop');
    if (dialog && backdrop) {
      dialog.classList.remove('show');
      backdrop.style.display = 'none';
      dialog.style.display = 'none';
    }

    this.userAttributes$
      .pipe(
        take(1),
        tap((attributes: CognitoUserAttribute[]) => {
          if (attributes) {
            const userId = attributes['sub'];
            this.store.dispatch(new PushUserIdToGTM(userId));
          }
        })
      )
      .subscribe();
  }

  handleNoConsentClick() {
    document.body.style.overflow = 'auto';
    this.store.dispatch(new GoToProgramme(this.congressSlug));
  }

  initializeChat() {
    if (this.awsMessagingService.session) {
      this.sortedMessages$.next([]);
      this.awsMessagingService.stopMessagingSession();
    }
    this.loadChatMessages();

    setTimeout(() => {
      this.scrollToChatbottom();
    }, 2500);
  }

  async loadMessages(userId: string, chatId: string) {
    this.isLoading$.next(true);
    const messages = await this.awsMessagingService.listChannelMessages(chatId, userId);

    if (messages) {
      this.sortedMessages$.next(
        [...messages].sort((a, b) => (a.LastUpdatedTimestamp! > b.LastUpdatedTimestamp! ? 1 : -1))
      );
    }

    if (this.sortedMessages$.value) {
      this.isLoading$.next(false);
    }
  }

  loadChannel() {
    this.channelSlug$
      .pipe(
        distinctUntilChanged(),
        tap(channelSlug => {
          if (channelSlug) {
            if (this.channelDeeplinkToken) {
              this.store.dispatch(new LoadTokenizedChannel(this.congressSlug, channelSlug, this.channelDeeplinkToken));
            } else {
              this.store.dispatch(new LoadChannelBySlug(this.congressSlug, channelSlug));
            }
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  loadChatMessages() {
    combineLatest([this.authService.awsCredentials$, this.currentMember$, this.activeChatChannel$])
      .pipe(
        filter(([creds, user, activeChatChannel]: [AwsCredentials, AwsMember, string]) =>
          Boolean(user && creds && activeChatChannel)
        ),
        take(1),
        tap(([creds, user, activeChatChannel]: [AwsCredentials, AwsMember, string]) => {
          this.awsMessagingService.connect(user.chimeUserId, creds).then(() => {
            this.awsMessagingService.joinChannel(activeChatChannel, user.chimeUserId).then(() => {
              this.loadMessages(user.chimeUserId, activeChatChannel);
            });
          });
        })
      )
      .subscribe();
  }

  processIncomingMessage(message: Message) {
    if (!message) {
      return;
    }

    switch (message.type) {
      case 'CREATE_CHANNEL_MESSAGE':
        combineLatest([this.currentMember$, this.activeChatChannel$])
          .pipe(
            take(1),
            tap(async ([member, activeChatChannel]: [AwsMember, string]) => {
              if (activeChatChannel) {
                const receivedMessage = await this.awsMessagingService.getMessage(
                  member.chimeUserId,
                  JSON.parse(message.payload).MessageId,
                  activeChatChannel
                );
                if (
                  receivedMessage &&
                  !this.sortedMessages$.value.find(msg => msg.MessageId === receivedMessage.MessageId)
                ) {
                  this.sortedMessages$.value?.push(receivedMessage as Chime.ChannelMessageSummary);
                  const senderArn = receivedMessage.Sender?.Arn;
                  if (
                    receivedMessage &&
                    member.chimeUserId !== senderArn?.slice(senderArn.lastIndexOf('/') + 1, senderArn.length)
                  ) {
                    this.newMessagesAvailable = true;
                  }
                  if (this.hasScrolledToBottomOfChat) {
                    this.scrollToChatbottom();
                  }
                }
              }
            })
          )
          .subscribe();
        break;

      default:
        break;
    }
  }

  scrollToChatbottom() {
    const chatContainer = document.getElementById('chat-container');
    const bottomElement = document.getElementById('chat-bottom');
    if (chatContainer && bottomElement) {
      setTimeout(() => {
        chatContainer.scrollTop = bottomElement.offsetTop;
      }, 100);
    }
    this.newMessagesAvailable = false;
  }

  observeChannel() {
    this.channelData$
      .pipe(
        filter((channel: Channel) => Boolean(channel)),
        distinctUntilChanged(),
        tap((channel: Channel) => {
          this.channel = channel;
          this.sortedMessages$.next([]);
          this.checkChannelLiveStatus = null;
          this.awsMessagingService.stopMessagingSession();
          this.createChannelNavItems();
          this.createTimeSlotViewItems();
          this.observeActiveChat();

          if (channel.hasSlido) {
            this.slidoLink = this.sanitizer.bypassSecurityTrustResourceUrl(
              'https://app.sli.do/event/' + this.channel.slidoId
            );
          }

          if (!channel.channelZoomRoomDescription && channel.sessions) {
            this.changeTab(Tab.Program);
          }

          if (!channel.channelZoomRoomDescription && !channel.sessions && this.channelExperts) {
            this.changeTab(Tab.Experts);
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  showConsentDialog() {
    setTimeout(() => {
      const dialog = document.getElementById('consentDialog');
      const backdrop = document.getElementById('backdrop');
      if (dialog && backdrop) {
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';
        dialog.classList.add('show');
        backdrop.style.display = 'block';
        dialog.style.display = 'block';
      }
    }, 400);
  }

  createChannelNavItems() {
    if (this.channel) {
      this.channelNavigationItems = this.channel.channelNavigationItems.map((cn: ChannelNavigationItem) => {
        const item: ChannelListItem = {
          text: cn.text,
          slug: cn.slug,
          live: this.getIsChannelLive(cn.timeSlots)
        };

        return item;
      });
    }
  }

  observeIncomingMessages(): void {
    this.awsMessagingService.messageObserver$
      .pipe(
        distinctUntilChanged(),
        tap((message: Message) => this.processIncomingMessage(message)),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  observeActiveSession() {
    this.channelData$
      .pipe(
        distinctUntilChanged(),
        filter((channel: Channel) => Boolean(channel)),
        tap((channel: Channel) => {
          if (channel?.sessions?.length > 0 && this.timeSlots?.length > 0) {
            this.getActiveSession();
          }
          if (channel?.sponsors?.length && this.activeSession?.additionalTracking && !this.hasAcceptedTrackingConsent) {
            this.showConsentDialog();
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  getActiveSession() {
    if (this.activeSession !== this.getCurrentlyActiveSession()) {
      this.activeSession = this.getCurrentlyActiveSession();
      this.activeSession$.next(this.activeSession);
    }
  }

  observeCongressSlug() {
    this.congressSlug$.pipe(takeUntil(this.ngDestroy$)).subscribe((slug: string) => (this.congressSlug = slug));
  }

  observeChannelSlug() {
    this.channelSlug$.pipe(takeUntil(this.ngDestroy$)).subscribe((slug: string) => (this.channelSlug = slug));
  }

  checkCurrentChannelLiveStatus() {
    this.checkChannelLiveStatus = setInterval(() => {
      this.createChannelNavItems();
      this.createTimeSlotViewItems();
      this.getActiveSession();
    }, this.checkChannelLiveStatusInterval);
  }

  createTimeSlotViewItems() {
    if (this.channel) {
      this.timeSlots = this.channel.timeSlots
        .map((slot: TimeSlot) => {
          return {
            id: slot.id,
            start: moment.utc(slot.start).toISOString(),
            finish: moment.utc(slot.finish).toISOString(),
            sessions: this.channel.sessions.filter((session: Session) => session.timeSlotId === slot.id)
          } as TimeSlotRow;
        })
        .sort((a: TimeSlotRow, b: TimeSlotRow) => a.start.localeCompare(b.start));
    }
  }

  async handleSendMessage(): Promise<void> {
    if (this.messageForm.invalid) {
      return;
    }

    combineLatest([this.currentMember$, this.activeChatChannel$])
      .pipe(
        take(1),
        takeUntil(this.ngDestroy$),
        tap(async ([member, activeChatChannel]: [AwsMember, string]) => {
          if (activeChatChannel) {
            await this.awsMessagingService.sendMessage(
              activeChatChannel,
              this.messageForm.controls.message.value!,
              member
            );
          }
        })
      )
      .subscribe();

    this.messageForm.controls['message'].setValue('');
    this.scrollToChatbottom();
  }

  changeTab(tab: Tab) {
    this.selectedTab = tab;
  }

  isTabActive(tab: Tab) {
    return this.selectedTab === tab;
  }

  hasAnyExpertsForSessions() {
    return this.channel?.sessions?.length > 0 && this.channel?.sessions?.map((sn: Session) => sn.experts).length > 0;
  }

  isLive(row: TimeSlotRow) {
    return this.sessionUtils.isActive(row);
  }

  getLiveSessionFormat() {
    return this.timeSlots.find((row: TimeSlotRow) => this.isLive(row))?.sessions[0]?.format;
  }

  getIsChannelLive(timeSlots: TimeSlot[]) {
    return timeSlots.some((ts: TimeSlot) =>
      this.sessionUtils.isActive({
        ...ts,
        sessions: this.channel.sessions.filter(s => s.timeSlotId === ts.id)
      })
    );
  }

  getAllExpertsOfSessions() {
    this.channelData$
      .pipe(
        filter((channel: Channel) => Boolean(channel)),
        tap((channel: Channel) => {
          this.channelExperts = [];
          const experts: ExpertDetailItem[] = [];
          channel.sessions.forEach((sn: Session) => {
            sn.experts.forEach((expert: ExpertDetailItem) => {
              if (this.channelExperts.findIndex((ex: ExpertDetailItem) => expert.id === ex.id) === -1) {
                experts.push(expert);
              }
            });
          });
          this.channelExperts = uniqBy(experts, 'id');
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  handleGoToStreamClick(channelSlug: string) {
    this.store.dispatch(new GoToChannel(this.congressSlug, channelSlug));
  }

  getActiveChannelSlug(navItems: ChannelNavigationItem[]) {
    return navItems.find((item: ChannelNavigationItem) => item.slug === this.channelSlug);
  }

  getCurrentlyActiveSession() {
    const activeTimeSlot = this.timeSlots.find((ts: TimeSlotRow) => this.sessionUtils.isActive(ts));
    return this.channel.sessions.find((sn: Session) => sn.timeSlotId === activeTimeSlot?.id);
  }

  getIsCurrentChannelLive() {
    const dateNow = moment.utc(new Date());
    const channel = this.channel.channelNavigationItems.find(
      (cn: ChannelNavigationItem) => cn.slug === this.channel.slug
    );
    return channel?.timeSlots.some((ts: TimeSlot) =>
      dateNow.isBetween(
        moment.utc(ts.start).subtract(this.timeSlotInterval, 'minutes'),
        moment.utc(ts.finish).add(this.timeSlotInterval, 'minutes')
      )
    );
  }

  private observeActiveChat() {
    this.activeSession$
      .pipe(
        withLatestFrom(this.channelData$),
        takeUntil(this.ngDestroy$),
        filter(([activeSession, channel]) => !!channel || !!activeSession)
      )
      .subscribe(([activeSession, channel]) => {
        if (channel.hasChat) {
          this.activeChatChannel$.next(channel.chatId!);
          this.initializeChat();
        } else if (activeSession?.hasChat) {
          this.activeChatChannel$.next(activeSession.chatId);
          this.initializeChat();
        } else {
          this.activeChatChannel$.next(null);
        }
      });
  }

  handleGoToCongressClick(slug: string, congressMode = 'Live') {
    this.store.dispatch(new GoToCongressLandingPage(slug, congressMode));
  }

  goBack() {
    this.location.back();
  }

  private observeChannelCommunicators() {
    this.activeChatChannel$
      .pipe(withLatestFrom(this.channelData$), takeUntil(this.ngDestroy$))
      .subscribe(([activeChatChannel, channelData]) => {
        if (!channelData) {
          return;
        }

        this.channelHasCommunicators = !!activeChatChannel || channelData.hasSlido;
      });
  }

  private observeToken() {
    this.activatedRoute.params
      .pipe(
        distinctUntilChanged(),
        filter(params => !!params.token),
        takeUntil(this.ngDestroy$)
      )
      .subscribe(params => {
        this.channelDeeplinkToken = params.token;
      });
  }
}
