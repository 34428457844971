import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Partner, PartnerListResponse, SponsorBooth } from 'medtoday-models-library/lib';
import { IS_MED_TODAY_APP } from '../../../../core/definitions/app.definitions';
import { createNeedsAuthenticationHeader } from '../../../../core/data/utilities/networking.utilites';

@Injectable()
export class PartnersService {
  constructor(
    @Inject('apiBaseUrl') protected apiBaseUrl: string,
    protected http: HttpClient,
    @Inject(IS_MED_TODAY_APP) public isMedTodayApp: boolean
  ) {}

  getPartners(): Observable<PartnerListResponse> {
    return this.http.get<PartnerListResponse>(`${this.apiBaseUrl}/partners`);
  }

  getPartnerDetails(partnerSlug: string): Observable<Partner> {
    return this.http.get<Partner>(`${this.apiBaseUrl}/partner/${partnerSlug}`);
  }

  getSponsorBoothDetails(congressSlug: string, sponsorBoothSlug: string): Observable<SponsorBooth> {
    const headers = createNeedsAuthenticationHeader();

    return this.http.get<SponsorBooth>(
      `${this.apiBaseUrl}/congress/${congressSlug}/sponsor/${sponsorBoothSlug}`,
      this.isMedTodayApp ? headers : undefined
    );
  }
}
