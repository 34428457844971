import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { BaseAppState } from '../../../../../core/store/reducers';
import { ObservableComponent } from '../../../../components/observable/observable.component';
import { LoadPartners, PartnersActionTypes } from '../../actions/partners.actions';
import { PartnersSelectors } from '../../selectors/partners.selectors';
import { filter, map, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { Partner, PartnerListItem, Topic } from 'medtoday-models-library/lib';
import { hasSrolled } from '../../../../utilities/window.utils';
import { FilterOption } from '../../../filters/models/filter-option.model';
import { IS_PATIENTS_TODAY_APP } from '../../../../../core/definitions/app.definitions';

export interface PartnersByTopics extends Topic {
  items: PartnerListItem[];
}

@Component({
  selector: 'app-partner-network-page',
  templateUrl: './partner-network-page.component.html',
  styleUrls: ['./partner-network-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerNetworkPageComponent extends ObservableComponent implements OnInit {
  readonly loadPartnersKey = PartnersActionTypes.LoadPartners;
  selectedTopic: string | undefined;
  showFilterBackground = false;
  topics$ = this.store.select(PartnersSelectors.selectTopics);
  filterTopics$: Observable<FilterOption<Partner>[]> = this.topics$.pipe(
    filter(topics => !!topics),
    map(this.mapTopicsForFilterOptions)
  );
  partners$ = this.store.select(PartnersSelectors.selectPartners);
  partnerBox$ = this.store.select(PartnersSelectors.selectPartnerBox);
  partnersWithoutTopics$ = this.partners$.pipe(
    filter(partners => !!partners?.length),
    map(partners => partners!.filter(partner => !partner.topics?.length))
  );
  partnersByTopics$: Observable<PartnersByTopics[]> = combineLatest([this.partners$, this.topics$]).pipe(
    filter(([partners, topics]) => !!partners?.length && !!topics?.length),
    map(([partners, topics]) => {
      return topics!
      .map(topic => ({
        ...topic,
        items: partners!.filter(partner => partner.topics?.some(partnerTopic => partnerTopic.id === topic.id))
      }))
      .filter(topics => topics.items?.length);
    }),
    takeUntil(this.ngDestroy$),
  );

  constructor(
    public store: Store<BaseAppState>,
    @Inject(IS_PATIENTS_TODAY_APP) public isPatientsTodayApp: string
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new LoadPartners());
  }

  public handleSelectTopic(topic: Topic): void {
    const anchor = document.getElementById(topic.id.toString())!;
    const offset = 100;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = anchor.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  hasScrolled() {
    return hasSrolled();
  }

  private mapTopicsForFilterOptions(topics) {
    return [
      {
        id: 0,
        source: {
          id: 0,
          title: 'PartnerNetwork.UnassignedGroup'
        },
        condition: () => true
      },
      ...topics!.map(topic => ({
        id: topic.id,
        source: topic,
        condition: () => true
      }))
    ];
  }
}
