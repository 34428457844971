import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { CongressLandingPage, SponsorLevel } from 'medtoday-models-library/lib/models';
import { imagePaths } from '../../../../medtoday/src/environments/environment';

@Component({
  selector: 'app-event-centered-sponsors',
  templateUrl: './event-centered-sponsors.component.html',
  styleUrls: ['./event-centered-sponsors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventCenteredSponsorsComponent {
  @Input() congressData: CongressLandingPage;
  sponsorLevel = SponsorLevel;
  logosImageFolderSlug = imagePaths.logos;

  constructor(@Inject('s3BucketUrl') public s3BucketUrl: string) {}

  protected readonly SponsorLevel = SponsorLevel;
}
