import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { GetMediaLibraryItem } from '../modules/file-download/actions/file-downloads-api.actions';

@Directive({
  selector: '[replaceToDownloadLink]'
})
export class ReplaceToDownloadLinkDirective implements AfterViewInit {
  constructor(private store: Store<BaseAppState>, private element: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    this.processLinks();
  }

  public processLinks() {
    // find our link elements
    const els = this.element.nativeElement.getElementsByTagName('a');

    for (let i = 0; i < els.length; i++) {
      const v = els[i];

      const parent = v.parentElement;
      if (parent && v.href.includes(`${window.location.host}/downloads/`)) {
        const newLink = document.createElement('span');
        newLink.classList.add('link');
        newLink.style.cursor = 'pointer';
        newLink.onclick = () => this.handleDownloadLinkClick(v.href.substring(v.href.lastIndexOf('/') + 1));
        newLink.innerHTML = v.text;
        newLink.title = v.title;
        parent.replaceChild(newLink, v);
      }
    }
  }

  public handleDownloadLinkClick = (slug: string) => {
    this.store.dispatch(new GetMediaLibraryItem(slug));
  };
}
