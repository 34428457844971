import { KNOWLEDGE_SERIES_OPTIONS } from '../../../todaylib/static/knowledge-series/knowledge-series-constants';
import packageInfo from '../../../../package.json';

export const environment = {
  version: packageInfo.version,
  production: false,
  apiBaseUrl: ' https://vwhj8nn1xj.execute-api.eu-central-1.amazonaws.com/prod',
  // apiBaseUrl: ' http://localhost:3000',
  s3BucketUrl: 'https://patientstoday-stack-de-de-appassetsandcontentbucke-4vlrkoe68ojv.s3.eu-central-1.amazonaws.com/',
  reactAppScriptUrl: 'https://medtoday-react.de/api/embed-talks-app.js',
  surveyMonkeyScriptUrl:
    'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd1Dvcv88EFVcM0b_2B2kWcWLODPa_2B6LqcU5b_2FT07NH97lz.js',
  name: 'PatientsToday',
  domain: 'de',
  payRexxDomain: '',
  language: 'de',
  cognito: {
    cognitoUserPoolId: 'eu-central-1_z4oVpiVz6',
    cognitoAppClientId: '66i9hk3vdrh3qeq1lsdh2tu66b',
    cognitoIdentityPoolId: 'eu-central-1:49fcf6b8-136b-4e56-a263-5e44bcdcce88',
    appInstanceArn: 'arn:aws:chime:us-east-1:322690393080:app-instance/59412174-026e-4d4a-8335-110656f24589',
    region: 'eu-central-1',
    oauth: {
      domain: '',
      scope: [],
      redirectSignIn: '',
      redirectSignOut: '',
      responseType: ''
    }
  },
  twitterWallId: '',
  twitterProfileUrl: '',
  instagramProfileUrl: 'https://www.instagram.com/patientstoday.de/',
  medboardsUrl: '',
  medBoardsApiUrl: '',
  linkedInProfileUrl: '',
  hasNewsletter: false,
  facebookProfileUrl: 'https://www.facebook.com/patientstoday.de/',
  facebookWallId: '11237',
  megaMenuFilterExclude: 'MedEd',
  youtubeApiKey: 'AIzaSyA-tgNx748ZPoLVGrXTrXTpMi6MfoCuqac',
  knowledgeSeriesVideosIds: {
    [KNOWLEDGE_SERIES_OPTIONS[0]]: [323],
    [KNOWLEDGE_SERIES_OPTIONS[1]]: [323, 324],
    [KNOWLEDGE_SERIES_OPTIONS[2]]: [325]
  }
};

export const imagePaths = {
  logos: 'logo/', // sponsors, patrons, website logos etc.
  thumbnails: 'thumbnail/', // video thumbnails, feature blocks
  avatars: 'avatar/', // profile pictures
  images: 'image/', // general image
  pdfs: 'pdf/'
};
