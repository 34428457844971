import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnumToArrayPipe } from '../pipes/enum-to-array.pipe';
import { DynamicScriptLoaderService } from '../services/dynamic-script-loader-service.service';
import { JwPlayerComponent } from './jw-player/jw-player.component';
import { VimeoPlayerComponent } from './vimeo-player/vimeo-player.component';

import { InlineSVGModule } from 'ng-inline-svg-2';
import { HttpClientModule } from '@angular/common/http';

import { ScrollToTopButtonComponent } from './scroll-to-top-button/scroll-to-top-button.component';
import { ReactWrapperComponent } from './react-wrapper/react-wrapper.component';
import { CongressBannerComponent } from './congress-banner/congress-banner.component';
import { CarouselSmallComponent } from './carousel-small/carousel-small.component';
import { SpacerComponent } from './spacer/spacer.component';
import { LiveStreamComponent } from './live-stream/live-stream.component';
import { TeaserCardComponent } from './teaser-card/teaser-card.component';
import { FilterComponent } from './filter/filter.component';
import { CarouselLabelComponent } from './carousel-label/carousel-label.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ExpertDetailsModalComponent } from './expert-details-modal/expert-details-modal.component';
import { DateLocalPipe } from '../pipes/date-local.pipe';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { ExpertPipe } from '../pipes/expert.pipe';
import { ExpertsPipe } from '../pipes/experts.pipe';
import { PatientsExpertsPipe } from '../pipes/patients-experts.pipe';
import { PremiumTeaserBoxComponent } from 'projects/todaylib/shared/components/premium-teaser-box/premium-teaser-box.component';
import { VideoListItemComponent } from './video-list-item/video-list-item.component';
import { VideoThumbnailComponent } from './video-thumbnail/video-thumbnail.component';
import { CarouselLargeComponent } from './carousel-large/carousel-large.component';
import { HeroSliderComponent } from './hero-slider/hero-slider.component';
import { SponsorsBoxComponent } from './sponsors-box/sponsors-box.component';
import { CarouselSmallSlideComponent } from './carousel-small-slide/carousel-small-slide.component';
import { LiveIndicatorComponent } from './live-indicator/live-indicator.component';
import { SessionRowComponent } from '../pages/components/programme-page/session-row/session-row.component';
import { VideoRowComponent } from '../pages/components/programme-page/video-row/video-row.component';
import { VodJwTimestampsComponent } from './vod-timestamps/vod-jw-timestamps/vod-jw-timestamps.component';
import { SecondsToTimePipe } from '../pipes/seconds-to-time.pipe';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DropdownMultipleComponent } from './dropdown/dropdown-multiple/dropdown-multiple.component';
import { DaysUntil } from '../pipes/days-until';
import { ImageBoxComponent } from './image-box/image-box.component';
import { ExpertListItemComponent } from './expert-list-item/expert-list-item.component';
import { AccordionTextCardComponent } from './accordion-text-card/accordion-text-card.component';
import { HeroSliderSidelyComponent } from './hero-slider-sidely/hero-slider-sidely.component';
import { EventInfoModalComponent } from './event-info-modal/event-info-modal.component';
import { SocialShareButtonComponent } from './social-share-button/social-share-button.component';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';
import { EventPageHeaderComponent } from './event-page-header/event-page-header.component';
import { PtSocialWallComponent } from './pt-social-wall/pt-social-wall.component';
import { MtSocialWallComponent } from './mt-social-wall/mt-social-wall.component';
import { PartnersBoxComponent } from './partners-box/partners-box.component';
import { VideoSeriesComponent } from '../../static/knowledge-series/knowledge-series-page/video-series/video-series.component';
import { VideoDurationHumanizePipe } from '../pipes/video-duration-humanize.pipe';
import { VodYoutubeTimestampsComponent } from './vod-timestamps/vod-youtube-timestamps/vod-youtube-timestamps.component';
import { SubpageHeaderComponent } from './subpage-header/subpage-header.component';
import { SponsorMoreInfoLinkComponent } from './sponsor-more-info-link/sponsor-more-info-link.component';
import { EventCenteredSponsorsComponent } from './event-centered-sponsors/event-centered-sponsors.component';
import { CmeTrackingToggleComponent } from './cme-tracking-toggle/cme-tracking-toggle.component';

const components = [
  ScrollToTopButtonComponent,
  JwPlayerComponent,
  VimeoPlayerComponent,
  EnumToArrayPipe,
  ReactWrapperComponent,
  CongressBannerComponent,
  CarouselSmallComponent,
  CarouselLargeComponent,
  CarouselLabelComponent,
  SpacerComponent,
  LiveStreamComponent,
  TeaserCardComponent,
  FilterComponent,
  ExpertDetailsModalComponent,
  DateLocalPipe,
  ExpertPipe,
  ExpertsPipe,
  PatientsExpertsPipe,
  PremiumTeaserBoxComponent,
  VideoListItemComponent,
  VideoThumbnailComponent,
  HeroSliderComponent,
  SponsorsBoxComponent,
  CarouselSmallSlideComponent,
  SessionRowComponent,
  VideoRowComponent,
  LiveIndicatorComponent,
  SponsorsBoxComponent,
  VodJwTimestampsComponent,
  DropdownComponent,
  DropdownMultipleComponent,
  DaysUntil,
  ImageBoxComponent,
  ExpertListItemComponent,
  AccordionTextCardComponent,
  DaysUntil,
  SecondsToTimePipe,
  HeroSliderSidelyComponent,
  EventInfoModalComponent,
  SocialShareButtonComponent,
  YoutubePlayerComponent,
  PtSocialWallComponent,
  MtSocialWallComponent,
  EventPageHeaderComponent,
  PartnersBoxComponent,
  VideoSeriesComponent,
  VideoDurationHumanizePipe,
  VodYoutubeTimestampsComponent,
  SubpageHeaderComponent,
  SponsorMoreInfoLinkComponent,
  EventCenteredSponsorsComponent,
  CmeTrackingToggleComponent
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule.forRoot(),
    TranslateModule,
    SharedDirectivesModule
  ],
  declarations: [...components],
  exports: [...components],
  providers: [DynamicScriptLoaderService, DatePipe, ExpertPipe, ExpertsPipe, TranslatePipe, SecondsToTimePipe]
})
export class SharedComponentsModule {}
