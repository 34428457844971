import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getCongressMetaData } from '../../../core/data/selectors/data-api.selectors';
import { BaseAppState } from '../../../core/store/reducers';
import { DynamicScriptLoaderService } from '../../services/dynamic-script-loader-service.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-react-wrapper',
  templateUrl: './react-wrapper.component.html',
  styleUrls: ['./react-wrapper.component.scss']
})
export class ReactWrapperComponent implements OnInit, OnDestroy {
  static readonly reactRouterBaseName = 'REACT_ROUTER_BASENAME';
  static readonly reactCustomColor = 'CUSTOM_COLOR';

  @Input() playbackLink: string;
  @Output() loaded = new EventEmitter<void>();

  get routeUrl(): string {
    return `/${this.route.snapshot.url.join('/')}`;
  }

  congressMetaData$ = this.store.select(getCongressMetaData);

  constructor(
    @Inject('reactAppScriptUrl') private reactAppScriptUrl: string,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<BaseAppState>
  ) {}

  ngOnInit(): void {
    this.congressMetaData$.pipe(take(1)).subscribe(congressMetaData => {
      window[ReactWrapperComponent.reactRouterBaseName] = this.routeUrl;
      window[ReactWrapperComponent.reactCustomColor] = `#${congressMetaData?.layout.colors.primary}`;
      this.loadScripts();
    });
  }

  ngOnDestroy() {
    window[ReactWrapperComponent.reactRouterBaseName] = null;
    window[ReactWrapperComponent.reactCustomColor] = null;
    this.dynamicScriptLoader.removeScript(this.reactAppScriptUrl);
  }

  private async loadScripts() {
    await this.dynamicScriptLoader.loadScript(this.reactAppScriptUrl);
    this.loaded.emit();
    this.router.navigateByUrl(`${this.routeUrl}/${this.playbackLink}`);
  }
}
