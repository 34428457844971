import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'localeDate',
})
export class DateLocalPipe implements PipeTransform {
  transform(value: string | Date): string {
    if (value) {
      return moment.utc(value).toISOString();
    }
    return value;
  }
}
