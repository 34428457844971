import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getCongressMetaData, getIsCongressMenuEmpty } from '../../../../data/selectors/data-api.selectors';
import { getCongressSlugRouterParam, getThirdUrlSegment } from '../../../../router/selectors/router.selectors';
import { BaseAppState } from '../../../../store/reducers';
import { GoToCongressLandingPage, GoToExperts, GoToFeaturedContent, GoToNewsletter, GoToOnDemandVideos, GoToProgramme } from '../../../../router/actions/main-navigation.actions';
import { distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { ObservableComponent } from '../../../../../shared/components/observable/observable.component';

@Component({
  selector: 'app-navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent extends ObservableComponent implements OnInit {

  @Input() mobileView = false;
  @Output() toggleNavEmitter = new EventEmitter();


  congressSlug = '';

  isCongressMenuEmpty$ = this.store.pipe(select(getIsCongressMenuEmpty));
  congressMetaData$ = this.store.pipe(select(getCongressMetaData));
  thirdUrlSegment$ = this.store.pipe(select(getThirdUrlSegment));
  congressSlug$ = this.store.pipe(select(getCongressSlugRouterParam));
  constructor(
    private store: Store<BaseAppState>,
    @Inject('applicationName') public applicationName: string,
  ) {
    super();
  }

  ngOnInit(): void {
    this.observeCongressSlug();
  }

  observeCongressSlug() {
    this.congressSlug$.pipe(
      distinctUntilChanged(),
      takeUntil(this.ngDestroy$),
    ).subscribe((slug: string) => this.congressSlug = slug);
  }

  handleHomeClick() {
    this.store.dispatch(new GoToCongressLandingPage(this.congressSlug));
    this.toggleNavEmitter.emit();
  }

  handleProgrammeClick() {
    this.store.dispatch(new GoToProgramme(this.congressSlug, false));
    this.toggleNavEmitter.emit();
  }

  handleExpertsClick() {
    this.store.dispatch(new GoToExperts(this.congressSlug));
    this.toggleNavEmitter.emit();
  }

  handleOnDemandClick() {
    this.store.dispatch(new GoToOnDemandVideos(this.congressSlug));
    this.toggleNavEmitter.emit();
  }

  handleFeaturedContentClick() {
    this.store.dispatch(new GoToFeaturedContent(this.congressSlug));
    this.toggleNavEmitter.emit();
  }

  handleNewsletterClick() {
    this.store.dispatch(new GoToNewsletter());
    this.toggleNavEmitter.emit();
  }

  getIsNavItemActive(navLink: string) {
    let isActive = false;
    this.thirdUrlSegment$.pipe(
      take(1),
      tap((urlSegment: string | null) => {
        isActive = urlSegment === navLink;
      }),
    ).subscribe();
    return isActive;
  }
}
