import { Routes } from '@angular/router';
import { LoggedInGuard } from '../../../todaylib/core/auth/guards/logged-in-guard.service';
import { ChannelPageComponent } from '../../../todaylib/shared';
import { CongressListComponent } from '../../../todaylib/shared';
import { ExpertsPageComponent } from '../../../todaylib/shared';
import { FeaturedContentPageComponent } from '../../../todaylib/shared';
import { CookiesPageComponent } from '../../../todaylib/shared';
import { DisclaimerPageComponent } from '../../../todaylib/shared';
import { ImprintPageComponent } from '../../../todaylib/shared';
import { ProgrammePageComponent } from '../../../todaylib/shared';
import { VideoOnDemandDetailPageComponent } from '../../../todaylib/shared';
import { VideosOnDemandPageComponent } from '../../../todaylib/shared';
import {
  CONGRESS_PARAMS,
  MAIN_NAVIGATION_PATH_ELEMENTS,
  MAIN_NAVIGATION_PATHS,
  MISC_PARAMS
} from '../../../todaylib/core/router/definitions/main-navigation.definitions';
import { EventCmePageComponent } from './pages/components/event-cme-page/event-cme-page.component';
import { MedicalEducationPageComponent } from 'projects/medtoday/src/app/pages/components/medical-education-page/medical-education-page.component';
import { MedTodayHomePageComponent } from './pages/components/home-page/medtoday-home-page';
import { AUTH_UI_PATH_ELEMENTS } from 'projects/medtoday/src/app/auth-ui/definitions/auth-ui-navigation.definitions';
import { UpcomingEventsPageComponent } from 'projects/medtoday/src/app/pages/components/upcoming-events-page/upcoming-events-page.component';
import { CmeCongressPageComponent } from './pages';
import { CmeInfoPageComponent } from './pages/components/cme-info-page/cme-info-page.component';
import { TermsOfServiceComponent } from 'projects/todaylib/shared/pages/components/terms-of-service/terms-of-service.component';
import { SearchPageComponent } from '../../../todaylib/shared/modules/search/components/search-page/search-page.component';
import { MedTodayEventPageComponent } from './pages/components/event-page/medtoday-event-page';
import { SponsorDetailPageComponent } from '../../../todaylib/shared/modules/partners/components/sponsor-booth-detail-page/sponsor-detail-page.component';
import { QuestionsComponent } from '../../../todaylib/shared/modules/e-learning/components/questions/questions.component';
import { ELearningBlacklistedGuardService } from '../../../todaylib/core/auth/guards/e-learning-blacklisted-guard.service';
import { ResultsComponent } from '../../../todaylib/shared/modules/e-learning/components/results/results.component';
import { MyCmePageComponent } from './pages/components/my-cme-page/my-cme-page.component';
import { MissingUserDataResolverGuard } from '../../../todaylib/core/auth/guards/missing-user-data-resolver-guard.service';
import { KnowledgeSeriesPageComponent } from '../../../todaylib/static/knowledge-series/knowledge-series-page/knowledge-series-page.component';
import { KNOWLEDGE_SERIES_SLUG } from '../../../todaylib/static/knowledge-series/knowledge-series-event-tile';
import { ChannelCmePromptGuard } from './guards/channel-cme-prompt.guard';
import { DownloadFileComponent } from 'projects/todaylib/shared/modules/file-download/components/download-file/download-file.component';
// import { MyCmePageComponent } from './pages/components/my-cme-page/my-cme-page.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [MissingUserDataResolverGuard],
    children: [
      {
        path: '',
        component: CongressListComponent,
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.medicalEducation}`,
        component: MedicalEducationPageComponent,
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.upcomingEvents}`,
        component: UpcomingEventsPageComponent,
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.knowledgeSeries}/:${CONGRESS_PARAMS.congressSlug}`,
        component: KnowledgeSeriesPageComponent,
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.events}/${KNOWLEDGE_SERIES_SLUG.ONKOLOGIE_DER_ZUKUNFT}`,
        redirectTo: `${MAIN_NAVIGATION_PATHS.root.knowledgeSeries}/:${CONGRESS_PARAMS.congressSlug}`,
        pathMatch: 'full'
      },
      {
        // tslint:disable-next-line: max-line-length
        path: `${MAIN_NAVIGATION_PATHS.root.events}/:${CONGRESS_PARAMS.congressSlug}`,
        component: MedTodayEventPageComponent,
        data: { hideHeader: true }
      },
      {
        // tslint:disable-next-line: max-line-length
        path: `${MAIN_NAVIGATION_PATHS.root.events}/:${CONGRESS_PARAMS.congressSlug}/token/:${AUTH_UI_PATH_ELEMENTS.token}`,
        component: MedTodayEventPageComponent,
        data: { hideHeader: true }
      },
      {
        // tslint:disable-next-line: max-line-length
        path: `${MAIN_NAVIGATION_PATHS.root.events}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.cmeElement}`,
        component: EventCmePageComponent,
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}`,
        redirectTo: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressLandingPageElement}`
      },
      {
        // tslint:disable-next-line: max-line-length
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressLandingPageElement}`,
        component: MedTodayHomePageComponent
      },
      {
        // tslint:disable-next-line: max-line-length
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.congressLandingPageElement}/token/:${AUTH_UI_PATH_ELEMENTS.token}`,
        component: MedTodayHomePageComponent
      },
      {
        // tslint:disable-next-line: max-line-length
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.programmeElement}`,
        component: ProgrammePageComponent
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.expertsElement}`,
        component: ExpertsPageComponent
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.onDemandVideosElement}`,
        component: VideosOnDemandPageComponent
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.onDemandVideosElement}/:${CONGRESS_PARAMS.onDemandVideoId}/token/:${AUTH_UI_PATH_ELEMENTS.token}`,
        component: VideoOnDemandDetailPageComponent,
        data: { hideNavigation: true },
        children: [
          {
            path: '**',
            component: VideoOnDemandDetailPageComponent,
            data: { hideNavigation: true }
          }
        ]
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.onDemandVideosElement}/:${CONGRESS_PARAMS.onDemandVideoId}`,
        component: VideoOnDemandDetailPageComponent,
        data: { hideNavigation: true },
        canActivate: [LoggedInGuard],
        children: [
          {
            path: '**',
            component: VideoOnDemandDetailPageComponent,
            data: { hideNavigation: true }
          }
        ]
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.programmeElement}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.channelElement}/:${CONGRESS_PARAMS.channelSlug}`,
        component: ChannelPageComponent,
        data: { hideNavigation: true },
        canActivate: [LoggedInGuard, ChannelCmePromptGuard]
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.programmeElement}/:${MAIN_NAVIGATION_PATH_ELEMENTS.root.channelElement}/:${CONGRESS_PARAMS.channelSlug}/token/:${AUTH_UI_PATH_ELEMENTS.token}`,
        component: ChannelPageComponent,
        data: { hideNavigation: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.featuredContentElement}`,
        component: FeaturedContentPageComponent
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.featuredContentElement}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.channelElement}/:${CONGRESS_PARAMS.channelSlug}`,
        component: ChannelPageComponent,
        data: { hideNavigation: true },
        canActivate: [LoggedInGuard, ChannelCmePromptGuard]
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.featuredContentElement}/:${MAIN_NAVIGATION_PATH_ELEMENTS.root.channelElement}/:${CONGRESS_PARAMS.channelSlug}/token/:${AUTH_UI_PATH_ELEMENTS.token}`,
        component: ChannelPageComponent,
        data: { hideNavigation: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.cmeElement}`,
        component: CmeCongressPageComponent,
        data: { hideHeader: true }
      },
      {
        path: `:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.cmeElement}`,
        // tslint:disable-next-line: max-line-length
        redirectTo: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.cmeElement}`
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATH_ELEMENTS.root.sponsor}/:${CONGRESS_PARAMS.sponsorBoothSlug}`,
        component: SponsorDetailPageComponent,
        canActivate: [LoggedInGuard],
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATHS.root.eLearning}/:${CONGRESS_PARAMS.onDemandVideoId}`,
        component: QuestionsComponent,
        canActivate: [LoggedInGuard, ELearningBlacklistedGuardService],
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.congresses}/:${CONGRESS_PARAMS.congressSlug}/${MAIN_NAVIGATION_PATHS.root.eLearning}/:${CONGRESS_PARAMS.onDemandVideoId}/results`,
        component: ResultsComponent,
        canActivate: [LoggedInGuard],
        pathMatch: 'full',
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.myCme}`,
        component: MyCmePageComponent,
        canActivate: [LoggedInGuard],
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.cmeInfoPage}`,
        component: CmeInfoPageComponent,
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.imprint}`,
        component: ImprintPageComponent,
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.disclaimer}`,
        component: DisclaimerPageComponent,
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.termsOfService}`,
        component: TermsOfServiceComponent
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.cookies}`,
        component: CookiesPageComponent,
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATHS.root.search}`,
        component: SearchPageComponent,
        data: { hideHeader: true }
      },
      {
        path: `${MAIN_NAVIGATION_PATH_ELEMENTS.root.downloads}/:${MISC_PARAMS.fileSlug}`,
        component: DownloadFileComponent,
        data: { hideHeader: true }
      },
      { path: '**', redirectTo: '' }
    ]
  }
];
