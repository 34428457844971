import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { SignUpActionTypes, SignUpConfirmation } from 'projects/todaylib/core/auth/actions/auth-sign-up.actions';
import { GoToHome } from 'projects/todaylib/core/router/actions/main-navigation.actions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { combineLatest } from 'rxjs';

import { GoToLogin } from '../../actions/auth-ui-navigation.actions';
import { getCodeQueryParam, getUserIdQueryParam } from '../../selectors/auth-ui-navigation.selectors';

@Component({
  selector: 'app-sign-up-confirm-page',
  templateUrl: './sign-up-confirm-page.component.html',
  styleUrls: ['./sign-up-confirm-page.component.scss']
})
export class SignUpConfirmPageComponent implements OnInit {
  asyncProcessKey = SignUpActionTypes.SignUpConfirmation;

  userId$ = this.store.select(getUserIdQueryParam);
  code$ = this.store.select(getCodeQueryParam);

  get currentLang() {
    return this.translateService.getDefaultLang();
  }

  constructor(private store: Store<BaseAppState>, private translateService: TranslateService) {}

  ngOnInit() {
    this.confirmSignUp();
  }

  handleGoHome() {
    this.store.dispatch(new GoToHome());
  }

  handleLoginClick() {
    this.store.dispatch(new GoToLogin());
  }

  private confirmSignUp() {
    combineLatest([this.userId$, this.code$])
      .pipe(take(1))
      .subscribe(([userId, code]) => {
        if (!userId || !code) {
          this.store.dispatch(new GoToHome());
          return;
        }

        this.store.dispatch(new SignUpConfirmation(userId, code));
      });
  }
}
