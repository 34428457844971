import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify from '@aws-amplify/core';
import { CookieStorage } from '@aws-amplify/auth';
import { getDomainWithoutSubdomain } from '../../todaylib/shared/utilities/domainWithoutSubdomain.utils';

Amplify.Logger.LOG_LEVEL = !environment.production ? 'DEBUG' : 'WARN';

if (environment.production) {
  enableProdMode();
}

const amplifyConfiguration = {
  Auth: {
    identityPoolId: environment.cognito.cognitoIdentityPoolId,
    region: environment.cognito.region,
    userPoolId: environment.cognito.cognitoUserPoolId,
    userPoolWebClientId: environment.cognito.cognitoAppClientId,
    cookieStorage: new CookieStorage({
      domain:
        window.location.hostname === 'localhost'
          ? 'localhost'
          : '.' + (environment.production ? getDomainWithoutSubdomain(window.location.href) : window.location.hostname),
      sameSite: 'lax',
      path: '/',
      secure: environment.production
    }),
    oauth: environment.cognito.oauth
  }
};

// in this way you are only importing Auth and configuring it.
Amplify.configure(amplifyConfiguration);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
