import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { EventRegisterModalComponent } from './components/event-register-modal/event-register-modal.component';
import { routes } from './auth-ui.routes';
import { EditPasswordFormComponent } from './components/edit-password/edit-password-form/edit-password-form.component';
import { EditPasswordPageComponent } from './components/edit-password/edit-password-page/edit-password-page.component';
import { LoginFormComponent } from './components/login/login-form/login-form.component';
import { LoginPageComponent } from './components/login/login-page/login-page.component';
import { RequestPasswordResetComponent } from './components/request-password-reset/request-password-reset.component';
import { ResetPasswordPageComponent } from './components/reset-password/reset-password.component';
import { SignUpConfirmPageComponent } from './components/sign-up-confirm-page/sign-up-confirm-page.component';
import { SignUpSuccessfulPageComponent } from './components/sign-up-successful-page/sign-up-successful-page.component';
import { SignUpFormComponent } from './components/sign-up/sign-up-form/sign-up-form.component';
import { SignUpPageComponent } from './components/sign-up/sign-up-page/sign-up-page.component';
import { UserSalutationPipe } from './pipes/user-salutation.pipe';
import { EditUserPageComponent } from './components/edit-user-page/edit-user-page.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncStateOverlayModule } from 'projects/todaylib/core/async-state/async-state.module';
import { SharedComponentsModule } from 'projects/todaylib/shared';
import { DeleteUserModalComponent } from './components/delete-user-modal/delete-user-modal.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { SignUpStepperComponent } from './components/sign-up/sign-up-form/sign-up-stepper/sign-up-stepper/sign-up-stepper.component';
import { SignUpSocialComponent } from './components/sign-up-social/sign-up-social.component';
import { MissingUserDataModalComponent } from './components/missing-user-data-modal/missing-user-data-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    InlineSVGModule.forRoot(),
    EffectsModule.forFeature([]),
    FormsModule,
    ReactiveFormsModule,
    AsyncStateOverlayModule,
    SharedComponentsModule,
    TranslateModule,
    CdkStepperModule
  ],
  declarations: [
    LoginFormComponent,
    LoginPageComponent,
    SignUpPageComponent,
    SignUpFormComponent,
    SignUpSuccessfulPageComponent,
    SignUpConfirmPageComponent,
    RequestPasswordResetComponent,
    ResetPasswordPageComponent,
    EditPasswordFormComponent,
    EditPasswordPageComponent,
    UserSalutationPipe,
    EditUserPageComponent,
    EventRegisterModalComponent,
    DeleteUserModalComponent,
    SignUpStepperComponent,
    SignUpSocialComponent,
    MissingUserDataModalComponent
  ],
  exports: [EventRegisterModalComponent, MissingUserDataModalComponent],
  providers: [
    UserSalutationPipe,
    {
      provide: 'payRexxDomain',
      useValue: 'medtoday-tests'
    }
  ]
})
export class AuthUIModule {}
