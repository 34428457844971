import { ChangeDetectionStrategy, Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { BaseAppState } from '../../../core/store/reducers';
import { Store } from '@ngrx/store';
import { getCMEActiveState } from '../../../core/auth/selectors/auth.selectors';
import { FormControl } from '@angular/forms';
import { ObservableComponent } from '../observable/observable.component';
import { takeUntil } from 'rxjs/operators';
import { ToggleCME } from '../../../core/layout/nav-bar/actions/nav-bar.actions';
import { CmeTrackingTriggerStrategy } from '../../../../medtoday/src/app/classes/cme/cme-tracking-trigger-strategy';

@Component({
  selector: 'app-cme-tracking-toggle',
  templateUrl: './cme-tracking-toggle.component.html',
  styleUrls: ['./cme-tracking-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmeTrackingToggleComponent extends ObservableComponent implements OnInit {
  cmeActiveState$ = this.store.select(getCMEActiveState);
  cmeToggleStateControl = new FormControl<boolean>(false);
  toggleCollapsed = false;

  @HostBinding('class.collapsed') get collapsed() {
    return this.toggleCollapsed;
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.toggleCollapsed = false;
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.toggleCollapsed = true;
  }

  constructor(
    private store: Store<BaseAppState>,
    private CmeTrackingTriggerStrategyService: CmeTrackingTriggerStrategy
  ) {
    super();
  }

  ngOnInit(): void {
    this.cmeActiveState$.pipe(takeUntil(this.ngDestroy$)).subscribe(cmeActiveState => {
      this.cmeToggleStateControl.setValue(cmeActiveState, { emitEvent: false });
    });

    setTimeout(() => {
      this.toggleCollapsed = true;
    }, 5000);
  }

  handleCmeToggleClick() {
    if (!this.cmeToggleStateControl.value) {
      this.CmeTrackingTriggerStrategyService.triggerCmeStateIfNeeded().subscribe();
    } else {
      this.store.dispatch(new ToggleCME());
    }
  }
}
