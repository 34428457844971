import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
  MedicalEducationResponse,
  DeeplinkModel,
  CongressParticipantResponseModel,
  LandingPageVideoListItem
} from 'medtoday-models-library/lib/models';

import { CmeCertificatesResponse, CongressCmePageDataResponse, OnDemandVideoUserData } from 'medtoday-models-library';
import { MedBoardsResponseModel } from '../../models/medboards.model';
import { MedtodayStoreState } from '../reducers/medtoday-store.reducer';

export const getMedtodayStoreRootState = createFeatureSelector<MedtodayStoreState>('medtoday-store');

export const getDeeplinkProperties: MemoizedSelector<object, DeeplinkModel> = createSelector(
  getMedtodayStoreRootState,
  (state: MedtodayStoreState) => state?.deeplink!
);

export const getMedicalEducationData: MemoizedSelector<object, MedicalEducationResponse> = createSelector(
  getMedtodayStoreRootState,
  (state: MedtodayStoreState) => state?.medicalEducation.data!
);

export const getOnDemandVideosForMedicalEducation: MemoizedSelector<object, LandingPageVideoListItem[]> =
  createSelector(
    getMedtodayStoreRootState,
    (state: MedtodayStoreState) => state?.medicalEducation.onDemandVideos?.results!
  );

export const getMedicalEducationOnDemandVideosNextPage: MemoizedSelector<object, number> = createSelector(
  getMedtodayStoreRootState,
  (state: MedtodayStoreState) => state?.medicalEducation.onDemandVideos?.nextPage!
);

export const getCongressCmePageData: MemoizedSelector<object, CongressCmePageDataResponse> = createSelector(
  getMedtodayStoreRootState,
  (state: MedtodayStoreState) => state?.congressCmePageData!
);

export const getCongressParticipantData: MemoizedSelector<object, CongressParticipantResponseModel> = createSelector(
  getMedtodayStoreRootState,
  (state: MedtodayStoreState) => state?.congressParticipantData!
);

export const getMedBoardsData: MemoizedSelector<object, MedBoardsResponseModel> = createSelector(
  getMedtodayStoreRootState,
  (state: MedtodayStoreState) => state?.medBoardsData!
);

export const getOnDemandVideoUserData: MemoizedSelector<object, OnDemandVideoUserData> = createSelector(
  getMedtodayStoreRootState,
  (state: MedtodayStoreState) => state?.onDemandVideoUserData!
);

export const getCmeCertificatesData: MemoizedSelector<object, CmeCertificatesResponse> = createSelector(
  getMedtodayStoreRootState,
  (state: MedtodayStoreState) => state?.cmeCertificates!
);

export const getShowCmeTrackingPromptModal = createSelector(
  getMedtodayStoreRootState,
  (state: MedtodayStoreState) => state.showCmeTrackingPromptModal
);
