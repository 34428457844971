<header>
  <img
    draggable="false"
    id="image-header"
    *ngIf="backgroundImageUrl?.length && checkIfReact() && isMedTodayApp"
    class="teaser-image"
    [src]="backgroundImageUrl"
  />
  <div class="container mt-5" [hidden]="hideNavigation">
    <div class="row justify-content-between align-items-center mb-3">
      <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12 h-100 pr-sm-2">
        <app-navigation-menu class="hide-tablet"></app-navigation-menu>
      </div>
      <app-social-share-button *ifApp="'MedToday'" class="hide-tablet" size="big"></app-social-share-button>
    </div>
  </div>
</header>
