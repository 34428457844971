<div class="loadingindicator-scrim" *ngIf="loading$ | async" @flyInOut>
  <div class="loadingindicator-spinner-host">
    <div class="spinner-container">
      <app-spinner [size]="size" id="loadingindicator-spinner" @flyInOutChild></app-spinner>
    </div>
  </div>
</div>
<div class="loadingindicator-scrim" *ngIf="failed$ | async">
  <div class="loadingindicator-prompt" @flyInOutChild>
    <div class="card bg-dark shadow-lg error-dialog" *ngIf="errorConfig$ | async">
      <div class="card-header pl-5 pt-3" *ngIf="errorTitle$ | async as errorTitle">
        <h4
          class="float-left"
          [innerHTML]="
            (errorTitle$ | async) === 'NotAuthorizedException'
              ? ('LogIn.' + errorTitle | translate)
              : (errorTitle | translate)
          "
        ></h4>
        <button type="button" class="close float-right pl-5" aria-label="Close" (click)="onDismissClick()">
          <i class="bi bi-x text-light font-size-xx-large"></i>
        </button>
      </div>
      <div class="card-body px-5">
        <p *ngIf="errorMessage$ | async as errorMessage">
          <span
            [innerHTML]="
              (errorTitle$ | async) === 'NotAuthorizedException'
                ? ('LogIn.' + errorMessage | translate)
                : (errorMessage | translate)
            "
          ></span>
        </p>
      </div>
      <div class="card-footer clearfix px-5 pb-3">
        <div class="row">
          <div class="col-6">
            <button
              *ngIf="retryable$ | async"
              type="button"
              id="retryButton"
              class="btn btn-danger w-100 rounded-pill loading-overlay-button"
              (click)="onRetryClick()"
            >
              Wiederholen
            </button>
          </div>
          <div [ngClass]="(retryable$ | async) ? 'col-6' : 'col-12'">
            <button
              type="button"
              id="dismissButton"
              class="w-100 btn btn-danger rounded-pill loading-overlay-button"
              (click)="onDismissClick()"
              *ngIf="retryable$ | async"
            >
              <span>Abbrechen</span>
            </button>
            <ng-container
              *ngIf="
                ((errorTitle$ | async) === 'ExpiredCodeException' && isResetPassword) ||
                (errorTitle$ | async) === 'CodeMismatchException'
              "
            >
              <form [formGroup]="form" name="login" (ngSubmit)="handleResendPasswordCode()" #ngForm="ngForm" novalidate>
                <div class="form-group">
                  <label>{{ 'Auth.Email' | translate }}</label>
                  <input
                    type="email"
                    autocomplete="email"
                    class="form-control form-control-lg"
                    name="sign-up-email"
                    placeholder="{{ 'SignUp.EnterEmailAddress' | translate }}"
                    formControlName="emailAddress"
                    [class.is-invalid]="ngForm.submitted && form.controls['emailAddress'].invalid"
                  />
                  <div class="invalid-feedback">
                    <div *ngIf="form.controls['emailAddress'].hasError('required')">
                      {{ 'Auth.EmailRequired' | translate }}
                    </div>
                    <div *ngIf="form.controls['emailAddress'].hasError('pattern')">
                      {{ 'Auth.EmailInvalid' | translate }}
                    </div>
                  </div>
                </div>

                <button class="btn btn-danger rounded-pill w-100" type="submit">
                  {{ 'LogIn.ResetPassword' | translate }}
                </button>
              </form>
            </ng-container>
            <button
              *ngIf="
                ((errorTitle$ | async) === 'ExpiredCodeException' && !isResetPassword) ||
                (errorTitle$ | async) === 'UserNotConfirmedException'
              "
              class="btn btn-danger rounded-pill w-100"
              (click)="handleResendCode()"
            >
              {{ 'LogIn.RequestLink' | translate }}
            </button>
            <button
              *ngIf="(errorTitle$ | async) === 'NotAuthorizedException'"
              class="btn btn-danger rounded-pill w-100"
              (click)="onDismissClick()"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
