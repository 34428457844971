import { NgModule } from '@angular/core';
import { IfAppDirective } from './if-app.directive';
import { CommonModule } from '@angular/common';
import { TopicCategoryColorDirective } from './topic-category-color.directive';
import { ReplaceToDownloadLinkDirective } from './replace-to-download-link.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ReplaceToDownloadLinkDirective, IfAppDirective, TopicCategoryColorDirective],
  exports: [ReplaceToDownloadLinkDirective, IfAppDirective, TopicCategoryColorDirective]
})
export class SharedDirectivesModule {}
