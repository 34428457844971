<div class="d-flex">
  <span class="d-flex align-self-center" *ngIf="selectedSeries.value">
    <h4 class="mb-0">{{ seriesNames[selectedSeries.value] }}</h4>
  </span>
  <app-dropdown
    [options]="seriesDropdownOptions"
    [control]="selectedSeries"
    [hideAll]="true"
    (selected)="handleSelectedSeries($event)"
    class="ml-auto text-light"
  ></app-dropdown>
</div>

<ng-container *ngFor="let video of selectedSeriesVideos$ | async; let i = index">
  <div class="video-entry" (click)="handleVideoClick(video)" role="button" [class.active]="currentVideoId === video.id">
    <div class="d-flex w-100">
      <div class="col-1">
        <ng-container *ngIf="currentVideoId === video.id">
          <i [class.text-light]="isMedtodayApp" [inlineSVG]="'assets/icons/play-icon-filled.svg'"></i>
        </ng-container>
        <ng-container *ngIf="currentVideoId !== video.id">
          <span class="text-center text-note video-entry-count">
            {{ i + 1 }}
          </span>
        </ng-container>
        <div class="row"></div>
      </div>
      <div class="col offset-1 offset-md-0">
        <div class="row">
          <span class="video-entry-title">{{ video.title }}</span>
        </div>
      </div>
    </div>
    <div class="row text-note">
      <p class="col offset-2 offset-md-1 mt-2">{{ video.duration | videoDuration }}</p>
    </div>
  </div>
</ng-container>
