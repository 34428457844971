import { OnDemandVideoCrossPlatformSearchResultResponse } from 'medtoday-models-library';
import { AsyncAction } from 'projects/todaylib/core/async-state/models/async-action.model';
import { Action } from '@ngrx/store';

export enum SearchActionTypes {
  LoadSearchResults = '[Search] Load search term results',
  LoadSearchResultsSuccess = '[Search] Load search term results Success',
  LoadSearchResultsFail = '[Search] Load search term results Fail',

  PurgeData = '[Search] Purge search data'
}

export class LoadSearchResults implements AsyncAction {
  readonly asyncKey = SearchActionTypes.LoadSearchResults;
  readonly type = SearchActionTypes.LoadSearchResults;
  readonly asyncState = 'start';

  constructor(public searchTerm: string, public reset = true) {}
}

export class LoadSearchResultsSuccess implements AsyncAction {
  readonly asyncKey = SearchActionTypes.LoadSearchResults;
  readonly type = SearchActionTypes.LoadSearchResultsSuccess;
  readonly asyncState = 'success';

  constructor(public response: OnDemandVideoCrossPlatformSearchResultResponse, public append = true) {}
}

export class LoadSearchResultsFail implements AsyncAction {
  readonly asyncKey = SearchActionTypes.LoadSearchResults;
  readonly type = SearchActionTypes.LoadSearchResultsFail;
  readonly asyncState = 'fail';
}

export class PurgeData implements Action {
  readonly type = SearchActionTypes.PurgeData;
}

export type SearchActions = LoadSearchResultsSuccess | PurgeData;
