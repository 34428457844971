<div class="vimeo-player">
  <iframe
    #player
    [src]="safeSrc"
    width="100%"
    height="100%"
    frameborder="0"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
  >
  </iframe>
</div>
