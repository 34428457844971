import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  ArchivedCongressListItem,
  CongressListItem,
  EventListItem,
  InternationalContent,
  LandingPageVideoListItem
} from 'medtoday-models-library/lib/models';
import { PremiumBox } from 'medtoday-models-library/lib/models/premium-box.model';
import { combineLatest } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { environment, imagePaths } from '../../../../../medtoday/src/environments/environment';
import { environment as patientsenv } from '../../../../../patientstoday/src/environments/environment';
import { isLoggedIn } from '../../../../core/auth/selectors/auth.selectors';
import { DataApiActionTypes } from '../../../../core/data/actions/data-api.actions';
import {
  getArchivedCongressesList,
  getCongressesList,
  getCongressListInertnationalContent,
  getCongressListPageItems,
  getCongressListPremiumBox,
  getCongressListUpcomingEvents,
  getMostWatchedVideosList,
  getUpcomingEventsData
} from '../../../../core/data/selectors/data-api.selectors';
import {
  GoToCMEPage,
  GoToMedicalEducationPage,
  GoToUpcomingEventsPage
} from '../../../../core/router/actions/main-navigation.actions';
import { BaseAppState } from '../../../../core/store/reducers';
import { ObservableComponent } from '../../../components/observable/observable.component';
import { CarouselItem, LargeCarouselItem } from '../../../models/carousel-item.model';
import { ExpertsPipe } from '../../../pipes/experts.pipe';
import { DynamicScriptLoaderService } from '../../../services/dynamic-script-loader-service.service';
import { hasSrolled } from '../../../utilities/window.utils';
import { GoToSignUp, GoToLogin } from 'projects/medtoday/src/app/auth-ui/actions/auth-ui-navigation.actions';

@Component({
  selector: 'app-congress-list',
  templateUrl: './congress-list.component.html',
  styleUrls: ['./congress-list.component.scss']
})
export class CongressListComponent extends ObservableComponent implements OnInit {
  readonly loadOnDemandVideoAsyncKey = DataApiActionTypes.LoadOnDemandVideo;

  congresses: CongressListItem[] = [];
  displayedCongressCarouselItems: ArchivedCongressListItem[] = [];
  mostWatchedVideosCarouselItems: CarouselItem[] = [];
  upcomingEvents: LargeCarouselItem[] = [];
  congressSlider;
  premiumBox: PremiumBox;
  logoPathUrl = imagePaths.logos;
  thumbnailPathUrl = imagePaths.thumbnails;
  twitterWallId = environment.twitterWallId;
  twitterProfileUrl = environment.twitterProfileUrl;
  facebookWallId = patientsenv.facebookWallId;
  currentLang;

  congressListUpcomingEvents$ = this.store.select(getCongressListUpcomingEvents);
  congressListPremiumBox$ = this.store.select(getCongressListPremiumBox);
  congressListInertnationalContent$ = this.store.select(getCongressListInertnationalContent);
  congressListPageItems$ = this.store.select(getCongressListPageItems);
  congresses$ = this.store.select(getCongressesList);
  archivedCongresses$ = this.store.select(getArchivedCongressesList);
  mostWatchedVideos$ = this.store.select(getMostWatchedVideosList);
  isLoggedIn$ = this.store.select(isLoggedIn);
  upcomingEventsPageData$ = this.store.select(getUpcomingEventsData);

  constructor(
    protected store: Store<BaseAppState>,
    @Inject('applicationName') public applicationName: string,
    protected dynamicScriptLoader: DynamicScriptLoaderService,
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    private translateService: TranslateService,
    private expertsPipe: ExpertsPipe,
    public router: Router
  ) {
    super();
  }

  signUpForm: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl('')
  });

  @HostListener('window:scroll', []) hasScrolled() {
    return hasSrolled();
  }

  ngOnInit(): void {
    this.observeCongresses();
    this.observePremiumBox();
    this.observeInternationalContent();
    this.buildCarouselItems();

    this.currentLang = this.translateService.currentLang;
  }

  handleGoToCMEClick() {
    this.store.dispatch(new GoToCMEPage());
  }

  observePremiumBox() {
    this.congressListPremiumBox$
      .pipe(
        filter((box: PremiumBox) => Boolean(box)),
        tap((box: PremiumBox) => (this.premiumBox = box)),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  observeInternationalContent() {
    this.congressListInertnationalContent$
      .pipe(
        filter((content: InternationalContent[]) => !!content),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  observeCongresses() {
    combineLatest([this.congresses$, this.archivedCongresses$, this.mostWatchedVideos$, this.congressListPageItems$])
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(([congresses, archivedCongresses, mostWatchedvideos, congressListPageItems]) => {
        if (congresses) {
          this.congresses = congresses;
        }

        if (congressListPageItems) {
          this.congressSlider = congressListPageItems.find(item => item.name === 'Kongress Slider');
        }

        if (archivedCongresses) {
          this.displayedCongressCarouselItems = archivedCongresses;
        }

        if (mostWatchedvideos) {
          this.mostWatchedVideosCarouselItems = mostWatchedvideos.map((vd: LandingPageVideoListItem) => {
            const item: CarouselItem = {
              id: vd.id,
              thumbnail: vd.thumbnail,
              label: vd.congressShortcut ? vd.congressShortcut : vd.seriesName!,
              url: vd.congressSlug ? vd.congressSlug : undefined,
              isExternalUrl: undefined,
              title: vd.title ? vd.title : vd.topicName,
              subTitle: this.expertsPipe.transform(vd.experts, 3),
              viewsCount: vd.viewCount ? vd.viewCount : undefined,
              topic: vd.topicName ? vd.topicName : undefined,
              format: vd.formatName ? vd.formatName : undefined,
              additionalData: {
                mediaIdentifier: vd.mediaIdentifier
              }
            };

            return item;
          });
        }
      });
  }

  buildCarouselItems() {
    this.congressListUpcomingEvents$
      .pipe(
        filter((events: EventListItem[]) => Boolean(events && events.length)),
        map(events => events.filter(event => event.displayInHighlights && event.displayInHighlights === true)),
        tap((filteredEvents: EventListItem[]) => {
          this.upcomingEvents = filteredEvents.map((item: EventListItem) => {
            return {
              ...item,
              congressFeedHeader: item.congressFeedHeader,
              congressFeedDescription: item.congressFeedDescription,
              eventType: item.congressMode,
              start: item.start,
              finish: item.finish,
              topicCategories: item.topicCategories!,
              date: item.congressEventDate,
              headerDarkTheme: item.headerDarkTheme
            };
          });
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  handleGoToUpcomingEventsPageClick() {
    this.store.dispatch(new GoToUpcomingEventsPage());
  }

  handleGoToMedicalEducationClick() {
    this.store.dispatch(new GoToMedicalEducationPage());
  }

  handleGoToSignUpClick() {
    this.store.dispatch(
      new GoToSignUp({
        email: this.signUpForm.get('email')?.value,
        password: this.signUpForm.get('password')?.value
      })
    );
  }

  handleGoToLoginClick() {
    this.store.dispatch(new GoToLogin());
  }
}
