import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { CongressUtils } from '../../../shared/utilities/congress.utils';
import { EventPageComponent } from '../../../shared/pages/components/event-page/event-page.component';
import { EventRegistrationService } from '../../../shared/services/event-registration.service';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../core/store/reducers';
import { AnalyticsService } from '../../../core/data/services/ga-analytics.service';
import { SESSION_UTILS_TOKEN } from '../../../shared/utilities/session.utils';
import { TokenValidationService } from '../../../core/auth/services/token-validation.service';
import { Observable } from 'rxjs';
import { Sponsor } from 'medtoday-models-library/lib/models';
import { TranslateService } from '@ngx-translate/core';
import { IS_MED_TODAY_APP, IS_PATIENTS_TODAY_APP } from '../../../core/definitions/app.definitions';

@Component({
  selector: 'app-knowledge-series-page',
  templateUrl: './knowledge-series-page.component.html',
  styleUrls: [
    '../../../shared/pages/components/event-page/event-page.component.scss',
    '../../../shared/components/event-page-header/event-page-header.component.scss',
    './knowledge-series-page.component.scss'
  ],
  providers: [EventRegistrationService, CongressUtils],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnowledgeSeriesPageComponent extends EventPageComponent implements OnInit {
  sponsors$: Observable<Sponsor[]>;
  seriesDropdownOptions = Object.keys(this.knowledgeSeriesVideosIds);

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    protected store: Store<BaseAppState>,
    analyticsService: AnalyticsService,
    @Inject(SESSION_UTILS_TOKEN) sessionUtils,
    registrationService: EventRegistrationService,
    public congressUtils: CongressUtils,
    tokenValidationService: TokenValidationService,
    @Inject(IS_MED_TODAY_APP) public isMedTodayApp: string,
    @Inject(IS_PATIENTS_TODAY_APP) public isPatientsTodayApp: string,
    translateService: TranslateService,
    @Inject('knowledgeSeriesVideosIds') private knowledgeSeriesVideosIds: object
  ) {
    super(
      s3BucketUrl,
      store,
      analyticsService,
      sessionUtils,
      registrationService,
      congressUtils,
      tokenValidationService,
      isMedTodayApp,
      isPatientsTodayApp,
      translateService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.mapSponsors();
  }
}
