import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';

import { AsyncStateModule, AsyncStateOverlayModule } from './async-state/async-state.module';
import { AuthModule } from './auth/auth.module';
import { AnalyticsEffects } from './data/effects/analytics.effects';
import { DataApiEffects } from './data/effects/data-api.effects';
import { reducer } from './data/reducers/app-data.reducer';
import { reducer as providersReducer } from './data/reducers/providers.reducer';
import { AwsMessagingService } from './data/services/aws-messaging.service';
import { DataApiService } from './data/services/data-api.service';
import { RouterStateModule } from './router/router-state.module';
import { CoreStoreModule } from './store/core-store.module';
import { ToastModule } from './toasts/toast.module';
import { LayoutModule } from './layout/layout.module';
import { SESSION_UTILS_TOKEN, SessionUtils, TIME_SLOT_INTERVAL } from '../shared/utilities/session.utils';
import { ELearningBlacklistedGuardService } from './auth/guards/e-learning-blacklisted-guard.service';
import { UpcomingEventsApiEffects } from './data/effects/upcoming-events-api.effects';
import { ProvidersEffects } from './data/effects/providers.effects';

@NgModule({
  imports: [
    CoreStoreModule,
    RouterStateModule,
    AsyncStateModule,
    AsyncStateOverlayModule,
    AuthModule,
    LayoutModule,
    InlineSVGModule.forRoot(),
    ToastModule.forRoot(),
    ModalModule.forRoot(),
    StoreModule.forFeature('data', reducer),
    StoreModule.forFeature('providersState', providersReducer),
    EffectsModule.forFeature([DataApiEffects, AnalyticsEffects, UpcomingEventsApiEffects, ProvidersEffects])
  ],
  exports: [FormsModule, AsyncStateModule, AsyncStateOverlayModule, ReactiveFormsModule, LayoutModule],
  providers: [
    BsModalRef,
    AwsMessagingService,
    ELearningBlacklistedGuardService,
    {
      provide: DataApiService,
      useClass: DataApiService
      // useClass: MockDataApiService,
    },
    {
      provide: TIME_SLOT_INTERVAL,
      useValue: 15
    },
    {
      provide: SESSION_UTILS_TOKEN,
      useClass: SessionUtils,
      deps: [TIME_SLOT_INTERVAL]
    },
    {
      provide: 'cognitoConfig',
      useValue: {}
    }
  ]
})
export class CoreModule {}
