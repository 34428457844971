import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ComponentsModule } from 'projects/medtoday/src/app/components/components.module';

import { SharedDirectivesModule } from '..';
import { CoreModule } from '../../core/core.module';
import { NavBarModule } from '../../core/layout/nav-bar/nav-bar.module';
import { SharedComponentsModule } from '../components/shared-components.module';
import { StringToAcronymPipe } from '../pipes/string-to-acronym.pipe';
import { ChannelPageComponent } from './components/channel-page/channel-page.component';
import { CongressListComponent } from './components/congress-list/congress-list.component';
import { EventPageComponent } from './components/event-page/event-page.component';
import { ExpertsPageComponent } from './components/experts-page/experts-page.component';
import { FeaturedContentPageComponent } from './components/featured-content-page/featured-content-page.component';
import { CookiesPageComponent } from './components/footer-subpages/cookies-page/cookies-page.component';
import { DisclaimerPageComponent } from './components/footer-subpages/disclaimer-page/disclaimer-page.component';
import { ImprintPageComponent } from './components/footer-subpages/imprint-page/imprint-page.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ProgrammePageComponent } from './components/programme-page/programme-page.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';
import { VideoOnDemandDetailPageComponent } from './components/videos-on-demand/video-on-demand-detail-page/video-on-demand-detail-page.component';
import { VideosOnDemandPageComponent } from './components/videos-on-demand/videos-on-demand-list/videos-on-demand-page.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { VideoOnDemandTabsComponent } from './components/videos-on-demand/video-on-demand-detail-page/video-on-demand-tabs/video-on-demand-tabs.component';

const pages = [
  HomePageComponent,
  ProgrammePageComponent,
  ExpertsPageComponent,
  ChannelPageComponent,
  VideosOnDemandPageComponent,
  VideoOnDemandDetailPageComponent,
  CongressListComponent,
  FeaturedContentPageComponent,
  ImprintPageComponent,
  DisclaimerPageComponent,
  CookiesPageComponent,
  EventPageComponent,
  TermsOfServiceComponent,
  VideoOnDemandTabsComponent
];

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    CoreModule,
    InlineSVGModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    NavBarModule,
    ComponentsModule,
    TooltipModule
  ],
  declarations: [StringToAcronymPipe, ...pages],
  exports: [StringToAcronymPipe, ...pages]
})
export class SharedPagesModule {}
