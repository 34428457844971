import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PartnersService } from './services/partners.service';
import { CoreModule } from '../../../core/core.module';
import { reducer } from './reducers/partners.reducer';
import { SharedComponentsModule } from '../../components/shared-components.module';
import { CommonModule } from '@angular/common';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { PartnerNetworkCardComponent } from './components/partner-network-page/partner-network-card/partner-network-card.component';
import { PartnerNetworkPageComponent } from './components/partner-network-page/partner-network-page.component';
import { PartnersEffects } from './effects/partners.effects';
import { PARTNERS_STATE_KEY } from './definitions/partners.definitions';
import { PartnerDetailPageComponent } from './components/partner-detail-page/partner-detail-page.component';
import { PartnerNetworkBoxComponent } from './components/partner-network-box/partner-network-box.component';
import { SponsorDetailPageComponent } from './components/sponsor-booth-detail-page/sponsor-detail-page.component';
import { InlineSVGModule } from 'ng-inline-svg-2';

@NgModule({
  declarations: [
    PartnerNetworkCardComponent,
    PartnerNetworkPageComponent,
    PartnerDetailPageComponent,
    PartnerNetworkBoxComponent,
    SponsorDetailPageComponent
  ],
  imports: [
    StoreModule.forFeature(PARTNERS_STATE_KEY, reducer),
    EffectsModule.forFeature([PartnersEffects]),
    TranslateModule,
    ReactiveFormsModule,
    CoreModule,
    SharedComponentsModule,
    CommonModule,
    SharedDirectivesModule,
    InlineSVGModule
  ],
  providers: [PartnersService]
})
export class PartnersModule {}
