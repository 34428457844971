import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { concatWith, map, take, takeUntil, tap } from 'rxjs/operators';
import { NewsletterCreateUpdateRequestModel } from 'medtoday-models-library';
import { PurgeTemporaryData, UpdateNewsletter } from 'projects/todaylib/core/auth/actions/auth.actions';
import { getTemporarySignUpData } from 'projects/todaylib/core/auth/selectors/auth.selectors';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';

import { GoToLogin } from '../../../actions/auth-ui-navigation.actions';
import { SignUpFormValue } from './sign-up-form-value.model';
import { GoToTermsOfService } from 'projects/todaylib/core/router/actions/main-navigation.actions';
import {
  firstStepSignUp,
  isUserDoctor,
  occupationActivities,
  secondStepSignUp,
  SIGNUP_PROCESS,
  thirdStepSignUp
} from './sign-up-form-definitions';
import { TOPICS } from '../../../definitions/topics';
import { includes } from 'lodash';
import { Auth } from '@aws-amplify/auth';
import { ActivatedRoute } from '@angular/router';
import { SignUpStepperComponent } from './sign-up-stepper/sign-up-stepper/sign-up-stepper.component';
import { AuthService } from '../../../../../../../todaylib/core/auth/services/auth.service';
import { ObservableComponent } from '../../../../../../../todaylib/shared/components/observable/observable.component';
import { defer, of } from 'rxjs';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpFormComponent extends ObservableComponent implements OnInit, AfterViewInit {
  @ViewChild('cdkStepper') cdkStepper: SignUpStepperComponent;
  showTopics = false;
  signupMode = SIGNUP_PROCESS.FULL;
  SignupProcess = SIGNUP_PROCESS;
  occupationActivities = occupationActivities;
  temporarySignUpData$ = this.store.select(getTemporarySignUpData);

  firstStepForm: FormGroup = firstStepSignUp;
  secondStepForm: FormGroup = secondStepSignUp;
  thirdStepForm: FormGroup = thirdStepSignUp;

  isUserDoctor$ = defer(() =>
    of(this.thirdStepForm.get('occupationActivity')?.value).pipe(
      concatWith(this.thirdStepForm.get('occupationActivity')!.valueChanges),
      map(isUserDoctor)
    )
  );

  topics = TOPICS;
  @Output('signUp') signUpEmitter = new EventEmitter<{
    formValue: SignUpFormValue;
    signupMode: SIGNUP_PROCESS;
  }>();

  get currentLang() {
    return this.translateService.getDefaultLang();
  }

  constructor(
    private store: Store<BaseAppState>,
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private authService: AuthService
  ) {
    super();
  }

  get getHostname(): string {
    return this.document.location.host;
  }

  get forms(): FormGroup {
    if (this.signupMode === SIGNUP_PROCESS.FULL) {
      return new FormGroup({
        ...this.firstStepForm.controls,
        ...this.secondStepForm.controls,
        ...this.thirdStepForm.controls
      });
    } else {
      return new FormGroup({
        ...this.secondStepForm.controls,
        ...this.thirdStepForm.controls
      });
    }
  }

  ngOnInit() {
    this.initializeFormData();
    this.observeOccupationActivity();
  }

  ngAfterViewInit() {
    this.setStartingStep();
  }

  initializeFormData() {
    this.temporarySignUpData$
      .pipe(
        take(1),
        tap(async (data: Partial<SignUpFormValue>) => {
          if (!data) {
            try {
              const userAttrs = await Auth.currentAuthenticatedUser();
              if (userAttrs) {
                data = this.authService.authProviderAttributesToModel(userAttrs.attributes);
              }
            } catch {
              return;
            }
          }
          if (data?.emailAddress) {
            this.firstStepForm.get('emailAddress')?.setValue(data?.emailAddress);
          }

          if (data.password) {
            this.firstStepForm.get('password')?.setValue(data.password);
          }

          this.secondStepForm.patchValue(data);
          this.thirdStepForm.patchValue(data);

          this.cdr.detectChanges();
          this.store.dispatch(new PurgeTemporaryData());
        })
      )
      .subscribe();

    this.thirdStepForm.get('sourceApp')?.setValue(this.getHostname);
  }

  handleSignUpClick(): void {
    if (!this.forms.valid) {
      setTimeout(() => {
        const firstInvalidElement = document.getElementsByClassName('is-invalid')[0];
        if (firstInvalidElement) {
          firstInvalidElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }, 100);
      return;
    }

    if (this.forms.get('hasAcceptedNewsLetter')?.value) {
      const request: NewsletterCreateUpdateRequestModel = {
        email: this.forms.get('emailAddress')?.value,
        firstName: this.forms.get('name')?.value,
        lastName: this.forms.get('lastName')?.value,
        newsletters: true
      };

      this.store.dispatch(new UpdateNewsletter(request, false));
    }

    this.signUpEmitter.emit({
      formValue: this.forms.value,
      signupMode: this.signupMode
    });
  }

  handleSalutationSelect(value: string) {
    this.secondStepForm.controls['salutation'].setValue(value);
  }

  handleLoginClick(): void {
    this.store.dispatch(new GoToLogin());
  }

  handleTOSClick() {
    this.store.dispatch(new GoToTermsOfService());
  }

  handleTopicSelect(topicName: string) {
    if (topicName !== '--') {
      this.thirdStepForm.get('topic')?.setValue(topicName);
    } else {
      this.thirdStepForm.get('topic')?.setValue('');
    }
    this.thirdStepForm.markAsDirty();
  }

  searchForTopics(term: string) {
    const termLc = term.toLowerCase();
    this.topics = TOPICS.filter(topic => includes(topic.toLowerCase(), termLc));
  }

  private setStartingStep() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      if (params.step) {
        this.signupMode = SIGNUP_PROCESS.DETAILS;
        this.cdkStepper.selectedIndex = params.step - 1;
        this.cdr.detectChanges();
      }
    });
  }

  handleSelectOccupationActivity(activity: string) {
    this.thirdStepForm.get('occupationActivity')?.setValue(activity);
  }

  private observeOccupationActivity() {
    this.isUserDoctor$.pipe(takeUntil(this.ngDestroy$)).subscribe(isUserDoctor => {
      const topicControl = this.thirdStepForm.controls['topic'];
      if (isUserDoctor) {
        topicControl.setValidators([Validators.required]);
      } else {
        topicControl.reset('');
        topicControl.clearValidators();
      }
      topicControl.updateValueAndValidity();
    });
  }
}
