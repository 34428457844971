import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { YouTubePlayer } from 'youtube-player/dist/types';
import youtubePlayer from 'youtube-player';
import { PlayerInterface } from '../../models/player.interface';
import { Timestamp } from '../../models/jw-timestamp.model';

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YoutubePlayerComponent implements OnInit, PlayerInterface {
  @Input() mediaId;
  player: YouTubePlayer;
  @Output() loaded = new EventEmitter<void>();

  constructor() {}

  async ngOnInit() {
    this.player = youtubePlayer('yt-video');
    await this.player.loadVideoById(this.mediaId);
    this.loaded.emit();
  }

  seekTo(timestamp: Timestamp) {
    this.player.seekTo(timestamp.begin, true);
  }

  getCurrentTime(): Promise<number> {
    return this.player.getCurrentTime();
  }
}
