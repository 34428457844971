import { AsyncAction } from 'projects/todaylib/core/async-state/models/async-action.model';
import { Action } from '@ngrx/store';
import { Partner, PartnerListResponse, SponsorBooth } from 'medtoday-models-library/lib';

export enum PartnersActionTypes {
  LoadPartners = '[Partners] Load Partners data',
  LoadPartnersSuccess = '[Partners] Load Partners data Success',
  LoadPartnersFail = '[Partners] Load Partners data Fail',

  LoadPartnerDetails = '[Partners] Load Partner details data',
  LoadPartnerDetailsSuccess = '[Partners] Load Partner details data Success',
  LoadPartnerDetailsFail = '[Partners] Load Partners details data Fail',

  LoadSponsorDetails = '[Partners] Load Sponsor booth details data',
  LoadSponsorDetailsSuccess = '[Partners] Load Sponsor booth  details data Success',
  LoadSponsorDetailsFail = '[Partners] Load Sponsor booth  details data Fail',

  PurgeData = '[Partners] Purge Partners data'
}

export class LoadPartners implements AsyncAction {
  readonly asyncKey = PartnersActionTypes.LoadPartners;
  readonly type = PartnersActionTypes.LoadPartners;
  readonly asyncState = 'start';

  constructor() {}
}

export class LoadPartnersSuccess implements AsyncAction {
  readonly asyncKey = PartnersActionTypes.LoadPartners;
  readonly type = PartnersActionTypes.LoadPartnersSuccess;
  readonly asyncState = 'success';

  constructor(public response: PartnerListResponse) {}
}

export class LoadPartnersFail implements AsyncAction {
  readonly asyncKey = PartnersActionTypes.LoadPartners;
  readonly type = PartnersActionTypes.LoadPartnersFail;
  readonly asyncState = 'fail';
}

export class LoadPartnerDetails implements AsyncAction {
  readonly asyncKey = PartnersActionTypes.LoadPartnerDetails;
  readonly type = PartnersActionTypes.LoadPartnerDetails;
  readonly asyncState = 'start';

  constructor(public partnerSlug: string) {}
}

export class LoadPartnerDetailsSuccess implements AsyncAction {
  readonly asyncKey = PartnersActionTypes.LoadPartnerDetails;
  readonly type = PartnersActionTypes.LoadPartnerDetailsSuccess;
  readonly asyncState = 'success';

  constructor(public response: Partner) {}
}

export class LoadPartnerDetailsFail implements AsyncAction {
  readonly asyncKey = PartnersActionTypes.LoadPartnerDetails;
  readonly type = PartnersActionTypes.LoadPartnerDetailsFail;
  readonly asyncState = 'fail';
}

export class LoadSponsorBoothDetails implements AsyncAction {
  readonly asyncKey = PartnersActionTypes.LoadSponsorDetails;
  readonly type = PartnersActionTypes.LoadSponsorDetails;
  readonly asyncState = 'start';

  constructor(public congressSlug: string, public sponsorSlug: string) {}
}

export class LoadSponsorBoothDetailsSuccess implements AsyncAction {
  readonly asyncKey = PartnersActionTypes.LoadSponsorDetails;
  readonly type = PartnersActionTypes.LoadSponsorDetailsSuccess;
  readonly asyncState = 'success';

  constructor(public response: SponsorBooth) {}
}

export class LoadSponsorBoothDetailsFail implements AsyncAction {
  readonly asyncKey = PartnersActionTypes.LoadSponsorDetails;
  readonly type = PartnersActionTypes.LoadSponsorDetailsFail;
  readonly asyncState = 'fail';
}

export class PurgeData implements Action {
  readonly type = PartnersActionTypes.PurgeData;
}

export type PartnersActions =
  | LoadPartnersSuccess
  | PurgeData
  | LoadPartnerDetailsSuccess
  | LoadSponsorBoothDetailsSuccess;
