import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { imagePaths } from '../../../../patientstoday/src/environments/environment';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HeroSliderSideItem } from './hero-slider-mapper';
import { BaseAppState } from '../../../core/store/reducers';
import { Store } from '@ngrx/store';
import { HeroSliderComponent } from '../hero-slider/hero-slider.component';
declare var $;

@Component({
  selector: 'app-hero-slider-sidely',
  templateUrl: './hero-slider-sidely.component.html',
  styleUrls: ['./hero-slider-sidely.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeroSliderSidelyComponent extends HeroSliderComponent implements AfterViewInit {
  @ViewChild('carousel') carousel: ElementRef<HTMLElement>;
  @ViewChildren('carouselItem') carouselItems: QueryList<ElementRef<HTMLElement>>;
  @Input() items: Partial<HeroSliderSideItem>[];
  @Input() tags: [];

  private currentCarouselItem: Subject<Partial<HeroSliderSideItem>> = new Subject();
  currentCarouselItem$ = this.currentCarouselItem.asObservable();
  thumbnailUrl = imagePaths.thumbnails;

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    private cdr: ChangeDetectorRef,
    protected store: Store<BaseAppState>,
    @Inject('applicationName') public applicationName: string
  ) {
    super(store, s3BucketUrl, applicationName);
  }

  ngAfterViewInit() {
    this.carouselItems.forEach(item => {
      const elem = item.nativeElement;
      let next = $(elem).next();
      if (!next.length) {
        next = $(elem).siblings(':first');
      }
      const clonnedEl = next.children(':first-child').clone();
      $(clonnedEl).addClass('blur');
      $(clonnedEl).appendTo($(elem));
    });

    this.carouselItems.forEach(item => {
      const elem = item.nativeElement;
      let prev = $(elem).prev();
      if (!prev.length) {
        prev = $(elem).siblings(':last');
      }
      const clonnedEl = prev.children(':nth-last-child(2)').clone();
      $(clonnedEl).addClass('blur');
      $(clonnedEl).prependTo($(elem));
    });

    this.currentCarouselItem.next(this.items[0]);
    this.cdr.detectChanges();
    this.initCarouselEvents();
  }

  private initCarouselEvents() {
    fromEvent($(this.carousel.nativeElement), 'slide.bs.carousel')
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((event: any) => {
        this.currentCarouselItem.next(this.items[event.to]);
        this.cdr.detectChanges();
      });
  }
}
