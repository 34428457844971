import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { PartnerListItem } from 'medtoday-models-library/lib';
import { imagePaths } from '../../../../../../../medtoday/src/environments/environment';
import { GoToPartnerDetails } from '../../../../../../core/router/actions/main-navigation.actions';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../../../../core/store/reducers';

@Component({
  selector: 'app-partner-network-card',
  templateUrl: './partner-network-card.component.html',
  styleUrls: ['./partner-network-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerNetworkCardComponent {
  @Input() partner: PartnerListItem;
  logoUrl = imagePaths.logos;

  constructor(@Inject('s3BucketUrl') public s3BucketUrl: string, private store: Store<BaseAppState>) {}

  goToPartner(partner: PartnerListItem) {
    if (partner.hasDetailPage) {
      this.store.dispatch(new GoToPartnerDetails(partner.slug));
    } else if (partner.link) {
      window.open(partner.link, '_blank');
    }
  }
}
