import { animate, style, transition, trigger } from '@angular/animations';
import { LocationStrategy } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { RequestPasswordResetFormValue } from 'projects/medtoday/src/app/auth-ui/components/request-password-reset/request-password-reset-form-value.model';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { emailPatternValidator } from '../../../../shared/form-validators/email-pattern-validator';

import { SetAsyncStateReset, SetAsyncStateRetry } from '../../actions/async-state.actions';
import { AsyncErrorState, AsyncState } from '../../reducers/async-state.reducers';
import {
  makeGetAsyncErrorState,
  makeGetAsyncIsFailed,
  makeGetAsyncIsPending,
  makeGetAsyncIsRetryable
} from '../../selectors/async-state.selectors';
import { ErrorConfig } from './ErrorConfig';

const inactiveStyle = style({
  opacity: 0
});
const inactiveStyleChild = style({
  opacity: 0,
  transform: 'scale(0.7)'
});
const timing = '.3s ease';

@Component({
  selector: 'app-async-state-overlay',
  templateUrl: './async-state-overlay.component.html',
  styleUrls: ['./async-state-overlay.component.scss'],
  animations: [
    trigger('flyInOut', [
      transition('void => *', [inactiveStyle, animate(timing)]),
      transition('* => void', [animate(timing, inactiveStyle)])
    ]),
    trigger('flyInOutChild', [
      transition('void => *', [inactiveStyleChild, animate(timing)]),
      transition('* => void', [animate(timing, inactiveStyleChild)])
    ])
  ]
})
export class AsyncStateOverlayComponent {
  private processKey$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private isComponentBasedErrorHandling$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  loading$ = this.processKey$.pipe(
    distinctUntilChanged(),
    filter(key => key !== ''),
    switchMap(key => this.store.select(makeGetAsyncIsPending(key)))
  );

  failed$ = combineLatest([this.processKey$, this.isComponentBasedErrorHandling$]).pipe(
    distinctUntilChanged(),
    filter(([key]) => key !== ''),
    switchMap(([key, isComponentBasedErrorHandling]: [string, boolean]) => {
      if (isComponentBasedErrorHandling) {
        return of(false);
      }
      return this.store.select(makeGetAsyncIsFailed(key));
    })
  );

  unauthorizedError$ = this.processKey$.pipe(
    distinctUntilChanged(),
    filter(key => key !== ''),
    switchMap(key => this.store.select(makeGetAsyncErrorState(key))),
    map((errorState: AsyncErrorState) => errorState && errorState.httpStatus === 401)
  );

  errorConfig$ = this.processKey$.pipe(
    distinctUntilChanged(),
    filter(key => key !== ''),
    switchMap(key => this.store.select(makeGetAsyncErrorState(key))),
    filter(errorState => Boolean(errorState)),
    map((errorState: AsyncErrorState) => new ErrorConfig(errorState))
  );

  public errorTitle$ = this.errorConfig$.pipe(map(errorConfig => errorConfig.title));

  public errorMessage$ = this.errorConfig$.pipe(map(errorConfig => this.getFlatErrorMessage(errorConfig)));

  retryable$ = this.processKey$.pipe(
    distinctUntilChanged(),
    filter(key => key !== ''),
    switchMap(key => this.store.select(makeGetAsyncIsRetryable(key))),
    switchMap((hasRetryAction: boolean) => {
      if (!hasRetryAction) {
        return of(false);
      }
      // might be retryable. to decide, we need the error code
      return this.errorConfig$.pipe(map(errorConfig => errorConfig.isRetryable));
    })
  );

  private _processKey: string;
  @Input() size = '40px';

  @Input()
  set processKey(processKey: string) {
    this.processKey$.next(processKey);
    this._processKey = processKey;
  }
  get processKey(): string {
    return this._processKey;
  }

  @Input()
  set isComponentBasedErrorHandling(value: boolean) {
    this.isComponentBasedErrorHandling$.next(value);
  }

  @Input() isAbsolutePositioned = false;

  @Output() retry: EventEmitter<{}> = new EventEmitter();
  @Output() dismiss: EventEmitter<{}> = new EventEmitter();
  @Output() resend: EventEmitter<{}> = new EventEmitter();
  @Output() resendPassword: EventEmitter<String> = new EventEmitter<String>();

  form: FormGroup = new FormGroup({
    emailAddress: new FormControl('', [Validators.required, emailPatternValidator])
  });

  get formValue(): RequestPasswordResetFormValue {
    return this.form.value as RequestPasswordResetFormValue;
  }

  get isResetPassword(): boolean {
    return this.url.path().includes('/reset');
  }

  constructor(private store: Store<AsyncState>, private url: LocationStrategy) {}

  onRetryClick(): void {
    this.store.dispatch(new SetAsyncStateRetry(this.processKey));
    if (this.retry.observers && this.retry.observers.length > 0) {
      this.retry.emit();
    }
  }

  onDismissClick(): void {
    this.processKey$
      .pipe(
        distinctUntilChanged(),
        filter(key => key !== ''),
        switchMap(key => this.store.select(makeGetAsyncErrorState(key))),
        take(1)
      )
      .subscribe((_errorState: AsyncErrorState) => {
        if (this.dismiss.observers && this.dismiss.observers.length > 0) {
          this.dismiss.emit();
        }

        // reset error
        this.store.dispatch(new SetAsyncStateReset(this.processKey));
      });
  }

  private getFlatErrorMessage(errorConfig: ErrorConfig): string {
    const message = errorConfig.messages.reduce((reducedMessage, currentMessage, currentIndex) => {
      let newMessage = reducedMessage;
      if (currentIndex > 0) {
        newMessage += '\n';
      }
      newMessage += currentMessage;
      return newMessage;
    }, '');
    return message;
  }

  handleResendCode(): void {
    this.resend.emit();
    setTimeout(() => this.onDismissClick(), 300);
  }

  handleResendPasswordCode(): void {
    if (!this.form.valid) {
      return;
    }

    this.resendPassword.emit(this.formValue.emailAddress);
    setTimeout(() => this.onDismissClick(), 300);
  }
}
