import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { PasswordActionTypes, RequestPasswordReset } from 'projects/todaylib/core/auth/actions/auth-password.actions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { emailPatternValidator } from 'projects/todaylib/shared/form-validators/email-pattern-validator';
import { RequestPasswordResetRequest } from 'projects/todaylib/shared/models';

import { GoToLogin } from '../../actions/auth-ui-navigation.actions';
import { RequestPasswordResetFormValue } from './request-password-reset-form-value.model';

@Component({
  selector: 'app-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss']
})
export class RequestPasswordResetComponent {
  readonly asyncProcessKey = PasswordActionTypes.RequestPasswordReset;

  form: FormGroup = new FormGroup({
    emailAddress: new FormControl('', [Validators.required, emailPatternValidator])
  });

  get formValue(): RequestPasswordResetFormValue {
    return this.form.value as RequestPasswordResetFormValue;
  }

  constructor(private store: Store<BaseAppState>) {}

  handleResetClick(): void {
    if (!this.form.valid) {
      return;
    }

    const request: RequestPasswordResetRequest = {
      email: this.formValue.emailAddress
    };
    this.store.dispatch(new RequestPasswordReset(request));
  }

  handleLoginClick(): void {
    this.store.dispatch(new GoToLogin());
  }
}
