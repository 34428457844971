import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

@Component({
  selector: 'app-sign-up-social',
  templateUrl: './sign-up-social.component.html',
  styleUrls: ['./sign-up-social.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpSocialComponent {
  socialSignUp(service: string) {
    switch (service) {
      case 'google':
        return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
      case 'apple':
        return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple });
    }
  }
}
