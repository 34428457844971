import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AsyncAction } from '../../async-state/models/async-action.model';
import { handleAsyncErrors } from '../../async-state/operators/handle-errors';
import { ToastService } from '../../toasts/services/toast.service';
import {
  Channel,
  CongressesListResponse,
  CongressLandingPage,
  CongressMetadata,
  ExpertsResponse,
  FeaturedContentResponse,
  OnDemandVideoDetail,
  OnDemandVideoListResponse,
  Programme,
  OnDemandVideoDeeplink,
  ChannelDeeplink,
  CongressTopicsResponseModel
} from 'medtoday-models-library';
import { of } from 'rxjs';
import { auditTime, catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import {
  DataApiActionTypes,
  LoadChannelBySlug,
  LoadChannelBySlugFail,
  LoadChannelBySlugSuccess,
  LoadCongressesList,
  LoadCongressesListFail,
  LoadCongressesListSuccess,
  LoadCongressLangingPageData,
  LoadCongressLangingPageDataFail,
  LoadCongressLangingPageDataSuccess,
  LoadCongressMetaData,
  LoadCongressMetaDataFail,
  LoadCongressMetaDataSuccess,
  LoadExpertsData,
  LoadExpertsDataFail,
  LoadExpertsDataSuccess,
  LoadFeaturedContentOverview,
  LoadFeaturedContentOverviewFail,
  LoadFeaturedContentOverviewSuccess,
  LoadOnDemandVideo,
  LoadOnDemandVideoFail,
  LoadOnDemandVideos,
  LoadOnDemandVideosFail,
  LoadOnDemandVideosSuccess,
  LoadOnDemandVideoSuccess,
  LoadProgrammeData,
  LoadProgrammeDataFail,
  LoadProgrammeDataSuccess,
  LoadTokenizedOnDemandVideo,
  LoadTokenizedOnDemandVideoFail,
  LoadTokenizedOnDemandVideoSuccess,
  SendOnDemandVideoQuestion,
  SendOnDemandVideoQuestionFail,
  SendOnDemandVideoQuestionSuccess,
  LoadTokenizedChannelFail,
  LoadTokenizedChannelSuccess,
  LoadTokenizedChannel,
  LoadCongressTopics,
  LoadCongressTopicsFail,
  LoadCongressTopicsSuccess
} from '../actions/data-api.actions';
import { DataApiService } from '../services/data-api.service';

@Injectable({
  providedIn: 'root'
})
export class DataApiEffects {
  loadCongressesList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.LoadCongressesList),
        mergeMap((_action: LoadCongressesList) =>
          this.api.getCongresses().pipe(
            handleAsyncErrors(() => new LoadCongressesListFail()),
            map(
              (response: CongressesListResponse) =>
                new LoadCongressesListSuccess(
                  response.congresses,
                  response.archivedCongresses,
                  response.mostWatchedVideos,
                  response.upcomingEvents,
                  response.premiumBox,
                  response.pageItems,
                  response.pages,
                  response.internationalContents
                )
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadCongressLandingPageData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.LoadCongressLandingPageData),
        mergeMap((action: LoadCongressLangingPageData) =>
          this.api.getHomeData(action.congressSlug).pipe(
            handleAsyncErrors(() => new LoadCongressLangingPageDataFail()),
            map((data: CongressLandingPage) => new LoadCongressLangingPageDataSuccess(data)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadCongressMetaData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.LoadCongressMetaData),
        mergeMap((action: LoadCongressMetaData) =>
          this.api.getCongressMetaData(action.congressSlug).pipe(
            handleAsyncErrors(() => new LoadCongressMetaDataFail()),
            map((data: CongressMetadata) => new LoadCongressMetaDataSuccess(data)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadProgrammeData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.LoadProgrammeData),
        mergeMap((action: LoadProgrammeData) =>
          this.api.getProgrammeData(action.congressSlug).pipe(
            handleAsyncErrors(() => new LoadProgrammeDataFail()),
            map((data: Programme) => new LoadProgrammeDataSuccess(data)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadExpertsData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.LoadExpertsData),
        mergeMap((action: LoadExpertsData) =>
          this.api.getExperts(action.congressSlug).pipe(
            handleAsyncErrors(() => new LoadExpertsDataFail()),
            map((data: ExpertsResponse) => new LoadExpertsDataSuccess(data)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadChannelData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.LoadChannelBySlug),
        switchMap((action: LoadChannelBySlug) =>
          this.api.getChannelDataBySlug(action.congressSlug, action.channelSlug).pipe(
            handleAsyncErrors(() => new LoadChannelBySlugFail()),
            map((data: Channel) => new LoadChannelBySlugSuccess(data)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadTokenizedChannelData = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.LoadTokenizedChannel),
        auditTime(100),
        switchMap((action: LoadTokenizedChannel) =>
          this.api.getTokenizedChannel(action.congressSlug, action.channelSlug, action.token).pipe(
            handleAsyncErrors(() => new LoadTokenizedChannelFail()),
            map((response: ChannelDeeplink) => new LoadTokenizedChannelSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadVideosOnDemand$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.LoadOnDemandVideos),
        mergeMap((action: LoadOnDemandVideos) =>
          this.api.getOnDemandVideos(action.congressSlug).pipe(
            handleAsyncErrors(() => new LoadOnDemandVideosFail()),
            map((data: OnDemandVideoListResponse) => new LoadOnDemandVideosSuccess(data)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadVideoOnDemand$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.LoadOnDemandVideo),
        auditTime(100),
        switchMap((action: LoadOnDemandVideo) =>
          this.api.getOnDemandVideo(action.id, action.congressSlug).pipe(
            handleAsyncErrors(() => new LoadOnDemandVideoFail()),
            map((response: OnDemandVideoDetail) => new LoadOnDemandVideoSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadTokenizedVideoOnDemand$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.LoadTokenizedOnDemandVideo),
        auditTime(100),
        switchMap((action: LoadTokenizedOnDemandVideo) =>
          this.api.getTokenizedOnDemandVideo(action.id, action.token, action.congressSlug).pipe(
            handleAsyncErrors(() => new LoadTokenizedOnDemandVideoFail()),
            map((response: OnDemandVideoDeeplink) => new LoadTokenizedOnDemandVideoSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadFeaturedContentOverview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.LoadFeaturedContentOverview),
        mergeMap((action: LoadFeaturedContentOverview) =>
          this.api.getFeaturedContent(action.congressSlug).pipe(
            handleAsyncErrors(() => new LoadFeaturedContentOverviewFail()),
            map((response: FeaturedContentResponse) => new LoadFeaturedContentOverviewSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  sendVideoOnDemandQuestion$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.SendOnDemandVideoQuestion),
        mergeMap((action: SendOnDemandVideoQuestion) =>
          this.api.addQuestionForOnDemandVideo(action.congressSlug, action.id, action.request).pipe(
            handleAsyncErrors(() => new SendOnDemandVideoQuestionFail()),
            map(() => new SendOnDemandVideoQuestionSuccess()),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  showToastAfterQuestionSendSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.SendOnDemandVideoQuestionSuccess),
        tap(() => this.toastService.showSuccess('Nachricht versendet', 'Ihre Nachricht wurde erfolgreich versendet.'))
      ),
    { dispatch: false }
  );

  loadCongressTopics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.LoadCongressTopics),
        mergeMap((action: LoadCongressTopics) =>
          this.api.getCongressTopics(action.exclude).pipe(
            handleAsyncErrors(() => new LoadCongressTopicsFail()),
            map((data: CongressTopicsResponseModel) => new LoadCongressTopicsSuccess(data)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  constructor(private api: DataApiService, private toastService: ToastService, private actions$: Actions) {}
}
