import {
  UpcomingEventsCountriesResponseModel,
  UpcomingEventsDatesResponseModel,
  UpcomingEventsResponseModel,
  UpcomingEventsTopicLanguagesResponseModel
} from 'medtoday-models-library/lib/models';
import { AsyncAction } from '../../async-state/models/async-action.model';
import { Action } from '@ngrx/store';
import { UpcomingEventListItemModel } from 'medtoday-models-library';

export enum UpcomingEventsApiActionTypes {
  LoadUpcomingEventsPageData = '[Data] Load Upcoming Events Page',
  LoadUpcomingEventsPageDataSuccess = '[Data] Load Upcoming Events Page Success',
  LoadUpcomingEventsPageDataFail = '[Data] Load Upcoming Events Page Fail',

  LoadUpcomingEventsTopicCategories = '[Data] Load Upcoming Events Topic Categories',
  LoadUpcomingEventsTopicCategoriesSuccess = '[Data] Load Upcoming Events Topic Categories Success',
  LoadUpcomingEventsTopicCategoriesFail = '[Data] Load Upcoming Events Topic Categories Fail',

  LoadUpcomingEventsDates = '[Data] Load Upcoming Events Dates',
  LoadUpcomingEventsDatesSuccess = '[Data] Load Upcoming Events Dates Success',
  LoadUpcomingEventsDatesFail = '[Data] Load Upcoming Events Dates Fail',

  LoadUpcomingEventsCountries = '[Data] Load Upcoming Events Countries',
  LoadUpcomingEventsCountriesSuccess = '[Data] Load Upcoming Events Countries Success',
  LoadUpcomingEventsCountriesFail = '[Data] Load Upcoming Events Countries Fail',

  LoadFilteredOnDemandVideos = '[Data] Load Filtered On Demand Videos',
  LoadFilteredOnDemandVideosSuccess = '[Data] Load Filtered On Demand Videos Success',
  LoadFilteredOnDemandVideosFail = '[Data] Load Filtered On Demand Videos Fail',

  SetSelectedUpcomingEvent = '[Modal] Set upcoming event info'
}

export class LoadUpcomingEventsPageData implements AsyncAction {
  readonly asyncKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsPageData;
  readonly asyncState = 'start';
  readonly type = UpcomingEventsApiActionTypes.LoadUpcomingEventsPageData;

  constructor(
    public page: number | null,
    public topicCategory: string | null,
    public year: number | null,
    public month: number | null,
    public language: string | null,
    public limit: number | null,
    public onlyCme = false,
    public onlyLive = false
  ) {}
}

export class LoadUpcomingEventsPageDataSuccess implements AsyncAction {
  readonly asyncKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsPageData;
  readonly type = UpcomingEventsApiActionTypes.LoadUpcomingEventsPageDataSuccess;
  readonly asyncState = 'success';

  constructor(public upcomingEventsPageData: UpcomingEventsResponseModel) {}
}

export class LoadUpcomingEventsPageDataFail implements AsyncAction {
  readonly asyncKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsPageData;
  readonly type = UpcomingEventsApiActionTypes.LoadUpcomingEventsPageDataFail;
  readonly asyncState = 'fail';
}

export class LoadUpcomingEventsTopicCategories implements AsyncAction {
  readonly asyncKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsTopicCategories;
  readonly asyncState = 'start';
  readonly type = UpcomingEventsApiActionTypes.LoadUpcomingEventsTopicCategories;

  constructor() {}
}

export class LoadUpcomingEventsTopicCategoriesSuccess implements AsyncAction {
  readonly asyncKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsTopicCategories;
  readonly type = UpcomingEventsApiActionTypes.LoadUpcomingEventsTopicCategoriesSuccess;
  readonly asyncState = 'success';

  constructor(public upcomingEventsTopicCategories: UpcomingEventsTopicLanguagesResponseModel) {}
}

export class LoadUpcomingEventsTopicCategoriesFail implements AsyncAction {
  readonly asyncKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsTopicCategories;
  readonly type = UpcomingEventsApiActionTypes.LoadUpcomingEventsTopicCategoriesFail;
  readonly asyncState = 'fail';
}

export class LoadUpcomingEventsDates implements AsyncAction {
  readonly asyncKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsDates;
  readonly asyncState = 'start';
  readonly type = UpcomingEventsApiActionTypes.LoadUpcomingEventsDates;

  constructor() {}
}

export class LoadUpcomingEventsDatesSuccess implements AsyncAction {
  readonly asyncKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsDates;
  readonly type = UpcomingEventsApiActionTypes.LoadUpcomingEventsDatesSuccess;
  readonly asyncState = 'success';

  constructor(public upcomingEventsDates: UpcomingEventsDatesResponseModel) {}
}

export class LoadUpcomingEventsDatesFail implements AsyncAction {
  readonly asyncKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsDates;
  readonly type = UpcomingEventsApiActionTypes.LoadUpcomingEventsDatesFail;
  readonly asyncState = 'fail';
}

export class LoadUpcomingEventsCountries implements AsyncAction {
  readonly asyncKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsCountries;
  readonly asyncState = 'start';
  readonly type = UpcomingEventsApiActionTypes.LoadUpcomingEventsCountries;

  constructor() {}
}

export class LoadUpcomingEventsCountriesSuccess implements AsyncAction {
  readonly asyncKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsCountries;
  readonly type = UpcomingEventsApiActionTypes.LoadUpcomingEventsCountriesSuccess;
  readonly asyncState = 'success';

  constructor(public upcomingEventsCountries: UpcomingEventsCountriesResponseModel) {}
}

export class LoadUpcomingEventsCountriesFail implements AsyncAction {
  readonly asyncKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsCountries;
  readonly type = UpcomingEventsApiActionTypes.LoadUpcomingEventsCountriesFail;
  readonly asyncState = 'fail';
}

export class SetSelectedUpcomingEvent implements Action {
  readonly type = UpcomingEventsApiActionTypes.SetSelectedUpcomingEvent;

  constructor(public selectedUpcomingEvent: UpcomingEventListItemModel) {}
}

export type UpcomingEventsApiActions =
  | LoadUpcomingEventsPageDataSuccess
  | LoadUpcomingEventsTopicCategoriesSuccess
  | LoadUpcomingEventsDatesSuccess
  | LoadUpcomingEventsCountriesSuccess
  | SetSelectedUpcomingEvent;
