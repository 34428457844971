import { AsyncAction } from 'projects/todaylib/core/async-state/models/async-action.model';
import { Action } from '@ngrx/store';
import {
  ELearningMetadataResponse,
  ElearningQuestionsResponse,
  ElearningResultResponse
} from 'medtoday-models-library/lib';

export enum ElearningActionTypes {
  LoadQuestions = '[Elearning] Load Questions data',
  LoadQuestionsSuccess = '[Elearning] Load Questions data Success',
  LoadQuestionsFail = '[Elearning] Load Questions data Fail',

  SubmitElearningAnswers = '[Elearning] Submit elearning answers',
  SubmitElearningAnswersSuccess = '[Elearning] Submit elearning answers success',
  SubmitElearningAnswersFail = '[Elearning] Submit elearning answers fail',

  GetElearningUserMetadata = '[Elearning] Get elearning user metadata',
  GetElearningUserMetadataSuccess = '[Elearning] Get elearning user metadata Success',
  GetElearningUserMetadataFail = '[Elearning] Get elearning user metadata Fail',

  PurgeData = '[Elearning] Purge Questions data'
}

export class LoadQuestions implements AsyncAction {
  readonly asyncKey = ElearningActionTypes.LoadQuestions;
  readonly type = ElearningActionTypes.LoadQuestions;
  readonly asyncState = 'start';

  constructor(public vodId: number) {}
}

export class LoadQuestionsSuccess implements AsyncAction {
  readonly asyncKey = ElearningActionTypes.LoadQuestions;
  readonly type = ElearningActionTypes.LoadQuestionsSuccess;
  readonly asyncState = 'success';

  constructor(public response: ElearningQuestionsResponse) {}
}

export class LoadQuestionsFail implements AsyncAction {
  readonly asyncKey = ElearningActionTypes.LoadQuestions;
  readonly type = ElearningActionTypes.LoadQuestionsFail;
  readonly asyncState = 'fail';
}

export class SubmitElearningAnswers implements AsyncAction {
  readonly asyncKey = ElearningActionTypes.SubmitElearningAnswers;
  readonly type = ElearningActionTypes.SubmitElearningAnswers;
  readonly asyncState = 'start';

  constructor(public vodId: number, public congressSlug, public data: {}) {}
}

export class SubmitElearningAnswersSuccess implements AsyncAction {
  readonly asyncKey = ElearningActionTypes.SubmitElearningAnswers;
  readonly type = ElearningActionTypes.SubmitElearningAnswersSuccess;
  readonly asyncState = 'success';

  constructor(public response: ElearningResultResponse) {}
}

export class SubmitElearningAnswersFail implements AsyncAction {
  readonly asyncKey = ElearningActionTypes.SubmitElearningAnswers;
  readonly type = ElearningActionTypes.SubmitElearningAnswersFail;
  readonly asyncState = 'fail';
}

export class GetElearningUserMetadata implements AsyncAction {
  readonly asyncKey = ElearningActionTypes.GetElearningUserMetadata;
  readonly type = ElearningActionTypes.GetElearningUserMetadata;
  readonly asyncState = 'start';

  constructor(public vodId: number) {}
}

export class GetElearningUserMetadataSuccess implements AsyncAction {
  readonly asyncKey = ElearningActionTypes.GetElearningUserMetadata;
  readonly type = ElearningActionTypes.GetElearningUserMetadataSuccess;
  readonly asyncState = 'success';

  constructor(public response: ELearningMetadataResponse) {}
}

export class GetElearningUserMetadataFail implements AsyncAction {
  readonly asyncKey = ElearningActionTypes.GetElearningUserMetadata;
  readonly type = ElearningActionTypes.GetElearningUserMetadataFail;
  readonly asyncState = 'fail';
}

export class PurgeData implements Action {
  readonly type = ElearningActionTypes.PurgeData;
}

export type ElearningActions =
  | LoadQuestionsSuccess
  | SubmitElearningAnswersSuccess
  | PurgeData
  | GetElearningUserMetadataSuccess;
