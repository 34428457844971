<div class="row">
  <ng-container *ngFor="let session of row.sessions; let i = index">
    <div
      [ngClass]="isFeaturedContent ? 'col-lg-8' : 'col-lg-6'"
      class="session col-12 adjust-top-2"
      *ngIf="row.id === session.timeSlotId"
    >
      <div *ngIf="!selectedTopic" class="divider w-100 mb-3" [class.hide-mobile]="isFeaturedContent"></div>
      <!-- desktop view -->
      <div class="h-100 d-flex flex-column hide-tablet">
        <div class="title-wrapper d-flex flex-row mt-3 flex-wrap">
          <ng-container
            [ngTemplateOutlet]="sessionHeadline"
            [ngTemplateOutletContext]="{ session: session, row: row }"
          ></ng-container>
        </div>
        <h5 class="m-0 mb-3 mt-md-2" *ngIf="session.title && !isFeaturedContent">{{ session.title }}</h5>
        <div *ngIf="isFeaturedContent" class="d-flex flex-row align-items-center">
          <h5 class="mt-md-2">
            {{ session.title ? session.title : session.format }}
          </h5>
          <app-live-indicator class="ml-2" *ngIf="isLive(row)" [timeSlotRow]="row"></app-live-indicator>
        </div>
        <ng-container *ngIf="session.experts?.length > 0">
          <span class="text-note" *ngFor="let expert of session.experts">{{ expert | expert }}<br /></span>
        </ng-container>
        <ng-container *ngIf="session.channelCmeTrackable">
          <span class="mt-2">CME</span>
        </ng-container>
        <div class="d-flex">
          <button
            *ngIf="isLive(row)"
            type="button"
            class="btn btn-danger shadow-sm mt-3 font-size-large"
            (click)="handleGoToStream(session.channelSlug)"
          >
            {{ !session.zoomLink ? ('Navigation.ToSession' | translate) : ('ProgrammePage.JoinWorkshop' | translate) }}
            <div class="icon-16 icon-stream" [inlineSVG]="'assets/icons/stream.svg'"></div>
          </button>
        </div>
      </div>
      <!-- mobile view -->
      <div id="accordion" class="hide-desktop">
        <div class="accordion-inner">
          <button
            class="btn collapsed accordion-btn"
            data-toggle="collapse"
            attr.data-target="#session{{ i }}{{ session.id }}"
            aria-expanded="true"
            attr.aria-controls="session{{ i }}{{ session.id }}"
          >
            <div class="d-flex button-inner flex-wrap">
              <ng-container
                [ngTemplateOutlet]="sessionHeadline"
                [ngTemplateOutletContext]="{ session: session, row: row }"
              ></ng-container>
            </div>
            <div class="indicator"></div>
          </button>
          <div id="session{{ i }}{{ session.id }}" class="collapse" data-parent="#accordion">
            <h5 class="m-0 mb-3" *ngIf="session.title && !isFeaturedContent">{{ session.title }}</h5>
            <ng-container *ngIf="session.experts?.length > 0">
              <span class="text-note" *ngFor="let expert of session.experts">{{ expert | expert }}<br /></span>
            </ng-container>
            <ng-container *ngIf="session.channelCmeTrackable">
              <div class="mt-2">CME</div>
            </ng-container>
            <div class="d-flex">
              <button
                *ngIf="isLive(row)"
                type="button"
                class="btn btn-danger shadow-sm mt-3 mt-md-4 font-size-large"
                (click)="handleGoToStream(session.channelSlug)"
              >
                {{
                  !session.zoomLink ? ('Navigation.ToSession' | translate) : ('ProgrammePage.JoinWorkshop' | translate)
                }}
                <div class="icon-16 icon-stream" [inlineSVG]="'assets/icons/stream.svg'"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #sessionHeadline let-session="session" let-row="row">
  <ng-container *ngIf="session.sponsors?.length && !(congressMetadata$ | async)?.hideSponsorInMediaListing">
    <ng-container *ngFor="let sponsor of session.sponsors">
      <h6 *ngIf="currentLang !== 'ch'" class="card-title badge badge-sponsored flex-shrink-0 p-2 mr-1 rounded">
        {{ sponsor.sponsorName }}
      </h6>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="(session.format && selectedTopic) || (session.format && isFeaturedContent)">
    <h6 class="session-format card-title" [ngClass]="isFeaturedContent ? 'badge mr-1 p-2' : ''">
      {{ session.format }}
    </h6>
  </ng-container>
  <ng-container *ngIf="(isFeaturedContent && session.topic) || (!selectedTopic && session.topic)">
    <h6
      class="session-format card-title badge p-2 mr-1"
      [ngClass]="applicationName === 'PatientsToday' ? 'badge-light' : 'badge-dark'"
    >
      {{ session.topic.title }}
    </h6>
  </ng-container>
  <ng-container *ngIf="session.spokenLanguage">
    <h6 class="card-title badge badge-dark flex-shrink-0 p-2 mr-1 rounded">
      {{ 'VideoOnDemand.' + session.spokenLanguage | translate }}
    </h6>
  </ng-container>
  <h6
    class="session-format card-title badge p-2 ml-2"
    [ngClass]="applicationName === 'PatientsToday' ? 'badge-light' : 'badge-dark'"
    *ngIf="format === '' && !selectedTopic && session.format && !isFeaturedContent"
  >
    {{ session.format }}
  </h6>
  <h6 *ngIf="session.title && isFeaturedContent" class="card-title mb-0 mt-2 d-md-none">
    {{ session.title }}
  </h6>
  <app-live-indicator
    class="ml-2"
    *ngIf="isLive(row)"
    [timeSlotRow]="row"
    [class.d-md-none]="isFeaturedContent"
  ></app-live-indicator>
</ng-template>
