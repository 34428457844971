<section>
  <header>
    <ng-container *ngFor="let step of steps; let i = index">
      <ng-container *ngIf="selectedIndex === i" [ngTemplateOutlet]="step.stepLabel.template"></ng-container>
    </ng-container>
  </header>

  <div [ngTemplateOutlet]="selected ? selected.content : null"></div>

  <footer class="mt-4">
    <div class="row">
      <div class="col-6" *ngIf="selectedIndex < steps.length - 1">
        <button
          class="btn ghost-button-light w-100 rounded px-2"
          *ngIf="selectedIndex > 0"
          type="submit"
          cdkStepperPrevious
        >
          {{ 'NavBar.Back' | translate }}
        </button>
      </div>

      <div class="col-6" [class.col-12]="selectedIndex === 0" *ngIf="selectedIndex < steps.length - 1">
        <button
          class="btn btn-danger w-100 rounded px-2"
          type="submit"
          cdkStepperNext
          [disabled]="!selected!.stepControl!.valid"
        >
          {{ 'Auth.Next' | translate }}
        </button>
      </div>

      <div class="col-12" *ngIf="selectedIndex === steps.length - 1">
        <button
          class="btn btn-danger w-100 rounded px-5"
          type="submit"
          (click)="handleSignupClick()"
          [disabled]="!selected!.stepControl!.valid"
        >
          {{ 'SignUp.Register' | translate }}
        </button>
      </div>
    </div>

    <ol class="carousel-indicators mt-4">
      <li *ngFor="let step of steps; let i = index" data-target="#carousel" [class.active]="i === selectedIndex"></li>
    </ol>
  </footer>
</section>
