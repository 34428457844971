<div
  class="modal fade"
  id="cmeTrackingPrompt"
  tabindex="-1"
  aria-labelledby="cmeTrackingPromptLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered rounded">
    <div class="modal-content bg-dark">
      <div class="modal-header p-4">
        <button type="button" class="close" data-dismiss="modal" (click)="handleCloseModal()" aria-label="Close">
          <i class="bi bi-x-lg text-white"></i>
        </button>
      </div>
      <div class="modal-body">
        <h4>{{ 'ChannelPage.CmeTrackingModalHeader' | translate }}</h4>
        <p>{{ 'ChannelPage.CmeTrackingModalDescription' | translate }}</p>
        <div class="switch-wrapper d-flex form-group my-4">
          <div class="mr-4 custom-switch">
            <input
              type="checkbox"
              class="custom-control-input cms-toggle"
              id="cmeActive"
              [formControl]="cmeActiveStateControl"
            />
            <label class="custom-control-label cms-toggle" for="cmeActive"></label>
          </div>
          <span>
            <ng-container *ngIf="cmeActiveStateControl.value === true">
              {{ 'ChannelPage.CmeTrackingModalToggleActiveLabel' | translate }}
            </ng-container>
            <ng-container *ngIf="cmeActiveStateControl.value === false">
              {{ 'ChannelPage.CmeTrackingModalToggleInactiveLabel' | translate }}
            </ng-container>
          </span>
        </div>
        <ng-container *ngIf="cmeActiveStateControl.value">
          <app-efn-updater-control></app-efn-updater-control>
        </ng-container>
        <button
          class="btn btn-danger btn-block mt-5"
          type="submit"
          (click)="handleUpdateCmeClick()"
          [disabled]="cmeActiveStateControl.value && (hasUserEfnNumber$ | async) === false"
        >
          <span *ngIf="cmeActiveStateControl.value"> {{ 'ChannelPage.CmeTrackingModalTrackButton' | translate }} </span>
          <span *ngIf="!cmeActiveStateControl.value"> {{ 'ChannelPage.CmeTrackingModalSkipButton' | translate }} </span>
        </button>
        <p class="text-note my-2">
          {{ 'ChannelPage.CmeTrackingModalNote' | translate }}
        </p>
        <div class="text-center mt-4">
          <a class="text-danger" (click)="handleCloseModal()" href="javascript:void(0)">{{
            'ChannelPage.CmeTrackingModalCancel' | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
