import moment from 'moment';
import { Injectable, InjectionToken } from '@angular/core';
import { Session, UpcomingEventEventSessionTimeframeModel } from 'medtoday-models-library/lib';
import { maxBy } from 'lodash';

export const SESSION_UTILS_TOKEN = new InjectionToken<SessionUtils>('is time slot active');
export const TIME_SLOT_INTERVAL = new InjectionToken<number>('time slot interval');

@Injectable()
export class SessionUtils {
  constructor(private timeSlotInterval = 15) {}

  getDifferentSessionTriggerTime(sessions: any[]) {
    const foundMinTriggerTimeSession = maxBy(sessions, 'liveButtonTriggerTime');
    return foundMinTriggerTimeSession && foundMinTriggerTimeSession.liveButtonTriggerTime > this.timeSlotInterval
      ? foundMinTriggerTimeSession.liveButtonTriggerTime
      : this.timeSlotInterval;
  }

  isActive(timeSlot: {
    sessions: Session[] | UpcomingEventEventSessionTimeframeModel[];
    start: string;
    finish: string;
  }) {
    const dateNow = moment.utc(new Date());
    const timeSlotInterval = this.getDifferentSessionTriggerTime(timeSlot.sessions);
    return dateNow.isBetween(
      moment.utc(timeSlot.start).subtract(timeSlotInterval, 'minutes'),
      moment.utc(timeSlot.finish).add(timeSlotInterval, 'minutes')
    );
  }

  isAnySessionLiveWithoutInterval(sessions: { start: string; finish: string }[]): boolean {
    const dateNow = moment.utc(new Date());
    return sessions.some(session => dateNow.isBetween(moment.utc(session.start), moment.utc(session.finish)));
  }
}
