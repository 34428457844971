import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../../../../todaylib/core/store/reducers';
import { UpcomingEventsApiActionTypes } from '../../../../../../todaylib/core/data/actions/upcoming-events-api.actions';
import { getCmeCertificatesData } from '../../../medtoday-store/selectors/medtoday-store.selectors';
import {
  LoadCmeCertificates,
  MedtodayStoreActionTypes,
  PurgeCmeCertificates
} from '../../../medtoday-store/actions/medtoday-store.actions';
import { BehaviorSubject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { CmeCertificate, Mode } from 'medtoday-models-library/lib';
import { chunk } from 'lodash';
import { ObservableComponent } from '../../../../../../todaylib/shared/components/observable/observable.component';
import { UpcomingEventListItemModel } from 'medtoday-models-library';
import { imagePaths } from '../../../../environments/environment';
import { UpcomingEventsResponseModel } from 'medtoday-models-library/lib/models';
import { SESSION_UTILS_TOKEN, SessionUtils } from '../../../../../../todaylib/shared/utilities/session.utils';
import { getUpcomingEventsData } from '../../../../../../todaylib/core/data/selectors/data-api.selectors';

@Component({
  selector: 'app-my-cme-page',
  templateUrl: './my-cme-page.component.html',
  styleUrls: ['./my-cme-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyCmePageComponent extends ObservableComponent implements OnInit {
  upcomingEventsPageData$ = this.store.select(getUpcomingEventsData).pipe(
    filter(data => !!data),
    map(data => this.mapUpcomingEventsData(data))
  );
  readonly upcomingEventsDataKey = UpcomingEventsApiActionTypes.LoadUpcomingEventsPageData;
  readonly cmeCertificatesDataKey = MedtodayStoreActionTypes.LoadCmeCertificates;
  cmeCertificates$ = this.store.select(getCmeCertificatesData);
  currentPage$ = new BehaviorSubject<number>(1);
  paginatedCmeCertificates: CmeCertificate[][];
  lastCmeCertificateEvent: UpcomingEventListItemModel | any;
  thumbnails = imagePaths.thumbnails;

  constructor(
    private store: Store<BaseAppState>,
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    @Inject(SESSION_UTILS_TOKEN) private sessionUtils: SessionUtils
  ) {
    super();
  }

  ngOnInit(): void {
    this.initDataHandler();
    this.loadData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(new PurgeCmeCertificates());
  }

  private loadData() {
    this.store.dispatch(new LoadCmeCertificates());
  }

  private initDataHandler() {
    this.cmeCertificates$
      .pipe(
        filter(cmeCertificates => !!cmeCertificates?.cmeEntries?.length),
        take(1)
      )
      .subscribe(cmeCertificates => {
        const sortableArray = [...cmeCertificates.cmeEntries];
        this.lastCmeCertificateEvent = this.adaptCertificateToEvent(sortableArray.shift()!);
        this.paginatedCmeCertificates = chunk(sortableArray, 7);
      });
  }

  private adaptCertificateToEvent(cmeCertificate: CmeCertificate): any {
    return {
      congressSlug: '',
      start: cmeCertificate.congressDate,
      end: '',
      title: cmeCertificate.moduleName,
      thumbnail: this.s3BucketUrl + this.thumbnails + cmeCertificate.congressThumbnail,
      topicCategory: cmeCertificate.topics.map(topic => topic.title),
      city: '',
      language: '',
      congressLinkActive: false,
      link: '',
      cmeActive: false,
      congressMode: Mode.Live,
      requiresRegistration: false,
      sessionTimeslots: [],
      congressName: cmeCertificate.congressName,
      certificateUrl: cmeCertificate.certificateUrl,
      blacklistedUntil: cmeCertificate.blacklistedUntil
    };
  }

  goToPage(number: number) {
    this.currentPage$.next(number);
  }

  openCertificate(certificateUrl: string) {
    window.open(certificateUrl, '_blank');
  }

  private mapUpcomingEventsData(upcomingEvents: UpcomingEventsResponseModel): UpcomingEventsResponseModel {
    return {
      events: upcomingEvents.events.filter(event =>
        this.sessionUtils.isAnySessionLiveWithoutInterval(event.sessionTimeslots)
      ),
      nextPage: upcomingEvents.nextPage
    };
  }
}
