import { Component, HostListener, Inject, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { Format, Mode, OnDemandVideoListItem, Session, Topic } from 'medtoday-models-library/lib/models';
import moment, { Moment } from 'moment';
import { getVideoDurationAsString } from 'projects/todaylib/shared/utilities/video.utils';
import { combineLatest } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import { imagePaths } from '../../../../../../medtoday/src/environments/environment';
import {
  DataApiActionTypes,
  LoadOnDemandVideos,
  PurgeOnDemandVideosList
} from '../../../../../core/data/actions/data-api.actions';
import {
  getCongressMetaData,
  getOnDemandVideosData,
  getOnDemandVideoTopicIdFilter,
  getSelectedOnDemandVideo
} from '../../../../../core/data/selectors/data-api.selectors';
import { GoToOnDemandVideo } from '../../../../../core/router/actions/main-navigation.actions';
import { getCongressSlugRouterParam } from '../../../../../core/router/selectors/router.selectors';
import { BaseAppState } from '../../../../../core/store/reducers';
import { FilterComponent } from '../../../../components/filter/filter.component';
import { ObservableComponent } from '../../../../components/observable/observable.component';
import { VideoListItem } from '../../../../components/video-list-item/video-list-item.component';
import { hasSrolled } from '../../../../utilities/window.utils';
import { ProgramFilters } from '../../programme-page/program-filters';
import { Filter } from '../../../../modules/filters/classes/filter';
import { FilterOption } from '../../../../modules/filters/models/filter-option.model';
import { FilterContainer } from '../../../../modules/filters/classes/filter-container';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { OnDemandVideoListResponse } from 'medtoday-models-library';
import { AnalyticsService } from '../../../../../core/data/services/ga-analytics.service';

export interface TimeGroup {
  timeSlot: string;
  videos: VideoListItem[];
}

@Component({
  selector: 'app-videos-on-demand-page',
  templateUrl: './videos-on-demand-page.component.html',
  styleUrls: ['./videos-on-demand-page.component.scss']
})
export class VideosOnDemandPageComponent extends ObservableComponent implements OnInit, OnDestroy {
  showFilterBackground = false;

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    @Inject('applicationName') public applicationName: string,
    private store: Store<BaseAppState>,
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  @ViewChildren('filter') filters: QueryList<FilterComponent>;

  readonly loadOnDemandVideosAsyncKey = DataApiActionTypes.LoadOnDemandVideos;
  readonly loadOnDemandVideoAsyncKey = DataApiActionTypes.LoadOnDemandVideo;
  readonly NOT_ASSIGNED = 'NotAssigned';
  thumbnailsImageFolderSlug = imagePaths.thumbnails;
  logosFolderSlug = imagePaths.logos;

  congressSlug: string;
  filteredVideos: VideoListItem[];
  formats: Format[] = [];

  stateOfTheArtVideos: VideoListItem[];

  timeGroups: TimeGroup[];

  congressMetaData$ = this.store.select(getCongressMetaData);
  onDemandVideosData$ = this.store.select(getOnDemandVideosData);
  onDemandVideo$ = this.store.select(getSelectedOnDemandVideo);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  topicIdFilter$ = this.store.select(getOnDemandVideoTopicIdFilter);
  filterContainer = new FilterContainer({
    videosFilter: new Filter<OnDemandVideoListItem>()
  });
  selectedDays: FilterOption<Session | OnDemandVideoListItem>[] = [];
  selectedTopics: FilterOption<Session | OnDemandVideoListItem>[] = [];
  selectedLanguages: FilterOption<Session | OnDemandVideoListItem>[] = [];
  selectedFormats: FilterOption<Session | OnDemandVideoListItem>[] = [];

  get videosFilter() {
    return this.filterContainer.filters['videosFilter'];
  }

  @HostListener('window:scroll', []) hasScrolled() {
    if (window.pageYOffset > 180 && window.outerWidth < 575) {
      this.showFilterBackground = true;
    } else {
      this.showFilterBackground = false;
    }
    return hasSrolled();
  }

  ngOnInit(): void {
    this.observeCongressSlug();
    this.observeFormats();
    this.loadVideoOnDemandData();
    this.initializeVideos();
    this.initFilters();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.filteredVideos = [];
    this.formats = [];
    this.stateOfTheArtVideos = [];
    this.timeGroups = [];
    this.filterContainer.destroy();
    this.store.dispatch(new PurgeOnDemandVideosList());
  }

  observeCongressSlug() {
    this.congressSlug$
      .pipe(
        tap(slug => (this.congressSlug = slug)),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  observeFormats() {
    combineLatest([this.congressMetaData$, this.onDemandVideosData$])
      .pipe(
        filter(([congressMetaData, onDemandVideosData]) => !!(congressMetaData && onDemandVideosData?.formats?.length)),
        tap(([congressMetaData, onDemandVideosData]) => {
          this.formats = [];
          onDemandVideosData.formats.forEach((fmt: Format) => {
            const isAnyVideoAvailableForFormat = onDemandVideosData.onDemandVideos.some((vd: OnDemandVideoListItem) => {
              return this.isVideoVisibleInOverview(vd, congressMetaData.mode) && vd?.formatId === fmt.id;
            });
            if (isAnyVideoAvailableForFormat) {
              this.formats.push(fmt);
            }
          });
          this.formats.sort((a, b) => a.title.localeCompare(b.title));
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  initializeVideos() {
    combineLatest([this.congressMetaData$, this.videosFilter.filteredData$])
      .pipe(
        filter(([congressMetaData, filteredVideos]) => !!congressMetaData && !!filteredVideos),
        tap(([congressMetaData, filteredVideos]) => {
          this.timeGroups = [];
          const timeSlots: string[] = [];
          const commonTimeSlots: string[] = [];
          this.stateOfTheArtVideos = [];
          filteredVideos?.forEach((vd: OnDemandVideoListItem) => {
            timeSlots.push(vd.timeSlotStart || this.NOT_ASSIGNED);
            if (vd.formatId === this.getFormatByName('State of the Art', this.formats)?.id) {
              this.stateOfTheArtVideos.push(this.makeVideoListViewItem(vd));
            }
          });

          timeSlots.forEach((slot: string) => {
            const timeSlotFromSameDay = timeSlots.find((slt: string) => moment(slt).isSame(slot, 'D'));
            if (!commonTimeSlots.includes(timeSlotFromSameDay || this.NOT_ASSIGNED)) {
              commonTimeSlots.push(slot);
            }
          });

          this.createTimeGroups(commonTimeSlots, filteredVideos, congressMetaData.mode);
          this.timeGroups.sort((tg1, tg2) => tg1.timeSlot.localeCompare(tg2.timeSlot));
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  createTimeGroups(timeSlots: string[], onDemandVideos: OnDemandVideoListItem[], congressMode: Mode) {
    timeSlots.forEach((time: string) => {
      const videos: VideoListItem[] = [];
      onDemandVideos.forEach((vd: OnDemandVideoListItem) => {
        if (
          this.isVideoVisibleInOverview(vd, congressMode) &&
          ((!vd.timeSlotStart && time === this.NOT_ASSIGNED) || moment(vd.timeSlotStart).isSame(time, 'D')) &&
          vd.formatId !== this.getFormatByName('State of the Art', this.formats)?.id
        ) {
          const videoItem = this.makeVideoListViewItem(vd);
          videos.push(videoItem);
        }
      });

      const videoGroup = {
        timeSlot: time,
        videos: videos
      };

      this.timeGroups.push(videoGroup);
    });
  }

  isVideoVisibleInOverview(video: OnDemandVideoListItem, congressMode: Mode) {
    const isLiveCongress = congressMode === Mode.Live;
    const now = moment();
    // check if it is a live congress or if the video was posted before 10 p.m. today
    return isLiveCongress || now.isAfter(moment(video.timeSlotStart).set('h', 22).set('m', 0));
  }

  makeVideoListViewItem(vd: OnDemandVideoListItem) {
    const videoItem: VideoListItem = {
      ...vd,
      experts: vd.experts,
      duration: getVideoDurationAsString(vd.duration),
      mediaIdentifier: vd.mediaIdentifier
    };
    return videoItem;
  }

  resetSelection() {
    this.initializeVideos();
  }

  loadVideoOnDemandData() {
    this.store.dispatch(new LoadOnDemandVideos(this.congressSlug));
  }

  handleGoToVideoClick(video: VideoListItem) {
    // if only 1 selected then pass arg
    const languagesSelected = this.videosFilter.getSelectedFilterOptions('language');
    const language = languagesSelected?.length === 1 ? languagesSelected[0].source : undefined;
    this.store.dispatch(new GoToOnDemandVideo(video.id, false, language, this.congressSlug));
  }

  getTopicById(topicId: number, topics: Topic[]) {
    return topics.find((t: Topic) => t.id === topicId);
  }

  getFormatById(formatId: number, formats: Format[]) {
    return formats.find((f: Format) => f.id === formatId);
  }

  getFormatByName(formatName: string, formats: Format[]) {
    return formats.find((f: Format) => f.title === formatName);
  }

  private initFilters() {
    combineLatest([this.onDemandVideosData$, this.congressMetaData$])
      .pipe(
        filter(([onDemandVideosData, metadata]) => !!onDemandVideosData && !!metadata),
        take(1)
      )
      .subscribe(([onDemandVideosData, metadata]) => {
        ProgramFilters.initVideosFilter(
          onDemandVideosData,
          this.videosFilter,
          this.getProgrammeDays(onDemandVideosData),
          metadata.filterOptions['vod']
        );
        this.setFiltersFromQuery();
      });
  }

  handleSelectFilter(filterName: string, filterOption: FilterOption<any>) {
    this.videosFilter.applyFilter(filterName, filterOption);
    this.refreshSelectedOptions(filterName);
  }

  private refreshSelectedOptions(filterName: string) {
    switch (filterName) {
      case 'day':
        this.selectedDays = this.videosFilter.getSelectedFilterOptions('day');
        break;
      case 'topic':
        this.selectedTopics = this.videosFilter.getSelectedFilterOptions('topic');
        break;
      case 'language':
        this.selectedLanguages = this.videosFilter.getSelectedFilterOptions('language');
        if (this.selectedLanguages?.length) {
          this.analyticsService.pushTag(
            {},
            'video-language',
            'location',
            this.selectedLanguages.map(lang => lang.source).join(',')
          );
        }
        break;
      case 'format':
        this.selectedFormats = this.videosFilter.getSelectedFilterOptions('format');
        break;
    }

    this.filterContainer.updateFilterQueryParams(this.router, this.location, ['topic', 'language', 'format']);
  }

  resetFilter(filterName: string) {
    this.videosFilter.resetFilter(filterName);
    this.refreshSelectedOptions(filterName);
  }

  private setFiltersFromQuery() {
    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      for (const filterName in params) {
        const filterOptions = params[filterName].split(',');
        filterOptions.forEach(filterOption => {
          const matchedFilterOption = this.videosFilter
            .getFilterOptions(filterName)
            .find(option => option.id == filterOption);
          if (matchedFilterOption) {
            this.handleSelectFilter(filterName, matchedFilterOption);
          }
        });
      }
    });
  }

  private getProgrammeDays(onDemandVideosData: OnDemandVideoListResponse) {
    return onDemandVideosData.onDemandVideos
      .map(vod => moment.utc(vod.timeSlotStart))
      .reduce((acc: Moment[], current) => {
        if (!acc.some(time => time.isSame(current, 'd'))) {
          acc.push(current);
        }
        return acc;
      }, []);
  }
}
