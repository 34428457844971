import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PartnerBox } from 'medtoday-models-library';

@Component({
  selector: 'app-partner-network-box',
  templateUrl: './partner-network-box.component.html',
  styleUrls: ['./partner-network-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartnerNetworkBoxComponent {
  @Input() partnerBox: PartnerBox;
}
