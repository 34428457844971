import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringToAcronym',
})

export class StringToAcronymPipe implements PipeTransform {
    transform(fullName: string): string {
        return fullName
            .split(' ')
            .map(n => n[0])
            .join('');
    }
}
