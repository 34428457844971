<div class="accordion-card">
  <button
    class="btn collapsed px-3 py-3"
    [class.collapsed]="!defaultShow"
    data-toggle="collapse"
    [attr.data-target]="'#' + accordionName + id"
    aria-expanded="false"
    [attr.aria-controls]="accordionName"
  >
    {{ title }}
    <div class="indicator"></div>
  </button>
  <div [id]="accordionName + id" class="collapse px-3" [class.show]="defaultShow" data-parent="#accordion">
    <div class="divider pb-3"></div>
    <div class="row p-3">
      <div class="col-12 col-lg-7 mb-4 mb-lg-0" *ngIf="video">
        <div class="video-container">
          <video class="w-100 video" controls="true" [src]="videoSrc" [poster]="videoPoster"></video>
        </div>
      </div>
      <div [ngClass]="video ? 'col-12 col-lg-5' : 'col-12'">
        <h5 *ngIf="optionalHeading">{{ optionalHeading }}</h5>
        <p>{{ text }}</p>
      </div>
    </div>
  </div>
</div>
