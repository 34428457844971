import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SignUpActionTypes, SignUpSuccess } from '../../auth/actions/auth-sign-up.actions';
import { AuthActionTypes, LoginSuccess } from '../../auth/actions/auth.actions';
import { tap } from 'rxjs/operators';
import { DataApiActionTypes, PushUserIdToGTM } from '../actions/data-api.actions';
import { AnalyticsService } from '../services/ga-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { getTranslatedOccupationActivity } from '../../../../medtoday/src/app/auth-ui/components/sign-up/sign-up-form/sign-up-form-definitions';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsEffects {
  pushAfterLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LoginSuccess),
        tap((action: LoginSuccess) => {
          this.analyticsService.pushTag({}, 'login', '', {
            userType: action.loginResponse!['attributes']['custom:occupationActivity'],
            userId: action.loginResponse!['attributes']['sub'],
            field_of_expertise: action.loginResponse!['attributes']['custom:topic']
          });
        })
      ),
    { dispatch: false }
  );

  pushAfterSignUp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignUpActionTypes.SignUpSuccess),
        tap((action: SignUpSuccess) => {
          const translatedOccupationActivity = getTranslatedOccupationActivity(
            action.request.occupationActivity,
            this.translateService
          );
          const translatedTopic = this.translateService.instant(action.request.topic);
          this.analyticsService.pushTag({}, 'new_registration', 'userType', translatedOccupationActivity);
          this.analyticsService.pushTag({}, 'new_registration_expertise', 'field_of_expertise', translatedTopic);
        })
      ),
    { dispatch: false }
  );

  pushUserIdOnTrackingAccept$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataApiActionTypes.PushUserIdToGTM),
        tap((action: PushUserIdToGTM) => {
          this.analyticsService.pushTag({}, 'acceptTracking', 'userId', action.userId);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService
  ) {}
}
