import { Action } from '@ngrx/store';
import { ExpertListItem } from 'medtoday-models-library/lib/models';

export enum ExpertsDetailsActionTypes {
  SetSelectedExpert = '[Experts Details] Set selected expert',
  PurgeSelectedExpert = '[Experts Details] Purge selected expert',
}

export class SetSelectedExpert implements Action {
  readonly type = ExpertsDetailsActionTypes.SetSelectedExpert;

  constructor(public expert: ExpertListItem) {}
}

export class PurgeSelectedExpert implements Action {
  readonly type = ExpertsDetailsActionTypes.PurgeSelectedExpert;
}

export type ExpertsDetailsActions =
| SetSelectedExpert
| PurgeSelectedExpert;
