import { Filter } from './filter';
import { FilterOption } from '../models/filter-option.model';
import { uniqBy } from 'lodash';
import { Params, Router } from '@angular/router';
import { Location } from '@angular/common';

export class FilterContainer {
  filters: { [key: string]: Filter<any> } = {};
  filterOptions;
  constructor(filters: { [key: string]: Filter<any> }) {
    this.filters = filters;
  }

  /**
   * Gets all filter options from each filter, returns prioritized properties with producesRecords to true
   * @param filterName
   */
  getFilterOptions(filterName: string) {
    let allFilterOptions: FilterOption<any>[] = [];
    for (const key in this.filters) {
      const filterOptions = this.filters[key].getFilterOptions(filterName);
      if (filterOptions?.length) {
        allFilterOptions = allFilterOptions.concat(filterOptions);
      }
    }

    allFilterOptions.forEach(filterOption => {
      const hasAnyOptionPredictedRecords = allFilterOptions.some(fo => fo.id === filterOption.id && fo.producesRecords);
      if (hasAnyOptionPredictedRecords) {
        filterOption.producesRecords = true;
      }
    });

    return uniqBy(allFilterOptions, 'id');
  }

  getSelectedFilterOptions(filterName: string) {
    let selectedFilterOptions: FilterOption<any>[] = [];
    for (const key in this.filters) {
      selectedFilterOptions = selectedFilterOptions.concat(this.filters[key].getSelectedFilterOptions(filterName));
    }
    return uniqBy(selectedFilterOptions, 'id');
  }

  reset(filterName: string) {
    for (const key in this.filters) {
      this.filters[key].resetFilter(filterName);
    }
  }

  applyFilter(filterName: string, filterOption: FilterOption<any>) {
    for (const key in this.filters) {
      this.filters[key].applyFilter(filterName, filterOption);
    }
  }

  destroy() {
    for (const key in this.filters) {
      this.filters[key].destroy();
    }
  }

  updateFilterQueryParams(router: Router, location: Location, filterNames: string[]) {
    const queryParams: Params = {};
    for (const filterName of filterNames) {
      queryParams[filterName] = this.getSelectedFilterOptions(filterName)
        .map(filterOption => filterOption.id)
        .join(',');
    }

    const tree = router.createUrlTree([], { queryParams: queryParams }).toString();
    location.go(tree);
  }
}
