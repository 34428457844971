<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="container text-center">
        <app-sign-up-form (signUp)="handleSignUpClick($event)"></app-sign-up-form>
      </div>
      <app-spacer></app-spacer>
    </div>
    <div class="d-none d-md-flex col-md-6 bg-dark-x2 features-legend-container">
      <div class="container">
        <div class="features-legend ml-4">
          <div class="d-flex">
            <span><i class="bi bi-check-circle icon-24 text-danger"></i></span>
            <span [innerHTML]="'SignUp.Legend1St' | translate"></span>
          </div>
          <div class="d-flex mt-4">
            <span><i class="bi bi-check-circle icon-24 text-danger"></i></span>
            <span [innerHTML]="'SignUp.Legend2nd' | translate"></span>
          </div>
          <div class="d-flex mt-4" *ngIf="currentLanguage === 'ch'">
            <span><i class="bi bi-check-circle icon-24 text-danger"></i></span>
            <span [innerHTML]="'SignUp.Legend3rd' | translate"></span>
          </div>
        </div>
      </div>
    </div>
    <app-spacer></app-spacer>
  </div>
</div>
<app-async-state-overlay [processKey]="asyncProcessKey"></app-async-state-overlay>
