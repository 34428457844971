import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';

import { ValidationError } from '../../../shared/models/response/error.model';

export enum AsyncStateActionTypes {
  SetAsyncStateStart = '[Async State Handler] Async Process Start',
  SetAsyncStateSuccess = '[Async State Handler] Async Process Success',
  SetAsyncStateFail = '[Async State Handler] Async Process Failure',
  SetAsyncStateReset = '[Async State Handler] Async Process Reset',
  SetAsyncStateRetry = '[Async State Handler] Async Process Retry',
  AsyncStateClear = '[Async State Handler] Async State Clear',
}

export class SetAsyncStateStart implements Action {
  readonly type = AsyncStateActionTypes.SetAsyncStateStart;
  constructor(public key: string, public initialAction: Action) {}
}

export class SetAsyncStateSuccess implements Action {
  readonly type = AsyncStateActionTypes.SetAsyncStateSuccess;
  constructor(public key: string) {}
}

export class SetAsyncStateFail implements Action {
  readonly type = AsyncStateActionTypes.SetAsyncStateFail;
  constructor(
    public key: string,
    public error: unknown,
    public httpError?: HttpErrorResponse,
    public errorTitle?: string,
    public errorMessage?: string,
    public validationErrors?: ValidationError[],
  ) {}
}

export class SetAsyncStateReset implements Action {
  readonly type = AsyncStateActionTypes.SetAsyncStateReset;
  constructor(public key: string) {}
}

export class SetAsyncStateRetry implements Action {
  readonly type = AsyncStateActionTypes.SetAsyncStateRetry;
  constructor(public key: string) {}
}

export class AsyncStateClear implements Action {
  readonly type = AsyncStateActionTypes.AsyncStateClear;
  constructor() {}
}

export type AsyncStateActions = SetAsyncStateStart | SetAsyncStateSuccess | SetAsyncStateFail | SetAsyncStateReset | AsyncStateClear;
