import { Pipe, PipeTransform } from '@angular/core';
import { utc } from 'moment/moment';

@Pipe({
  name: 'daysUntil'
})
export class DaysUntil implements PipeTransform {
  transform(value: string | Date): number | string {
    if (value) {
      return utc(value).diff(utc(), 'd');
    }

    return value;
  }
}
