import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe implements PipeTransform {
  transform(value: number): any {
    return moment.utc(1000 * value).format('mm:ss');
  }
}
