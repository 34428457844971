import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  ArchivedCongressListItem,
  EventCategory,
  EventListItem,
  Format,
  LandingPageVideoListItem,
  MedicalEducationResponse,
  TeaserCardDesigns,
  Topic,
  TopicCategory,
  InternationalContent
} from 'medtoday-models-library/lib/models';
import { PremiumBox } from 'medtoday-models-library/lib/models/premium-box.model';
import { imagePaths } from 'projects/medtoday/src/environments/environment';
import { MedicalEducationApiActionTypes } from 'projects/medtoday/src/app/medtoday-store/actions/medical-education-api.actions';
import { GoToCMEContent, GoToOnDemandVideo } from 'projects/todaylib/core/router/actions/main-navigation.actions';
import { MAIN_NAVIGATION_PATH_ELEMENTS } from 'projects/todaylib/core/router/definitions/main-navigation.definitions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { ObservableComponent } from 'projects/todaylib/shared/components/observable/observable.component';
import { CarouselItem, LargeCarouselItem } from 'projects/todaylib/shared/models/carousel-item.model';
import { filter, skipWhile, take, takeUntil, tap } from 'rxjs/operators';
import {
  LoadFilteredOnDemandVideos,
  LoadMedicalEducationData
} from '../../../medtoday-store/actions/medical-education-api.actions';
import { ExpertsPipe } from '../../../../../../todaylib/shared/pipes/experts.pipe';
import { getVideoDurationAsString } from '../../../../../../todaylib/shared/utilities/video.utils';
import { VideoListItem } from '../../../../../../todaylib/shared/components/video-list-item/video-list-item.component';
import { DataApiActionTypes } from 'projects/todaylib/core/data/actions/data-api.actions';
import { TranslateService } from '@ngx-translate/core';
import {
  getMedicalEducationData,
  getMedicalEducationOnDemandVideosNextPage,
  getOnDemandVideosForMedicalEducation
} from '../../../medtoday-store/selectors/medtoday-store.selectors';
import { getSelectedOnDemandVideo } from 'projects/todaylib/core/data/selectors/data-api.selectors';

@Component({
  selector: 'app-medical-education-page',
  templateUrl: './medical-education-page.component.html',
  styleUrls: ['./medical-education-page.component.scss']
})
export class MedicalEducationPageComponent extends ObservableComponent implements OnInit {
  readonly medicalEducationAsynKey = MedicalEducationApiActionTypes.LoadMedicalEducationData;
  readonly onDemandVideosAsyncKey = MedicalEducationApiActionTypes.LoadFilteredOnDemandVideos;
  readonly onDemandVideoAsyncKey = DataApiActionTypes.LoadOnDemandVideo;
  readonly thumbnailPathUrl = imagePaths.thumbnails;

  videos: VideoListItem[];
  topicCategories: TopicCategory[];
  internationalContents: InternationalContent[];
  topics: Topic[];
  formats: Format[];
  onDemandVideos: VideoListItem[];
  remoteArchivedCongresses: ArchivedCongressListItem[] = [];
  displayedCongressCarouselItems: ArchivedCongressListItem[] = [];
  highlightedOnDemandVideos: CarouselItem[] = [];

  upcomingEventCarouselItems: LargeCarouselItem[] = [];
  liveCmeEventCarouselItems: LargeCarouselItem[] = [];
  mostWatchedVideosCarouselItems: CarouselItem[] = [];

  congressModes: EventCategory[];

  premiumBox: PremiumBox;

  currentLang = this.translateService.currentLang;
  congressSlider;
  videosExists = false;
  medicalEducationData$ = this.store.select(getMedicalEducationData);
  videos$ = this.store.select(getOnDemandVideosForMedicalEducation);
  onDemandVideo$ = this.store.select(getSelectedOnDemandVideo);
  nextVideosPage$ = this.store.select(getMedicalEducationOnDemandVideosNextPage);

  filterForm: FormGroup = new FormGroup({
    selectedTopicCategory: new FormControl(),
    selectedTopic: new FormControl(),
    selectedCongressMode: new FormControl(),
    selectedCongressCategory: new FormControl(),
    selectedFormat: new FormControl()
  });

  thumbnailPath = imagePaths.thumbnails;
  logoPathUrl = imagePaths.logos;
  MAIN_NAVIGATION_PATH_ELEMENTS = MAIN_NAVIGATION_PATH_ELEMENTS;
  TeaserCardDesigns = TeaserCardDesigns;
  showFilterFormMobile = false;

  constructor(
    private store: Store<BaseAppState>,
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    private expertsPipe: ExpertsPipe,
    @Inject('applicationName') public applicationName,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new LoadMedicalEducationData());
    this.store.dispatch(
      new LoadFilteredOnDemandVideos(
        1,
        this.filterForm.get('selectedTopicCategory')?.value,
        this.filterForm.get('selectedTopic')?.value,
        this.getCongressModeTitle(this.filterForm.get('selectedCongressMode')?.value),
        this.filterForm.get('selectedFormat')?.value
      )
    );

    this.initializeMedicalEducationData();
    this.buildCarouselItems();
    this.initializeVideos();
  }

  loadOnDemandVideos(requestedPageNumber: number, isSubsequentRequest: boolean) {
    this.store.dispatch(
      new LoadFilteredOnDemandVideos(
        requestedPageNumber,
        this.filterForm.get('selectedTopicCategory')?.value,
        this.filterForm.get('selectedTopic')?.value,
        this.getCongressModeTitle(this.filterForm.get('selectedCongressMode')?.value),
        this.filterForm.get('selectedFormat')?.value,
        isSubsequentRequest
      )
    );
  }

  buildCarouselItems() {
    this.medicalEducationData$
      .pipe(
        filter((data: MedicalEducationResponse) => Boolean(data)),
        tap((data: MedicalEducationResponse) => {
          if (data.liveCmeEvents?.length) {
            this.collectLiveCmeEventCarouselItems(data.liveCmeEvents);
          }

          if (data.upcomingEvents?.length) {
            this.collectUpcomingEventCarouselItems(data.upcomingEvents);
          }

          if (data.highlightedOnDemandVideos?.length) {
            this.collectHighlightedOnDemandVideos(data.highlightedOnDemandVideos);
          }

          if (data.mostWatchedVideos?.length) {
            this.collectMostWatchedVideos(data.mostWatchedVideos);
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  initializeMedicalEducationData() {
    this.medicalEducationData$
      .pipe(
        filter((data: MedicalEducationResponse) => Boolean(data)),
        tap((data: MedicalEducationResponse) => {
          if (data.topics?.length > 0) {
            this.topics = data.topics;
          }

          if (data.formats?.length > 0) {
            this.formats = data.formats;
          }

          if (data.topicCategories?.length > 0) {
            this.topicCategories = data.topicCategories;
          }

          if (data.internationalContents?.length > 0) {
            this.internationalContents = data.internationalContents;
          }

          if (data.congressModes?.length > 0) {
            this.congressModes = data.congressModes;
          }

          if (data.premiumBox) {
            this.premiumBox = data.premiumBox;
          }

          if (data.archivedCongresses) {
            this.displayedCongressCarouselItems = data.archivedCongresses;
          }

          if (data.pageItems) {
            this.congressSlider = data.pageItems.find(item => item.name === 'Kongress Slider');
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  initializeVideos() {
    this.onDemandVideos = [];
    this.videos$
      .pipe(
        skipWhile(videos => videos === undefined),
        take(1),
        tap(videos => (this.videosExists = !!videos?.length))
      )
      .subscribe();

    this.videos$
      .pipe(
        filter((videos: LandingPageVideoListItem[]) => Boolean(videos)),
        tap((videos: LandingPageVideoListItem[]) => {
          this.onDemandVideos = [];
          this.onDemandVideos = videos.map((video: LandingPageVideoListItem) => {
            return {
              ...video,
              duration: getVideoDurationAsString(video.duration),
              congressShortcut: video.congressShortcut ?? null,
              congressStartsAt: video.congressStartsAt ?? null,
              congressSlug: video.congressSlug,
              languages: [],
              spokenLanguage: null,
              mediaProvider: video.mediaProvider,
              subVideoType: video.subVideoType ?? null
            };
          });
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  handleGoToVideoClick(video: VideoListItem) {
    this.store.dispatch(new GoToOnDemandVideo(video.id, false, undefined, video.congressSlug!));
  }

  handleLoadMoreVideosClick() {
    const index = this.onDemandVideos.length - 1;
    this.nextVideosPage$
      .pipe(
        take(1),
        tap((pageNumber: number) => {
          return this.loadOnDemandVideos(pageNumber, true);
        })
      )
      .subscribe();
    document.querySelector(`#video-${index}`)?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  handleSelectTopicCategory(topicCategoryId: number) {
    this.handleResetFilter('selectedTopic');
    this.filterForm.get('selectedTopicCategory')?.setValue(topicCategoryId);
    this.loadVideosForPageNumber();
  }

  handleSelectTopic(topicId: number) {
    this.filterForm.get('selectedTopic')?.setValue(topicId);
    this.loadVideosForPageNumber();
  }

  handleSelectCongressMode(congressMode: string) {
    this.filterForm.get('selectedCongressMode')?.setValue(congressMode);
    this.loadVideosForPageNumber();
  }

  handleSelectFormat(formatId: number) {
    this.filterForm.get('selectedFormat')?.setValue(formatId);
    this.loadVideosForPageNumber();
  }

  loadVideosForPageNumber() {
    this.store.dispatch(
      new LoadFilteredOnDemandVideos(
        1,
        this.filterForm.get('selectedTopicCategory')?.value,
        this.filterForm.get('selectedTopic')?.value,
        this.getCongressModeTitle(this.filterForm.get('selectedCongressMode')?.value),
        this.filterForm.get('selectedFormat')?.value
      )
    );
  }

  getSelectedTopicCategory(topicCategoryId: number) {
    return this.topicCategories?.find((tC: TopicCategory) => tC.id === topicCategoryId);
  }

  getSelectedTopic(topicId: number) {
    return this.topics?.find((tp: Topic) => tp.id === topicId);
  }

  getSelectedCongressMode(congressMode: string) {
    return this.congressModes?.find((eC: EventCategory) => eC.id === congressMode);
  }

  getSelectedFormat(formatId: number) {
    return this.formats?.find((ft: Format) => ft.id === formatId);
  }

  getSelectableTopics(selectedTopicCategoryId: Number) {
    return this.topics?.filter((tp: Topic) => tp.topicCategoryId === selectedTopicCategoryId);
  }

  handleResetFilter(filterName: string) {
    this.filterForm.get(`${filterName}`)?.reset();
    this.loadVideosForPageNumber();
  }

  private collectLiveCmeEventCarouselItems(liveCmeEvents: EventListItem[]) {
    this.liveCmeEventCarouselItems = liveCmeEvents.map((item: EventListItem) => {
      return {
        ...item,
        congressFeedHeader: item.congressFeedHeader,
        congressFeedDescription: item.congressFeedDescription,
        eventType: item.congressMode,
        start: item.start,
        finish: item.finish,
        additionalHeader: 'Live CME Punkte sammeln!'
      } as LargeCarouselItem;
    });
  }

  private collectUpcomingEventCarouselItems(upcomingEvents: EventListItem[]) {
    this.upcomingEventCarouselItems = upcomingEvents.map((item: EventListItem) => {
      return {
        ...item,
        congressFeedHeader: item.congressFeedHeader,
        congressFeedDescription: item.congressFeedDescription,
        eventType: item.congressMode,
        start: item.start,
        finish: item.finish,
        showLinkButton: item.showCongressLinkButton
      } as LargeCarouselItem;
    });
  }

  private collectHighlightedOnDemandVideos(highlightedOnDemandVideos: LandingPageVideoListItem[]) {
    this.highlightedOnDemandVideos = highlightedOnDemandVideos.map((item: LandingPageVideoListItem) => {
      return {
        id: item.id,
        thumbnail: item.thumbnail,
        label: item.congressShortcut ? item.congressShortcut : item.seriesName!,
        url: item.congressSlug ? item.congressSlug : undefined,
        isExternalUrl: undefined,
        title: item.title ? item.title : item.topicName,
        subTitle: this.expertsPipe.transform(item.experts),
        viewsCount: item.viewCount ? item.viewCount : undefined,
        labelColor: item.congressColor ? item.congressColor : undefined,
        topic: item.topicName,
        format: item.formatId ? this.getSelectedFormat(item.formatId)?.title : undefined,
        additionalData: {
          mediaIdentifier: item.mediaIdentifier
        }
      };
    });
  }

  private collectMostWatchedVideos(mostWatchedVideos: LandingPageVideoListItem[]) {
    this.mostWatchedVideosCarouselItems = mostWatchedVideos.map((vd: LandingPageVideoListItem) => {
      const item: CarouselItem = {
        id: vd.id,
        thumbnail: vd.thumbnail,
        label: vd.congressShortcut ? vd.congressShortcut : vd.seriesName!,
        url: vd.congressSlug ? vd.congressSlug : undefined,
        isExternalUrl: undefined,
        title: vd.title ? vd.title : vd.topicName,
        subTitle: this.expertsPipe.transform(vd.experts, 3),
        viewsCount: vd.viewCount ? vd.viewCount : undefined,
        labelColor: vd.congressColor ? vd.congressColor : undefined,
        format: vd.formatId ? this.getSelectedFormat(vd.formatId)?.title : undefined,
        topic: vd.topicName ? vd.topicName : undefined,
        additionalData: {
          mediaIdentifier: vd.mediaIdentifier
        }
      };

      return item;
    });
  }

  private getCongressModeTitle(value: string | undefined) {
    if (!value) {
      return '';
    }

    return this.congressModes.find(congressMode => congressMode.id === value)!.title;
  }

  showFilterForm() {
    const filterFormDom = document.querySelector('.filter-form');
    const filterBtn = document.querySelector('.filter-btn');

    if (!this.showFilterFormMobile) {
      filterFormDom?.setAttribute('style', 'height: 300px; overflow: visible');
      filterBtn?.setAttribute('style', 'background: #dc2061');
      this.showFilterFormMobile = true;
    } else {
      filterFormDom?.removeAttribute('style');
      filterBtn?.removeAttribute('style');
      this.showFilterFormMobile = false;
    }
  }

  carouselItemClickCallback(congressSlug: string) {
    this.store.dispatch(new GoToCMEContent(congressSlug));
  }
}
