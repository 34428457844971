<div class="video-wrapper d-flex">
  <video-thumbnail
    [video]="video"
    [mediaProvider]="mediaProvider$ | async"
    (goToVideo)="handleGoToVideoClick()"
  ></video-thumbnail>
  <div class="d-flex flex-column video-details">
    <a (click)="handleGoToVideoClick()">
      <h6 *ngIf="video.title !== topicTitle" class="video-title">{{ video.title }}</h6>
    </a>
    <div class="d-flex flex-wrap">
      <span
        class="p-2 badge font-weight-light mr-1 mb-1"
        [class.badge-dark]="applicationName === 'MedToday'"
        [class.badge-light]="applicationName === 'PatientsToday'"
        *ngIf="topicTitle"
      >
        {{ topicTitle }}
      </span>
      <span
        class="p-2 badge font-weight-light mr-1 mb-1"
        [class.badge-dark]="applicationName === 'MedToday'"
        [class.badge-light]="applicationName === 'PatientsToday'"
        *ngIf="formatTitle"
      >
        {{ formatTitle }}
      </span>
      <span
        class="p-2 badge font-weight-light mr-1 mb-1"
        [class.badge-dark]="applicationName === 'MedToday'"
        [class.badge-light]="applicationName === 'PatientsToday'"
        *ngIf="video.spokenLanguage"
      >
        {{ 'VideoOnDemand.' + video.spokenLanguage | translate }}
      </span>
      <span
        class="p-2 badge font-weight-light mr-1 mb-1"
        [class.badge-dark]="applicationName === 'MedToday'"
        [class.badge-light]="applicationName === 'PatientsToday'"
        *ngIf="video.languages?.length"
      >
        {{ 'VideoOnDemand.Subtitles' | translate }}
      </span>
      <ng-container
        *ngIf="translateService.currentLang !== 'ch' && !(congressMetadata$ | async)?.hideSponsorInMediaListing"
      >
        <span *ngFor="let sponsor of video.sponsors" class="p-2 badge badge-secondary font-weight-light mr-1 mb-1">
          {{ sponsor.name }}
        </span>
      </ng-container>
    </div>
    <p>
      <small *ifApp="'MedToday'">{{ video.experts | experts }}</small>
      <small class="text-dark" *ifApp="'PatientsToday'">{{ video.experts | patientsExperts }}</small>
    </p>
  </div>
</div>
