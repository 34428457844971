import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  UpcomingEventsCountriesResponseModel,
  UpcomingEventsDatesResponseModel,
  UpcomingEventsResponseModel,
  UpcomingEventsTopicLanguagesResponseModel
} from 'medtoday-models-library';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { AsyncAction } from '../../async-state/models/async-action.model';
import { handleAsyncErrors } from '../../async-state/operators/handle-errors';
import {
  LoadUpcomingEventsCountries,
  LoadUpcomingEventsCountriesFail,
  LoadUpcomingEventsCountriesSuccess,
  LoadUpcomingEventsDates,
  LoadUpcomingEventsDatesFail,
  LoadUpcomingEventsDatesSuccess,
  LoadUpcomingEventsPageData,
  LoadUpcomingEventsPageDataFail,
  LoadUpcomingEventsPageDataSuccess,
  LoadUpcomingEventsTopicCategories,
  LoadUpcomingEventsTopicCategoriesFail,
  LoadUpcomingEventsTopicCategoriesSuccess,
  UpcomingEventsApiActionTypes
} from '../actions/upcoming-events-api.actions';
import { DataApiService } from '../services/data-api.service';

@Injectable({
  providedIn: 'root'
})
export class UpcomingEventsApiEffects {
  loadUpcomingEventsPageData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpcomingEventsApiActionTypes.LoadUpcomingEventsPageData),
        mergeMap((action: LoadUpcomingEventsPageData) =>
          this.api
            .getUpcomingEventsPageData(
              action.page,
              action.topicCategory,
              action.year,
              action.month,
              action.language,
              action.limit,
              action.onlyCme,
              action.onlyLive
            )
            .pipe(
              handleAsyncErrors(() => new LoadUpcomingEventsPageDataFail()),
              map((response: UpcomingEventsResponseModel) => new LoadUpcomingEventsPageDataSuccess(response)),
              catchError((errorAction: AsyncAction) => of(errorAction))
            )
        )
      ),
    { dispatch: true }
  );

  loadUpcomingEventsTopicCategories$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpcomingEventsApiActionTypes.LoadUpcomingEventsTopicCategories),
        mergeMap((_action: LoadUpcomingEventsTopicCategories) =>
          this.api.getUpcomingEventsTopicCategories().pipe(
            handleAsyncErrors(() => new LoadUpcomingEventsTopicCategoriesFail()),
            map(
              (response: UpcomingEventsTopicLanguagesResponseModel) =>
                new LoadUpcomingEventsTopicCategoriesSuccess(response)
            ),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadUpcomingEventsDates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UpcomingEventsApiActionTypes.LoadUpcomingEventsDates),
        mergeMap((_action: LoadUpcomingEventsDates) =>
          this.api.getUpcomingEventsDates().pipe(
            handleAsyncErrors(() => new LoadUpcomingEventsDatesFail()),
            map((response: UpcomingEventsDatesResponseModel) => new LoadUpcomingEventsDatesSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  loadUpcomingEventsCountries$ = createEffect(
    () =>
      // eslint-disable-next-line ngrx/prefer-effect-callback-in-block-statement
      this.actions$.pipe(
        ofType(UpcomingEventsApiActionTypes.LoadUpcomingEventsCountries),
        mergeMap((_action: LoadUpcomingEventsCountries) =>
          this.api.getUpcomingEventsCountries().pipe(
            handleAsyncErrors(() => new LoadUpcomingEventsCountriesFail()),
            map((response: UpcomingEventsCountriesResponseModel) => new LoadUpcomingEventsCountriesSuccess(response)),
            catchError((errorAction: AsyncAction) => of(errorAction))
          )
        )
      ),
    { dispatch: true }
  );

  constructor(private api: DataApiService, private actions$: Actions) {}
}
