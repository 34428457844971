import {
  CmeCertificatesResponse,
  CongressCmePageDataResponse,
  CongressParticipantResponseModel,
  DeeplinkModel,
  LandingPageVideoListItem,
  MedicalEducationResponse,
  OnDemandVideoUserData
} from 'medtoday-models-library';
import { MainNavigationActions } from 'projects/todaylib/core/router/actions/main-navigation.actions';

import { MedBoardsResponseModel } from '../../models/medboards.model';
import { MedtodayStoreActions, MedtodayStoreActionTypes } from '../actions/medtoday-store.actions';
import { MedBoardsApiActions, MedBoardsDataApiActionTypes } from '../actions/medboards-api.actions';
import { MedicalEducationApiActions, MedicalEducationApiActionTypes } from '../actions/medical-education-api.actions';

export interface MedtodayStoreState {
  congressCmePageData: CongressCmePageDataResponse | undefined;
  deeplink: DeeplinkModel | undefined;
  medicalEducation: {
    onDemandVideos: {
      results: LandingPageVideoListItem[] | undefined;
      nextPage: number | undefined;
    };
    data: MedicalEducationResponse | undefined;
  };
  congressParticipantData: CongressParticipantResponseModel | undefined;
  medBoardsData: MedBoardsResponseModel | undefined;
  onDemandVideoUserData: OnDemandVideoUserData | undefined;
  cmeCertificates: CmeCertificatesResponse | undefined;
  showCmeTrackingPromptModal: boolean;
}

const initialState: MedtodayStoreState = {
  congressCmePageData: undefined,
  deeplink: undefined,
  medicalEducation: {
    data: undefined,
    onDemandVideos: {
      results: undefined,
      nextPage: undefined
    }
  },
  congressParticipantData: undefined,
  medBoardsData: undefined,
  onDemandVideoUserData: undefined,
  cmeCertificates: undefined,
  showCmeTrackingPromptModal: false
};

export function reducer(
  state = initialState,
  action: MedtodayStoreActions | MainNavigationActions | MedicalEducationApiActions | MedBoardsApiActions
) {
  switch (action.type) {
    case MedtodayStoreActionTypes.LoadTokenizedChannelSuccess: {
      return {
        ...state,
        channelData: action.response.channel,
        deeplink: {
          utmSource: action.response.utmSource,
          utmCampaign: action.response.utmCampaign,
          utmMedium: action.response.utmMedium
        }
      };
    }

    case MedtodayStoreActionTypes.LoadTokenizedOnDemandVideoSuccess: {
      return {
        ...state,
        onDemandVideo: action.response.onDemandVideo,
        deeplink: {
          utmSource: action.response.utmSource,
          utmCampaign: action.response.utmCampaign,
          utmMedium: action.response.utmMedium
        }
      };
    }
    case MedtodayStoreActionTypes.LoadCongressCmePageDataSuccess: {
      return {
        ...state,
        congressCmePageData: action.response
      };
    }
    case MedtodayStoreActionTypes.LoadCongressParticipantDataSuccess: {
      return {
        ...state,
        congressParticipantData: action.response
      };
    }
    case MedicalEducationApiActionTypes.LoadMedicalEducationDataSuccess: {
      return {
        ...state,
        medicalEducation: {
          ...state.medicalEducation,
          data: action.response
        }
      };
    }
    case MedBoardsDataApiActionTypes.LoadMedBoardsDataSuccess: {
      return {
        ...state,
        medBoardsData: action.response
      };
    }
    case MedicalEducationApiActionTypes.LoadFilteredOnDemandVideos: {
      return {
        ...state,
        medicalEducation: {
          ...state.medicalEducation,
          onDemandVideos: {
            ...state.medicalEducation.onDemandVideos
          }
        }
      };
    }
    case MedicalEducationApiActionTypes.LoadFilteredOnDemandVideosSuccess: {
      return {
        ...state,
        medicalEducation: {
          ...state.medicalEducation,
          onDemandVideos: {
            ...state.medicalEducation.onDemandVideos,
            results: action.isSubsequentVideosRequest
              ? getNewVideoEntries(state.medicalEducation.onDemandVideos.results!, action.response.results)
              : action.response.results,
            nextPage: action.response.nextPage ? Number(action.response.nextPage) : 0
          }
        }
      };
    }
    case MedtodayStoreActionTypes.LoadOnDemandVideoUserDataSuccess:
    case MedtodayStoreActionTypes.SetVideoAsWatchedSuccess: {
      return {
        ...state,
        onDemandVideoUserData: action.userData
      };
    }
    case MedtodayStoreActionTypes.LoadCmeCertificatesSuccess: {
      return {
        ...state,
        cmeCertificates: action.response
      };
    }
    case MedtodayStoreActionTypes.PurgeUserCmeCertificates: {
      return {
        ...state,
        cmeCertificates: undefined
      };
    }
    case MedtodayStoreActionTypes.OpenCmeTrackingPromptModal: {
      return {
        ...state,
        showCmeTrackingPromptModal: true
      };
    }
    case MedtodayStoreActionTypes.CloseCmeTrackingPromptModal: {
      return {
        ...state,
        showCmeTrackingPromptModal: false
      };
    }
    default:
      return state;
  }
}
function getNewVideoEntries(existingEntries: LandingPageVideoListItem[], newEntries: LandingPageVideoListItem[]) {
  const videos: LandingPageVideoListItem[] = existingEntries?.length > 0 ? [...existingEntries] : [];

  if (newEntries?.length > 0) {
    newEntries.forEach((vd: LandingPageVideoListItem) => {
      if (!videos.find((existingVideo: LandingPageVideoListItem) => vd.id === existingVideo.id)) {
        videos.push(vd);
      }
    });
  } else {
    return videos;
  }

  return videos;
}
