import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { CongressMetadata, CongressParticipantRequestModel } from 'medtoday-models-library';
import { ResendSignUp } from 'projects/todaylib/core/auth/actions/auth-sign-up.actions';
import { AuthActionTypes, EditUserProperties, Login } from 'projects/todaylib/core/auth/actions/auth.actions';
import { isLoggedIn } from 'projects/todaylib/core/auth/selectors/auth.selectors';
import { AnalyticsService } from 'projects/todaylib/core/data/services/ga-analytics.service';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { ObservableComponent } from 'projects/todaylib/shared/components/observable/observable.component';
import { LoginRequest } from 'projects/todaylib/shared/models';
import { DynamicScriptLoaderService } from 'projects/todaylib/shared/services/dynamic-script-loader-service.service';

import {
  AddCongressParticipant,
  MedtodayStoreActionTypes
} from '../../../medtoday-store/actions/medtoday-store.actions';
import { LoginFormValue } from '../login/login-form/login-form-value.model';
import { UpdateUserRequest } from '../../../../../../todaylib/core/auth/services/auth.service';
import { CongressUtils } from 'projects/todaylib/shared/utilities/congress.utils';
import { ICalendar } from 'datebook';

// tslint:disable-next-line:no-any
declare const jQuery: any;

@Component({
  selector: 'app-event-register-modal',
  templateUrl: './event-register-modal.component.html',
  styleUrls: [
    './event-register-modal.component.scss',
    '../../../auth-ui/components/sign-up/sign-up-form/sign-up-form.component.scss'
  ],
  providers: [CongressUtils],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventRegisterModalComponent extends ObservableComponent implements OnDestroy {
  @Input() congressMetaData: CongressMetadata;
  @Input() user;
  @Output() registrationCompleteEmitter = new EventEmitter();

  registrationComplete = false;
  readonly asyncProcessKey = AuthActionTypes.Login;
  readonly addParticipantAsyncProcessKey = MedtodayStoreActionTypes.AddCongressParticipant;

  private congressParticipantModel: CongressParticipantRequestModel;
  userEmail: string;
  modalPaymentPage: string;

  isLoggedIn$ = this.store.select(isLoggedIn);

  form: FormGroup = new FormGroup({
    institution: new FormControl('', [Validators.required]),
    attendance: new FormControl('', [Validators.required])
  });

  constructor(
    protected store: Store<BaseAppState>,
    private analyticsService: AnalyticsService,
    private router: Router,
    private congressUtils: CongressUtils,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private cdr: ChangeDetectorRef,
    @Inject('payRexxDomain') private payRexxDomain: string
  ) {
    super();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.modalPaymentPage) {
      this.dynamicScriptLoader.removeScript('https://media.payrexx.com/modal/v1/modal.min.js');
    }
  }

  setModalPaymentPage(Id: string) {
    this.modalPaymentPage =
      `https://${this.payRexxDomain}.payrexx.com/pay?tid=${Id}` +
      `&custom_userSubId=${this.user.sub}` +
      `&custom_participationType=${this.congressParticipantModel?.participationType}` +
      `&custom_congressSlug=${this.congressMetaData.slug}` +
      `&contact_forename=${this.user.given_name}` +
      `&contact_surname=${this.user.name}` +
      `&contact_street=${this.user['custom:streetAddress']}` +
      `&contact_email=${this.user.email}`;
  }

  handleAddToCalendarClick(congressMetaData?: CongressMetadata) {
    const icalendar = new ICalendar(
      this.congressUtils.createCalendarEvent(this.congressUtils.createCalendarEventItem(congressMetaData, undefined))
    );
    icalendar.download();
  }

  loadPaymentModal() {
    this.dynamicScriptLoader.loadScript('https://media.payrexx.com/modal/v1/modal.min.js').then(() => {
      jQuery('.payrexx-modal-window').payrexxModal({
        hide: transaction => {
          if (transaction?.id) {
            this.congressParticipantModel.transactionId = transaction.id;
            this.addCongressParticipant(this.congressParticipantModel);
          }
        }
      });
    });
  }

  handleSelectAttendance(attendance: string) {
    switch (attendance) {
      case 'offline':
        return this.form.get('attendance')?.setValue('ON_SITE');
      case 'online':
        return this.form.get('attendance')?.setValue('ONLINE');
      default:
        break;
    }
  }

  handleSignUpClick(): void {
    this.signUpForEvent();
  }

  handleLoginClick(formValue: LoginFormValue): void {
    const request: LoginRequest = {
      email: formValue.emailAddress,
      password: formValue.password
    };

    this.userEmail = formValue.emailAddress;
    this.store.dispatch(new Login(request, this.router.url));
  }

  private showPaymentModal() {
    (document.querySelector('.payrexx-modal-window') as HTMLButtonElement).click();
  }

  private addCongressParticipant(congressParticipantRequestModel: CongressParticipantRequestModel) {
    this.store.dispatch(new AddCongressParticipant(this.congressMetaData.slug, congressParticipantRequestModel));

    this.analyticsService.pushTag(
      {},
      'add-congress-participant',
      'participationType',
      this.form.controls.attendance.value
    );
    this.analyticsService.pushTag({}, 'add-congress-participant', 'eventName', this.congressMetaData.name);
    this.analyticsService.pushTag({}, 'add-congress-participant', 'userId', this.user.sub);

    this.registrationComplete = true;
    this.registrationCompleteEmitter.emit();
  }

  resendSignUpCode(): void {
    this.store.dispatch(new ResendSignUp(this.userEmail));
  }

  private signUpForEvent() {
    if (
      !this.form.controls.attendance.valid ||
      (!this.user['custom:institution'] && !this.form.controls.institution.valid)
    ) {
      setTimeout(() => {
        const firstInvalidElement = document.getElementsByClassName('is-invalid')[0];
        if (firstInvalidElement) {
          firstInvalidElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }, 100);
      return;
    }

    if (this.form.controls.institution.value) {
      const request: UpdateUserRequest = {
        currentUser: this.user['email'],
        salutation: this.user['custom:salutation'] ? this.user['custom:salutation'] : null,
        efnNumber: this.user['custom:efnNumber'] ? this.user['custom:efnNumber'] : '',
        title: this.user['custom:title'] ? this.user['custom:title'] : '',
        name: this.user['given_name'],
        lastName: this.user['name'],
        streetAddress: this.user['custom:streetAddress'] ?? '',
        city: this.user['custom:city'] ?? '',
        zipCode: this.user['custom:zipCode'] ?? '',
        phoneNumber: this.user['custom:phoneNumber'] ?? '',
        topic: this.user['custom:topic'] ?? '',
        institution: this.form.controls.institution.value,
        hasAcceptedNewsletter: this.user['custom:hasNewsletter'],
        hasAcceptedPrivacyPolicy: this.user['custom:hasPrivacyPolicy'],
        sourceApp: this.user['custom:sourceApp'] ?? '',
        occupationActivity: this.user['custom:occupationActivity'] ?? ''
      };
      this.store.dispatch(new EditUserProperties(request));
    }

    const congressParticipantModel: CongressParticipantRequestModel = {
      userSubId: this.user.sub,
      participationType: this.form.controls.attendance.value
    };

    if (this.congressMetaData.congressPayable) {
      this.congressParticipantModel = congressParticipantModel;

      if (this.congressMetaData.paymentTemplateOnline && this.form.controls.attendance.value == 'ONLINE') {
        this.setModalPaymentPage(this.congressMetaData.paymentTemplateOnline);
      } else {
        this.setModalPaymentPage(this.congressMetaData.paymentTemplateId);
      }

      this.loadPaymentModal();

      this.cdr.detectChanges();
      setTimeout(() => {
        this.showPaymentModal();
      }, 500);
      return;
    } else {
      this.addCongressParticipant(congressParticipantModel);
    }
  }
}
