<ng-container *ngIf="congressLandingPageData$ | async as congressData">
  <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
    <div class="row my-0 my-md-3 mobile-full-width">
      <div class="col-12">
        <div class="header-card card shadow-lg h-100" [class.patients]="isPatientsTodayApp">
          <img
            *ngIf="!congressMetaData.layout.backgroundImage"
            class="card-image"
            src="../../../assets/images/Mobile-Kugel@2x.png"
          />
          <img
            *ngIf="congressMetaData.layout.backgroundImage"
            class="card-image"
            [src]="s3BucketUrl + congressMetaData.layout.backgroundImage"
          />
          <h1 class="hide-desktop show-mobile mb-4 headline" *ngIf="!congressMetaData.layout.logo">
            {{ congressData.title }}
          </h1>
          <div class="row h-100">
            <div class="col-lg-8 pt-lg-4 col-sm-12 col-12 d-flex align-items-center hide-mobile">
              <div
                [class.text-white]="congressMetaData.headerDarkTheme"
                class="p-5 d-flex flex-column w-100 h-100 justify-content-between"
              >
                <span>
                  <h1>{{ congressData.title }}</h1>
                  <h4 class="my-4">{{ congressTimeRange }}</h4>
                </span>
                <ng-container
                  *ngIf="
                    (isAnySessionLive$ | async) &&
                    registrationService.isUserAuthorized &&
                    congressMetaData.congressCategory !== 'ON_SITE'
                  "
                >
                  <button
                    class="btn btn-primary w-fit-content"
                    *ngIf="!liveSession?.zoomLink"
                    (click)="scrollToElement('#live')"
                  >
                    {{ 'Event.Stream' | translate }}
                    <i class="text-light pl-2" [inlineSVG]="'assets/icons/stream.svg'"></i>
                  </button>
                  <button
                    class="btn btn-primary w-fit-content"
                    *ngIf="(isMultiChannel$ | async) === false && liveSession?.zoomLink"
                    (click)="scrollToElement('#live')"
                  >
                    {{ 'ChannelPage.JoinZoomRoom' | translate }}
                    <i class="text-light pl-2" [inlineSVG]="'assets/icons/stream.svg'"></i>
                  </button>
                </ng-container>
                <ng-container>
                  <div class="d-flex">
                    <a
                      class="btn btn-primary w-fit-content"
                      href="#registerModal"
                      draggable="false"
                      *ngIf="showSignUpButton"
                      data-toggle="modal"
                      (click)="registrationService.handleShowRegisterModal(true)"
                      role="button"
                    >
                      {{ 'Auth.SignUp' | translate }}
                    </a>
                    <a
                      *ngIf="!congressMetaData.authorizedOnly"
                      class="btn btn-primary w-fit-content"
                      role="button"
                      (click)="handleGoToProgramClick()"
                    >
                      {{ 'Navigation.GoToProgramme' | translate }}
                    </a>
                    <ng-container *ngIf="isLoggedIn$ | async">
                      <div *ngIf="congressData.congressBag.active" class="d-flex mt-0 ml-3">
                        <button
                          class="btn d-flex text-white btn-{{ congressData.congressBag.buttonBackgroundColor }}"
                          (click)="handleGoToCongressBag(congressData.congressBag.buttonUrl)"
                        >
                          {{ congressData.congressBag.buttonLabel ?? 'Navigation.GoToCongressBag' | translate }}
                        </button>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <div
                  class="dropup"
                  *ngIf="
                    (isAnySessionLive$ | async) === false &&
                    congressUtils.isBeforeCongress(congressMetaData) &&
                    (registrationService.isUserAuthorized || isPatientsTodayApp)
                  "
                >
                  <button
                    class="btn w-fit-content"
                    [class.btn-patients-blue]="isPatientsTodayApp"
                    (click)="handleAddToCalendarClick(congressMetaData)"
                    [class.btn-primary]="isMedTodayApp"
                  >
                    {{ 'Event.AddToCalendar' | translate }}
                  </button>
                </div>
              </div>
            </div>
            <div
              class="col-lg-4 d-flex justify-content-center justify-content-lg-end align-items-end"
              *ngIf="congressMetaData.layout.logo"
            >
              <div class="p-lg-5 p-3 d-flex flex-column w-100 logo">
                <img [src]="s3BucketUrl + logosImageFolderSlug + congressMetaData.layout.logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
