<div data-overlay class="d-flex flex-column justify-content-md-center o-hidden">
  <section class="py-3">
    <div class="container">
      <div class="row justify-content-center mt-5">
        <div class="col-xl-4 col-lg-5 col-md-6 mb-5 mb-md-0">
          <h1 class="h5 text-center">{{ 'EditUser.ChangePassword' | translate }}</h1>
          <form [formGroup]="form" name="edit-password" (ngSubmit)="editPassword()" #ngForm="ngForm" novalidate>
            <div class="form-group">
              <label>{{ 'EditUser.CurrentPassword' | translate }}</label>
              <input
                type="password"
                autocomplete="current-password"
                class="form-control form-control-lg"
                name="current-password"
                placeholder="{{ 'EditUser.CurrentPassword' | translate }}"
                formControlName="currentPassword"
                [class.is-invalid]="ngForm.submitted && form.controls['currentPassword'].invalid"
              />
              <div class="invalid-feedback">
                <div *ngIf="form.controls['currentPassword'].hasError('required')">
                  {{ 'EditUser.CurrentPasswordRequired' | translate }}
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>{{ 'EditUser.NewPassword' | translate }}</label>
              <input
                type="password"
                class="form-control form-control-lg"
                name="new-password"
                placeholder="{{ 'EditUser.NewPassword' | translate }}"
                formControlName="password"
                [class.is-invalid]="ngForm.submitted && form.controls['password'].invalid"
              />
              <div class="invalid-feedback">
                <div *ngIf="form.controls['password'].hasError('required')">
                  {{ 'EditUser.NewPasswordRequired' | translate }}
                </div>
                <div *ngIf="form.controls['password'].hasError('minlength')">
                  {{ 'SignUp.PasswordMinLength' | translate }}
                </div>
                <div
                  *ngIf="
                    !form.controls['password'].hasError('minlength') && form.controls['password'].hasError('pattern')
                  "
                >
                  {{ 'SignUp.PasswordInvalidRules' | translate }}
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>{{ 'EditUser.ConfirmNewPassword' | translate }}</label>
              <input
                type="password"
                class="form-control form-control-lg"
                name="new-password-confirm"
                [placeholder]="'EditUser.ConfirmNewPassword' | translate"
                formControlName="passwordConfirmation"
                [class.is-invalid]="ngForm.submitted && form.controls['passwordConfirmation'].invalid"
              />
              <div class="invalid-feedback">
                <div *ngIf="form.controls['passwordConfirmation'].hasError('required')">
                  {{ 'SignUp.ConfirmPasswordRequired' | translate }}
                </div>
                <div
                  *ngIf="
                    form.controls['passwordConfirmation'].hasError('mismatchedPasswords') &&
                    !form.controls['passwordConfirmation'].hasError('required')
                  "
                >
                  {{ 'SignUp.PasswordNotSame' | translate }}
                </div>
              </div>
            </div>

            <button class="btn btn-danger rounded-pill btn-block mb-3" type="submit">
              {{ 'EditUser.ChangePassword' | translate }}
            </button>
            <button class="btn btn-danger rounded-pill btn-block" type="button" (click)="handleBackToProfileClick()">
              {{ 'Navigation.BackToProfile' | translate }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>
