<div class="container">
  <div class="row mt-0 mt-lg-5">
    <div class="col-12 col-lg-6 mt-5 order-1 order-lg-0">
      <h1>{{ 'MedicalEducation.Title' | translate }}</h1>
      <h6>
        {{ 'MedicalEducation.Description' | translate }}
      </h6>
    </div>
    <div
      class="col-12 col-lg-6 align-items-start justify-content-sm-center justify-content-lg-end d-flex order-0 order-lg-1 header-image"
    >
      <img src="assets/images/Kombi-MedEducation-Desktop@2x.png" />
    </div>
  </div>

  <!-- VoD filter section -->
  <ng-container *ngIf="videosExists">
    <div class="row mt-5">
      <div class="col-12 d-flex align-items-center justify-content-between mb-3">
        <h3>{{ 'MedicalEducation.FilterContent' | translate }}</h3>
        <button (click)="showFilterForm()" class="btn filter-btn text-light">
          {{
            showFilterFormMobile
              ? ('MedicalEducation.filterClose' | translate)
              : ('MedicalEducation.filter' | translate)
          }}
          <i [inlineSVG]="'assets/icons/filter.svg'" class="ml-1"></i>
        </button>
      </div>
    </div>
    <form class="filter-form" [formGroup]="filterForm" name="filter-form" #ngForm="ngForm" novalidate>
      <div class="form-group row mb-5 mobile-full-width">
        <div class="col-lg-3 col-md-6 col-12 mb-3">
          <div class="dropdown secondary w-100">
            <button
              type="button"
              class="btn d-flex dropdown-toggle navigation-menu align-items-center justify-content-between w-100 h-100 text-light"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <p class="m-0">
                {{
                  filterForm.controls['selectedTopicCategory'].value
                    ? getSelectedTopicCategory(filterForm.controls['selectedTopicCategory'].value)?.title
                    : ('MedicalEducation.AllTopicCategories' | translate)
                }}
              </p>
              <i class="bi bi-chevron-down"></i>
            </button>
            <div class="dropdown-menu custom-scrollbar bg-dark w-100" aria-labelledby="select">
              <a
                class="dropdown-item d-flex flex-row"
                [ngClass]="!filterForm.controls['selectedTopicCategory'].value ? 'text-danger' : 'text-light'"
                (click)="handleResetFilter('selectedTopicCategory'); handleResetFilter('selectedTopic')"
              >
                {{ 'MedicalEducation.AllTopicCategories' | translate }}
              </a>
              <a
                class="dropdown-item d-flex flex-row"
                *ngFor="let topicCategory of topicCategories"
                [ngClass]="
                  filterForm.controls['selectedTopicCategory'].value === topicCategory.id ? 'text-danger' : 'text-light'
                "
                (click)="handleSelectTopicCategory(topicCategory.id)"
              >
                {{ topicCategory.title }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-3">
          <div class="dropdown secondary w-100">
            <button
              type="button"
              [disabled]="!filterForm.controls['selectedTopicCategory'].value"
              class="btn d-flex dropdown-toggle navigation-menu align-items-center justify-content-between w-100 h-100 text-light"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <p class="m-0">
                {{
                  filterForm.controls['selectedTopic'].value
                    ? getSelectedTopic(filterForm.controls['selectedTopic'].value)?.title
                    : ('MedicalEducation.AllTopics' | translate)
                }}
              </p>
              <i class="bi bi-chevron-down"></i>
            </button>
            <div class="dropdown-menu custom-scrollbar bg-dark w-100" aria-labelledby="select">
              <a
                class="dropdown-item d-flex flex-row"
                [ngClass]="!filterForm.controls['selectedTopic'].value ? 'text-danger' : 'text-light'"
                (click)="handleResetFilter('selectedTopic')"
              >
                {{ 'MedicalEducation.AllTopics' | translate }}
              </a>
              <a
                class="dropdown-item d-flex flex-row"
                *ngFor="let topic of getSelectableTopics(filterForm.controls['selectedTopicCategory'].value)"
                [ngClass]="filterForm.controls['selectedTopic'].value === topic.id ? 'text-danger' : 'text-light'"
                (click)="handleSelectTopic(topic.id)"
                >{{ topic.title }}</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-3">
          <div class="dropdown secondary w-100">
            <button
              type="button"
              class="btn d-flex dropdown-toggle navigation-menu align-items-center justify-content-between w-100 h-100 text-light"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <p class="m-0">
                {{
                  filterForm.controls['selectedCongressMode'].value
                    ? getSelectedCongressMode(filterForm.controls['selectedCongressMode'].value)?.title
                    : ('MedicalEducation.AllEventCategories' | translate)
                }}
              </p>
              <i class="bi bi-chevron-down"></i>
            </button>
            <div class="dropdown-menu custom-scrollbar bg-dark w-100" aria-labelledby="select">
              <a
                class="dropdown-item d-flex flex-row"
                [ngClass]="!filterForm.controls['selectedCongressMode'].value ? 'text-danger' : 'text-light'"
                (click)="handleResetFilter('selectedCongressMode')"
              >
                {{ 'MedicalEducation.AllEventCategories' | translate }}
              </a>
              <a
                class="dropdown-item d-flex flex-row"
                *ngFor="let mode of congressModes"
                [ngClass]="filterForm.controls['selectedCongressMode'].value === mode.id ? 'text-danger' : 'text-light'"
                (click)="handleSelectCongressMode(mode.id)"
              >
                {{ mode.title }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12 mb-0 mb-lg-3">
          <div class="dropdown secondary w-100">
            <button
              type="button"
              class="btn d-flex dropdown-toggle navigation-menu align-items-center justify-content-between w-100 h-100 text-light"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <p class="m-0">
                {{
                  filterForm.controls['selectedFormat'].value
                    ? getSelectedFormat(filterForm.controls['selectedFormat'].value)?.title
                    : ('MedicalEducation.AllFormats' | translate)
                }}
              </p>
              <i class="bi bi-chevron-down"></i>
            </button>
            <div class="dropdown-menu custom-scrollbar bg-dark w-100" aria-labelledby="select">
              <a
                class="dropdown-item d-flex flex-row"
                [ngClass]="!filterForm.controls['selectedFormat'].value ? 'text-danger' : 'text-light'"
                (click)="handleResetFilter('selectedFormat')"
              >
                {{ 'MedicalEducation.AllFormats' | translate }}
              </a>
              <a
                *ngFor="let format of formats"
                class="dropdown-item d-flex flex-row"
                [ngClass]="filterForm.controls['selectedFormat'].value === format.id ? 'text-danger' : 'text-light'"
                (click)="handleSelectFormat(format.id)"
              >
                {{ format.title }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- VoD's section -->
    <div class="row mt-3 mb-3 video-row">
      <div id="video-{{ i }}" class="video col-lg-6 col-12 pb-4" *ngFor="let video of onDemandVideos; let i = index">
        <video-list-item
          [video]="video"
          [topicTitle]="video.topicName"
          [formatTitle]="getSelectedFormat(video.formatId)?.title"
          (goToVideo)="handleGoToVideoClick($event)"
        >
        </video-list-item>
      </div>
      <div class="col-12 my-5 text-center" *ngIf="onDemandVideos.length === 0">
        <h4>{{ 'MedicalEducation.NoContentSelection' | translate }}</h4>
      </div>
    </div>

    <!-- Load more videos button -->
    <div class="row btn-row flex-row align-items-center justify-content-center mb-3" *ngIf="nextVideosPage$ | async">
      <div class="divider w-100 mb-2 mx-3"></div>
      <button
        type="button"
        class="btn ghost-button-light rounded-pill w-sm-100 more-btn"
        (click)="handleLoadMoreVideosClick()"
      >
        {{ 'MedicalEducation.ShowMoreButtonLabel' | translate }}
      </button>
    </div>
  </ng-container>
</div>

<!-- upcoming events -->
<div class="container" *ngIf="upcomingEventCarouselItems?.length > 0">
  <div class="row mb-lg-4 mb-2">
    <div class="col-12">
      <h4 class="mb-0">
        {{ 'Common.MostRecent' | translate }}
      </h4>
    </div>
  </div>
</div>

<ng-container *ngIf="upcomingEventCarouselItems?.length > 0">
  <app-carousel-large
    [carouselItems]="upcomingEventCarouselItems"
    [folderUrl]="thumbnailPath"
    [halfWidthItem]="true"
  ></app-carousel-large>
</ng-container>

<!-- Premium Teaser Box -->
<div class="container" *ngIf="premiumBox">
  <app-spacer></app-spacer>
  <div class="row mb-3">
    <div class="col-12">
      <app-premium-teaser-box
        [headline]="premiumBox?.headline"
        [description]="premiumBox?.description"
        [backgroundImage]="premiumBox?.backgroundImage"
        [logo]="premiumBox?.logo"
        [time]="premiumBox?.date"
        [start]="premiumBox?.timeslotStart"
        [end]="premiumBox?.timeslotEnd"
        [openInNewTab]="premiumBox?.detailsButtonOpenNewTab"
        [detailButtonUrl]="premiumBox?.detailsButtonUrl"
        [detailButtonText]="premiumBox?.detailsButtonText"
        [liveUrl]="premiumBox?.goLiveButtonUrl"
        [postUrl]="premiumBox?.detailsButtonUrl"
        *ngIf="premiumBox.visible"
      ></app-premium-teaser-box>
    </div>
  </div>
</div>

<!-- past congresses -->
<section id="past-congresses" *ngIf="displayedCongressCarouselItems.length > 0 && congressSlider?.show">
  <app-spacer></app-spacer>
  <div class="container">
    <div class="row mb-lg-4 mb-2">
      <div class="col-12">
        <h4 class="mb-0">
          {{ 'MedicalEducation.Congresses' | translate }}
        </h4>
      </div>
    </div>
  </div>
  <app-carousel-small
    [isCongressList]="true"
    [carouselItems]="displayedCongressCarouselItems"
    [coverImage]="false"
    *ngIf="displayedCongressCarouselItems.length > 0"
  ></app-carousel-small>
</section>

<!-- live cme events -->
<div class="container" *ngIf="liveCmeEventCarouselItems?.length && currentLang !== 'ch'">
  <div class="row mb-lg-4 mb-2">
    <div class="col-12">
      <h4 class="mb-0">{{ 'MedicalEducation.LiveCME' | translate }}</h4>
    </div>
  </div>
</div>

<ng-container *ngIf="liveCmeEventCarouselItems?.length && currentLang !== 'ch'">
  <app-carousel-large
    class="live-cme-carousel"
    [isCmeCarousel]="true"
    [carouselItems]="liveCmeEventCarouselItems"
    [folderUrl]="thumbnailPath"
    [carouselItemClickAction]="carouselItemClickCallback"
  ></app-carousel-large>
</ng-container>

<!-- Highlights -->
<div id="congress-highlights" *ngIf="highlightedOnDemandVideos.length > 0">
  <app-spacer></app-spacer>
  <div class="container">
    <div class="row mb-lg-4 mb-2">
      <div class="col-12">
        <h4 class="mb-0" [attr.data-number]="highlightedOnDemandVideos.length">
          {{ 'CongressList.Highlights' | translate }}
        </h4>
      </div>
    </div>
  </div>
  <app-carousel-small
    [carouselItems]="highlightedOnDemandVideos"
    [imageKeyField]="'thumbnail'"
    [coverImage]="true"
  ></app-carousel-small>
</div>

<!-- Newsletter teaser card -->
<div class="container" *ngIf="currentLang !== 'ch'">
  <div class="row mt-4 mobile-full-width">
    <div class="teaser-card-wrapper px-2 pb-3 col-12">
      <app-teaser-card
        [title]="'MedicalEducation.NewsletterTitle' | translate"
        [description]="'MedicalEducation.NewsletterDescription' | translate"
        [buttonLabel]="'MedicalEducation.SubscribeToKnowledge!' | translate"
        [type]="'SUBSCRIBE'"
      ></app-teaser-card>
    </div>
  </div>
  <app-spacer></app-spacer>
</div>

<!-- Most Watched -->
<section id="most-watched-videos" *ngIf="mostWatchedVideosCarouselItems.length > 0">
  <div class="container">
    <div class="row mb-lg-4 mb-2">
      <div class="col-12">
        <h4 class="mb-0" [attr.data-number]="mostWatchedVideosCarouselItems.length">
          {{ 'CongressList.MostWatchedTitle' | translate }}
        </h4>
      </div>
    </div>
  </div>
  <app-carousel-small
    [carouselItems]="mostWatchedVideosCarouselItems"
    [imageKeyField]="'thumbnail'"
    [coverImage]="true"
  ></app-carousel-small>
</section>

<!-- inernational content -->
<ng-container *ngIf="internationalContents?.length">
  <div class="container">
    <div class="row mb-lg-4 mb-2">
      <div class="col-12">
        <h4 class="mb-0">{{ 'Common.InternationalContent' | translate }}</h4>
      </div>
    </div>
  </div>
  <app-carousel-large
    [carouselItems]="internationalContents"
    [folderUrl]="thumbnailPathUrl"
    [halfWidthItem]="true"
  ></app-carousel-large>
  <app-spacer></app-spacer>
</ng-container>

<app-async-state-overlay [processKey]="medicalEducationAsynKey"></app-async-state-overlay>
<app-async-state-overlay [processKey]="onDemandVideosAsyncKey"></app-async-state-overlay>
<app-async-state-overlay [processKey]="onDemandVideoAsyncKey"></app-async-state-overlay>
