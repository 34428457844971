import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../patientstoday/src/environments/environment';
import { DynamicScriptLoaderService } from '../../../shared/services/dynamic-script-loader-service.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Observable, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

declare var gapi;

@Injectable()
export class ProvidersService {
  readonly YOUTUBE_API_KEY = environment.youtubeApiKey;

  constructor(protected http: HttpClient, private dynamicScriptLoaderService: DynamicScriptLoaderService) {}

  getYoutubeVideoData(id: string): Observable<unknown> {
    return fromPromise(this.initGoogleApi()).pipe(
      switchMap(() =>
        fromPromise(
          gapi.client.youtube.videos.list({
            part: ['snippet'],
            id: [id]
          })
        ).pipe(
          map((data: any) => {
            if (data?.result?.items?.length) {
              return data.result.items[0];
            } else {
              return null;
            }
          })
        )
      )
    );
  }

  private async initGoogleApi() {
    await this.dynamicScriptLoaderService.loadScript('https://apis.google.com/js/api.js');

    return await new Promise((resolve, reject) => {
      gapi.load('client:auth2', async () => {
        try {
          gapi.client.setApiKey(this.YOUTUBE_API_KEY);

          await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest').then(
            function () {
              console.log('GAPI client loaded for API');
              resolve(true);
            },
            function (err) {
              console.error('Error loading GAPI client for API', err);
              reject(false);
            }
          );
        } catch (e) {
          console.log('error');
          console.log(e);
          reject(false);
        }
      });
    });
  }
}
