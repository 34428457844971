import { Component, Inject, Input } from '@angular/core';
import { imagePaths } from 'projects/medtoday/src/environments/environment';

@Component({
  selector: 'app-congress-banner',
  templateUrl: './congress-banner.component.html',
  styleUrls: ['./congress-banner.component.scss']
})
export class CongressBannerComponent {
  readonly logosUrl = imagePaths.logos;

  @Input() congressLogo: string;
  @Input() congressSeriesLogo: string;
  @Input() disclaimer: string;
  @Input() onBottom = false;

  constructor(@Inject('s3BucketUrl') public s3BucketUrl: string) {}
}
