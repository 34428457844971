import { Component, Inject, Input, OnInit } from '@angular/core';
import { Sponsor } from 'medtoday-models-library/lib/models';
import { MapSponsorsByLevel } from '../../utilities/sponsor.utils';
import { imagePaths } from '../../../../medtoday/src/environments/environment';
import { getCongressSlugRouterParam } from '../../../core/router/selectors/router.selectors';
import { Store } from '@ngrx/store';
import { BaseAppState } from '../../../core/store/reducers';

@Component({
  selector: 'app-sponsors-box',
  templateUrl: './sponsors-box.component.html',
  styleUrls: ['./sponsors-box.component.scss']
})
export class SponsorsBoxComponent implements OnInit {
  congressSlug$ = this.store.select(getCongressSlugRouterParam);

  @Input()
  sponsors: Sponsor[];

  @Input()
  showCategories: boolean;

  @Input()
  sponsorAreaTitle: string | null;

  @Input()
  sponsorsPerLine: number = 2;

  @Input()
  noTopDivider = false;

  @Input()
  noContainerPadding = false;

  // if showSponsorsCategories is enabled
  sponsorsByLevels: ReadonlyMap<string, Sponsor[]>;
  logosUrl = imagePaths.logos;
  unsorted = () => 0;

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    private store: Store<BaseAppState>,
    @Inject('applicationName') public applicationName: string
  ) {}

  ngOnInit(): void {
    if (this.showCategories && this.sponsors?.length) {
      this.sponsorsByLevels = MapSponsorsByLevel(this.sponsors) as unknown as ReadonlyMap<string, Sponsor[]>;
    }
  }
}
