<div class="d-flex flex-wrap">
  <div class="divider w-100 my-3 mb-1"></div>
  <p class="w-100 pl-2">{{ 'Event.PartnerSponsorsNoInfluence' | translate }}</p>
  <div *ngFor="let partner of partners" class="col-12 col-md-6 p-2">
    <a
      target="_blank"
      draggable="false"
      (click)="handlePartnerUrlClick(partner)"
      class="card sponsor rounded-lg text-light justify-content-center"
    >
      <img
        class="w-100"
        draggable="false"
        [src]="s3BucketUrl + logosImageFolderSlug + partner.logo"
        alt="partner-logo"
      />
    </a>
    <div class="mt-1 partner-booth" *ngIf="partner.hasDetailPage">
      <i [inlineSVG]="'assets/icons/booth-icon.svg'"></i>
      <a (click)="handlePartnerBoothClick(partner)">
        <span class="font-size-small ml-2">
          {{ 'ChannelPage.SponsorPdfDownload' | translate }}
        </span>
      </a>
    </div>
  </div>
</div>
