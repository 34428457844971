import { Component, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../../../medtoday/src/environments/environment';
import { environment as patientsenv } from '../../../../patientstoday/src/environments/environment';
import { AnalyticsService } from '../../data/services/ga-analytics.service';
import {
  GoToDisclaimer,
  GoToImprint,
  GoToNetiquette,
  GoToTermsOfService
} from '../../router/actions/main-navigation.actions';
import { BaseAppState } from '../../store/reducers';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  currentLang = this.translateService.getDefaultLang();

  twitterProfileUrl = environment.twitterProfileUrl;
  linkedInProfileUrl = environment.linkedInProfileUrl;
  instagramProfileUrl = environment.instagramProfileUrl;

  patientsInstagram = patientsenv.instagramProfileUrl;
  patientsFacebook = patientsenv.facebookProfileUrl;
  currentDate = new Date();
  constructor(
    protected store: Store<BaseAppState>,
    private translateService: TranslateService,
    @Inject('domain') public domain: string,
    protected analyticsService: AnalyticsService
  ) {}

  handleGoToImprintClick() {
    this.store.dispatch(new GoToImprint());
  }

  handleDataProtectionClick() {
    this.store.dispatch(new GoToTermsOfService());
  }

  handleDisclaimerClick() {
    this.store.dispatch(new GoToDisclaimer());
  }

  handleNetiquetteClick() {
    this.store.dispatch(new GoToNetiquette());
  }
}
