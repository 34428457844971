import { Pipe, PipeTransform } from '@angular/core';
import { getVideoDurationAsString } from '../utilities/video.utils';

@Pipe({
  name: 'videoDuration'
})
export class VideoDurationHumanizePipe implements PipeTransform {
  constructor() {}

  transform(duration: number | null): string {
    if (duration) {
      return getVideoDurationAsString(duration);
    } else {
      return '0 min.';
    }
  }
}
