<ng-container *ngIf="congressMetaData$ | async as congressMetaData">
  <ng-container *ngIf="featuredContentOverviewData$ | async as featuredData">
    <app-subpage-header
      [congressMetaData]="congressMetaData"
      [subpageName]="'FeaturedPage.FeaturedContent' | translate"
    ></app-subpage-header>
    <div
      class="container sticky-container"
      [class.filter-background]="showFilterBackground && applicationName === 'MedToday'"
      [class.filter-background-patients]="showFilterBackground && applicationName === 'PatientsToday'"
      [class.bg-medtoday]="applicationName === 'MedToday'"
    >
      <ng-container *ngIf="sponsors?.length > 0 && congressMetaData.filterOptions['featured'].includes('sponsor')">
        <app-filter
          #filter
          [filterOptions]="filterContainer.getFilterOptions('sponsor')"
          [hideLabel]="showFilterBackground"
          [type]="'sponsor'"
          [hasAllButton]="true"
          [selectedOptions]="selectedSponsors"
          (allButtonHandler)="resetFilter('sponsor')"
          (handleFilter)="handleSelectFilter('sponsor', $event)"
          [filterLabel]="'FeaturedPage.Sponsor' | translate"
          [attributeAsButtonLabel]="'sponsorName'"
        ></app-filter>
      </ng-container>
      <ng-container *ngIf="selectableDays?.length > 0 && congressMetaData.filterOptions['featured'].includes('day')">
        <app-filter
          #filter
          [type]="'date'"
          [hideLabel]="showFilterBackground"
          [filterOptions]="filterContainer.getFilterOptions('day')"
          [selectedOptions]="selectedDays"
          (handleFilter)="handleSelectFilter('day', $event)"
          [labelPipe]="'date'"
          [dateFormat]="'EEEE, dd.MM.'"
          [filterLabel]="'FeaturedPage.CongressDay' | translate"
        ></app-filter>
      </ng-container>
      <ng-container *ngIf="topics?.length > 0 && congressMetaData.filterOptions['featured'].includes('topic')">
        <app-filter
          #filter
          [filterOptions]="filterContainer.getFilterOptions('topic')"
          [type]="'topic'"
          [hideLabel]="showFilterBackground"
          [hasAllButton]="true"
          [selectedOptions]="selectedTopics"
          (allButtonHandler)="resetFilter('topic')"
          (handleFilter)="handleSelectFilter('topic', $event)"
          [filterLabel]="'Common.Departments' | translate"
          [attributeAsButtonLabel]="'title'"
        ></app-filter>
      </ng-container>
      <ng-container *ngIf="formats?.length > 0 && congressMetaData.filterOptions['featured'].includes('format')">
        <app-filter
          #filter
          [filterOptions]="filterContainer.getFilterOptions('format')"
          [type]="'format'"
          [hideLabel]="showFilterBackground"
          [hasAllButton]="true"
          [selectedOptions]="selectedFormats"
          (allButtonHandler)="resetFilter('format')"
          (handleFilter)="handleSelectFilter('format', $event)"
          [filterLabel]="'FeaturedPage.Format' | translate"
        ></app-filter>
      </ng-container>
      <ng-container
        *ngIf="
          featuredData.programme.languages?.length > 0 &&
          congressMetaData.filterOptions['featured'].includes('language') &&
          congressMetaData.mode === 'VoD'
        "
      >
        <app-filter
          #filter
          [filterOptions]="filterContainer.getFilterOptions('language')"
          [type]="'languages'"
          [hideLabel]="showFilterBackground"
          [hasAllButton]="true"
          [selectedOptions]="selectedLanguages"
          (allButtonHandler)="resetFilter('language')"
          (handleFilter)="handleSelectFilter('language', $event)"
          [filterLabel]="'Common.Languages' | translate"
          [prefix]="'VideoOnDemand.'"
          [filterDropdownPlaceholder]="'Common.SelectLanguage' | translate"
        ></app-filter>
      </ng-container>
    </div>
    <div class="container pt-3" [class.subpage-content]="applicationName === 'MedToday'">
      <div class="col-12 py-4 px-0" *ngIf="!isAnyContentAvailable()">
        <h5 class="text-light">{{ 'FeaturedPage.NoSessions' | translate }}</h5>
      </div>
      <ng-container *ngIf="isAnyContentAvailable()">
        <h4>{{ 'FeaturedPage.Content' | translate }}</h4>
        <ng-container *ngFor="let row of visibleTimeSlots; let r = index">
          <section [id]="r" *ngIf="row.sessions?.length > 0 || row.videos?.length > 0">
            <div class="row mt-3" [id]="isLive(row) ? 'live' : ''">
              <div class="left-divider divider w-100"></div>
              <div class="col-lg-4 col-12 pt-4 headline">
                <span>
                  <h5
                    class="text-medium text-primary pb-0 pb-md-1 my-0 my-md-2 time"
                    *ngIf="isLiveCongress || row.sessions?.length"
                  >
                    {{ row.start | date : 'EEEE' }}, {{ row.start | date : 'HH:mm' }} -
                    {{ row.finish | date : 'HH:mm' }}
                    {{ 'Common.Hour' | translate }}
                  </h5>
                  <h5
                    class="text-medium text-primary pb-0 pb-md-1 my-0 my-md-2 time"
                    *ngIf="isVoDCongress && !row.sessions?.length"
                  >
                    {{ row.start | date : 'EEEE' }} {{ 'Common.ab' | translate }} {{ row.start | date : 'HH:mm' }}
                    {{ 'Common.Hour' | translate }}
                  </h5>
                  <h2 class="pb-3 hide-mobile" *ngIf="!selectedTopic && isLiveCongress">
                    {{ getCommonSessionFormat(row.sessions) }}
                  </h2>
                  <h2 class="pb-3 hide-mobile" *ngIf="!selectedTopic && isVoDCongress">
                    {{ getCommonVoDFormat(row.videos) }}
                  </h2>
                </span>
              </div>
              <div class="col-lg-8 col-12">
                <app-session-row
                  [session]="session"
                  [row]="row"
                  [congressSlug]="congressSlug"
                  [isFeaturedContent]="true"
                  [selectedTopic]="selectedTopic"
                ></app-session-row>

                <ng-container *ngIf="isVoDCongress">
                  <app-video-row
                    [row]="row"
                    [programmeData]="featuredData.programme"
                    [congressSlug]="congressSlug"
                    [isFeaturedContent]="true"
                    [selectedTopic]="selectedTopic"
                  ></app-video-row>
                </ng-container>
              </div>
            </div>
          </section>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<app-spacer></app-spacer>

<app-scroll-to-top-button [ngClass]="hasScrolled() ? 'd-block' : 'd-none'"></app-scroll-to-top-button>
<app-async-state-overlay [processKey]="loadFeaturedContentAsyncKey"></app-async-state-overlay>
