import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCongressSlugRouterParam } from '../../../core/router/selectors/router.selectors';
import { BaseAppState } from '../../../core/store/reducers';
import {
  GoToChannel,
  GoToCongressLandingPage,
  GoToOnDemandVideo,
  GoToProgramme
} from '../../../core/router/actions/main-navigation.actions';
import { takeUntil } from 'rxjs/operators';
import { ObservableComponent } from '../observable/observable.component';
import { getSelectedOnDemandVideo } from 'projects/todaylib/core/data/selectors/data-api.selectors';
import { AnalyticsService } from 'projects/todaylib/core/data/services/ga-analytics.service';
import { MAIN_NAVIGATION_PATH_ELEMENTS } from 'projects/todaylib/core/router/definitions/main-navigation.definitions';
import { CarouselItem, LargeCarouselItem } from '../../models/carousel-item.model';
import { UpcomingEventListItemModel } from 'medtoday-models-library';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { TIME_SLOT_INTERVAL } from '../../utilities/session.utils';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-carousel',
  template: ''
})
export class CarouselComponent extends ObservableComponent implements AfterViewInit, OnDestroy {
  @Input() carouselItems: CarouselItem[] | LargeCarouselItem[] | UpcomingEventListItemModel[];
  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  @Input() carouselItemClickAction: (slug: string) => any;

  congressSlug: string;

  onDemandVideo$ = this.store.select(getSelectedOnDemandVideo);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  private nextSlide = new BehaviorSubject<number>(0);
  nextSlide$ = this.nextSlide.asObservable();
  slider: KeenSliderInstance;
  currentSlide: number = 0;
  rels: number;

  constructor(
    @Inject('applicationName') public applicationName: string,
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    protected store: Store<BaseAppState>,
    private analyticsService: AnalyticsService,
    @Inject(TIME_SLOT_INTERVAL) protected timeSlotInterval
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.observeCongressSlug();
  }

  ngOnDestroy() {
    if (this.slider) {
      this.slider.destroy();
    }
  }

  public initCarousel(mobileSlides, tabletSlides, desktopSlides, spacing = 16): void {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      initial: this.currentSlide,
      slideChanged: s => {
        this.currentSlide = s.track.details.rel;
        this.nextSlide.next(this.currentSlide);
      },
      loop: false,
      mode: 'free-snap',
      breakpoints: {
        '(min-width: 992px)': {
          slides: { perView: desktopSlides, spacing: 16 }
        },
        '(max-width: 992px) and (min-width: 576px)': {
          slides: { perView: tabletSlides, spacing: 16 }
        },
        '(max-width: 576px)': {
          slides: { perView: mobileSlides, spacing: spacing }
        }
      },
      range: {
        align: true,
        min: 0,
        max: this.carouselItems.length - 1
      }
    });
    this.rels = Math.ceil(this.slider.track.details?.slides.length - desktopSlides);
  }

  observeCongressSlug() {
    this.congressSlug$.pipe(takeUntil(this.ngDestroy$)).subscribe((slug: string) => (this.congressSlug = slug));
  }

  isPreviousButtonDisabled() {
    return this.currentSlide === 0;
  }

  isNextButtonDisabled() {
    return this.rels === this.currentSlide;
  }

  handleCongressClick(congressSlug: string, congressMode: string) {
    if (this.carouselItemClickAction) {
      this.carouselItemClickAction(congressSlug);
    } else {
      this.store.dispatch(new GoToCongressLandingPage(congressSlug, congressMode));
    }
  }

  handleLiveButtonClick(congressSlug: string) {
    this.store.dispatch(new GoToProgramme(congressSlug, true));
  }

  handleGoToChannelClick(congressSlug: string) {
    this.analyticsService.pushTag({}, 'navigate-to-channel', 'is-featured-content', false);
    this.store.dispatch(
      new GoToChannel(congressSlug, congressSlug, MAIN_NAVIGATION_PATH_ELEMENTS.root.programmeElement)
    );
  }

  handleItemOnDemandClick(congressSlug: string, onDemandVideoId: number) {
    this.store.dispatch(new GoToOnDemandVideo(onDemandVideoId, false, undefined, congressSlug));
  }
}
