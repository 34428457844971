import { ProvidersActions, ProvidersActionTypes } from '../actions/providers.actions';

export interface ProvidersState {
  youtubeVideoData: { snippet: { description: string } } | undefined;
}

export const initialState: ProvidersState = {
  youtubeVideoData: undefined
};

export function reducer(state = initialState, action: ProvidersActions): ProvidersState {
  switch (action.type) {
    case ProvidersActionTypes.LoadYoutubeVideoDataSuccess: {
      return {
        ...state,
        youtubeVideoData: action.response
      };
    }
    case ProvidersActionTypes.ClearYoutubeVideoData: {
      return {
        ...state,
        youtubeVideoData: undefined
      };
    }
    default: {
      return state;
    }
  }
}
