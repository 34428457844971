<div class="container justify-content-center">
  <header>
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-4">
        <h1 class="h2 mb-3">{{ 'Auth.RegistrationSuccess' | translate }}</h1>
        <div class="mb-4">
          {{ 'Auth.RegistrationSuccessDesc' | translate }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 offset-md-4 mb-2">
        <button *ngIf="currentLang !== 'ch'" class="btn btn-danger btn-block rounded-pill" (click)="handleGoHome()">
          {{ (currentLang === 'de' ? 'SignUp.ToMedtoday' : 'Navigation.BackHome') | translate }}
        </button>
        <button *ngIf="currentLang === 'ch'" class="btn btn-danger btn-block rounded-pill" (click)="handleLoginClick()">
          {{ 'Auth.SignInNow' | translate }}
        </button>
        <a
          *ngIf="currentLang === 'de'"
          class="btn mt-3 btn-danger btn-block rounded-pill"
          href="https://medboards.medtoday.de/#/login"
          >{{ 'SignUp.ToMedboards' | translate }}</a
        >
      </div>
    </div>
  </header>

  <app-async-state-overlay [processKey]="asyncProcessKey"></app-async-state-overlay>
</div>
