import { Action } from '@ngrx/store';
import { AsyncStateActions, AsyncStateActionTypes, SetAsyncStateFail } from '../actions/async-state.actions';

import { ApiResponse, ValidationError } from '../../../shared/models';

export interface AsyncErrorState {
  httpStatus?: number;
  errorTitle?: string;
  errorText?: string;
  validationErrors?: ValidationError[];
}

interface AsyncProcessState {
  pending: boolean;
  error: AsyncErrorState | undefined;
  initialAction?: Action;
}

export interface AsyncState {
  [asyncKey: string]: AsyncProcessState;
}

const initialState: AsyncState = {};

function getErrorState(action: SetAsyncStateFail): AsyncErrorState | undefined {
  if (!action.error && !action.httpError && !action.errorMessage && !action.validationErrors) {
    return undefined;
  }

  const errorResponse: ApiResponse<null> = action.httpError
    && action.httpError.error
    && action.httpError.error ? action.httpError.error : null;

  /**
   * Es kam vom Server ein Fehler Objekt zurück
   */
  if (action.httpError && errorResponse && errorResponse.error) {
    const error = errorResponse.error;
    return {
      httpStatus: action.httpError.status,
      errorText: error.message,
      validationErrors: errorResponse.validationErrors,
    };

  /**
   * Es kam vom Server kein dediziertes Fehlerobjekt zurück, aber es
   * können validierungs Fehler vorhanden sein.
   */
  } else if (action.httpError) {
    return {
      httpStatus: action.httpError.status,
      errorTitle: action.errorTitle,
      errorText: action.errorMessage,
      validationErrors: action.validationErrors,
    };
  } else {
    return {
      errorTitle: action.errorTitle,
      errorText: action.errorMessage,
    };
  }
}

function updateForKey(key: string, state, action: AsyncStateActions) {
  let processState: AsyncProcessState | undefined = state[key] || {};
  switch (action.type) {
    case AsyncStateActionTypes.SetAsyncStateStart:
      processState = {
        ...processState,
        pending: true,
        error: undefined,
        initialAction: action.initialAction,
      };
      break;

    case AsyncStateActionTypes.SetAsyncStateFail:
      processState = {
        ...processState,
        pending: false,
        error: getErrorState(action),
      };
      break;
    case AsyncStateActionTypes.SetAsyncStateSuccess:
    case AsyncStateActionTypes.SetAsyncStateReset:
      processState = undefined;
      break;

    default:
      break;
  }
  const newState = { ...state };

  if (processState) {
    newState[key] = processState;
  } else {
    delete newState[key];
  }
  return newState;
}

export function reducer(state = initialState, action: AsyncStateActions): AsyncState {
  switch (action.type) {
    case AsyncStateActionTypes.SetAsyncStateStart:
    case AsyncStateActionTypes.SetAsyncStateSuccess:
    case AsyncStateActionTypes.SetAsyncStateFail:
    case AsyncStateActionTypes.SetAsyncStateReset:
      return updateForKey(action.key, state, action);
    case AsyncStateActionTypes.AsyncStateClear:
      return initialState;

    default:
      return state;
  }
}
