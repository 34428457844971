import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { imagePaths } from '../../../../medtoday/src/environments/environment';
import { VideoListItem } from '../video-list-item/video-list-item.component';
import { getVideoDurationAsString } from '../../utilities/video.utils';
import { MediaProvider } from 'medtoday-models-library/lib/models';

@Component({
  selector: 'video-thumbnail',
  templateUrl: './video-thumbnail.component.html',
  styleUrls: ['./video-thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoThumbnailComponent implements OnInit, AfterContentInit {
  @Input() video: VideoListItem;
  @Input() thumbnailsDirectory = this.s3BucketUrl + imagePaths.thumbnails;
  @Input() isShowLabel = true;
  @Input() mediaProvider: MediaProvider;
  duration: string;
  thumbnail: string;
  label: string | undefined | null;

  MEDIA_PROVIDERS = MediaProvider;

  @Output() goToVideo = new EventEmitter<VideoListItem>();

  constructor(@Inject('s3BucketUrl') public s3BucketUrl: string) {}

  ngOnInit(): void {
    this.thumbnail = this.video.thumbnail?.includes('https')
      ? this.video.thumbnail
      : this.thumbnailsDirectory + this.video.thumbnail;
    this.label = this.video.congressShortcut ? this.video.congressShortcut.toUpperCase() : this.video.seriesName;
  }

  ngAfterContentInit() {
    if (this.video.duration) {
      if (!Number.isNaN(Number(this.video.duration))) {
        this.duration = getVideoDurationAsString(parseInt(this.video.duration as string));
      } else {
        this.duration = this.video.duration as string;
      }
    }
  }

  handleGoToVideoClick(video: VideoListItem) {
    this.goToVideo.emit(video);
  }

  protected readonly MediaProvider = MediaProvider;
}
