<div
  class="row mobile-full-width"
  [class.mb-5]="partnerBox.partnerBoxPosition === 'top'"
  [class.mt-5]="partnerBox.partnerBoxPosition === 'bottom'"
>
  <div class="col-12">
    <div class="partner-box">
      <div class="col-lg-6 col-12 p-0">
        <h4 class="mb-3">{{ partnerBox.partnerBoxHeader }}</h4>
        <p replaceToDownloadLink [innerHTML]="partnerBox.partnerBoxDescription"></p>
        <a
          href="mailto: {{ 'PartnerNetwork.Mail' | translate }}"
          class="btn font-size-large btn-danger rounded-pill mt-3"
          >{{ 'PartnerNetwork.Mail' | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
