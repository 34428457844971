<nav
  class="navbar navbar-expand-lg navbar-dark sticky-top px-0"
  id="navbar"
  [ngClass]="shouldShowNavBarBackground ? 'nav-scrolling shadow-nav border-bottom-md-light' : ''"
>
  <div class="navbar-background"></div>
  <div class="container navbar-container">
    <div class="d-flex justify-content-between align-items-center sticky-top logo-wrapper">
      <a data-toggle="collapse" data-target=".navbar-collapse.show" (click)="handleHomeClick()" draggable="false">
        <img class="hide-mobile hide-tablet" draggable="false" src="assets/images/logo.svg" />
        <img class="hide-desktop logo-mobile" draggable="false" src="assets/images/logo-small.svg" />
      </a>
      <div class="d-flex hide-desktop align-items-center">
        <a class="mr-4 icon-nav mt-1" type="button" (click)="handleGoToSearchClick()">
          <i class="bi-search text-white"></i>
        </a>
        <a
          class="mr-3 icon-nav collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarToggleUserContent"
          aria-controls="navbarToggleUserContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i [inlineSVG]="'assets/icons/account.svg'"></i>
        </a>
        <button
          id="navbarButton"
          class="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarToggleExternalContent"
          aria-controls="navbarToggleExternalContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          (click)="navBarService.handleToggleNavBar()"
        >
          <span></span>
        </button>
      </div>
    </div>

    <div
      class="hide-desktop collapse shadow navbar-collapse justify-content-end mt-3 mt-lg-0 align-items-baseline"
      id="navbarToggleUserContent"
    >
      <div class="nav-content-wrapper navbar-collapse">
        <ul class="navbar-nav hide-desktop mx-0 mx-lg-2">
          <div class="divider my-2 w-100 hide-desktop"></div>

          <ng-container *ngIf="(isLoggedIn$ | async) === false">
            <li class="d-flex align-items-center nav-item cme-label">
              <a
                class="text-danger py-2 w-100"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                (click)="handleLoginClick()"
                draggable="false"
              >
                {{ 'Auth.SignInNow' | translate }}
              </a>
            </li>
          </ng-container>

          <ng-container *ngIf="(isLoggedIn$ | async) === true">
            <!-- Reenable when MyCme Feature is done -->
            <!-- <li class="d-flex align-items-center nav-item cme-label" *ngIf="currentLanguage === 'ch'">
              <a
                class="nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                (click)="handleMyCmeClick()"
                draggable="false"
              >
                {{ 'NavBar.MyCme' | translate }}
              </a>
            </li> -->

            <li class="d-flex align-items-center nav-item cme-label">
              <a
                class="nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                (click)="handleEditUserClick()"
                draggable="false"
              >
                {{ 'NavBar.Account' | translate }}
              </a>
            </li>

            <div class="divider my-2 w-100 hide-desktop"></div>

            <li class="d-flex align-items-center nav-item cme-label">
              <a
                class="nav-link"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                (click)="handleLogoutClick()"
                draggable="false"
              >
                {{ 'NavBar.Logout' | translate }}
              </a>
            </li>
          </ng-container>

          <div class="divider my-2 w-100 hide-desktop"></div>
        </ul>
      </div>
    </div>

    <div id="navbarToggleExternalContent" class="collapse navbar-collapse">
      <div class="nav-content-wrapper justify-content-end mt-3 mt-lg-0 align-items-baseline navbar-collapse">
        <ng-container *ngIf="congressMetaData$ | async as congressMetaData">
          <app-social-share-button
            *ngIf="inCongress()"
            size="small"
            class="social-share hide-desktop"
          ></app-social-share-button>
          <div *ngIf="inCongress()" class="mobile-links d-flex flex-column mb-5 mt-n4 hide-desktop">
            <div class="d-flex w-100 justify-content-between align-items-baseline">
              <p *ngIf="congressMetaData?.mode !== 'MedEd'" class="m-0">
                {{ 'NavigationMenu.Title' | translate }}
              </p>
              <a
                class="event-title m-0"
                (click)="handleGoToCongressClick(congressMetaData?.slug, congressMetaData?.mode)"
                *ngIf="congressMetaData?.mode === 'MedEd' && !congressMetaData.feedOnDemandVideoId"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                draggable="false"
                >{{ congressMetaData?.name }}</a
              >
              <p *ngIf="congressMetaData?.mode === 'MedEd' && congressMetaData.feedOnDemandVideoId" class="m-0">
                {{ congressMetaData?.name }}
              </p>
            </div>
            <app-navigation-menu
              *ngIf="congressMetaData?.mode !== 'MedEd'"
              [mobileView]="'true'"
              (toggleNavEmitter)="navBarService.toggleNavCongress()"
            ></app-navigation-menu>
          </div>
        </ng-container>

        <ng-container *ifApp="'MedToday'">
          <div class="nav-tablet-wrapper">
            <ul class="navbar-nav mx-0 mx-lg-2 slide-left">
              <ng-container *ngIf="domain === 'de' || domain === 'ch'">
                <div class="divider my-2 w-100 hide-desktop"></div>
                <li class="d-flex align-items-center nav-item cme-label hide-tablet">
                  <a
                    class="nav-link w-100 d-flex justify-content-between align-items-center pink-active collapsed"
                    (click)="handleCongressesMenuClick()"
                    data-toggle="collapse"
                    data-target="#congressDropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    draggable="false"
                  >
                    {{ 'MedicalEducation.Congresses' | translate }}
                    <i class="ml-2 icon-nav" [inlineSVG]="'assets/icons/arrow-down.svg'"></i>
                  </a>
                </li>

                <li class="d-flex align-items-center nav-item cme-label hide-desktop">
                  <a
                    class="nav-link w-100 d-flex justify-content-between align-items-center"
                    draggable="false"
                    (click)="navBarService.showMobileTopicChildren(true)"
                  >
                    {{ 'MedicalEducation.Congresses' | translate }}
                    <i [inlineSVG]="'assets/icons/arrow-right.svg'"></i>
                  </a>
                </li>
              </ng-container>

              <ng-container
                *ngIf="
                  applicationName === 'MedToday' &&
                  domain === 'de' &&
                  (firstMenuPage$ | async) &&
                  (firstMenuPage$ | async)?.active
                "
              >
                <div class="divider my-2 w-100 hide-desktop"></div>
                <li
                  class="d-flex align-items-center nav-item cme-label px-lg-2"
                  *ngIf="
                    getActiveLinksCount(firstMenuPage$ | async) === 1 &&
                    getOnlyActiveLink(firstMenuPage$ | async)[0]?.active
                  "
                >
                  <a
                    class="nav-link w-100"
                    [href]="getOnlyActiveLink(firstMenuPage$ | async)[0]?.link"
                    draggable="false"
                  >
                    {{ (firstMenuPage$ | async).menuPoint }}
                  </a>
                </li>
                <div class="dropdown secondary">
                  <button
                    type="button"
                    class="btn nav-link d-flex dropdown-toggle align-items-center justify-content-between w-100 h-100"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    *ngIf="
                      (firstMenuPage$ | async)?.subPages?.length > 1 && getActiveLinksCount(firstMenuPage$ | async) > 1
                    "
                  >
                    <span class="w-100 mr-2" draggable="false"> {{ (firstMenuPage$ | async)?.menuPoint }} </span>
                    <div class="icon-16 arrow-indicator" [inlineSVG]="'assets/icons/arrow-down.svg'"></div>
                  </button>
                  <div class="dropdown-menu dropdown-small-gap w-100" aria-labelledby="select">
                    <ng-container *ngFor="let subPage of (firstMenuPage$ | async)?.subPages">
                      <li class="dropdown-item text-light" *ngIf="subPage?.active">
                        <a class="nav-link" [href]="subPage?.link">{{ subPage?.name }}</a>
                      </li>
                    </ng-container>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="domain !== 'eu'">
                <div class="divider my-2 w-100 hide-desktop"></div>
                <li class="d-flex align-items-center nav-item cme-label px-lg-2">
                  <a
                    class="nav-link w-100"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    (click)="handleMedEdClick()"
                    draggable="false"
                  >
                    {{ 'NavBar.Medical Education' | translate }}
                  </a>
                </li>
              </ng-container>

              <ng-container
                *ngIf="
                  applicationName === 'MedToday' &&
                  domain === 'de' &&
                  (secondMenuPage$ | async) &&
                  (secondMenuPage$ | async)?.active
                "
              >
                <div class="divider my-2 w-100 hide-desktop"></div>
                <li
                  class="d-flex align-items-center nav-item cme-label px-lg-2"
                  *ngIf="
                    getActiveLinksCount(secondMenuPage$ | async) === 1 &&
                    getOnlyActiveLink(secondMenuPage$ | async)[0]?.active
                  "
                >
                  <a
                    class="nav-link w-100"
                    [href]="getOnlyActiveLink(secondMenuPage$ | async)[0]?.link"
                    draggable="false"
                  >
                    {{ (secondMenuPage$ | async).menuPoint }}
                  </a>
                </li>
                <div class="dropdown secondary">
                  <button
                    type="button"
                    class="btn nav-link d-flex dropdown-toggle align-items-center justify-content-between w-100 h-100"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    *ngIf="
                      (secondMenuPage$ | async)?.subPages?.length > 1 &&
                      getActiveLinksCount(secondMenuPage$ | async) > 1
                    "
                  >
                    <span class="w-100 mr-2" draggable="false"> {{ (secondMenuPage$ | async)?.menuPoint }} </span>
                    <div class="icon-16 arrow-indicator" [inlineSVG]="'assets/icons/arrow-down.svg'"></div>
                  </button>
                  <div class="dropdown-menu dropdown-small-gap w-100" aria-labelledby="select">
                    <ng-container *ngFor="let subPage of (secondMenuPage$ | async)?.subPages">
                      <li class="dropdown-item text-light" *ngIf="subPage?.active">
                        <a class="nav-link" [href]="subPage?.link">{{ subPage?.name }}</a>
                      </li>
                    </ng-container>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="(thirdMenuPage$ | async) && (thirdMenuPage$ | async)?.active">
                <div class="divider my-2 w-100 hide-desktop"></div>
                <li
                  class="d-flex align-items-center nav-item cme-label px-lg-2"
                  *ngIf="
                    getActiveLinksCount(thirdMenuPage$ | async) === 1 &&
                    getOnlyActiveLink(thirdMenuPage$ | async)[0]?.active
                  "
                >
                  <a
                    class="nav-link w-100"
                    [href]="getOnlyActiveLink(thirdMenuPage$ | async)[0]?.link"
                    draggable="false"
                  >
                    {{ (thirdMenuPage$ | async).menuPoint }}
                  </a>
                </li>
                <div class="dropdown secondary">
                  <button
                    type="button"
                    class="btn nav-link d-flex dropdown-toggle align-items-center justify-content-between w-100 h-100"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    *ngIf="
                      (thirdMenuPage$ | async)?.subPages?.length > 1 && getActiveLinksCount(thirdMenuPage$ | async) > 1
                    "
                  >
                    <span class="w-100 mr-2" draggable="false"> {{ (thirdMenuPage$ | async)?.menuPoint }} </span>
                    <div class="icon-16 arrow-indicator" [inlineSVG]="'assets/icons/arrow-down.svg'"></div>
                  </button>
                  <div class="dropdown-menu dropdown-small-gap w-100" aria-labelledby="select">
                    <ng-container *ngFor="let subPage of (thirdMenuPage$ | async)?.subPages">
                      <li class="dropdown-item text-light" *ngIf="subPage?.active">
                        <a class="nav-link" [href]="subPage?.link">{{ subPage?.name }}</a>
                      </li>
                    </ng-container>
                  </div>
                </div>
              </ng-container>

              <div class="divider my-2 w-100 hide-desktop"></div>
              <li class="d-flex align-items-center nav-item cme-label px-lg-2">
                <a class="nav-link w-100" [href]="medboardsUrl" draggable="false"> medboards </a>
              </li>

              <ng-container *ngIf="domain === 'de'">
                <div class="divider my-2 w-100 hide-desktop"></div>
                <li class="d-flex align-items-center nav-item cme-label px-lg-2">
                  <a
                    class="nav-link w-100"
                    data-toggle="collapse"
                    data-target=".navbar-collapse.show"
                    (click)="handleCMEGlobalPage()"
                    draggable="false"
                  >
                    {{ 'CME' | translate }}
                  </a>
                </li>
              </ng-container>

              <li class="d-flex align-items-center nav-item cme-label px-lg-2 hide-tablet">
                <a class="nav-link w-100" (click)="handleGoToSearchClick()" draggable="false">
                  <i class="bi-search"></i>
                </a>
              </li>

              <div class="divider my-2 w-100 hide-desktop"></div>
            </ul>

            <div class="slide-left congress-dropdown-list">
              <app-congress-dropdown-list-mobile></app-congress-dropdown-list-mobile>
            </div>
          </div>
        </ng-container>

        <ul
          class="navbar-nav ml-auto mx-0 mx-lg-2 hide-tablet align-items-baseline"
          *ngIf="(isLoggedIn$ | async) === false"
        >
          <li
            class="nav-item ml-lg-auto mx-2 login"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            [class.active]="(lastUrlSegment$ | async) === LOGIN_PATH"
          >
            <a
              class="nav-link"
              (click)="handleLoginClick()"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              draggable="false"
            >
              {{ 'Auth.SignIn' | translate }}
            </a>
          </li>
          <li
            class="nav-item ml-lg-auto register"
            data-toggle="collapse"
            data-target=".navbar-collapse.show"
            [class.active]="(lastUrlSegment$ | async) === SIGN_UP_PATH"
          >
            <a
              class="btn btn-danger pl-3 pr-3 ml-2 rounded"
              (click)="handleSignUpClick()"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              draggable="false"
            >
              {{ 'Auth.Register' | translate }}
            </a>
          </li>
        </ul>

        <div class="hide-desktop social-media">
          <p class="mb-1">Follow Us!</p>
          <div class="navbar-nav ml-auto mx-0 mx-lg-2 d-flex flex-row">
            <a [href]="twitterProfileUrl" target="_blank" draggable="false"
              ><div class="icon-24 icon-twitter" [inlineSVG]="'assets/icons/twitter.svg'"></div
            ></a>
            <a [href]="linkedInProfileUrl" target="_blank" draggable="false"
              ><div class="icon-24 icon-linkedin" [inlineSVG]="'assets/icons/linkedin.svg'"></div
            ></a>
          </div>
        </div>

        <div class="mt-4 text-left hide-desktop legal">
          <h6 class="mb-3 text-light">{{ 'Footer.Legal' | translate }}</h6>
          <div class="d-flex flex-column text-md-left text-center">
            <a
              class="text-note text-left mb-3"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              (click)="handleGoToImprintClick()"
              draggable="false"
              >{{ 'Footer.Copyright' | translate }}</a
            >
            <a
              class="text-note text-left mb-3"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              (click)="handleDataProtectionClick()"
              draggable="false"
              >{{ 'Footer.PrivacyPolicy' | translate }}</a
            >
            <a
              class="text-note text-left mb-3"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              (click)="handleDisclaimerClick()"
              draggable="false"
              >{{ 'Footer.Disclaimer' | translate }}</a
            >
          </div>
        </div>

        <ul class="navbar-nav mx-0" *ngIf="(isLoggedIn$ | async) === true">
          <li class="nav-item dropdown px-2">
            <a
              class="nav-link d-flex flex-row align-items-center w-100 px-2 hide-mobile"
              *ngIf="userAttributes$ | async as userAttributes"
              role="button"
              href="#"
              id="select"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              draggable="false"
            >
              <div class="profile rounded-circle p-2">
                <h6 class="m-0 text-white">
                  {{ userAttributes.given_name?.substr(0, 1) }}{{ userAttributes.name?.substr(0, 1) }}
                </h6>
              </div>
              <i class="bi bi-chevron-down pl-3 ml-auto"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <a
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                class="dropdown-item text-white"
                draggable="false"
                *ngIf="currentLanguage === 'ch'"
                (click)="handleMyCmeClick()"
                >{{ 'NavBar.MyCme' | translate }}</a
              >
              <a
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                class="dropdown-item text-white"
                draggable="false"
                (click)="handleEditUserClick()"
                >{{ 'NavBar.Account' | translate }}</a
              >
              <a
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                class="dropdown-item text-danger"
                draggable="false"
                (click)="handleLogoutClick()"
                >{{ 'NavBar.Logout' | translate }}</a
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <app-congress-dropdown class="hide-mobile hide-tablet"></app-congress-dropdown>
</nav>
