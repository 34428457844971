<div
  class="modal fade"
  id="missingUserDataModal"
  tabindex="-1"
  aria-labelledby="missingUserDataModalLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered rounded">
    <div class="modal-content bg-dark">
      <div class="modal-header p-4">
        <button type="button" class="close" data-dismiss="modal" (click)="handleCloseModal()" aria-label="Close">
          <i class="bi bi-x-lg text-white"></i>
        </button>
      </div>
      <div class="modal-body">
        <h4>{{ 'SignUp.FillMissingUserDataHeadline' | translate }}</h4>
        <p>{{ 'SignUp.FillMissingUserDataDescription' | translate }}</p>
        <p class="text-right text-note">*{{ 'SignUp.RequiredField' | translate }}</p>
        <form name="missing-user-data" [formGroup]="form" (ngSubmit)="handleSaveData()" #ngForm="ngForm" novalidate>
          <div class="form-group mt-2">
            <label class="d-block">{{ 'EditUser.AreaOfExpertise' | translate }}*</label>
            <app-dropdown
              class="d-block"
              [control]="form.controls.topic"
              [options]="topics"
              (selected)="handleTopicSelect($event)"
              [placeholder]="'EditUser.ChooseSubjectArea' | translate"
              [search]="true"
              [hideAll]="true"
              [searchPlaceholder]="'EditUser.SearchTopic' | translate"
              (searchChanged)="searchForTopics($event)"
            ></app-dropdown>
          </div>
          <div class="form-group row">
            <div class="col-12 dropup">
              <label>{{ 'EditUser.ActivityType' | translate }}*</label>
              <app-dropdown
                class="d-block"
                [control]="form.controls.occupationActivity"
                [options]="occupationActivities"
                (selected)="handleOccupationActivityChange($event)"
                [placeholder]="'EditUser.EnterActivityType' | translate"
                optionLabelPath="text"
                optionValuePath="key"
                [hideAll]="true"
                [searchPlaceholder]="'EditUser.ActivityType' | translate"
              ></app-dropdown>
            </div>
          </div>
          <div class="form-group row" *ngIf="currentLang === 'de'">
            <div class="col-12">
              <label>EFN</label>
              <input
                placeholder="{{ 'SignUp.EnterEFN' | translate }}"
                class="form-control form-control-lg"
                name="efn"
                formControlName="efn"
              />
            </div>
          </div>
          <button [disabled]="!form.valid" class="btn btn-danger btn-block mt-4" type="submit">
            <span> {{ 'EditUser.SaveMissingUserData' | translate }} </span>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
