import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  CongressLandingPage,
  CongressLandingPageVideoListItem,
  CongressMetadata,
  FeatureBlock,
  MediaProvider,
  SponsorLevel
} from 'medtoday-models-library/lib/models';
import { GoToEditUser } from 'projects/medtoday/src/app/auth-ui/actions/auth-ui-navigation.actions';
import { getCongressParticipantData } from 'projects/medtoday/src/app/medtoday-store/selectors/medtoday-store.selectors';
import { BehaviorSubject, interval } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { imagePaths } from '../../../../../medtoday/src/environments/environment';
import { getUserAttributes, isLoggedIn } from '../../../../core/auth/selectors/auth.selectors';
import {
  DataApiActionTypes,
  LoadCongressLangingPageData,
  LoadProgrammeData
} from '../../../../core/data/actions/data-api.actions';
import {
  getCongressLandingPageData,
  getCongressMetaData,
  getProgrammeData
} from '../../../../core/data/selectors/data-api.selectors';
import {
  GoToExperts,
  GoToFeaturedContent,
  GoToNewsletter,
  GoToOnDemandVideos,
  GoToProgramme
} from '../../../../core/router/actions/main-navigation.actions';
import { getCongressSlugRouterParam } from '../../../../core/router/selectors/router.selectors';
import { BaseAppState } from '../../../../core/store/reducers';
import { ObservableComponent } from '../../../components/observable/observable.component';
import { CarouselItem } from '../../../models/carousel-item.model';
import { ExpertsPipe } from '../../../pipes/experts.pipe';
import { DynamicScriptLoaderService } from '../../../services/dynamic-script-loader-service.service';
import { SESSION_UTILS_TOKEN } from '../../../utilities/session.utils';
import { hasSrolled } from '../../../utilities/window.utils';
import { CongressUtils } from 'projects/todaylib/shared/utilities/congress.utils';
import { TimeSlot } from 'medtoday-models-library/lib/models/timeslot.model';
import moment from 'moment';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent extends ObservableComponent implements OnInit {
  readonly loadDataAsyncKey = DataApiActionTypes.LoadCongressLandingPageData;
  readonly loadOnDemandVideoDataAsyncKey = DataApiActionTypes.LoadOnDemandVideo;

  congressSlug: string;
  mediaId: string | undefined;
  playerId: string | undefined;
  thumbnailsImageFolderSlug = imagePaths.thumbnails;
  logosImageFolderSlug = imagePaths.logos;
  avatarsImageFolderSlug = imagePaths.avatars;
  sponsorLevel = SponsorLevel;
  stateOfTheArtVideos: CarouselItem[] = [];

  isAnySessionLive = new BehaviorSubject<boolean>(false);
  isFeaturedLive = false;
  MediaProvider = MediaProvider;

  userAttributes$ = this.store.select(getUserAttributes);
  congressLandingPageData$ = this.store.select(getCongressLandingPageData);
  congressMetaData$ = this.store.select(getCongressMetaData);
  congressSlug$ = this.store.select(getCongressSlugRouterParam);
  congressParticipantData$ = this.store.select(getCongressParticipantData);
  programmeData$ = this.store.select(getProgrammeData);
  isLoggedIn$ = this.store.select(isLoggedIn);

  expertVideoVisible = false;

  displayLogoInCircle = true;

  constructor(
    @Inject('s3BucketUrl') public s3BucketUrl: string,
    @Inject('applicationName') public applicationName: string,
    private translationService: TranslateService,
    protected store: Store<BaseAppState>,
    protected dynamicScriptLoader: DynamicScriptLoaderService,
    private expertsPipe: ExpertsPipe,
    @Inject(SESSION_UTILS_TOKEN) public sessionUtils,
    public congressUtils: CongressUtils
  ) {
    super();
    this.sessionUtils.isActive({});
  }

  @HostListener('window:scroll', []) hasScrolled() {
    return hasSrolled();
  }

  ngOnInit() {
    this.observeCongressSlug();
    this.loadDataForCongress();
    this.createCarouselViewItems();
    this.checkLiveSession();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  get currentLang() {
    return this.translationService.getDefaultLang();
  }

  hasProgramme(congressMetaData: CongressMetadata) {
    return congressMetaData.menuOptions.includes('program');
  }

  hasOnDemand(congressMetaData: CongressMetadata) {
    return congressMetaData.menuOptions.includes('onDemand');
  }

  createCarouselViewItems() {
    this.congressLandingPageData$
      .pipe(
        distinctUntilChanged(),
        filter(congressData => !!congressData),
        tap((congressData: CongressLandingPage) => {
          if (congressData && congressData.congressLandingPageVideos?.length > 0) {
            this.stateOfTheArtVideos = congressData.congressLandingPageVideos.map(
              (vd: CongressLandingPageVideoListItem) => {
                const item: CarouselItem = {
                  id: vd.id,
                  thumbnail: vd.thumbnail,
                  label: '',
                  url: this.congressSlug,
                  isExternalUrl: undefined,
                  title: vd.title ? vd.title : vd.topicName,
                  subTitle: this.expertsPipe.transform(vd.experts),
                  viewsCount: undefined,
                  additionalData: {
                    mediaIdentifier: vd.mediaIdentifier,
                    mediaProvider: vd.mediaProvider
                  }
                };
                return item;
              }
            );
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  checkLiveSession() {
    interval(10000)
      .pipe(
        startWith(0),
        switchMap(() => this.programmeData$),
        withLatestFrom(this.congressMetaData$),
        filter(([programmeData, metaData]) => !!programmeData && !!metaData),
        takeUntil(this.ngDestroy$)
      )
      .subscribe(([programmeData, metaData]) => {
        if (metaData.mode === 'VoD') {
          const dateNow = moment(new Date());
          const start = moment(metaData.startDate);
          const end = moment(metaData.endDate);
          const isLive = dateNow.isBetween(start, end);
          this.isAnySessionLive.next(isLive);
        } else {
          const hasLiveSession = programmeData.timeSlots.some((row: TimeSlot) =>
            this.sessionUtils.isActive({
              ...row,
              sessions: programmeData.sessions.filter(s => s.timeSlotId === row.id)
            })
          );
          this.isAnySessionLive.next(hasLiveSession);
        }

        for (let i = 0; i < programmeData.sessions.length; i++) {
          if (programmeData.sessions[i].sponsors.length > 0) {
            const slot = programmeData.timeSlots.filter(
              (timeSlot: TimeSlot) => timeSlot.id === programmeData.sessions[i].timeSlotId
            );
            this.isFeaturedLive =
              metaData.menuOptions.includes('featuredContent') &&
              this.sessionUtils.isActive({
                ...slot,
                sessions: [programmeData.sessions[i]]
              });

            if (this.isFeaturedLive) {
              break;
            }
          }
        }
      });
  }

  hasAnySponsor() {
    let hasSponsors = false;
    this.congressLandingPageData$
      .pipe(
        filter((congressData: CongressLandingPage) => Boolean(congressData)),
        take(1),
        tap((congressData: CongressLandingPage) => {
          hasSponsors =
            congressData.sponsorsSection.firstLevelSponsors?.length > 0 ||
            congressData.sponsorsSection.secondLevelSponsors?.length > 0 ||
            congressData.sponsorsSection.thirdLevelSponsors?.length > 0 ||
            congressData.sponsorsSection.fourthLevelSponsors?.length > 0 ||
            congressData.sponsorsSection.associateSponsors?.length > 0;
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
    return hasSponsors;
  }

  setSelectedExpertVideoDetails(mediaId: string, playerId: string) {
    this.mediaId = mediaId;
    this.playerId = playerId;
    this.expertVideoVisible = true;
  }

  loadDataForCongress() {
    this.congressSlug$
      .pipe(
        filter((slug: string) => !!slug),
        takeUntil(this.ngDestroy$)
      )
      .subscribe((slug: string) => {
        this.store.dispatch(new LoadCongressLangingPageData(slug));
      });
  }

  observeCongressSlug() {
    this.congressSlug$
      .pipe(
        distinctUntilChanged(),
        filter((slug: string) => !!slug),
        takeUntil(this.ngDestroy$)
      )
      .subscribe((slug: string) => {
        this.congressSlug = slug;
        this.store.dispatch(new LoadProgrammeData(slug));
      });
  }

  handleFeatureLinkClick(featureBlock: FeatureBlock) {
    switch (featureBlock.url) {
      case 'Programm': {
        this.congressSlug$.pipe(take(1)).subscribe((slug: string) => {
          this.store.dispatch(new GoToProgramme(slug));
        });
        break;
      }
      case 'Experten': {
        this.handleGoToExpertsClick();
        break;
      }
      case 'Newsfeed': {
        if (this.applicationName === 'PatientsToday') {
          this.store.dispatch(new GoToNewsletter());
        } else {
          this.store.dispatch(new GoToEditUser({ scrollToNewsletter: true }));
        }
        break;
      }
      case 'OnDemand': {
        this.store.dispatch(new GoToOnDemandVideos(this.congressSlug));
        break;
      }
      case 'ExternalLink': {
        if (featureBlock.externalLink) {
          window.open(featureBlock.externalLink, '_blank')?.focus();
        }
      }
    }
  }

  calculateColumnsWidth(numberPerRow: number) {
    // calculate bootstrap gutters for entries per row
    let gutterWidth = 0;
    switch (numberPerRow) {
      case 1: {
        gutterWidth = 12;
        break;
      }
      case 2: {
        gutterWidth = 6;
        break;
      }
      case 3: {
        gutterWidth = 4;
        break;
      }
      case 4: {
        gutterWidth = 3;
        break;
      }
      case 6: {
        gutterWidth = 2;
        break;
      }
    }
    return gutterWidth;
  }

  handleCloseVideo() {
    this.expertVideoVisible = false;
    this.mediaId = undefined;
    this.playerId = undefined;
  }

  handleLiveButtonClick() {
    if (this.isFeaturedLive) {
      this.store.dispatch(new GoToFeaturedContent(this.congressSlug, true));
    } else {
      this.store.dispatch(new GoToProgramme(this.congressSlug, true));
    }
  }

  handleGoToExpertsClick() {
    this.store.dispatch(new GoToExperts(this.congressSlug));
  }

  handleGoToOnDemandVideosClick() {
    this.store.dispatch(new GoToOnDemandVideos(this.congressSlug));
  }

  handleGoToCongressBag(buttonUrl: string) {
    if (buttonUrl) {
      window.open(buttonUrl, '_blank');
      return;
    }
  }
}
