import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { NavBarService } from '../../services/nav-bar.service';

@Component({
  selector: 'app-congress-dropdown-list-item',
  templateUrl: './congress-dropdown-list-item.component.html',
  styleUrls: ['./congress-dropdown-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CongressDropdownListItemComponent {
  @Input() congressForTopic: any;
  @Input() mobileView = false;
  @Output() congressClick = new EventEmitter();

  constructor(@Inject('domain') public domain: string, public navBarService: NavBarService) {}

  handleCongressClick() {
    if (this.mobileView) {
      this.navBarService.showMobileTopicChildren(false);
    }
    this.navBarService.showTopicChildren(false);
    this.navBarService.handleGoToCongressClick(this.congressForTopic.congressSlug, this.congressForTopic.congressMode);
    this.congressClick.emit();
  }
}
