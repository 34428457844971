import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ObservableComponent } from '../observable/observable.component';
import { Timestamp } from '../../models/jw-timestamp.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PlayerInterface } from '../../models/player.interface';
import { map, take, withLatestFrom } from 'rxjs/operators';

@Component({ template: '' })
export abstract class TimestampsBaseComponent extends ObservableComponent {
  timestamps$: BehaviorSubject<Timestamp[]> = new BehaviorSubject<Timestamp[]>([]);

  @Output()
  selectedTimestamp = new EventEmitter<Timestamp>();

  activeTimestampIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  hasStar = false;
  @Input() showIndexColumn = false;

  @Input() hasTimestamps$: Subject<boolean>;

  @Input() player: PlayerInterface;

  selectCurrentTimestamp(time: number): Observable<any> {
    return this.timestamps$.pipe(
      withLatestFrom(this.activeTimestampIndex$),
      take(1),
      map(([timestamps, timestampIndex]) => {
        const activeTimestamp = timestamps.findIndex(
          (timestamp, index) =>
            timestamp.begin <= time && (!timestamps[index + 1] || timestamps[index + 1].begin > time)
        );

        if (activeTimestamp > -1 && activeTimestamp !== timestampIndex) {
          this.activeTimestampIndex$.next(activeTimestamp);
          return activeTimestamp;
        }

        return null;
      })
    );
  }

  handleTimestampSelected(timestamp: Timestamp) {
    this.selectedTimestamp.emit(timestamp);
  }
}
