import { Pipe, PipeTransform } from '@angular/core';
import { enumToArray } from '../utilities/object.utils';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {

  transform(value: {}): number[] {
    return enumToArray(value);
  }
}
