import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { SignUp, SignUpActionTypes } from 'projects/todaylib/core/auth/actions/auth-sign-up.actions';
import { BaseAppState } from 'projects/todaylib/core/store/reducers';
import { SignUpRequest } from 'projects/todaylib/shared/models/request/sign-up-request.model';

import { SignUpFormValue } from '../sign-up-form/sign-up-form-value.model';
import { SIGNUP_PROCESS } from '../sign-up-form/sign-up-form-definitions';
import { UpdateUserRequest } from '../../../../../../../todaylib/core/auth/services/auth.service';
import { EditUserProperties } from '../../../../../../../todaylib/core/auth/actions/auth.actions';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-up-page',
  templateUrl: './sign-up-page.component.html',
  styleUrls: ['./sign-up-page.component.scss']
})
export class SignUpPageComponent {
  readonly asyncProcessKey = SignUpActionTypes.SignUp;
  currentLanguage = this.translateService.currentLang;
  constructor(public store: Store<BaseAppState>, private translateService: TranslateService) {}

  handleSignUpClick(signUpResult: { formValue: SignUpFormValue; signupMode: SIGNUP_PROCESS }): void {
    if (signUpResult.signupMode === SIGNUP_PROCESS.FULL) {
      const request: SignUpRequest = {
        email: signUpResult.formValue.emailAddress,
        password: signUpResult.formValue.password,
        efnNumber: signUpResult.formValue.efnNumber,
        name: signUpResult.formValue.name,
        institution: signUpResult.formValue.institution,
        hasAcceptedPrivacyPolicy: signUpResult.formValue.hasAcknowledgedDataProtectionInformation,
        hasAcceptedNewsLetter: signUpResult.formValue.hasAcceptedNewsLetter,
        hasConfirmedAuthorizedPerson: true,
        lastName: signUpResult.formValue.lastName,
        salutation: signUpResult.formValue.salutation,
        title: signUpResult.formValue.title,
        occupationActivity: signUpResult.formValue.occupationActivity,
        sourceApp: signUpResult.formValue.sourceApp,
        topic: signUpResult.formValue.topic
      };
      this.store.dispatch(new SignUp(request));
    } else if (signUpResult.signupMode === SIGNUP_PROCESS.DETAILS) {
      const updateUserRequest: UpdateUserRequest = {
        city: '',
        phoneNumber: '',
        streetAddress: '',
        zipCode: '',
        currentUser: signUpResult.formValue.emailAddress,
        efnNumber: signUpResult.formValue.efnNumber,
        name: signUpResult.formValue.name,
        institution: signUpResult.formValue.institution,
        hasAcceptedPrivacyPolicy: signUpResult.formValue.hasAcknowledgedDataProtectionInformation,
        hasAcceptedNewsletter: signUpResult.formValue.hasAcceptedNewsLetter,
        lastName: signUpResult.formValue.lastName,
        salutation: signUpResult.formValue.salutation,
        title: signUpResult.formValue.title,
        occupationActivity: signUpResult.formValue.occupationActivity,
        sourceApp: signUpResult.formValue.sourceApp,
        topic: signUpResult.formValue.topic
      };
      this.store.dispatch(new EditUserProperties(updateUserRequest, true));
    }
  }
}
