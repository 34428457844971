<div id="row" class="my-md-2 justify-content-center justify-content-md-start px-2 px-md-0 pb-2 pb-md-0">
  <div class="dropdown secondary w-100" [class.patients]="applicationName === 'PatientsToday'">
    <button
      type="button"
      class="btn d-flex dropdown-toggle navigation-menu align-items-center justify-content-between w-100 h-100 text-light rounded"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <p class="m-0">
        <ng-container *ngIf="selectedOption">
          {{ optionLabel(selectedOption) | translate }}
        </ng-container>
        <ng-container *ngIf="!selectedOption">
          {{ placeholder | translate }}
        </ng-container>
      </p>
      <i class="bi bi-chevron-down"></i>
    </button>
    <div
      class="dropdown-menu custom-scrollbar w-100"
      [class.bg-dark]="applicationName === 'MedToday'"
      aria-labelledby="select"
    >
      <a
        class="dropdown-item d-flex flex-row"
        *ngIf="!hideAll"
        [ngClass]="!selectedOption ? 'text-danger' : applicationName === 'MedToday' ? 'text-light' : 'text-dark'"
        (click)="handleReset()"
      >
        {{ 'Common.All' | translate }}
      </a>

      <!--   SEARCH   -->
      <div *ngIf="search" class="px-3 search mb-2">
        <i class="bi bi-search"></i>
        <input
          type="text"
          class="form-control form-control-lg border-0"
          name="search"
          [placeholder]="searchPlaceholder"
          [formControl]="searchControl"
        />
      </div>

      <ng-container *ngFor="let option of options; let i = index">
        <a
          class="dropdown-item d-flex flex-row flex-wrap parent align-items-center"
          [ngClass]="
            (selectedOption?.title ? selectedOption.title === option?.title : (selectedOption === option)) ? 'text-danger' : applicationName === 'MedToday' ? 'text-light' : 'text-dark'
          "
          (click)="handleSelectOption(option)"
          >

          <ng-container *ngIf="firstLevelIndicator">
            <span
              class="circle indicator"
              [appTopicCategoryColor]="option[firstLevelIndicator]"
              [inverseColors]="true"
            >
            </span>
          </ng-container>
          {{ optionLabel(option) | translate }}
        </a>
        <ng-container *ngIf="multiLevel && option.subTopics?.length">
          <a
            class="dropdown-item d-flex flex-column child"
            *ngFor="let subOption of option.subTopics"
            [ngClass]="
              selectedOption === subOption ? 'text-danger' : applicationName === 'MedToday' ? 'text-light' : 'text-dark'
            "
            (click)="handleSelectOption(subOption)"
          >
            {{ optionLabel(subOption) | translate }}
          </a>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
