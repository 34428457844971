import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ObservableComponent } from '../../../../components/observable/observable.component';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { BaseAppState } from '../../../../../core/store/reducers';
import { Store } from '@ngrx/store';
import { LoadSearchResults, PurgeData, SearchActionTypes } from '../../actions/search.actions';
import { SearchSelectors } from '../../selectors/search.selectors';
import { Observable } from 'rxjs';
import { CrossPlatformSearchVideoListItem } from 'medtoday-models-library/src/models/responses/on-demand-video/cross-platform-search-video-list-item.model';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchPageComponent extends ObservableComponent implements OnInit, OnDestroy {
  readonly asyncKey = SearchActionTypes.LoadSearchResults;
  searchControl = new FormControl(null, [Validators.required, Validators.minLength(2)]);
  results$: Observable<CrossPlatformSearchVideoListItem[] | undefined> = this.store.select(
    SearchSelectors.selectSearchResults
  );
  hasNextPage$: Observable<boolean> = this.store.select(SearchSelectors.selectHasNextPage);

  constructor(private store: Store<BaseAppState>, @Inject('applicationName') public applicationName: string) {
    super();
  }

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.ngDestroy$),
        distinctUntilChanged(),
        debounceTime(400),
        filter(() => this.searchControl.valid)
      )
      .subscribe(searchTerm => {
        this.store.dispatch(new LoadSearchResults(searchTerm!, true));
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(new PurgeData());
  }

  handleGoToVideoClick(video) {
    window.location.href = video.link;
  }

  handleLoadMoreResults() {
    if (this.searchControl.value) {
      this.store.dispatch(new LoadSearchResults(this.searchControl.value, false));
    }
  }
}
