import { Routes } from '@angular/router';
import { LoggedInGuard } from 'projects/todaylib/core/auth/guards/logged-in-guard.service';
import { LoggedOutGuard } from 'projects/todaylib/core/auth/guards/logged-out-guard.service';
import { EditPasswordPageComponent } from './components/edit-password/edit-password-page/edit-password-page.component';
import { EditUserPageComponent } from './components/edit-user-page/edit-user-page.component';

import { LoginPageComponent } from './components/login/login-page/login-page.component';
import { RequestPasswordResetComponent } from './components/request-password-reset/request-password-reset.component';
import { ResetPasswordPageComponent } from './components/reset-password/reset-password.component';
import { SignUpConfirmPageComponent } from './components/sign-up-confirm-page/sign-up-confirm-page.component';
import { SignUpSuccessfulPageComponent } from './components/sign-up-successful-page/sign-up-successful-page.component';
import { SignUpPageComponent } from './components/sign-up/sign-up-page/sign-up-page.component';

import { AUTH_UI_PATH_ELEMENTS } from './definitions/auth-ui-navigation.definitions';

export const routes: Routes = [
  {
    path: AUTH_UI_PATH_ELEMENTS.rootElement,
    data: { hideHeader: true },
    children: [
      {
        path: AUTH_UI_PATH_ELEMENTS.root.authElement,
        component: LoginPageComponent
      },
      {
        path: AUTH_UI_PATH_ELEMENTS.root.loginElement,
        component: LoginPageComponent,
        canActivate: [LoggedOutGuard]
      },
      {
        path: AUTH_UI_PATH_ELEMENTS.root.signUpElement,
        component: SignUpPageComponent,
        canActivate: [LoggedOutGuard]
      },
      {
        path: AUTH_UI_PATH_ELEMENTS.root.signUpSuccessElement,
        component: SignUpSuccessfulPageComponent,
        canActivate: [LoggedOutGuard]
      },
      {
        path: AUTH_UI_PATH_ELEMENTS.root.signUpConfirmElement,
        component: SignUpConfirmPageComponent
      },
      {
        path: `${AUTH_UI_PATH_ELEMENTS.root.passwordResetElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordReset.requestElement}`,
        component: RequestPasswordResetComponent,
        canActivate: [LoggedOutGuard]
      },
      {
        path: `${AUTH_UI_PATH_ELEMENTS.root.passwordResetElement}/${AUTH_UI_PATH_ELEMENTS.root.passwordReset.resetElement}`,
        component: ResetPasswordPageComponent
      },
      {
        path: AUTH_UI_PATH_ELEMENTS.root.editPasswordElement,
        component: EditPasswordPageComponent
      },
      {
        path: AUTH_UI_PATH_ELEMENTS.root.editUserElement,
        component: EditUserPageComponent,
        canActivate: [LoggedInGuard]
      }
    ]
  }
];
