import { CmeTrackingTrigger } from '../../../models/cme-tracking-trigger.model';
import { combineLatest, interval } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { CongressMetadata } from 'medtoday-models-library/lib/models';
import { ToggleCME } from '../../../../../../todaylib/core/layout/nav-bar/actions/nav-bar.actions';
import { getCMEActiveState } from '../../../../../../todaylib/core/auth/selectors/auth.selectors';
import { getCongressMetaData } from '../../../../../../todaylib/core/data/selectors/data-api.selectors';
import { Injectable, Injector } from '@angular/core';
import { CmeTrackingTriggerStrategy } from '../cme-tracking-trigger-strategy';
import { Store } from '@ngrx/store';

@Injectable()
export class AutoCmeTrigger extends CmeTrackingTriggerStrategy implements CmeTrackingTrigger {
  cmeActiveState$ = this.store.select(getCMEActiveState);
  congressMetaData$ = this.store.select(getCongressMetaData);

  constructor(store: Store, injector: Injector) {
    super(store, injector);
  }

  triggerCmeStateIfNeeded(): any {
    combineLatest([interval(5000), this.congressMetaData$, this.cmeActiveState$])
      .pipe(
        filter(([_interval, data, _cmeActive]: [number, CongressMetadata, boolean]) => Boolean(data)),
        tap(([_interval, data, cmeActive]: [number, CongressMetadata, boolean]) => {
          if (data.cmeData?.days?.length > 0) {
            const hasActiveCmeTimeframe = this.hasActiveCmeDay(data.cmeData);

            // if cme can be activated, enable the control
            if (hasActiveCmeTimeframe) {
              // TODO hasEfn disabled for the upcoming event
              if (!cmeActive) {
                this.store.dispatch(new ToggleCME());
              }
              // }
            } else {
              if (cmeActive) {
                this.store.dispatch(new ToggleCME());
              }
            }
          }
        })
      )
      .subscribe();
  }
}
