<div class="container">
  <div class="header mt-5 mt-lg-2">
    <div class="header-title">
      <h1>{{ 'Search.HeaderTitle' | translate }}</h1>
    </div>

    <div class="header-search mt-3 mt-lg-5">
      <div class="form-group position-relative row">
        <div class="col-12 col-lg-6">
          <i [inlineSVG]="'assets/icons/magnify.svg'"></i>
          <input
            placeholder="{{ 'Search.InputPlaceholder' | translate }}"
            class="form-control form-control-lg"
            [class.patients-form]="applicationName === 'PatientsToday'"
            name="title"
            [formControl]="searchControl"
          />
        </div>
        <!-- <img *ifApp="'PatientsToday'" class="bg-image search-image hide-mobile" src="/assets/images/search.png" /> -->
      </div>
    </div>
  </div>

  <div
    class="results-container mt-4"
    [class.top-gap]="applicationName === 'PatientsToday'"
    *ngIf="results$ | async as results"
  >
    <ng-container *ngIf="results?.length">
      <div class="section-header">
        <h4>{{ 'Search.VodHeader' | translate }}</h4>
        <div class="divider full-width-divider hide-tablet"></div>
      </div>
      <div class="row results d-flex flex-wrap">
        <div class="col-12 col-lg-6 my-4 video" *ngFor="let video of results">
          <video-list-item
            [video]="video"
            [topicTitle]="video.topicName"
            [formatTitle]="video.formatName"
            (goToVideo)="handleGoToVideoClick(video)"
          ></video-list-item>
        </div>
      </div>
    </ng-container>

    <div class="p-4 text-center w-100" *ngIf="searchControl.dirty && !results.length">
      <h5>{{ 'Search.NoResults' | translate }}</h5>
    </div>

    <!-- Load more videos button -->
    <div class="row btn-row flex-row align-items-center justify-content-center my-3" *ngIf="hasNextPage$ | async">
      <div class="divider w-100 mb-2 mx-3"></div>
      <button
        type="button"
        class="btn ghost-button-light rounded-pill w-sm-100 more-btn"
        (click)="handleLoadMoreResults()"
      >
        {{ 'Search.MoreLabel' | translate }}
      </button>
    </div>
  </div>
</div>
<app-spacer [class.d-flex]="!(results$ | async)?.length"></app-spacer>
<app-async-state-overlay [processKey]="asyncKey"></app-async-state-overlay>
