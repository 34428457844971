import { Pipe, PipeTransform } from '@angular/core';
import { UserSalutation } from '../enums/user-salutation.enum';

@Pipe({
  name: 'userSalutation'
})
export class UserSalutationPipe implements PipeTransform {
  transform(type: UserSalutation): string {
    switch (type) {
      case UserSalutation.herr:
        return 'Herr';
      case UserSalutation.frau:
        return 'Frau';
      case UserSalutation.divers:
        return 'Divers';

      default:
        return '';
    }
  }
}
