import { HttpErrorResponse } from '@angular/common/http';
import { ApiResponse } from '../../../shared/models';
import { of, pipe, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AsyncAction } from '../models/async-action.model';

export function handleErrors(createAction: () => AsyncAction) {
  const errorAction = createAction();
  return pipe(
    // tslint:disable-next-line:no-any
    switchMap((response: ApiResponse<any>) => {
      if (!response.success) {
        errorAction.asyncErrorTitle = response.error ? response.error.code?.toString() : undefined;
        errorAction.asyncErrorMessage = response.error ? response.error.message : undefined;
        errorAction.asyncValidationErrors = response.validationErrors;
        return throwError(errorAction);
      } else {
        return of(response);
      }
    }),
    catchError((response: HttpErrorResponse) => {
      errorAction.asyncHttpError = response;
      errorAction.asyncValidationErrors = response.error ? response.error.validationErrors : undefined;
      return throwError(errorAction);
    }),
  );
}

export function handleAsyncErrors(createAction: () => AsyncAction) {
  const errorAction = createAction();
  return pipe(
    // tslint:disable-next-line: no-any
    switchMap((response: any) => {
      if (!response) {
        errorAction.asyncErrorTitle = response.error ? response.error.code?.toString() : undefined;
        errorAction.asyncErrorMessage = response.error ? response.error.message : undefined;
        errorAction.asyncValidationErrors = response.validationErrors;
        return throwError(errorAction);
      }
      return of(response);
    }),
    catchError((response: HttpErrorResponse) => {
      errorAction.asyncHttpError = response;
      errorAction.asyncValidationErrors = response.error ? response.error.validationErrors : undefined;
      return throwError(errorAction);
    }),
  );
}
