import { AsyncAction } from 'projects/todaylib/core/async-state/models/async-action.model';

export enum FileDownloadActionTypes {
  GetMediaLibraryItem = '[Media Library] Get Media Library Item',
  GetMediaLibraryItemSuccess = '[Media Library] Get Media Library Item Success',
  GetMediaLibraryItemFail = '[Media Library] Get Media Library Item Fail'
}

export class GetMediaLibraryItem implements AsyncAction {
  readonly asyncKey = FileDownloadActionTypes.GetMediaLibraryItem;
  readonly type = FileDownloadActionTypes.GetMediaLibraryItem;
  readonly asyncState = 'start';

  constructor(public slug: string) {}
}

export class GetMediaLibraryItemSuccess implements AsyncAction {
  readonly asyncKey = FileDownloadActionTypes.GetMediaLibraryItem;
  readonly type = FileDownloadActionTypes.GetMediaLibraryItemSuccess;
  readonly asyncState = 'success';

  constructor(public data: any) {}
}

export class GetMediaLibraryItemFail implements AsyncAction {
  readonly asyncKey = FileDownloadActionTypes.GetMediaLibraryItem;
  readonly type = FileDownloadActionTypes.GetMediaLibraryItemFail;
  readonly asyncState = 'fail';
}
