import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
  Channel,
  CongressLandingPage,
  CongressListItem,
  ExpertListItem,
  ExpertsResponse,
  OnDemandVideoListResponse,
  OnDemandVideoDetail,
  Programme,
  FeaturedContentResponse,
  CongressMetadata,
  ArchivedCongressListItem,
  LandingPageVideoListItem,
  DeeplinkModel,
  EventListItem,
  PremiumBox,
  PageItem,
  InternationalContent,
  UpcomingEventsResponseModel,
  UpcomingEventsTopicLanguagesResponseModel,
  UpcomingEventsDatesResponseModel,
  UpcomingEventsCountriesResponseModel,
  CongressesTopicModel,
  CongressNavBarItem,
  Page
} from 'medtoday-models-library/lib/models';

import { AppDataState } from '../reducers/app-data.reducer';
import { memoize } from 'lodash';
import { UpcomingEventListItemModel } from 'medtoday-models-library';

export const getAppDataRootState = createFeatureSelector<AppDataState>('data');

export const getCongressesList: MemoizedSelector<object, CongressListItem[]> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.congresses!
);

export const getCongressPage: MemoizedSelector<object, Page[]> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.congressPage!
);

export const getFirstMenuPage: MemoizedSelector<object, Page | undefined> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.congressPage?.find((page: Page) => page.name === 'Menüpunkt 1')
);

export const getSecondMenuPage: MemoizedSelector<object, Page | undefined> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.congressPage?.find((page: Page) => page.name === 'Menüpunkt 2')
);

export const getThirdMenuPage: MemoizedSelector<object, Page | undefined> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.congressPage?.find((page: Page) => page.name === 'Menüpunkt 3')
);

export const getArchivedCongressesList: MemoizedSelector<object, ArchivedCongressListItem[]> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.archivedCongresses!
);

export const getMostWatchedVideosList: MemoizedSelector<object, LandingPageVideoListItem[]> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.mostWatchedVideos!
);

export const getCongressListUpcomingEvents: MemoizedSelector<object, EventListItem[] | undefined> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.congressListUpcomingEvents
);

export const getCongressListPremiumBox: MemoizedSelector<object, PremiumBox | undefined> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.congressListPremiumBox
);

export const getCongressListPageItems: MemoizedSelector<object, PageItem[] | undefined> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.congressListPageItems
);

export const getCongressListInertnationalContent: MemoizedSelector<object, InternationalContent[] | undefined> =
  createSelector(getAppDataRootState, (state: AppDataState) => state?.congressListIntertnationalContent);

export const getCongressLandingPageData: MemoizedSelector<object, CongressLandingPage> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.congressLandingPageData!
);

export const getProgrammeData: MemoizedSelector<object, Programme> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.programmeData.data!
);

export const getProgrammeDataNavigatedFromLandingPage: MemoizedSelector<object, boolean | undefined> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.programmeData.navigatedFromLandingPage
);

export const getExpertsData: MemoizedSelector<object, ExpertsResponse | undefined> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.expertsData!.data
);

export const getExpertsDataByType = memoize((expertType: string) =>
  createSelector(getExpertsData, (expertsData: ExpertsResponse) => {
    const expertsFiltered = expertsData?.experts.filter(expert => expert.expertType === expertType);
    return {
      experts: expertsFiltered,
      topics: expertsData?.topics.filter(topic => expertsFiltered?.some(expert => expert.topicIds.includes(topic.id)))
    };
  })
);

export const getHasExpertsWithAtLeastOneTopic: MemoizedSelector<object, boolean | undefined> = createSelector(
  getExpertsData,
  (state: ExpertsResponse) => state?.experts?.some((expert: ExpertListItem) => expert.topicIds.length > 0)
);

export const getExpertsWithoutTopic: MemoizedSelector<object, ExpertListItem[] | undefined> = createSelector(
  getExpertsData,
  (state: ExpertsResponse) => state?.experts?.filter((expert: ExpertListItem) => expert.topicIds.length === 0)
);

export const getSelectedExpert: MemoizedSelector<object, ExpertListItem | undefined> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.expertsData!.selectedExpert
);

export const getSelectedUpcomingEvent: MemoizedSelector<object, UpcomingEventListItemModel | undefined> =
  createSelector(getAppDataRootState, (state: AppDataState) => state?.selectedUpcomingEvent);

export const getCongressMetaData: MemoizedSelector<object, CongressMetadata> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.congressMetaData!
);

export const getIsCongressMenuEmpty: MemoizedSelector<object, boolean> = createSelector(
  getCongressMetaData,
  (congressMetadata: CongressMetadata) => !congressMetadata?.menuOptions || congressMetadata.menuOptions.length === 0
);

export const getChannelData: MemoizedSelector<object, Channel> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.channelData!
);

export const getOnDemandVideosData: MemoizedSelector<object, OnDemandVideoListResponse> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.onDemandVideosData.data!
);

export const getOnDemandVideoTopicIdFilter: MemoizedSelector<object, number | undefined> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.onDemandVideosData.topicIdToFilter
);

export const getSelectedOnDemandVideo: MemoizedSelector<object, OnDemandVideoDetail> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.onDemandVideo!
);

export const getDeeplinkProperties: MemoizedSelector<object, DeeplinkModel> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.deeplink!
);

export const getFeaturedContentOverviewData: MemoizedSelector<object, FeaturedContentResponse> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.featuredContentOverview!
);

export const getUpcomingEventsState = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state && state?.upcomingEventsPageData
);

export const getUpcomingEventsData: MemoizedSelector<object, UpcomingEventsResponseModel> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.upcomingEventsPageData.data!
);

export const getUpcomingEventsTopicCategories: MemoizedSelector<object, UpcomingEventsTopicLanguagesResponseModel> =
  createSelector(getAppDataRootState, (state: AppDataState) => state?.upcomingEventsPageData.topicCategories!);

export const getUpcomingEventsDates: MemoizedSelector<object, UpcomingEventsDatesResponseModel> = createSelector(
  getAppDataRootState,
  (state: AppDataState) => state?.upcomingEventsPageData.dates!
);

export const getUpcomingEventsCountries: MemoizedSelector<object, UpcomingEventsCountriesResponseModel> =
  createSelector(getAppDataRootState, (state: AppDataState) => state?.upcomingEventsPageData.countries!);

export const getCongressTopics: MemoizedSelector<object, CongressesTopicModel[] | undefined> = createSelector(
  getAppDataRootState,
  (state: AppDataState) =>
    state && state?.congressTopics
      ? [...state.congressTopics].sort((a, b) => a.topicCategoryName.localeCompare(b.topicCategoryName))
      : []
);

export const getSpecialEventCongresses: MemoizedSelector<object, CongressNavBarItem[] | undefined> = createSelector(
  getUpcomingEventsData,
  events =>
    events &&
    events.events
      .filter(event => event.congressSeries === 'specialEvents')
      .map(event => {
        return {
          congressName: event.title,
          congressSlug: event.congressSlug,
          congressMode: event.congressMode,
          congressLogo: event.thumbnail
        };
      })
);
