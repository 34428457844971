import { MedicalEducationResponse, OnDemandVideoSearchResultResponse } from 'medtoday-models-library/lib/models';
import { AsyncAction } from '../../../../../todaylib/core/async-state/models/async-action.model';

export enum MedicalEducationApiActionTypes {
  LoadMedicalEducationData = '[Data] Load Medical Education Data',
  LoadMedicalEducationDataSuccess = '[Data] Load Medical Education Data Success',
  LoadMedicalEducationDataFail = '[Data] Load Medical Education Data Fail',

  LoadFilteredOnDemandVideos = '[Data] Load Filtered On Demand Videos',
  LoadFilteredOnDemandVideosSuccess = '[Data] Load Filtered On Demand Videos Success',
  LoadFilteredOnDemandVideosFail = '[Data] Load Filtered On Demand Videos Fail'
}

export class LoadMedicalEducationData implements AsyncAction {
  readonly asyncKey = MedicalEducationApiActionTypes.LoadMedicalEducationData;
  readonly asyncState = 'start';
  readonly type = MedicalEducationApiActionTypes.LoadMedicalEducationData;
}

export class LoadMedicalEducationDataSuccess implements AsyncAction {
  readonly asyncKey = MedicalEducationApiActionTypes.LoadMedicalEducationData;
  readonly type = MedicalEducationApiActionTypes.LoadMedicalEducationDataSuccess;
  readonly asyncState = 'success';

  constructor(public response: MedicalEducationResponse) {}
}

export class LoadMedicalEducationDataFail implements AsyncAction {
  readonly asyncKey = MedicalEducationApiActionTypes.LoadMedicalEducationData;
  readonly type = MedicalEducationApiActionTypes.LoadMedicalEducationDataFail;
  readonly asyncState = 'fail';
}

export class LoadFilteredOnDemandVideos implements AsyncAction {
  readonly asyncKey = MedicalEducationApiActionTypes.LoadFilteredOnDemandVideos;
  readonly type = MedicalEducationApiActionTypes.LoadFilteredOnDemandVideos;
  readonly asyncState = 'start';

  constructor(
    public page: number | null,
    public topicCategoryId: number,
    public topicId: number,
    public congressMode: string,
    public formatId: number,
    public isSubsequentVideosRequest?: boolean
  ) {}
}

export class LoadFilteredOnDemandVideosSuccess implements AsyncAction {
  readonly asyncKey = MedicalEducationApiActionTypes.LoadFilteredOnDemandVideos;
  readonly type = MedicalEducationApiActionTypes.LoadFilteredOnDemandVideosSuccess;
  readonly asyncState = 'success';

  constructor(public response: OnDemandVideoSearchResultResponse, public isSubsequentVideosRequest?: boolean) {}
}

export class LoadFilteredOnDemandVideosFail implements AsyncAction {
  readonly asyncKey = MedicalEducationApiActionTypes.LoadFilteredOnDemandVideos;
  readonly type = MedicalEducationApiActionTypes.LoadFilteredOnDemandVideosFail;
  readonly asyncState = 'fail';
}

export type MedicalEducationApiActions =
  | LoadFilteredOnDemandVideos
  | LoadMedicalEducationDataSuccess
  | LoadFilteredOnDemandVideosSuccess;
